export default {
	"militia_0": "The local militia was called upon to assist in the national emergency.",
	"militia_1": "Members of the militia trained every weekend to hone their skills.",
	"militia_2": "The history book detailed the role of the militia in the revolutionary war.",
	"militia_3": "The militia, comprised of local volunteers, played a key role in the town's defense.",
	"militia_4": "The government provided basic equipment to the newly formed militia.",
	"devastating_0": "The devastating hurricane left the city in ruins.",
	"devastating_1": "His criticism was devastating, leaving her feeling completely demoralized.",
	"devastating_2": "The news of the accident had a devastating effect on the community.",
	"devastating_3": "They suffered a devastating loss in the championship game.",
	"devastating_4": "The documentary highlighted the devastating impact of deforestation.",
	"cue_0": "The actor missed his cue and forgot his lines.",
	"cue_1": "She took a deep breath as a cue to start the presentation.",
	"cue_2": "The lights dimming was our cue to enter the stage.",
	"cue_3": "He gave me a subtle cue to stop talking.",
	"cue_4": "The arrival of the guests was my cue to start dinner.",
	"empirical_0": "The research was based on empirical evidence collected over five years.",
	"empirical_1": "Empirical data supports the theory that the treatment is effective.",
	"empirical_2": "Her approach was always empirical, relying on observation and experience.",
	"empirical_3": "The study's empirical findings challenged existing beliefs.",
	"empirical_4": "Empirical research is crucial in the field of medicine.",
	"prep_0": "I need to prep the vegetables for the soup.",
	"prep_1": "She spent the whole night prepping for her final exam.",
	"prep_2": "The team was busy prepping the equipment for the big launch.",
	"prep_3": "Meal prep on Sundays helps me eat healthily during the week.",
	"prep_4": "The lab assistant prepped the samples for testing.",
	"skillet_0": "He cooked the eggs in a cast-iron skillet for extra flavor.",
	"skillet_1": "She learned to flip pancakes perfectly using her favorite skillet.",
	"skillet_2": "Heat the oil in a skillet before adding the onions.",
	"skillet_3": "The recipe calls for searing the chicken in a hot skillet.",
	"skillet_4": "I bought a non-stick skillet to make cleanup easier.",
	"abs_0": "He exercises daily to maintain his strong abs.",
	"abs_1": "Pilates workouts are great for toning your abs.",
	"abs_2": "She could feel her abs burning after the intense workout.",
	"abs_3": "The actor showed off his chiseled abs in the movie.",
	"abs_4": "Strengthening your abs can help improve your posture.",
	"clove_0": "Add a clove of garlic to enhance the sauce's flavor.",
	"clove_1": "She pressed the clove into the orange as part of the holiday decoration.",
	"clove_2": "A single clove can add a lot of taste to a dish.",
	"clove_3": "The recipe required one clove of garlic, minced.",
	"clove_4": "He used a clove to spice up the mulled wine.",
	"broth_0": "She simmered the bones for hours to make a rich broth.",
	"broth_1": "The chicken broth was the base for the delicious soup.",
	"broth_2": "He seasoned the vegetable broth with herbs and spices.",
	"broth_3": "The chef strained the broth to remove any solids.",
	"broth_4": "I prefer using low-sodium broth in my cooking.",
	"calcium_0": "Dairy products are a good source of calcium.",
	"calcium_1": "She takes calcium supplements to strengthen her bones.",
	"calcium_2": "The doctor advised him to increase his calcium intake for better bone health.",
	"calcium_3": "Spinach is not only rich in iron but also in calcium.",
	"calcium_4": "Calcium is essential for healthy teeth and bones.",
	"whisk_0": "Whisk the eggs until they are frothy and light.",
	"whisk_1": "She used a whisk to mix the salad dressing thoroughly.",
	"whisk_2": "Add the sugar and whisk it into the cream.",
	"whisk_3": "He quickly whisked the flour into the gravy to avoid lumps.",
	"whisk_4": "The chef demonstrated how to whisk the batter properly.",
	"simmer_0": "Let the sauce simmer on low heat for an hour.",
	"simmer_1": "The soup should simmer until the vegetables are tender.",
	"simmer_2": "She watched the pot, making sure the stew didn't simmer over.",
	"simmer_3": "Simmer the broth gently to infuse the flavors.",
	"simmer_4": "He learned to simmer the chili slowly to bring out its spices.",
	"finely_0": "Finely chop the onions before adding them to the skillet.",
	"finely_1": "She finely grated the cheese for the pasta topping.",
	"finely_2": "The recipe calls for finely sliced carrots.",
	"finely_3": "Finely ground coffee is essential for an espresso.",
	"finely_4": "He finely minced the garlic for the marinade.",
	"nebula_0": "The Hubble Telescope captured stunning images of the Orion Nebula.",
	"nebula_1": "Astronomers study the formation of stars in a nebula.",
	"nebula_2": "The nebula's bright colors are due to ionized gases.",
	"nebula_3": "She wrote a paper on the chemical composition of a nebula.",
	"nebula_4": "The Crab Nebula is a remnant of a supernova explosion.",
	"observatory_0": "The observatory offers a clear view of the night sky.",
	"observatory_1": "They visited the observatory to see the lunar eclipse.",
	"observatory_2": "The new observatory is equipped with advanced telescopes.",
	"observatory_3": "School children went on a field trip to the local observatory.",
	"observatory_4": "The observatory is renowned for its research in astrophysics.",
	"modem_0": "We need to reset the modem to fix the internet connection.",
	"modem_1": "The old modem was replaced with a high-speed one.",
	"modem_2": "His computer is connected directly to the modem.",
	"modem_3": "The modem's lights indicate it's transmitting data.",
	"modem_4": "A good modem is essential for reliable home internet.",
	"diameter_0": "The diameter of the circle was measured at 10 centimeters.",
	"diameter_1": "The tree had a diameter of two feet at its base.",
	"diameter_2": "They calculated the diameter of the pipe for the construction project.",
	"diameter_3": "The pizza was impressive with its twenty-inch diameter.",
	"diameter_4": "He used a ruler to estimate the diameter of the hole.",
	"evenly_0": "Spread the batter evenly in the pan.",
	"evenly_1": "The teacher distributed the worksheets evenly among the students.",
	"evenly_2": "He painted the wall evenly without any streaks.",
	"evenly_3": "The lights were spaced evenly along the hallway.",
	"evenly_4": "The ingredients should be mixed evenly for the best results.",
	"parsley_0": "Garnish the dish with a sprinkle of fresh parsley.",
	"parsley_1": "She chopped some parsley to add to the salad.",
	"parsley_2": "The recipe calls for a tablespoon of dried parsley.",
	"parsley_3": "He added parsley for a fresh flavor in the soup.",
	"parsley_4": "Parsley is often used as a garnish in many cuisines.",
	"desktop_0": "I organized the files on my desktop for easy access.",
	"desktop_1": "She prefers a desktop computer for her design work.",
	"desktop_2": "The new software was installed on all the office desktops.",
	"desktop_3": "He cleaned his desktop before starting the project.",
	"desktop_4": "The desktop wallpaper was a calming picture of the beach.",
	"pesticide_0": "Farmers use pesticide to protect crops from insects.",
	"pesticide_1": "The use of organic pesticides is becoming more popular.",
	"pesticide_2": "She read the label to check for any harmful pesticides.",
	"pesticide_3": "The government regulated the use of pesticides in agriculture.",
	"pesticide_4": "He attended a seminar on the environmental effects of pesticides.",
	"accessory_0": "A scarf is a perfect accessory for her outfit.",
	"accessory_1": "He bought a camera accessory to enhance his photography.",
	"accessory_2": "This handbag is more than just an accessory; it's a necessity.",
	"accessory_3": "Sunglasses are a must-have summer accessory.",
	"accessory_4": "She has an impressive collection of fashion accessories.",
	"vintage_0": "She loves collecting vintage dresses from the 1950s.",
	"vintage_1": "The wine enthusiast appreciated the vintage bottle from 1982.",
	"vintage_2": "They restored a vintage car to its former glory.",
	"vintage_3": "The shop specializes in vintage vinyl records.",
	"vintage_4": "He wore a vintage watch that was a family heirloom.",
	"lodge_0": "They stayed at a mountain lodge during their ski trip.",
	"lodge_1": "The hunting lodge was deep in the forest.",
	"lodge_2": "She booked a room in a quaint lodge by the lake.",
	"lodge_3": "The lodge offered a cozy retreat in the wilderness.",
	"lodge_4": "Members of the lodge gathered for their annual meeting.",
	"basil_0": "Fresh basil adds a wonderful aroma to the pasta sauce.",
	"basil_1": "She grows basil in her garden for cooking.",
	"basil_2": "The recipe calls for a handful of chopped basil leaves.",
	"basil_3": "He made a pesto sauce using basil and pine nuts.",
	"basil_4": "Basil is a key ingredient in many Italian dishes.",
	"intake_0": "The doctor advised him to reduce his salt intake.",
	"intake_1": "Tracking her calorie intake helped her with weight management.",
	"intake_2": "The nutritionist suggested increasing her fiber intake.",
	"intake_3": "Monitoring water intake is important during hot weather.",
	"intake_4": "The athlete's protein intake was carefully planned.",
	"asteroid_0": "The telescope captured an image of a distant asteroid.",
	"asteroid_1": "Scientists are studying the composition of the asteroid.",
	"asteroid_2": "The asteroid belt lies between Mars and Jupiter.",
	"asteroid_3": "They simulated the impact of an asteroid collision with Earth.",
	"asteroid_4": "An asteroid passed close to Earth last night.",
	"preheat_0": "Preheat the oven to 350 degrees before baking the cake.",
	"preheat_1": "She forgot to preheat the grill before cooking.",
	"preheat_2": "Always preheat the skillet for even cooking.",
	"preheat_3": "The recipe instructed to preheat the oven for ten minutes.",
	"preheat_4": "He preheated the oven for the homemade pizza.",
	"saturated_0": "The cloth was saturated with water and dripping.",
	"saturated_1": "Nutritionists warn against diets high in saturated fats.",
	"saturated_2": "The colors in the painting were deeply saturated and vivid.",
	"saturated_3": "The sponge quickly became saturated with the cleaning solution.",
	"saturated_4": "The market is saturated with similar products.",
	"excess_0": "She trimmed the excess fabric from the dress.",
	"excess_1": "The excess water in the plant pot was drained.",
	"excess_2": "They sold their excess inventory at a discount.",
	"excess_3": "He donated his excess books to the local library.",
	"excess_4": "The project was delayed due to excess paperwork.",
	"portable_0": "She bought a portable charger for her phone.",
	"portable_1": "The portable speaker is perfect for beach outings.",
	"portable_2": "They used a portable stove for camping.",
	"portable_3": "The device's portable design makes it easy to travel with.",
	"portable_4": "He appreciated the convenience of the portable hard drive.",
	"cinnamon_0": "She sprinkled cinnamon on her oatmeal for extra flavor.",
	"cinnamon_1": "Cinnamon is a key ingredient in this cookie recipe.",
	"cinnamon_2": "He enjoyed the aroma of cinnamon in the coffee shop.",
	"cinnamon_3": "A dash of cinnamon adds warmth to the apple pie.",
	"cinnamon_4": "Cinnamon sticks are used in mulled wine recipes.",
	"setup_0": "The setup of the new computer system took several hours.",
	"setup_1": "They admired the wedding's elegant table setup.",
	"setup_2": "Her home office setup includes a desk with multiple monitors.",
	"setup_3": "The band began their sound setup for the concert.",
	"setup_4": "The intricate setup of the model train set impressed everyone.",
	"dense_0": "The forest was too dense to walk through easily.",
	"dense_1": "He preferred dense bread with lots of grains and seeds.",
	"dense_2": "The book was a dense read, packed with complex ideas.",
	"dense_3": "Fog was so dense this morning that it was hard to see the road.",
	"dense_4": "The city's dense population makes traffic a constant problem.",
	"inexpensive_0": "She found an inexpensive but reliable car.",
	"inexpensive_1": "They were looking for inexpensive ways to decorate the room.",
	"inexpensive_2": "The market is known for its inexpensive local produce.",
	"inexpensive_3": "He prefers using inexpensive materials for his art projects.",
	"inexpensive_4": "Inexpensive solutions can often be surprisingly effective.",
	"wedge_0": "He used a wedge to keep the door open.",
	"wedge_1": "Serve the pie with a wedge of lemon on the side.",
	"wedge_2": "She cut the cheese into small wedges.",
	"wedge_3": "The golfer chose a wedge for the short shot to the green.",
	"wedge_4": "A wedge of iceberg lettuce was served as a simple salad.",
	"rinse_0": "Rinse the vegetables thoroughly before eating.",
	"rinse_1": "After shampooing, she forgot to rinse her hair.",
	"rinse_2": "Rinse the glass with water before pouring the juice.",
	"rinse_3": "He rinsed his mouth with mouthwash for fresh breath.",
	"rinse_4": "Always rinse canned beans to reduce sodium.",
	"crater_0": "The meteor left a large crater in the field.",
	"crater_1": "They hiked around the rim of the volcanic crater.",
	"crater_2": "The moon's surface is marked by numerous craters.",
	"crater_3": "He examined the crater caused by the explosion.",
	"crater_4": "The crater in the road was filled by the city workers.",
	"po_0": "The Po River flows through many beautiful Italian regions.",
	"po_1": "They enjoyed a boat ride on the serene waters of the Po.",
	"po_2": "The fertile valley along the Po is known for its agriculture.",
	"po_3": "He wrote a poem inspired by the landscapes along the Po.",
	"po_4": "Historical towns dot the banks of the Po River.",
	"enzyme_0": "The enzyme in saliva helps break down food.",
	"enzyme_1": "Scientists are studying the enzyme's role in the metabolic process.",
	"enzyme_2": "The deficiency of a certain enzyme can lead to health problems.",
	"enzyme_3": "An enzyme in pineapples helps tenderize meat.",
	"enzyme_4": "The new laundry detergent uses an enzyme formula to remove stains.",
	"slot_0": "Insert the coin into the slot to start the game.",
	"slot_1": "She booked a slot for her TV interview next week.",
	"slot_2": "The mail goes through the slot in the door.",
	"slot_3": "He found a time slot for the meeting in his busy schedule.",
	"slot_4": "The card needs to be inserted into the correct slot in the machine.",
	"foil_0": "Wrap the leftovers in foil to keep them fresh.",
	"foil_1": "She used foil in the oven to catch any drips.",
	"foil_2": "The shiny foil packaging caught his eye.",
	"foil_3": "They used aluminum foil to reflect light onto the plants.",
	"foil_4": "The detective's plan was foiled by an unexpected turn of events.",
	"tee_0": "He grabbed his favorite tee before heading to the gym.",
	"tee_1": "The golf ball was placed on the tee carefully.",
	"tee_2": "She bought a graphic tee from the concert merchandise.",
	"tee_3": "After washing, the cotton tee shrunk slightly.",
	"tee_4": "The kids' section had a variety of colorful tees on display.",
	"hull_0": "The ship's hull was damaged in the storm.",
	"hull_1": "They painted the hull of the boat a bright blue.",
	"hull_2": "The hull of the canoe glided smoothly through the water.",
	"hull_3": "Workers were busy repairing the hull at the shipyard.",
	"hull_4": "The hull's design affects the vessel's speed and stability.",
	"angler_0": "The angler cast his line into the serene lake.",
	"angler_1": "She became a skilled angler during her summers by the river.",
	"angler_2": "The angler waited patiently for a bite.",
	"angler_3": "A group of anglers gathered for the fishing competition.",
	"angler_4": "He shared stories of his experiences as an experienced angler.",
	"evolutionary_0": "The museum exhibit explained evolutionary changes in marine life.",
	"evolutionary_1": "Evolutionary biology was her favorite subject in college.",
	"evolutionary_2": "The evolutionary adaptations of the species fascinated the researchers.",
	"evolutionary_3": "They discussed the evolutionary significance of the newly discovered fossil.",
	"evolutionary_4": "The book explored the evolutionary history of human beings.",
	"silicon_0": "Silicon is widely used in the manufacturing of computer chips.",
	"silicon_1": "The valley is known for its silicon-based technology industry.",
	"silicon_2": "She studied the properties of silicon in her chemistry class.",
	"silicon_3": "Researchers are exploring new uses for silicon in electronics.",
	"silicon_4": "The silicon wafer is a key component in solar panels.",
	"plug_0": "Remember to plug in the charger to recharge your phone.",
	"plug_1": "He pulled the plug out of the sink to drain the water.",
	"plug_2": "The lamp won't turn on unless it's plugged in.",
	"plug_3": "She found a plug adapter for her trip overseas.",
	"plug_4": "The bathtub plug needs to be replaced to prevent leaks.",
	"dietary_0": "Her dietary restrictions include gluten and dairy.",
	"dietary_1": "The nutritionist recommended changes to his dietary habits.",
	"dietary_2": "Dietary fiber is important for digestive health.",
	"dietary_3": "The dietary supplement claims to boost energy levels.",
	"dietary_4": "They examined the dietary patterns of different cultures.",
	"bargain_0": "She found a great bargain at the thrift store.",
	"bargain_1": "Negotiating a bargain is an art in many markets.",
	"bargain_2": "The sale offered many items at a bargain price.",
	"bargain_3": "He considered the antique car a real bargain.",
	"bargain_4": "They struck a bargain after lengthy discussions.",
	"compact_0": "She prefers a compact camera for her travels.",
	"compact_1": "The apartment was small but compact and well-organized.",
	"compact_2": "He drove a compact car that was easy to park in the city.",
	"compact_3": "The makeup compact fit easily in her purse.",
	"compact_4": "The soil was too compact, making it difficult for planting.",
	"shaft_0": "The elevator shaft extended the height of the building.",
	"shaft_1": "Light filtered down the old mine shaft.",
	"shaft_2": "He inspected the shaft of the arrow for any damage.",
	"shaft_3": "The golf club had a flexible carbon fiber shaft.",
	"shaft_4": "The shaft of sunlight through the window illuminated the room.",
	"linen_0": "The linen tablecloth added elegance to the dinner setting.",
	"linen_1": "She preferred linen dresses in the summer for their breathability.",
	"linen_2": "The bed was made with crisp, clean linen.",
	"linen_3": "He wore a linen suit to the outdoor wedding.",
	"linen_4": "Linen napkins are more sustainable than paper ones.",
	"mature_0": "The wine needs to mature for a few more years.",
	"mature_1": "His mature perspective was appreciated in the discussions.",
	"mature_2": "The mature trees provided much-needed shade in the park.",
	"mature_3": "She showed a mature understanding of the complex issues.",
	"mature_4": "As the cheese matures, its flavor becomes more intense.",
	"saddle_0": "He adjusted the saddle before mounting his horse.",
	"saddle_1": "The bike's saddle was uncomfortable on long rides.",
	"saddle_2": "She polished the leather saddle until it shone.",
	"saddle_3": "The saddle slipped, causing the rider to fall off.",
	"saddle_4": "He placed the saddle carefully on the horse's back.",
	"synthetic_0": "The jacket was made of synthetic material that repelled water.",
	"synthetic_1": "Synthetic fibers are often used in affordable clothing.",
	"synthetic_2": "She chose a synthetic rug for its durability.",
	"synthetic_3": "The experiment involved a reaction with synthetic chemicals.",
	"synthetic_4": "Synthetic oil is sometimes used in high-performance engines.",
	"herd_0": "The shepherd guided the herd through the valley.",
	"herd_1": "A herd of elephants was spotted near the waterhole.",
	"herd_2": "He owns a large herd of cattle on his farm.",
	"herd_3": "The herd moved together as a single unit.",
	"herd_4": "Predators often target the weakest in a herd.",
	"co-worker_0": "She planned a surprise birthday party for her co-worker.",
	"co-worker_1": "His co-worker provided valuable assistance on the project.",
	"co-worker_2": "They became friends with a few of their co-workers.",
	"co-worker_3": "Her co-worker shared an interesting idea during the meeting.",
	"co-worker_4": "He borrowed a book from a co-worker.",
	"insurer_0": "The insurer covered the costs of the car accident.",
	"insurer_1": "She contacted her insurer to discuss the policy details.",
	"insurer_2": "The insurer denied the claim due to a technicality.",
	"insurer_3": "He worked as an agent for a major health insurer.",
	"insurer_4": "The storm damage was assessed by the insurer.",
	"prairie_0": "The vast prairie stretched out under the open sky.",
	"prairie_1": "Wildflowers dotted the prairie in the spring.",
	"prairie_2": "They drove across the prairie for hours without seeing a town.",
	"prairie_3": "Prairie ecosystems support a variety of wildlife.",
	"prairie_4": "The pioneers traveled across the prairie in covered wagons.",
	"cosmic_0": "The cosmic event was observed by astronomers worldwide.",
	"cosmic_1": "She has always been fascinated by the mysteries of cosmic phenomena.",
	"cosmic_2": "The telescope allows us to explore cosmic bodies far beyond our galaxy.",
	"cosmic_3": "Cosmic rays constantly bombard the Earth's atmosphere.",
	"cosmic_4": "He wrote a thesis on cosmic dust and its impact on space exploration.",
	"sturdy_0": "The bridge was built to be sturdy and withstand heavy traffic.",
	"sturdy_1": "He chose a sturdy pair of boots for the hiking trip.",
	"sturdy_2": "The table was made from sturdy oak wood.",
	"sturdy_3": "She needed a sturdy bag to carry all her textbooks.",
	"sturdy_4": "The sturdy construction of the house protected it from the storm.",
	"vista_0": "The mountain top offered a breathtaking vista of the valley below.",
	"vista_1": "They stopped at a lookout point to admire the scenic vista.",
	"vista_2": "The hiking trail is known for its stunning vistas.",
	"vista_3": "His office has a window with a vista of the bustling city.",
	"vista_4": "The vista from the balcony included rolling hills and a river.",
	"trek_0": "The trek through the jungle was challenging but rewarding.",
	"trek_1": "They embarked on a three-day trek in the mountains.",
	"trek_2": "The group prepared for their trek across the desert.",
	"trek_3": "Her solo trek to the base camp was a journey of self-discovery.",
	"trek_4": "The annual trek to the historical site attracted many enthusiasts.",
	"appetite_0": "The long walk gave him a hearty appetite.",
	"appetite_1": "She lost her appetite when she heard the bad news.",
	"appetite_2": "The appetizer was delicious and whetted their appetite for the main course.",
	"appetite_3": "A good appetite is often a sign of health.",
	"appetite_4": "Cold weather always seems to increase my appetite.",
	"hue_0": "The sunset painted the sky in hues of orange and pink.",
	"hue_1": "She chose a soft blue hue for the nursery walls.",
	"hue_2": "The garden was filled with flowers of every imaginable hue.",
	"hue_3": "His portrait captured the subtle hues of the landscape.",
	"hue_4": "The hue of the leaves changes with the seasons.",
	"hybrid_0": "She bought a hybrid car to save on fuel costs.",
	"hybrid_1": "The hybrid species of the plant is more resilient than its parent varieties.",
	"hybrid_2": "They developed a hybrid method combining traditional and modern techniques.",
	"hybrid_3": "The conference discussed the future of hybrid learning models.",
	"hybrid_4": "He enjoys playing a hybrid role in the team, combining different skills.",
	"diesel_0": "The truck runs on diesel fuel.",
	"diesel_1": "Diesel engines are known for their efficiency and durability.",
	"diesel_2": "She smelled the strong scent of diesel in the harbor area.",
	"diesel_3": "The mechanic specializes in repairing diesel vehicles.",
	"diesel_4": "Diesel prices have risen significantly this year.",
	"lunar_0": "The lunar landscape is stark and barren.",
	"lunar_1": "They watched the lunar eclipse through a telescope.",
	"lunar_2": "The mission's goal was to explore the lunar surface.",
	"lunar_3": "Lunar phases are caused by the moon's orbit around the Earth.",
	"lunar_4": "He has a keen interest in lunar geology.",
	"preach_0": "The minister will preach a sermon on kindness this Sunday.",
	"preach_1": "He used to preach the importance of regular exercise.",
	"preach_2": "The teacher preached patience and understanding in dealing with conflicts.",
	"preach_3": "She often preached the benefits of a balanced diet to her friends.",
	"preach_4": "They gathered to hear the old man preach about his life experiences.",
	"grid_0": "The city streets were laid out in a grid pattern.",
	"grid_1": "The power outage affected the entire electrical grid.",
	"grid_2": "She drew a grid on the paper to plot the data.",
	"grid_3": "The map showed a complex grid of roads and highways.",
	"grid_4": "The solar panels were connected to the national grid.",
	"aging_0": "The aging process can be influenced by lifestyle choices.",
	"aging_1": "They specialize in the care of aging adults.",
	"aging_2": "The wine is aging in oak barrels.",
	"aging_3": "Her research focuses on the aging population.",
	"aging_4": "The building was showing signs of aging.",
	"blossom_0": "In spring, the cherry trees blossom beautifully.",
	"blossom_1": "The garden was full of blossom, signaling the arrival of spring.",
	"blossom_2": "Her artistic talents began to blossom at a young age.",
	"blossom_3": "The warm weather brought a rapid blossom of flowers.",
	"blossom_4": "He watched the apple trees blossom from his window.",
	"staffer_0": "A staffer in the office answered the phone.",
	"staffer_1": "The senator's lead staffer coordinated the campaign.",
	"staffer_2": "She was a dedicated staffer at the non-profit organization.",
	"staffer_3": "The new staffer brought fresh ideas to the team.",
	"staffer_4": "He worked as a junior staffer before being promoted.",
	"prop_0": "The stage crew set up the props for the play.",
	"prop_1": "She used a book as a prop for her presentation.",
	"prop_2": "The movie's props were designed to be historically accurate.",
	"prop_3": "He leaned on a walking stick, more as a prop than out of necessity.",
	"prop_4": "The photo booth had a variety of fun props.",
	"gen_0": "The latest gen of smartphones comes with advanced features.",
	"gen_1": "She is well-versed in genomics and gen therapy.",
	"gen_2": "The new gen console offers an immersive gaming experience.",
	"gen_3": "Their project focused on gen editing techniques.",
	"gen_4": "He is a gen tech enthusiast, always following the latest trends.",
	"obesity_0": "The doctor discussed the health risks associated with obesity.",
	"obesity_1": "They implemented a school program to fight childhood obesity.",
	"obesity_2": "Obesity is often linked to lifestyle and diet.",
	"obesity_3": "The government launched a campaign to reduce obesity rates.",
	"obesity_4": "Her research focuses on the genetic factors of obesity.",
	"void_0": "The contract was declared void by the court.",
	"void_1": "He felt a void in his life after retiring.",
	"void_2": "The void left by her absence was palpable.",
	"void_3": "Space explorers ventured into the void of the universe.",
	"void_4": "The sudden cancellation of the event left a void in the schedule.",
	"module_0": "The spacecraft consisted of several interconnected modules.",
	"module_1": "He completed the training module with ease.",
	"module_2": "The module on ancient history was particularly interesting.",
	"module_3": "Each module of the software serves a specific function.",
	"module_4": "The habitat module provides living quarters for the astronauts.",
	"blur_0": "The car sped past in a blur.",
	"blur_1": "Her memories of that night were a blur.",
	"blur_2": "He saw a blur of faces in the crowd.",
	"blur_3": "The rain caused the landscape to blur outside the window.",
	"blur_4": "The days seemed to blur together during the busy season.",
	"trigger_0": "Pollen can trigger allergic reactions in many people.",
	"trigger_1": "The movie's plot twist triggered a lot of discussions.",
	"trigger_2": "Loud noises can trigger anxiety in pets.",
	"trigger_3": "The economic crisis was triggered by multiple factors.",
	"trigger_4": "The sensor is designed to trigger the alarm when it detects movement.",
	"foam_0": "The waves left foam on the sandy beach.",
	"foam_1": "She used foam padding to protect the fragile items.",
	"foam_2": "The fire extinguisher released a thick foam.",
	"foam_3": "He enjoyed the foam on top of his cappuccino.",
	"foam_4": "The mattress was made of memory foam for extra comfort.",
	"cellular_0": "Cellular biology is a fascinating field of study.",
	"cellular_1": "The area had poor cellular reception.",
	"cellular_2": "She opted for a cellular plan with unlimited data.",
	"cellular_3": "The invention revolutionized cellular communication.",
	"cellular_4": "Cellular regeneration is a key aspect of medical research.",
	"handling_0": "The car's smooth handling made it a joy to drive.",
	"handling_1": "Proper handling of chemicals is essential for safety.",
	"handling_2": "The package requires careful handling due to its fragility.",
	"handling_3": "His handling of the situation was commendable.",
	"handling_4": "The handling fee was included in the shipping cost.",
	"dome_0": "The ancient dome was a masterpiece of architectural design.",
	"dome_1": "They admired the intricate paintings on the cathedral's dome.",
	"dome_2": "The observatory's dome opened to reveal the night sky.",
	"dome_3": "The sports complex was covered by a massive dome.",
	"dome_4": "He pointed out the dome of the state capitol in the distance.",
	"marsh_0": "The marsh was teeming with birdlife.",
	"marsh_1": "They went on a guided walk through the marsh.",
	"marsh_2": "The marshy terrain made hiking difficult.",
	"marsh_3": "A variety of plants thrive in the marsh ecosystem.",
	"marsh_4": "The marsh borders the river, creating a natural habitat.",
	"convenience_0": "The convenience of online shopping has increased its popularity.",
	"convenience_1": "He chose the apartment for its convenience to the workplace.",
	"convenience_2": "The store offers a range of ready meals for convenience.",
	"convenience_3": "They appreciated the convenience of having a grocery store nearby.",
	"convenience_4": "Mobile phones offer the convenience of communication on the go.",
	"batch_0": "She baked a fresh batch of cookies for the guests.",
	"batch_1": "The next batch of products will be available soon.",
	"batch_2": "The brewery released a limited batch of its seasonal ale.",
	"batch_3": "The factory is running tests on the first batch of the new design.",
	"batch_4": "Each batch of the handmade soap is unique.",
	"curator_0": "The museum's curator is an expert in ancient art.",
	"curator_1": "The curator organized a new exhibit of modern sculptures.",
	"curator_2": "He worked closely with the curator to set up the gallery.",
	"curator_3": "The curator led a tour, explaining the significance of each piece.",
	"curator_4": "The film festival's curator selected a diverse range of movies.",
	"prescribe_0": "The doctor prescribed medication for her condition.",
	"prescribe_1": "He was prescribed rest and relaxation to recover.",
	"prescribe_2": "The physician prescribed a new treatment regimen.",
	"prescribe_3": "She prescribed a series of exercises for the patient's rehabilitation.",
	"prescribe_4": "The therapist prescribed a change in diet to help manage symptoms.",
	"dub_0": "The film was dubbed into several languages for international release.",
	"dub_1": "He was dubbed 'the fastest man alive' after his record-breaking sprint.",
	"dub_2": "They watched the anime in its original language with subtitles, preferring it over the dubbed version.",
	"dub_3": "The city was dubbed the 'Silicon Valley of the East' due to its booming tech industry.",
	"dub_4": "She dubbed the old, cozy library as her 'sanctuary of peace'.",
	"swiss_0": "He savored the rich flavor of the Swiss chocolate.",
	"swiss_1": "They went skiing in the Swiss Alps during their vacation.",
	"swiss_2": "The precision of Swiss watches is renowned worldwide.",
	"swiss_3": "She enjoyed a traditional Swiss fondue at the restaurant.",
	"swiss_4": "The Swiss flag is recognizable by its distinctive red and white cross.",
	"tab_0": "Keep a tab on expenses to stay within budget.",
	"tab_1": "He opened a new tab in his browser to search for information.",
	"tab_2": "The waiter brought the bill, and she asked to split the tab.",
	"tab_3": "She kept a tab on the latest news through social media.",
	"tab_4": "The jacket's size was written on the tab on the collar.",
	"altitude_0": "The mountain climbers acclimatized to the high altitude gradually.",
	"altitude_1": "The pilot announced that the plane had reached its cruising altitude.",
	"altitude_2": "Symptoms of altitude sickness can include dizziness and shortness of breath.",
	"altitude_3": "The city is known for being one of the highest capitals in altitude.",
	"altitude_4": "At this altitude, the air is much thinner and colder.",
	"feminist_0": "She wrote her thesis on feminist theory in contemporary literature.",
	"feminist_1": "The feminist movement advocated for equal rights and opportunities.",
	"feminist_2": "As a feminist, she actively participated in campaigns for gender equality.",
	"feminist_3": "The conference gathered feminist leaders from around the world.",
	"feminist_4": "He considered himself a feminist and supported women's rights.",
	"archive_0": "The archive held historical documents dating back centuries.",
	"archive_1": "She spent hours in the archive researching for her book.",
	"archive_2": "The digital archive made accessing old records much easier.",
	"archive_3": "The library's archive was a treasure trove of rare manuscripts.",
	"archive_4": "Archiving important emails helped him stay organized.",
	"insider_0": "As an insider in the industry, he had valuable insights.",
	"insider_1": "The scandal was revealed by an insider who leaked information.",
	"insider_2": "Insider trading is illegal and unethical.",
	"insider_3": "She felt like an outsider at first, but soon became an insider.",
	"insider_4": "The book offers an insider's perspective on the political system.",
	"glamor_0": "The old Hollywood glamor is often romanticized in movies.",
	"glamor_1": "She was drawn to the glamor and excitement of city life.",
	"glamor_2": "The glamor of the fashion industry is not without its challenges.",
	"glamor_3": "The event was a night of elegance and glamor.",
	"glamor_4": "Despite its glamor, the job required hard work and dedication.",
	"emit_0": "The machine emits a low humming noise.",
	"emit_1": "The chemical reaction caused the substance to emit light.",
	"emit_2": "Plants emit oxygen during photosynthesis.",
	"emit_3": "The policy aimed to reduce the amount of harmful gases emitted by factories.",
	"emit_4": "The fireflies emitted a soft glow in the dark.",
	"slim_0": "He followed a strict diet to maintain a slim figure.",
	"slim_1": "The chances of success seemed slim, but they tried anyway.",
	"slim_2": "She preferred the slim design of the new smartphone.",
	"slim_3": "The book's slim volume belied its profound content.",
	"slim_4": "They found a slim gap and squeezed through the fence.",
	"arc_0": "The rainbow formed a perfect arc in the sky.",
	"arc_1": "He traced the arc of the ball as it flew through the air.",
	"arc_2": "The story's narrative arc was both engaging and satisfying.",
	"arc_3": "The arc of her golf swing was smooth and precise.",
	"arc_4": "The bridge was designed with a majestic stone arc.",
	"par_0": "He managed to finish the course at par.",
	"par_1": "Her performance was considered above par in the industry.",
	"par_2": "The new policy aims to bring standards up to par with international levels.",
	"par_3": "Being on par with his competitors was not enough; he wanted to excel.",
	"par_4": "The quality of the product was below par compared to their usual standards.",
	"intimacy_0": "The small concert hall provided an intimacy that larger venues lacked.",
	"intimacy_1": "They shared a level of intimacy only found in deep friendships.",
	"intimacy_2": "The book explored the theme of intimacy in modern relationships.",
	"intimacy_3": "Despite the crowd, there was a sense of intimacy in their conversation.",
	"intimacy_4": "The room's warm lighting created an atmosphere of intimacy.",
	"nurture_0": "The teacher's role is to nurture young minds.",
	"nurture_1": "They created a nurturing environment for the children.",
	"nurture_2": "Her artistic talent was nurtured from an early age.",
	"nurture_3": "He believed in the need to nurture relationships with care.",
	"nurture_4": "The garden required a lot of care to nurture the plants to full bloom.",
	"mold_0": "The sculpture was made using a specialized mold.",
	"mold_1": "Moisture in the room led to mold growth on the walls.",
	"mold_2": "She used a heart-shaped mold for the chocolates.",
	"mold_3": "The teacher's methods aimed to mold students into critical thinkers.",
	"mold_4": "After weeks of neglect, mold had formed on the leftovers.",
	"breed_0": "The farmer specialized in breeding rare livestock.",
	"breed_1": "That breed of dog is known for its loyalty and intelligence.",
	"breed_2": "Selective breeding has led to various types of roses.",
	"breed_3": "The exhibition featured several exotic breeds of cats.",
	"breed_4": "They are trying to breed a new variety of wheat that's more resistant to disease.",
	"compelling_0": "The novel was so compelling that she read it in one sitting.",
	"compelling_1": "His arguments were compelling and persuaded many.",
	"compelling_2": "The documentary presented compelling evidence on climate change.",
	"compelling_3": "She had a compelling reason to refuse the offer.",
	"compelling_4": "The speaker's compelling presence captivated the audience.",
	"vacuum_0": "He vacuumed the carpet to remove all the dust.",
	"vacuum_1": "The vacuum of space is a hostile environment for humans.",
	"vacuum_2": "She used a handheld vacuum for the car's interior.",
	"vacuum_3": "After the leader's resignation, a power vacuum emerged.",
	"vacuum_4": "The vacuum cleaner was efficient but noisy.",
	"prototype_0": "The engineers tested the prototype before final production.",
	"prototype_1": "The first prototype of the invention was quite rudimentary.",
	"prototype_2": "They unveiled a prototype of the new electric car.",
	"prototype_3": "Feedback on the prototype will shape the final design.",
	"prototype_4": "The prototype was built to demonstrate the concept.",
	"rosemary_0": "She added rosemary to the roast for extra flavor.",
	"rosemary_1": "The fragrance of rosemary filled the kitchen.",
	"rosemary_2": "Rosemary is a popular herb in Mediterranean cooking.",
	"rosemary_3": "He grew rosemary in his garden for culinary use.",
	"rosemary_4": "A sprig of rosemary garnished the cocktail.",
	"precision_0": "The watchmaker was known for his precision and attention to detail.",
	"precision_1": "Precision in measurements is crucial for scientific experiments.",
	"precision_2": "The gymnast executed her routine with incredible precision.",
	"precision_3": "The machine's precision allows for accurate and consistent production.",
	"precision_4": "The surgeon's hands moved with precision during the delicate operation.",
	"abundant_0": "Fish are abundant in the coastal waters.",
	"abundant_1": "The region is known for its abundant natural resources.",
	"abundant_2": "The abundant harvest promised a bountiful winter supply.",
	"abundant_3": "Wildlife was abundant in the pristine forest.",
	"abundant_4": "She took advantage of the abundant opportunities in the city.",
	"gauge_0": "The pressure gauge indicated that the tire was underinflated.",
	"gauge_1": "He used a gauge to measure the thickness of the metal.",
	"gauge_2": "Her mood could be gauged by her facial expressions.",
	"gauge_3": "The survey was used to gauge public opinion on the issue.",
	"gauge_4": "The water gauge showed that the tank was nearly full.",
	"meditation_0": "She practiced meditation every morning to find inner peace.",
	"meditation_1": "The group attended a meditation retreat over the weekend.",
	"meditation_2": "Meditation has been shown to reduce stress and anxiety.",
	"meditation_3": "He found meditation helpful in improving his concentration.",
	"meditation_4": "The meditation session ended with a period of silent reflection.",
	"boomer_0": "As a baby boomer, he witnessed many historical events.",
	"boomer_1": "The marketing strategy targeted the boomer generation.",
	"boomer_2": "Boomers and millennials often have different perspectives on technology.",
	"boomer_3": "The boomer population is a significant segment of the consumer market.",
	"boomer_4": "He reminisced about the music of his boomer youth.",
	"electron_0": "The electron microscope revealed the cell's intricate structures.",
	"electron_1": "Electrons orbit the nucleus of an atom.",
	"electron_2": "The movement of electrons is fundamental to electricity.",
	"electron_3": "Scientists study electron behavior to understand chemical reactions.",
	"electron_4": "The experiment involved measuring electron flow.",
	"infantry_0": "The infantry troops were ready for deployment.",
	"infantry_1": "He served in the infantry during the war.",
	"infantry_2": "The infantry moved through the terrain with caution.",
	"infantry_3": "In historical battles, infantry units played a crucial role.",
	"infantry_4": "The training for infantry soldiers is rigorous and demanding.",
	"compensate_0": "The company offered to compensate him for his lost luggage.",
	"compensate_1": "She worked overtime to compensate for the time off she'd taken.",
	"compensate_2": "Plants in dark environments compensate by growing towards light.",
	"compensate_3": "The heating system compensates for the cold weather outside.",
	"compensate_4": "To compensate for his inexperience, he worked twice as hard.",
	"elevation_0": "The mountain's elevation made it a challenging climb.",
	"elevation_1": "They experienced difficulty breathing at high elevations.",
	"elevation_2": "The map indicated the elevation of the various peaks.",
	"elevation_3": "The city's elevation affects its climate.",
	"elevation_4": "The hike involved a gradual elevation gain.",
	"rugged_0": "The rugged terrain made the hike more challenging.",
	"rugged_1": "He preferred rugged outdoor adventures to relaxing vacations.",
	"rugged_2": "The vehicle is designed for rugged off-road conditions.",
	"rugged_3": "His rugged appearance suited the character he played.",
	"rugged_4": "The phone case was rugged to protect against drops and impacts.",
	"orthodox_0": "The building was an example of traditional Orthodox architecture.",
	"orthodox_1": "Her views are considered too orthodox in the rapidly changing industry.",
	"orthodox_2": "He followed the Orthodox Jewish dietary laws strictly.",
	"orthodox_3": "The ceremony was conducted in the Orthodox Christian tradition.",
	"orthodox_4": "She challenged the orthodox theories in her field with new research.",
	"scripture_0": "The priest quoted passages from the scripture during the sermon.",
	"scripture_1": "She studied ancient scriptures as part of her theology degree.",
	"scripture_2": "Different religions have their own sacred scriptures.",
	"scripture_3": "The scripture class explored various interpretations of the texts.",
	"scripture_4": "He found solace in reading the scriptures every morning.",
	"collision_0": "The collision between the two cars blocked traffic for hours.",
	"collision_1": "Scientists study particle collisions to understand fundamental forces.",
	"collision_2": "The collision of their ideas led to a heated debate.",
	"collision_3": "Safety features in the car minimize injury in case of a collision.",
	"collision_4": "The asteroid's collision with Earth had catastrophic consequences.",
	"cedar_0": "The cedar tree in our yard has been standing for decades.",
	"cedar_1": "They built the fence out of cedar because of its durability.",
	"cedar_2": "The scent of cedar filled the air in the forest.",
	"cedar_3": "Cedar wood is often used in making outdoor furniture.",
	"cedar_4": "She placed cedar blocks in her closet to repel moths.",
	"testament_0": "The successful event was a testament to the team's hard work.",
	"testament_1": "His recovery was a testament to his determination and resilience.",
	"testament_2": "The ancient ruins were a testament to a once-thriving civilization.",
	"testament_3": "The large turnout for the protest was a testament to growing public discontent.",
	"testament_4": "Her extensive knowledge was a testament to years of study.",
	"potent_0": "The medicine is highly potent, so take it in small doses.",
	"potent_1": "His speech was potent in motivating the crowd.",
	"potent_2": "The chili pepper was surprisingly potent and spicy.",
	"potent_3": "The potent symbol of the movement galvanized supporters.",
	"potent_4": "She had a potent influence on the artistic community.",
	"batter_0": "She whisked the batter until it was smooth for the pancakes.",
	"batter_1": "The stormy waves battered the coastline relentlessly.",
	"batter_2": "He was a skilled batter, known for his home runs.",
	"batter_3": "The batter for the cake was poured into the pan.",
	"batter_4": "The wooden door had been battered by years of use.",
	"co-author_0": "She was the co-author of a paper on climate change.",
	"co-author_1": "The book's co-authors appeared together at the book signing.",
	"co-author_2": "As co-author, he contributed several chapters to the book.",
	"co-author_3": "They collaborated well, resulting in a successful co-author partnership.",
	"co-author_4": "The study's findings were credited to its two co-authors.",
	"sleek_0": "She admired the sleek design of the new smartphone.",
	"sleek_1": "The car's sleek body made it stand out in the showroom.",
	"sleek_2": "He always dressed in sleek, fashionable attire.",
	"sleek_3": "The yacht moved through the water with sleek efficiency.",
	"sleek_4": "The cat's sleek fur shone in the sunlight.",
	"tribute_0": "The concert was a tribute to the legendary musician.",
	"tribute_1": "The statue stands as a tribute to the war heroes.",
	"tribute_2": "The film pays tribute to the golden era of cinema.",
	"tribute_3": "Her speech was a touching tribute to her mentor.",
	"tribute_4": "The art exhibition was a tribute to traditional craftsmanship.",
	"bead_0": "She added colorful beads to the bracelet.",
	"bead_1": "Sweat beads formed on his forehead during the workout.",
	"bead_2": "The dress was adorned with intricate beadwork.",
	"bead_3": "He traded beads with the locals during his travels.",
	"bead_4": "The lampshade was decorated with hanging beads.",
	"penetrate_0": "The sunlight managed to penetrate the dense forest canopy.",
	"penetrate_1": "The company struggled to penetrate the new market.",
	"penetrate_2": "The sound of the drilling penetrated the thick walls.",
	"penetrate_3": "The secret agent was trained to penetrate secure facilities.",
	"penetrate_4": "The investigation sought to penetrate the mystery surrounding the case.",
	"inject_0": "The nurse carefully injected the vaccine into the patient's arm.",
	"inject_1": "The chef used a syringe to inject flavor into the meat.",
	"inject_2": "The company plans to inject more capital into the project.",
	"inject_3": "He tried to inject some humor into the conversation.",
	"inject_4": "Scientists inject dye into cells to observe them under a microscope.",
	"bang_0": "The fireworks concluded with a loud bang.",
	"bang_1": "He cut his hair and now sports a trendy bang.",
	"bang_2": "The door slammed shut with a bang.",
	"bang_3": "The sudden bang startled everyone in the room.",
	"bang_4": "The play started with a bang, capturing the audience's attention immediately.",
	"pharmaceutical_0": "She works for a leading pharmaceutical company.",
	"pharmaceutical_1": "The new pharmaceutical product underwent rigorous testing.",
	"pharmaceutical_2": "Pharmaceutical research is vital for developing new medicines.",
	"pharmaceutical_3": "The debate on pharmaceutical pricing is ongoing.",
	"pharmaceutical_4": "The doctor discussed various pharmaceutical options with the patient.",
	"spirituality_0": "Her journey to India was a quest for personal spirituality.",
	"spirituality_1": "The retreat focused on deepening participants' spirituality.",
	"spirituality_2": "He found his spirituality grounded in nature.",
	"spirituality_3": "The book explores the intersection of art and spirituality.",
	"spirituality_4": "Discussions about spirituality can be deeply personal and varied.",
	"catalogue_0": "The librarian catalogued the new books into the system.",
	"catalogue_1": "She browsed through the furniture catalogue for inspiration.",
	"catalogue_2": "The museum's art collection was meticulously catalogued.",
	"catalogue_3": "He kept a detailed catalogue of all his music recordings.",
	"catalogue_4": "The catalogue of rare stamps attracted collectors worldwide.",
	"evoke_0": "The old house evoked memories of her childhood.",
	"evoke_1": "The painting's vivid colors evoke a sense of joy.",
	"evoke_2": "His speech evoked strong emotions in the audience.",
	"evoke_3": "The fragrance evoked a sense of nostalgia.",
	"evoke_4": "The novel evokes the atmosphere of medieval Europe.",
	"valve_0": "The plumber fixed the leak by replacing the valve.",
	"valve_1": "Heart valve surgery can be a complex procedure.",
	"valve_2": "The pressure valve released steam from the boiler.",
	"valve_3": "She turned the valve to shut off the gas supply.",
	"valve_4": "The safety valve is designed to prevent overpressurization.",
	"epidemic_0": "Health officials are working to contain the flu epidemic.",
	"epidemic_1": "The opioid epidemic has affected communities nationwide.",
	"epidemic_2": "Measures were taken to educate the public about the epidemic.",
	"epidemic_3": "The epidemic spread rapidly in the densely populated area.",
	"epidemic_4": "Researchers study patterns to understand how epidemics evolve.",
	"grind_0": "He used a mortar and pestle to grind the spices.",
	"grind_1": "The daily grind of commuting can be exhausting.",
	"grind_2": "The mill grinds wheat into flour.",
	"grind_3": "She could hear the grind of gears from the machine.",
	"grind_4": "The coffee shop offers a choice of different coffee grinds.",
	"evangelical_0": "The evangelical preacher held a large gathering in the park.",
	"evangelical_1": "Her evangelical zeal for the project inspired the whole team.",
	"evangelical_2": "The church is part of the evangelical movement.",
	"evangelical_3": "He spoke about his faith in an evangelical tone.",
	"evangelical_4": "The book discussed the history of evangelical Christianity.",
	"condom_0": "Condoms are effective in preventing STDs and unplanned pregnancies.",
	"condom_1": "The health clinic provides free condoms to the public.",
	"condom_2": "They discussed the importance of condom use in sex education classes.",
	"condom_3": "The campaign aimed to destigmatize condom buying.",
	"condom_4": "A new type of condom was introduced with enhanced safety features.",
	"asthma_0": "He uses an inhaler to manage his asthma.",
	"asthma_1": "Asthma attacks can be triggered by allergens.",
	"asthma_2": "The doctor provided guidelines for controlling her asthma.",
	"asthma_3": "Exercise-induced asthma affects many athletes.",
	"asthma_4": "Environmental pollution can exacerbate asthma symptoms.",
	"gown_0": "She wore an elegant gown to the gala.",
	"gown_1": "The graduate donned a cap and gown for the ceremony.",
	"gown_2": "The designer's collection featured evening gowns.",
	"gown_3": "Hospital patients are often required to wear a gown.",
	"gown_4": "The bridal gown was adorned with intricate lace.",
	"bore_0": "The lecture was so dull it bored her to tears.",
	"bore_1": "The drill easily bored a hole through the wall.",
	"bore_2": "His stories never bore his audience.",
	"bore_3": "The machine was designed to bore tunnels for the subway.",
	"bore_4": "The monotonous task bored him immensely.",
	"dictate_0": "The doctor used a voice recorder to dictate his notes.",
	"dictate_1": "Market trends often dictate business strategies.",
	"dictate_2": "The terms of the agreement were dictated by the more powerful party.",
	"dictate_3": "Tradition should not dictate our choices in modern times.",
	"dictate_4": "The script was dictated by historical accuracy.",
	"infect_0": "The virus can infect people of all age groups.",
	"infect_1": "His enthusiasm was so infectious, it quickly spread through the team.",
	"infect_2": "The infected wound required immediate medical attention.",
	"infect_3": "Measures were taken to prevent the disease from infecting more people.",
	"infect_4": "The computer was infected with malware.",
	"interface_0": "The software's user interface is intuitive and easy to navigate.",
	"interface_1": "She specializes in designing interfaces for mobile applications.",
	"interface_2": "The team worked on improving the machine's human-computer interface.",
	"interface_3": "A good interface enhances the user's experience.",
	"interface_4": "The device interfaces seamlessly with various digital platforms.",
	"lush_0": "The hikers walked through the lush green forest.",
	"lush_1": "Her garden is lush with a variety of flowers and plants.",
	"lush_2": "The landscape was a lush tapestry of colors in spring.",
	"lush_3": "The rain transformed the arid land into lush fields.",
	"lush_4": "The resort is known for its lush surroundings.",
	"hub_0": "The city is a major hub for international trade.",
	"hub_1": "The airport serves as a hub for numerous airlines.",
	"hub_2": "The wheel's hub was damaged in the accident.",
	"hub_3": "The community center is a hub of local activities.",
	"hub_4": "They transformed the area into a vibrant cultural hub.",
	"theologian_0": "The theologian published a book on religious ethics.",
	"theologian_1": "He was a renowned theologian and philosopher.",
	"theologian_2": "The conference gathered theologians from around the world.",
	"theologian_3": "Her studies in theology led her to become a respected theologian.",
	"theologian_4": "The theologian's lecture on faith and science was enlightening.",
	"stack_0": "He stacked the books neatly on the shelf.",
	"stack_1": "The children enjoyed playing with the stack of colorful blocks.",
	"stack_2": "A stack of papers awaited her on the desk.",
	"stack_3": "The chimney stack emitted a plume of smoke.",
	"stack_4": "They created a stack of pancakes topped with syrup.",
	"mound_0": "The children built a sand mound at the beach.",
	"mound_1": "A small mound of earth marked the spot.",
	"mound_2": "The pitcher stood on the mound, ready to throw.",
	"mound_3": "Ants had created a large mound in the yard.",
	"mound_4": "The archaeological team excavated an ancient burial mound.",
	"transplant_0": "The heart transplant surgery was a success.",
	"transplant_1": "She transplanted the seedlings into the garden.",
	"transplant_2": "The transplant of traditional methods into modern contexts can be challenging.",
	"transplant_3": "The patient is recovering well after the kidney transplant.",
	"transplant_4": "He underwent a hair transplant procedure.",
	"rigid_0": "The rigid structure of the building made it very stable.",
	"rigid_1": "His opinions were rigid and not easily changed.",
	"rigid_2": "The company's rigid policies needed to be more flexible.",
	"rigid_3": "She maintained a rigid schedule for her training.",
	"rigid_4": "The materials used in the construction were too rigid to bend.",
	"imperial_0": "The museum displayed artifacts from the imperial dynasty.",
	"imperial_1": "He wrote a thesis on the imperial policies of the 19th century.",
	"imperial_2": "The imperial palace was known for its magnificent architecture.",
	"imperial_3": "The history book detailed the rise and fall of the imperial empire.",
	"imperial_4": "The imperial decree was issued to all the provinces.",
	"niche_0": "The company carved out a niche in the competitive market.",
	"niche_1": "He found his niche in the field of environmental science.",
	"niche_2": "Niche markets offer opportunities for specialized products.",
	"niche_3": "The artist gained popularity in a particular niche audience.",
	"niche_4": "The product caters to a niche segment with specific needs.",
	"flaw_0": "The plan had a major flaw that needed addressing.",
	"flaw_1": "She embraced her flaws as part of her unique character.",
	"flaw_2": "The diamond's value was reduced due to its flaw.",
	"flaw_3": "The experiment's design had a fundamental flaw.",
	"flaw_4": "The legal contract contained a significant flaw.",
	"induce_0": "The medication is used to induce sleep.",
	"induce_1": "Certain conditions can induce stress and anxiety.",
	"induce_2": "The film's shocking scenes were intended to induce strong reactions.",
	"induce_3": "They used techniques to induce a state of deep relaxation.",
	"induce_4": "The treatment may induce temporary discomfort.",
	"intriguing_0": "The detective found the case increasingly intriguing.",
	"intriguing_1": "Her latest research presents an intriguing theory.",
	"intriguing_2": "The book's intriguing plot captured his imagination.",
	"intriguing_3": "They explored the intriguing old town with its winding streets.",
	"intriguing_4": "The exhibition offered an intriguing glimpse into ancient civilizations.",
	"enlist_0": "He enlisted in the army after finishing school.",
	"enlist_1": "They enlisted the help of a professional to renovate the house.",
	"enlist_2": "The campaign enlisted local businesses as sponsors.",
	"enlist_3": "She enlisted her friends to help with the charity event.",
	"enlist_4": "To complete the project, they enlisted the expertise of several specialists.",
	"platter_0": "The platter of assorted cheeses was a hit at the party.",
	"platter_1": "He presented a seafood platter as the main course.",
	"platter_2": "The artist arranged her paints on a large platter.",
	"platter_3": "They served the roast on a decorative silver platter.",
	"platter_4": "The dessert platter included a variety of sweet treats.",
	"cultivate_0": "The farmer cultivated a variety of crops on his land.",
	"cultivate_1": "She cultivated a passion for music at a young age.",
	"cultivate_2": "The organization aimed to cultivate a sense of community.",
	"cultivate_3": "They cultivated a garden full of herbs and vegetables.",
	"cultivate_4": "To succeed, he cultivated relationships with key people in the industry.",
	"bureaucracy_0": "Navigating the bureaucracy of the permit process was time-consuming.",
	"bureaucracy_1": "The new policy aimed to reduce the level of bureaucracy in government.",
	"bureaucracy_2": "She found herself entangled in bureaucratic red tape.",
	"bureaucracy_3": "The company's bureaucracy slowed down innovative ideas.",
	"bureaucracy_4": "He criticized the inefficiency of the local bureaucracy.",
	"reside_0": "The ambassador resides in the official embassy residence.",
	"reside_1": "Many different species of birds reside in this forest.",
	"reside_2": "Her family has resided in the same neighborhood for generations.",
	"reside_3": "He took a year off to reside in a remote mountain village.",
	"reside_4": "The right to reside in the country was granted to her after many years.",
	"prosperity_0": "The city experienced a period of prosperity during the boom.",
	"prosperity_1": "He wished her prosperity and success in her new venture.",
	"prosperity_2": "The festival was a symbol of the region's cultural prosperity.",
	"prosperity_3": "Policies were implemented with the aim of increasing national prosperity.",
	"prosperity_4": "The community's prosperity depended heavily on the local industry.",
	"staple_0": "Rice is a staple food in many Asian countries.",
	"staple_1": "The report was missing a staple and came apart.",
	"staple_2": "Wheat has been a staple crop in this region for centuries.",
	"staple_3": "Denim jeans are a staple in most people's wardrobes.",
	"staple_4": "Coffee has become a morning staple for many workers.",
	"speculate_0": "Analysts speculate that the market will rebound next year.",
	"speculate_1": "She didn't want to speculate on the outcome of the election.",
	"speculate_2": "It's not wise to speculate on rumors without evidence.",
	"speculate_3": "The media tended to speculate about the celebrity's private life.",
	"speculate_4": "Investors began to speculate in the burgeoning tech industry.",
	"severely_0": "The drought severely affected the agricultural output.",
	"severely_1": "He was severely reprimanded for his actions.",
	"severely_2": "The accident severely damaged the vehicle.",
	"severely_3": "Her confidence was severely shaken by the incident.",
	"severely_4": "The storm severely disrupted travel plans across the region.",
	"originate_0": "The river originates in the high mountains.",
	"originate_1": "The idea for the invention originated during a brainstorming session.",
	"originate_2": "The customs originate from ancient traditions.",
	"originate_3": "The movement originated in the early 20th century.",
	"originate_4": "The rumor was found to originate from a miscommunication.",
	"arch_0": "The ancient Roman arch is still standing after centuries.",
	"arch_1": "She arched her eyebrow in a questioning manner.",
	"arch_2": "The cat arched its back when it was frightened.",
	"arch_3": "The arch of the bridge was an engineering marvel.",
	"arch_4": "Vines covered the wooden arch at the garden entrance.",
	"lobbyist_0": "The lobbyist met with legislators to discuss the proposed law.",
	"lobbyist_1": "He worked as a lobbyist for an environmental advocacy group.",
	"lobbyist_2": "The new regulations impact the role of lobbyists in political campaigns.",
	"lobbyist_3": "As a lobbyist, her job was to influence policy decisions.",
	"lobbyist_4": "The company hired a lobbyist to represent its interests in Washington.",
	"memoir_0": "She published a memoir detailing her experiences abroad.",
	"memoir_1": "The veteran's memoir provided a poignant account of the war.",
	"memoir_2": "His memoir was filled with anecdotes from his long career in theater.",
	"memoir_3": "The actress's memoir became a bestseller.",
	"memoir_4": "He decided to write a memoir to share his life lessons.",
	"cuisine_0": "Italian cuisine is known for its diverse regional dishes.",
	"cuisine_1": "The restaurant offers a fusion of Asian and European cuisine.",
	"cuisine_2": "She developed a love for French cuisine while living in Paris.",
	"cuisine_3": "The chef specialized in traditional Japanese cuisine.",
	"cuisine_4": "The food festival showcased cuisine from around the world.",
	"salesman_0": "The salesman demonstrated the features of the new car model.",
	"salesman_1": "He worked for years as a door-to-door salesman.",
	"salesman_2": "The insurance salesman offered various coverage options.",
	"salesman_3": "He was a natural salesman, with a persuasive and charismatic approach.",
	"salesman_4": "The top salesman of the company was awarded a trip to Hawaii.",
	"fax_0": "They sent the contract over via fax for immediate response.",
	"fax_1": "The office still had a machine that could send and receive faxes.",
	"fax_2": "Fax technology was revolutionary in its time for business communication.",
	"fax_3": "She requested that the documents be sent to her fax number.",
	"fax_4": "The fax arrived with important information for the meeting.",
	"quilt_0": "She sewed a beautiful quilt from different fabric scraps.",
	"quilt_1": "The handmade quilt was passed down through generations.",
	"quilt_2": "They bought a warm quilt for the winter months.",
	"quilt_3": "The quilt exhibit displayed intricate designs and patterns.",
	"quilt_4": "He wrapped himself in a quilt and read by the fireplace.",
	"fertility_0": "The couple sought medical help to address their fertility issues.",
	"fertility_1": "The fertility of the soil was ideal for growing crops.",
	"fertility_2": "Ancient cultures often had deities associated with fertility.",
	"fertility_3": "The doctor specialized in fertility treatments.",
	"fertility_4": "They used organic methods to improve the land's fertility.",
	"vibrant_0": "The city is known for its vibrant arts scene.",
	"vibrant_1": "The garden was filled with vibrant colors during spring.",
	"vibrant_2": "The festival was a vibrant celebration of local culture.",
	"vibrant_3": "She brought the room to life with her vibrant personality.",
	"vibrant_4": "The fabric's vibrant hues remained bright even after washing.",
	"forge_0": "The blacksmith used the forge to heat the metal.",
	"forge_1": "She managed to forge a successful career in finance.",
	"forge_2": "The artist's signature was forged on the painting.",
	"forge_3": "The two companies decided to forge a partnership.",
	"forge_4": "They forged ahead with the plan despite the obstacles.",
	"provoke_0": "His controversial statements were meant to provoke a reaction.",
	"provoke_1": "The movie's ending is likely to provoke debate among viewers.",
	"provoke_2": "She knew how to provoke laughter in even the dullest meetings.",
	"provoke_3": "The policy changes may provoke unrest in the community.",
	"provoke_4": "The artist's work often seeks to provoke thought and discussion.",
	"elevate_0": "The platform was elevated to give the audience a better view.",
	"elevate_1": "The appointment elevated her to a senior management position.",
	"elevate_2": "His performance elevated the entire team's morale.",
	"elevate_3": "They elevated the house to protect it from floodwaters.",
	"elevate_4": "The ceremony will elevate the candidate to knighthood.",
	"hemisphere_0": "The Northern Hemisphere experiences winter when it's summer in the Southern Hemisphere.",
	"hemisphere_1": "The left hemisphere of the brain controls the right side of the body.",
	"hemisphere_2": "The map illustrated animal species native to each hemisphere.",
	"hemisphere_3": "Meteorological patterns differ significantly between hemispheres.",
	"hemisphere_4": "The telescope can observe stars in both the northern and southern hemispheres.",
	"cult_0": "The ancient cult had rituals that are still a mystery today.",
	"cult_1": "The movie gained a cult following for its unique storyline.",
	"cult_2": "The book examines the rise and fall of various cult movements.",
	"cult_3": "The band's music appeals to a small but devoted cult audience.",
	"cult_4": "She studied the cult's influence on early art forms.",
	"tract_0": "The organization bought a large tract of land for conservation.",
	"tract_1": "The pamphlet was a religious tract distributed to the public.",
	"tract_2": "The digestive tract plays a vital role in processing food.",
	"tract_3": "They owned a vast tract of forest in the countryside.",
	"tract_4": "The census tract data provided insights into the local population.",
	"hover_0": "The helicopter hovered above the crowd.",
	"hover_1": "Prices tend to hover around a certain point.",
	"hover_2": "He hovered near the door, unsure whether to enter.",
	"hover_3": "Insects hovered over the surface of the water.",
	"hover_4": "The drone hovered in place, capturing aerial footage.",
	"donna_0": "Donna received accolades for her groundbreaking research in biology.",
	"donna_1": "At the party, Donna wore a dress that sparkled under the lights.",
	"donna_2": "Everyone turned to Donna for her expert opinion on marketing strategies.",
	"donna_3": "Donna volunteered at the local shelter every weekend.",
	"donna_4": "The painting of Donna in the gallery captured her elegance perfectly.",
	"flourish_0": "The plants flourish in the rich, fertile soil of the valley.",
	"flourish_1": "Under her leadership, the business began to flourish.",
	"flourish_2": "The arts scene in the city has been flourishing in recent years.",
	"flourish_3": "She watched her children flourish into responsible adults.",
	"flourish_4": "With the right care, the small startup flourished into a major corporation.",
	"complication_0": "The surgery went well without any complications.",
	"complication_1": "A complication arose during the negotiation process.",
	"complication_2": "The plot of the novel involves a series of intriguing complications.",
	"complication_3": "They were prepared to face any complications during the expedition.",
	"complication_4": "Diabetes can lead to complications if not properly managed.",
	"facial_0": "She treated herself to a relaxing facial at the spa.",
	"facial_1": "The police used facial recognition software in their investigation.",
	"facial_2": "He had distinct facial features that made him easily recognizable.",
	"facial_3": "The therapist specialized in facial muscle exercises for stroke patients.",
	"facial_4": "The advertisement promoted a new line of facial skincare products.",
	"descent_0": "The plane began its descent towards the airport.",
	"descent_1": "She is of French descent, with her family originating from Paris.",
	"descent_2": "The hikers carefully navigated the steep descent down the mountain.",
	"descent_3": "Stories of his heroic descent from the royal family fascinated everyone.",
	"descent_4": "The descent into the cave was dark and somewhat eerie.",
	"axis_0": "The Earth rotates on its axis, causing day and night.",
	"axis_1": "The graph was plotted with time on the horizontal axis.",
	"axis_2": "During World War II, the Axis powers included Germany, Italy, and Japan.",
	"axis_3": "The machine's cutting tool moved along the X and Y axis.",
	"axis_4": "The planet's axis tilt contributes to its seasonal changes.",
	"curl_0": "She used a curling iron to curl her hair for the event.",
	"curl_1": "The cat curled up in a cozy ball on the sofa.",
	"curl_2": "He watched the leaves curl as they burned in the fire.",
	"curl_3": "The ribbon began to curl at the edges.",
	"curl_4": "The young fern leaves unfurled from their tight curls.",
	"established_0": "The established company has a solid reputation in the industry.",
	"established_1": "She became a member of the well-established scientific community.",
	"established_2": "The rules were established to ensure fairness in the competition.",
	"established_3": "He challenged the established norms with his innovative ideas.",
	"established_4": "The park is an established habitat for numerous bird species.",
	"genome_0": "Scientists sequenced the human genome to understand genetic makeup.",
	"genome_1": "The genome study provided insights into hereditary diseases.",
	"genome_2": "Geneticists use genome mapping in their research.",
	"genome_3": "The project aimed to compare the genomes of different species.",
	"genome_4": "Advances in genome editing have opened new possibilities in medicine.",
	"ample_0": "There's ample space in the backyard for a garden.",
	"ample_1": "She provided ample evidence to support her theory.",
	"ample_2": "The room had ample natural light, making it bright and welcoming.",
	"ample_3": "They made sure there was ample food for all the guests.",
	"ample_4": "The parking area offered ample parking spaces for visitors.",
	"cockpit_0": "The pilot made final checks in the cockpit before takeoff.",
	"cockpit_1": "The cockpit of the race car was compact and functional.",
	"cockpit_2": "Only authorized personnel are allowed in the airplane's cockpit.",
	"cockpit_3": "The museum displayed a replica of a World War II fighter cockpit.",
	"cockpit_4": "From the cockpit, the pilot had a clear view of the runway.",
	"suppress_0": "The government tried to suppress the news from spreading.",
	"suppress_1": "She took medication to suppress her cough.",
	"suppress_2": "The rebel forces were quickly suppressed by the army.",
	"suppress_3": "The police used water cannons to suppress the demonstration.",
	"suppress_4": "He struggled to suppress his anger during the heated debate.",
	"quantum_0": "Quantum physics explores the behavior of matter and energy at the smallest scales.",
	"quantum_1": "The scientist made a significant quantum leap in her research.",
	"quantum_2": "Quantum computing could revolutionize data processing.",
	"quantum_3": "They discussed the potential of quantum mechanics in technology.",
	"quantum_4": "The quantum theory provided a new perspective on atomic structure.",
	"fungus_0": "The fungus on the tree indicated a potential disease.",
	"fungus_1": "They studied different types of fungus in the biology class.",
	"fungus_2": "The bread had a greenish mold, a type of fungus.",
	"fungus_3": "Some fungus species are used in the production of antibiotics.",
	"fungus_4": "The damp conditions in the basement led to fungus growth.",
	"commission_0": "The artist received a commission to create a sculpture for the park.",
	"commission_1": "The commission investigated allegations of corruption in the agency.",
	"commission_2": "She earns a commission for every product she sells.",
	"commission_3": "The commission's report on the environmental impact was revealing.",
	"commission_4": "He was appointed to the commission that oversees public works.",
	"dull_0": "The lecture was so dull that several students fell asleep.",
	"dull_1": "The knife is dull and needs sharpening.",
	"dull_2": "Overcast skies can make the colors in the landscape appear dull.",
	"dull_3": "His routine life had become dull and monotonous.",
	"dull_4": "She tried to brighten the dull room with colorful paintings.",
	"tilt_0": "Tilt your head back slightly to stop the nosebleed.",
	"tilt_1": "The camera was on a tilt, giving an unusual perspective.",
	"tilt_2": "The earth's tilt is responsible for the changing seasons.",
	"tilt_3": "He had to tilt the bottle to pour out the last few drops.",
	"tilt_4": "The pinball machine flashed a warning when he tried to tilt it.",
	"reservoir_0": "The reservoir supplies water to the entire city.",
	"reservoir_1": "They hiked around the scenic reservoir on the weekend.",
	"reservoir_2": "The dam created a large reservoir in the valley.",
	"reservoir_3": "The reservoir's water level was low due to the drought.",
	"reservoir_4": "Birds flocked to the reservoir during their migration.",
	"payroll_0": "The new employees were added to the company's payroll.",
	"payroll_1": "Managing payroll is an important part of human resources.",
	"payroll_2": "The payroll system automates the calculation of wages and taxes.",
	"payroll_3": "Due to budget cuts, several positions were removed from the payroll.",
	"payroll_4": "She received her first check since being on the payroll.",
	"embody_0": "The statue is meant to embody the spirit of the city.",
	"embody_1": "He embodied the values of hard work and perseverance.",
	"embody_2": "The new policy embodies our commitment to environmental sustainability.",
	"embody_3": "Her speech embodied the hopes and dreams of many.",
	"embody_4": "The character in the novel embodies the typical hero archetype.",
	"vigorous_0": "He maintains his health through vigorous daily exercise.",
	"vigorous_1": "The debate among the candidates was vigorous and lively.",
	"vigorous_2": "The plant requires vigorous pruning to control its growth.",
	"vigorous_3": "She made a vigorous effort to clean up the neighborhood.",
	"vigorous_4": "The vigorous storm caused significant damage to the coastline.",
	"primitive_0": "The museum displayed primitive tools used by early humans.",
	"primitive_1": "Their living conditions were quite primitive compared to modern standards.",
	"primitive_2": "The artist's style was raw and primitive, yet captivating.",
	"primitive_3": "Primitive societies had their own complex social structures.",
	"primitive_4": "He enjoyed camping in the wilderness, away from primitive comforts.",
	"optimism_0": "Despite the setbacks, he maintained an air of optimism.",
	"optimism_1": "The team's victory restored the fans' optimism about the season.",
	"optimism_2": "Her optimism was infectious, uplifting everyone around her.",
	"optimism_3": "Economic indicators have led to renewed optimism in the market.",
	"optimism_4": "The doctor's optimism about the patient's recovery was reassuring.",
	"artillery_0": "The sound of artillery fire echoed in the distance.",
	"artillery_1": "The army's artillery unit was deployed to the front lines.",
	"artillery_2": "During the battle, the artillery played a crucial role.",
	"artillery_3": "He studied the history of artillery in military warfare.",
	"artillery_4": "The museum's exhibit included a piece of medieval artillery.",
	"cathedral_0": "The cathedral is famous for its stunning Gothic architecture.",
	"cathedral_1": "They attended the midnight service at the old cathedral.",
	"cathedral_2": "The cathedral's stained glass windows were a sight to behold.",
	"cathedral_3": "Restoration work on the cathedral aimed to preserve its historical integrity.",
	"cathedral_4": "The sound of the cathedral bells could be heard across the city.",
	"clergy_0": "The clergy played a vital role in the community's spiritual life.",
	"clergy_1": "Members of the clergy were invited to participate in the interfaith dialogue.",
	"clergy_2": "She decided to join the clergy after years of religious study.",
	"clergy_3": "The clergyman gave an inspiring sermon at the cathedral.",
	"clergy_4": "The event was attended by clergy from various denominations.",
	"injection_0": "The patient received an antibiotic injection to treat the infection.",
	"injection_1": "The new policy was like a fresh injection of enthusiasm into the team.",
	"injection_2": "He was nervous about the injection, but it was over quickly.",
	"injection_3": "The company needed a cash injection to survive the financial crisis.",
	"injection_4": "The development of injection molding revolutionized plastic manufacturing.",
	"persistent_0": "Her persistent efforts eventually led to the project's success.",
	"persistent_1": "The detective's persistent questioning uncovered the truth.",
	"persistent_2": "Despite persistent rain, the outdoor event continued as planned.",
	"persistent_3": "He had a persistent cough that wouldn't go away.",
	"persistent_4": "Persistent advocacy led to changes in the legislation.",
	"license_0": "She finally got her driver's license after months of practice.",
	"license_1": "The restaurant applied for a license to serve alcohol.",
	"license_2": "His book was adapted into a movie under a creative license.",
	"license_3": "Operating without a valid business license can lead to penalties.",
	"license_4": "The software license allows use on up to three devices.",
	"stat_0": "The coach reviewed the player's stats before the game.",
	"stat_1": "The hospital staff responded to the emergency stat.",
	"stat_2": "He is a sports enthusiast who loves discussing player stats.",
	"stat_3": "The project's success stats were impressive.",
	"stat_4": "The doctor requested blood work to be done stat.",
	"caribbean_0": "They went on a cruise to several Caribbean islands.",
	"caribbean_1": "The Caribbean is known for its diverse cultures and beautiful beaches.",
	"caribbean_2": "Reggae music originated in the Caribbean.",
	"caribbean_3": "She studied the history of the Caribbean nations at university.",
	"caribbean_4": "The hurricane caused significant damage in the Caribbean region.",
	"beverage_0": "The cafe offers a variety of hot and cold beverages.",
	"beverage_1": "The flight attendant served beverages to the passengers.",
	"beverage_2": "Her favorite morning beverage is a strong cup of coffee.",
	"beverage_3": "The company is a major producer of non-alcoholic beverages.",
	"beverage_4": "They raised a toast with a sparkling beverage to celebrate.",
	"defect_0": "The inspector found a defect in the pipe system.",
	"defect_1": "The product was returned due to a manufacturing defect.",
	"defect_2": "The scientist studied genetic defects and their implications.",
	"defect_3": "The defect in the car's design led to a recall.",
	"defect_4": "Despite its defect, the antique vase was valuable.",
	"beta_0": "The software is currently in beta testing.",
	"beta_1": "Beta readers provided feedback on her novel's early draft.",
	"beta_2": "The company released a beta version of its new app.",
	"beta_3": "As a beta tester, he reported several bugs.",
	"beta_4": "The beta phase is crucial for product development.",
	"memo_0": "The manager distributed a memo to all employees.",
	"memo_1": "She wrote a memo outlining the new policy changes.",
	"memo_2": "The confidential memo was leaked to the press.",
	"memo_3": "He kept the memo brief and to the point.",
	"memo_4": "The memo included a summary of the meeting's key points.",
	"trench_0": "Archaeologists excavated a trench to uncover ancient artifacts.",
	"trench_1": "Soldiers dug trenches for protection during the battle.",
	"trench_2": "The construction workers dug a trench for the new pipeline.",
	"trench_3": "He wore a classic trench coat on rainy days.",
	"trench_4": "The trench around the castle was part of its defenses.",
	"notorious_0": "The hacker became notorious for his cybercrimes.",
	"notorious_1": "The hotel was notorious for its extravagant parties.",
	"notorious_2": "She wrote a book about the city's most notorious criminals.",
	"notorious_3": "The notorious scandal rocked the government.",
	"notorious_4": "He was a notorious figure in the art world.",
	"stereo_0": "He turned up the stereo to enjoy the music.",
	"stereo_1": "The stereo system was a key part of his home theater.",
	"stereo_2": "She bought a new car stereo with advanced features.",
	"stereo_3": "The room was filled with the sound of the stereo.",
	"stereo_4": "His collection of vinyl records sounded great on the stereo.",
	"crude_0": "Crude oil prices fluctuated due to the global market changes.",
	"crude_1": "His crude drawing captured the essence of the scene.",
	"crude_2": "The joke he told was rather crude and offensive.",
	"crude_3": "They extracted crude oil from the offshore wells.",
	"crude_4": "The mechanism was crude but functional.",
	"viable_0": "The business plan seemed viable and promising.",
	"viable_1": "Doctors assessed the viability of the proposed treatment.",
	"viable_2": "The seeds were no longer viable after being stored improperly.",
	"viable_3": "They discussed whether the project was a viable option.",
	"viable_4": "The company sought viable solutions to reduce costs.",
	"filter_0": "The water filter removes impurities before consumption.",
	"filter_1": "He used a filter to enhance his photo on social media.",
	"filter_2": "The coffee dripped slowly through the filter.",
	"filter_3": "The air filter needs to be changed regularly.",
	"filter_4": "She could hear everything through the thin wall, with no filter.",
	"permanently_0": "The accident permanently affected his mobility.",
	"permanently_1": "The sculpture was permanently installed in the public square.",
	"permanently_2": "She moved to the countryside permanently.",
	"permanently_3": "The data was permanently deleted from the hard drive.",
	"permanently_4": "The landmark was permanently closed for restoration.",
	"simulation_0": "The pilot trained using a flight simulation program.",
	"simulation_1": "The simulation showed how the hurricane might progress.",
	"simulation_2": "Students practiced on the medical simulation before the real procedure.",
	"simulation_3": "The computer simulation modeled the behavior of galaxies.",
	"simulation_4": "They used a simulation to predict traffic patterns in the city.",
	"destructive_0": "The hurricane was one of the most destructive in history.",
	"destructive_1": "His criticism was often harsh and destructive.",
	"destructive_2": "The fire caused destructive damage to the forest.",
	"destructive_3": "The invasive species had a destructive impact on the local ecosystem.",
	"destructive_4": "The destructive nature of the conflict led to widespread despair.",
	"stabilize_0": "The patient's condition began to stabilize after treatment.",
	"stabilize_1": "Measures were taken to stabilize the economy.",
	"stabilize_2": "The engineers worked to stabilize the structure.",
	"stabilize_3": "Stabilizing the camera is essential for a clear shot.",
	"stabilize_4": "The government aimed to stabilize the housing market.",
	"illuminate_0": "The streetlights illuminate the road at night.",
	"illuminate_1": "The documentary aimed to illuminate important social issues.",
	"illuminate_2": "Candles were used to illuminate the room during the power outage.",
	"illuminate_3": "Her explanation helped to illuminate the complex topic.",
	"illuminate_4": "The art exhibit was beautifully illuminated with spotlights.",
	"roam_0": "The cattle roamed freely across the vast plains.",
	"roam_1": "He liked to roam the city streets, discovering hidden gems.",
	"roam_2": "Wild animals roam the national park.",
	"roam_3": "She roamed the world as a travel writer.",
	"roam_4": "Children were roaming the neighborhood on their bicycles.",
	"utterly_0": "The movie was utterly captivating from start to finish.",
	"utterly_1": "He felt utterly defeated after the loss.",
	"utterly_2": "The garden was utterly transformed by the new design.",
	"utterly_3": "She was utterly amazed by the unexpected surprise.",
	"utterly_4": "The statement was utterly ridiculous and unfounded.",
	"configuration_0": "The room's configuration was changed to create more space.",
	"configuration_1": "The software allows users to customize their configuration.",
	"configuration_2": "The seating configuration in the auditorium varied for different events.",
	"configuration_3": "The molecules in the crystal had a unique configuration.",
	"configuration_4": "They tested various aircraft configurations for efficiency.",
	"loom_0": "The deadline loomed, adding pressure to the team.",
	"loom_1": "Dark clouds loomed overhead, signaling a storm.",
	"loom_2": "The mountain loomed large in the distance.",
	"loom_3": "The possibility of failure loomed in his mind.",
	"loom_4": "The old castle loomed eerily in the fog.",
	"probe_0": "The probe into the company's finances revealed discrepancies.",
	"probe_1": "The spacecraft sent a probe to study the planet's surface.",
	"probe_2": "Doctors used a probe to examine the wound.",
	"probe_3": "The investigation will probe deeper into the allegations.",
	"probe_4": "The journalist's probing questions made the politician uncomfortable.",
	"patriot_0": "The parade was a celebration of patriot pride in the country.",
	"patriot_1": "As a true patriot, he served in the armed forces.",
	"patriot_2": "The book chronicled the stories of unsung patriots in history.",
	"patriot_3": "Her speech was a stirring call to the patriots of the nation.",
	"patriot_4": "The museum exhibited artifacts belonging to the early American patriots.",
	"venue_0": "The concert venue could accommodate thousands of fans.",
	"venue_1": "They chose a beautiful outdoor venue for their wedding.",
	"venue_2": "The venue for the conference was a downtown hotel.",
	"venue_3": "The new sports venue will host major international events.",
	"venue_4": "Finding the right venue is crucial for the success of the event.",
	"banner_0": "The protesters carried banners expressing their demands.",
	"banner_1": "The championship team's banner was hung in the stadium.",
	"banner_2": "Their website features a banner with the latest news.",
	"banner_3": "The street was decorated with banners for the festival.",
	"banner_4": "Under the banner of unity, they marched forward.",
	"lone_0": "A lone tree stood in the middle of the field.",
	"lone_1": "She was the lone survivor of the accident.",
	"lone_2": "A lone wolf is often a symbol of independence.",
	"lone_3": "He took a lone stance against the proposal.",
	"lone_4": "The lone candle flickered in the dark room.",
	"cuff_0": "The detective put cuffs on the suspect.",
	"cuff_1": "His shirt had stylish French cuffs.",
	"cuff_2": "She adjusted the cuff of her sleeve.",
	"cuff_3": "The cuff of his jeans was frayed.",
	"cuff_4": "A blood pressure cuff was wrapped around her arm.",
	"machinery_0": "The factory's machinery was outdated and needed replacing.",
	"machinery_1": "He was fascinated by the heavy machinery used in construction.",
	"machinery_2": "The machinery in the workshop hummed steadily.",
	"machinery_3": "Maintenance is crucial to keep the machinery running smoothly.",
	"machinery_4": "The agricultural machinery transformed the efficiency of farming practices.",
	"radical_0": "His ideas were considered radical at the time.",
	"radical_1": "The group advocated for radical changes in policy.",
	"radical_2": "The treatment was a radical departure from traditional methods.",
	"radical_3": "She underwent radical surgery to combat the illness.",
	"radical_4": "The artist was known for his radical style.",
	"compile_0": "She compiled data from various sources for the report.",
	"compile_1": "The book was compiled over years of research.",
	"compile_2": "Programmers compile code to create software applications.",
	"compile_3": "He compiled a playlist for the road trip.",
	"compile_4": "The committee compiled a list of recommendations.",
	"victorian_0": "The museum showcased Victorian era clothing.",
	"victorian_1": "They restored a Victorian house to its original glory.",
	"victorian_2": "Victorian literature includes works by Dickens and Brontë.",
	"victorian_3": "The Victorian period was known for its distinct architectural style.",
	"victorian_4": "She wore a dress with a Victorian flair to the party.",
	"neighboring_0": "They visited the neighboring town for its famous market.",
	"neighboring_1": "The fire spread to neighboring buildings.",
	"neighboring_2": "Cooperation with neighboring countries strengthened regional security.",
	"neighboring_3": "The noise from the neighboring apartment was bothersome.",
	"neighboring_4": "The festival attracted people from neighboring states.",
	"commercial_0": "The commercial success of the product exceeded expectations.",
	"commercial_1": "They filmed a commercial to advertise the new car.",
	"commercial_2": "The property was zoned for commercial use.",
	"commercial_3": "Her song was used in a popular television commercial.",
	"commercial_4": "The artist's work moved from fine art to commercial design.",
	"plot_0": "The novel's plot was full of unexpected twists.",
	"plot_1": "They uncovered a plot to sabotage the project.",
	"plot_2": "The family owned a small plot of land in the countryside.",
	"plot_3": "The movie's plot revolved around a mysterious disappearance.",
	"plot_4": "Gardeners rented plots in the community garden.",
	"indulge_0": "She decided to indulge in a spa day.",
	"indulge_1": "They indulged their love of travel by going on a world tour.",
	"indulge_2": "The restaurant is the perfect place to indulge your sweet tooth.",
	"indulge_3": "He indulged in reminiscing about his childhood.",
	"indulge_4": "On her birthday, she indulged in some retail therapy.",
	"confine_0": "The patient was confined to bed after the surgery.",
	"confine_1": "They confined the discussion to the topic at hand.",
	"confine_2": "The animals should not be confined in small cages.",
	"confine_3": "He didn't like to confine his studies to just one subject.",
	"confine_4": "The outbreak was confined to a single region.",
	"expenditure_0": "The company's expenditure on research and development increased.",
	"expenditure_1": "They kept track of their daily expenditures while traveling.",
	"expenditure_2": "Reducing energy expenditure was a key goal for the factory.",
	"expenditure_3": "The budget report detailed the project's expenditure.",
	"expenditure_4": "The government aimed to cut unnecessary expenditures.",
	"jewel_0": "The crown was adorned with jewels of every color.",
	"jewel_1": "She treasured the jewel her grandmother had given her.",
	"jewel_2": "The museum displayed ancient jewels found in the excavation.",
	"jewel_3": "The artisan crafted a beautiful jewel-encrusted box.",
	"jewel_4": "The tiny village was a hidden jewel in the mountains.",
	"stroll_0": "They took a leisurely stroll through the park.",
	"stroll_1": "The couple strolled along the beach at sunset.",
	"stroll_2": "He enjoyed a morning stroll to start his day.",
	"stroll_3": "The street was perfect for a relaxed evening stroll.",
	"stroll_4": "On their vacation, they strolled through quaint village streets.",
	"federation_0": "The sports federation organized the national championship.",
	"federation_1": "Several states formed a federation for mutual benefit.",
	"federation_2": "The federation of clubs held an annual meeting.",
	"federation_3": "The trade federation lobbied for new industry regulations.",
	"federation_4": "The federation provided guidelines for its member organizations.",
	"discomfort_0": "The patient expressed discomfort after the procedure.",
	"discomfort_1": "There was a noticeable discomfort in the room during the discussion.",
	"discomfort_2": "To ease his discomfort, he adjusted the chair.",
	"discomfort_3": "She felt discomfort at the high altitude.",
	"discomfort_4": "The new shoes caused some discomfort on the first wear.",
	"nucleus_0": "The nucleus is the central and most important part of an atom.",
	"nucleus_1": "The team's nucleus of experienced players provided stability.",
	"nucleus_2": "The nucleus of the cell contains its genetic material.",
	"nucleus_3": "The old cathedral formed the nucleus of the medieval town.",
	"nucleus_4": "A strong family unit was the nucleus of her upbringing.",
	"communism_0": "The history book covered the rise and fall of communism in the region.",
	"communism_1": "His studies focused on the impact of communism in the 20th century.",
	"communism_2": "Under communism, the state controlled all means of production.",
	"communism_3": "She wrote her thesis on the artistic movements during the era of communism.",
	"communism_4": "The collapse of communism led to significant political changes.",
	"ivory_0": "The antique piano had keys made of ivory.",
	"ivory_1": "She wore a dress of soft ivory color for her wedding.",
	"ivory_2": "The museum displayed an intricate ivory carving from the 18th century.",
	"ivory_3": "The trade in ivory has been banned to protect elephants.",
	"ivory_4": "He admired the ivory chess pieces, hand-carved and delicate.",
	"granite_0": "The kitchen countertops were made of polished granite.",
	"granite_1": "The monument was carved from a single block of granite.",
	"granite_2": "They chose a durable granite surface for the outdoor table.",
	"granite_3": "Granite cliffs towered over the coastal village.",
	"granite_4": "The pathway was lined with smooth granite stones.",
	"lease_0": "They signed a lease for the new apartment.",
	"lease_1": "The company took out a lease on a larger office space.",
	"lease_2": "Her lease expires next month, and she's considering renewing it.",
	"lease_3": "The restaurant operates on leased property downtown.",
	"lease_4": "Before leasing the car, they reviewed all the terms and conditions.",
	"porter_0": "At the train station, a porter helped them with their luggage.",
	"porter_1": "He worked as a porter in the hotel, greeting guests.",
	"porter_2": "The mountain expedition hired a local porter to carry supplies.",
	"porter_3": "The porter navigated through the crowded lobby with the luggage trolley.",
	"porter_4": "In the old days, a railway porter would assist passengers onto the train.",
	"offset_0": "They planted trees to offset their carbon emissions.",
	"offset_1": "The increase in sales helped to offset the rise in production costs.",
	"offset_2": "He used humor to offset the tension in the meeting.",
	"offset_3": "The government introduced tax breaks to offset economic challenges.",
	"offset_4": "The benefits of the new policy should offset its drawbacks.",
	"bearing_0": "The compass helped them get their bearings in the forest.",
	"bearing_1": "He lost his bearings in the unfamiliar city.",
	"bearing_2": "The mechanic checked the car's wheel bearings during the service.",
	"bearing_3": "Her confident bearing impressed the interviewers.",
	"bearing_4": "The news had no bearing on the decision made.",
	"halt_0": "The train came to a sudden halt at the station.",
	"halt_1": "Production was brought to a halt due to equipment failure.",
	"halt_2": "The government imposed regulations to halt the spread of the disease.",
	"halt_3": "He raised his hand to halt the advancing crowd.",
	"halt_4": "Negotiations came to a halt after disagreements emerged.",
	"circulate_0": "The fan helped to circulate air in the stuffy room.",
	"circulate_1": "Rumors about the merger began to circulate in the office.",
	"circulate_2": "Blood circulates throughout the body, carrying oxygen and nutrients.",
	"circulate_3": "The petition is circulating online and gaining signatures.",
	"circulate_4": "The report circulated among the staff for feedback.",
	"refine_0": "The company continues to refine its strategies for market expansion.",
	"refine_1": "The process to refine oil involves several stages.",
	"refine_2": "Artists often refine their techniques over years of practice.",
	"refine_3": "The chef refined the recipe to achieve the perfect flavor balance.",
	"refine_4": "They used a filter to refine the water, removing impurities.",
	"lineup_0": "The festival's lineup includes several well-known bands.",
	"lineup_1": "The police asked the witness to identify the suspect in a lineup.",
	"lineup_2": "The store is introducing a new product lineup this fall.",
	"lineup_3": "The coach announced the team's lineup for the final match.",
	"lineup_4": "The network's fall lineup features a variety of new shows.",
	"petroleum_0": "The country's economy heavily relies on petroleum exports.",
	"petroleum_1": "Petroleum is a key raw material in the production of plastics.",
	"petroleum_2": "Environmentalists advocate for reducing dependence on petroleum-based fuels.",
	"petroleum_3": "The region's petroleum reserves have attracted global interest.",
	"petroleum_4": "The price of petroleum fluctuates with global market trends.",
	"recount_0": "She recounted her adventures during her trip abroad.",
	"recount_1": "The votes were recounted to ensure accuracy in the election.",
	"recount_2": "He could recount numerous stories from his childhood.",
	"recount_3": "The novel recounts the experiences of immigrants in the early 20th century.",
	"recount_4": "Witnesses were asked to recount what they saw during the incident.",
	"sedan_0": "They bought a four-door sedan for family convenience.",
	"sedan_1": "The sleek sedan stood out in the parking lot.",
	"sedan_2": "Her dream car was a luxury sedan with all the latest features.",
	"sedan_3": "The sedan was spacious enough to accommodate all their luggage.",
	"sedan_4": "In the race, the sports sedan outperformed its competitors.",
	"surgical_0": "The surgeon performed a complex surgical procedure.",
	"surgical_1": "Surgical precision is required in delicate operations.",
	"surgical_2": "They wore surgical masks in the sterile environment.",
	"surgical_3": "The advancements in surgical techniques have improved patient outcomes.",
	"surgical_4": "He chose to specialize in surgical oncology.",
	"peninsula_0": "The peninsula was known for its breathtaking coastal views.",
	"peninsula_1": "They spent their vacation exploring the small towns dotting the peninsula.",
	"peninsula_2": "The peninsula jutted out into the blue sea.",
	"peninsula_3": "A lighthouse stood at the tip of the peninsula.",
	"peninsula_4": "The ancient ruins were located on a remote part of the peninsula.",
	"battalion_0": "The battalion was deployed to the conflict zone.",
	"battalion_1": "He commanded a battalion during his military service.",
	"battalion_2": "The battalion consisted of several hundred soldiers.",
	"battalion_3": "The fire battalion responded swiftly to the emergency.",
	"battalion_4": "A ceremonial parade was held to honor the returning battalion.",
	"ponder_0": "She sat by the window, pondering her decision.",
	"ponder_1": "The philosopher's writings made him ponder the meaning of life.",
	"ponder_2": "He pondered the puzzle before finding the solution.",
	"ponder_3": "They took a moment to ponder their next move in the game.",
	"ponder_4": "The artist's work encourages viewers to ponder deeper issues.",
	"activate_0": "Press the button to activate the alarm system.",
	"activate_1": "The device will automatically activate in case of an emergency.",
	"activate_2": "They needed a password to activate the software.",
	"activate_3": "Activate your account by verifying your email address.",
	"activate_4": "The sensor is designed to activate under specific conditions.",
	"garment_0": "The tailor adjusted the garment for a perfect fit.",
	"garment_1": "She designs garments that combine comfort and style.",
	"garment_2": "Each garment in the collection was handcrafted.",
	"garment_3": "The museum displayed traditional garments from various cultures.",
	"garment_4": "He hung the delicate garment to dry naturally.",
	"velocity_0": "The velocity of the wind increased as the storm approached.",
	"velocity_1": "The experiment measured the velocity of falling objects.",
	"velocity_2": "The car's high velocity made it difficult to control.",
	"velocity_3": "Scientists study the velocity of seismic waves during earthquakes.",
	"velocity_4": "The runner increased his velocity in the final stretch of the race.",
	"motif_0": "The floral motif was a common theme in her artwork.",
	"motif_1": "The building's design incorporated traditional motifs.",
	"motif_2": "Her speech repeated the motif of hope and resilience.",
	"motif_3": "The fabric featured a colorful geometric motif.",
	"motif_4": "He recognized the cultural motif in the music.",
	"aside_0": "He pulled me aside to share a confidential matter.",
	"aside_1": "Aside from her job, she also volunteers at a local charity.",
	"aside_2": "She jokingly made an aside during her speech that had everyone laughing.",
	"aside_3": "Let's set that issue aside for now and focus on the immediate problem.",
	"aside_4": "He wrote down an aside in his notes, a reminder for later.",
	"usher_0": "The usher guided us to our seats in the theater.",
	"usher_1": "His invention helped usher in a new era of technology.",
	"usher_2": "The wedding usher ensured that guests were comfortably seated.",
	"usher_3": "The changes in policy will usher in much-needed reforms.",
	"usher_4": "As an usher at the cinema, he enjoyed watching the movies.",
	"perch_0": "The bird perched on the branch, observing its surroundings.",
	"perch_1": "She perched on the edge of the table while talking to him.",
	"perch_2": "The cat was perched atop the fence, watching the dogs below.",
	"perch_3": "They found a perfect perch on the hilltop to view the sunset.",
	"perch_4": "The small café is perched on a cliff overlooking the ocean.",
	"endorsement_0": "The product received an endorsement from a well-known athlete.",
	"endorsement_1": "Her endorsement of the book greatly increased its popularity.",
	"endorsement_2": "The candidate was thrilled to receive the party's official endorsement.",
	"endorsement_3": "The celebrity's endorsement deal was worth millions.",
	"endorsement_4": "He was hesitant to give his endorsement without more information.",
	"packet_0": "She tore open the packet of ketchup for her fries.",
	"packet_1": "The packet of documents contained all the necessary information.",
	"packet_2": "He downloaded a packet of resources for the course.",
	"packet_3": "The seed packet included instructions for planting.",
	"packet_4": "Each student received a packet with the workshop agenda.",
	"consulting_0": "After retiring, she started her own consulting business.",
	"consulting_1": "The company hired a consulting firm to improve efficiency.",
	"consulting_2": "His consulting work took him all over the world.",
	"consulting_3": "She offered consulting services to non-profit organizations.",
	"consulting_4": "The project benefited from his expertise in environmental consulting.",
	"mandatory_0": "Attendance at the meeting is mandatory for all staff members.",
	"mandatory_1": "The course includes a mandatory safety training session.",
	"mandatory_2": "It is mandatory to wear helmets while riding motorcycles.",
	"mandatory_3": "The government made it mandatory to report such incidents.",
	"mandatory_4": "A mandatory evacuation order was issued due to the hurricane.",
	"backdrop_0": "The mountains provided a stunning backdrop for the wedding.",
	"backdrop_1": "The political drama unfolded against the backdrop of war.",
	"backdrop_2": "The stage was set with an elaborate backdrop.",
	"backdrop_3": "They took photos against the backdrop of the city skyline.",
	"backdrop_4": "The novel is set against the backdrop of the 18th century.",
	"commentary_0": "The documentary featured commentary from various experts.",
	"commentary_1": "He provided live commentary during the football match.",
	"commentary_2": "The book offers insightful commentary on modern society.",
	"commentary_3": "The director's commentary on the DVD revealed interesting facts.",
	"commentary_4": "Her social media posts are filled with witty commentary.",
	"italian_0": "They dined at an authentic Italian restaurant downtown.",
	"italian_1": "She studied Italian Renaissance art in college.",
	"italian_2": "His love for Italian cuisine inspired him to learn cooking.",
	"italian_3": "The Italian landscape is renowned for its beauty.",
	"italian_4": "They traveled through several Italian cities on their vacation.",
	"proponent_0": "She was a leading proponent of the environmental movement.",
	"proponent_1": "As a proponent of education reform, he suggested several changes.",
	"proponent_2": "He's known as a proponent of clean energy technologies.",
	"proponent_3": "The policy found a strong proponent in the senator.",
	"proponent_4": "As an early proponent, she invested in the technology.",
	"compound_0": "The research facility was surrounded by a high-security compound.",
	"compound_1": "The chemical compound has a range of industrial applications.",
	"compound_2": "They lived in a residential compound with various amenities.",
	"compound_3": "The compound fracture required immediate medical attention.",
	"compound_4": "The linguistic study focused on the use of compound words.",
	"tuition_0": "Tuition fees for the university have increased this year.",
	"tuition_1": "They set up a fund to help with their children's tuition.",
	"tuition_2": "Tuition reimbursement is one of the benefits the company offers.",
	"tuition_3": "He took private tuition to improve his math skills.",
	"tuition_4": "The scholarship covers full tuition for the academic year.",
	"velvet_0": "She wore a dress made of deep blue velvet.",
	"velvet_1": "The antique chair was upholstered in red velvet.",
	"velvet_2": "He presented the ring in a small velvet box.",
	"velvet_3": "The velvet curtains added a touch of luxury to the room.",
	"velvet_4": "Her voice had a rich, velvet quality.",
	"bachelor_0": "He recently received his bachelor's degree in engineering.",
	"bachelor_1": "The bachelor party was planned for the weekend.",
	"bachelor_2": "As a confirmed bachelor, he enjoyed his independent lifestyle.",
	"bachelor_3": "The show's new bachelor is a successful entrepreneur.",
	"bachelor_4": "He lived in a bachelor apartment downtown.",
	"eternal_0": "The monument stands as an eternal symbol of peace.",
	"eternal_1": "They promised each other eternal love.",
	"eternal_2": "The search for eternal youth has fascinated people for centuries.",
	"eternal_3": "The stars in the night sky seem eternal and unchanging.",
	"eternal_4": "Her eternal optimism was contagious.",
	"tout_0": "The company touted its new product as a technological breakthrough.",
	"tout_1": "He was widely touted as the next CEO.",
	"tout_2": "Local tourism agencies tout the area as a hidden gem.",
	"tout_3": "The diet plan was touted for its quick results.",
	"tout_4": "Critics touted the film as the year's best.",
	"implant_0": "She received a dental implant to replace a missing tooth.",
	"implant_1": "The doctor discussed the possibility of a pacemaker implant.",
	"implant_2": "The technological implant enhanced his physical abilities.",
	"implant_3": "The implant procedure is relatively quick and painless.",
	"implant_4": "Her research focuses on biocompatible materials for implants.",
	"conquer_0": "The ancient general sought to conquer new territories.",
	"conquer_1": "She had to conquer her fear of public speaking.",
	"conquer_2": "The mountaineers conquered the peak after a grueling climb.",
	"conquer_3": "The novel tells the story of a hero who conquers adversity.",
	"conquer_4": "After many attempts, he finally conquered the complex puzzle.",
	"patio_0": "They enjoyed summer evenings on the patio.",
	"patio_1": "The patio was decorated with lights for the party.",
	"patio_2": "The restaurant offers outdoor dining on the patio.",
	"patio_3": "They renovated the backyard to include a stone patio.",
	"patio_4": "Plants and flowers adorned the sunny patio.",
	"inclined_0": "As an artist, she was inclined towards creative pursuits.",
	"inclined_1": "He felt inclined to agree with their proposal.",
	"inclined_2": "They were inclined to stay home due to the weather.",
	"inclined_3": "The path was steeply inclined, making the hike challenging.",
	"inclined_4": "She's always been inclined to help others in need.",
	"affirm_0": "The court's decision affirmed the rights of the individual.",
	"affirm_1": "He affirmed his commitment to the project.",
	"affirm_2": "The study's results affirm the theory's validity.",
	"affirm_3": "She publicly affirmed her support for the initiative.",
	"affirm_4": "The experience affirmed his belief in the goodness of people.",
	"deduction_0": "The detective made a deduction based on the evidence.",
	"deduction_1": "The accountant calculated the tax deductions for the business.",
	"deduction_2": "His clever deduction solved the complex problem.",
	"deduction_3": "The deduction from her salary was for the health insurance.",
	"deduction_4": "The novel featured a detective known for his brilliant deductions.",
	"postwar_0": "The postwar period was marked by rapid economic growth.",
	"postwar_1": "The museum featured an exhibit on postwar art.",
	"postwar_2": "Her book focuses on postwar political developments.",
	"postwar_3": "The postwar era brought significant social changes.",
	"postwar_4": "He specialized in postwar European history.",
	"embark_0": "They embarked on a journey around the world.",
	"embark_1": "The project team is ready to embark on the new venture.",
	"embark_2": "She embarked on a career in medicine.",
	"embark_3": "The ship embarked on its maiden voyage.",
	"embark_4": "He embarked on the writing process with enthusiasm.",
	"preacher_0": "The preacher delivered a moving sermon at the service.",
	"preacher_1": "He was known as a fiery preacher who captivated his audience.",
	"preacher_2": "The preacher's message of love and tolerance resonated with the congregation.",
	"preacher_3": "As a street preacher, she shared her beliefs with passersby.",
	"preacher_4": "The preacher worked to build a sense of community in the church.",
	"ivy_0": "The old building was covered in thick ivy.",
	"ivy_1": "She decided to plant ivy along the fence for natural beauty.",
	"ivy_2": "Ivy climbed up the walls of the quaint cottage.",
	"ivy_3": "They took photos in front of the ivy-covered archway.",
	"ivy_4": "The ivy league colleges are known for their academic excellence.",
	"financing_0": "They secured financing for their new home.",
	"financing_1": "The startup was in need of additional financing to expand.",
	"financing_2": "She attended a seminar on financing small businesses.",
	"financing_3": "The film's financing came from several independent investors.",
	"financing_4": "They discussed various options for financing the project.",
	"linger_0": "The scent of flowers lingered in the air.",
	"linger_1": "Some guests lingered after the party to help clean up.",
	"linger_2": "He lingered in the bookstore, browsing the shelves.",
	"linger_3": "Memories of the trip lingered long after her return.",
	"linger_4": "The question lingered in her mind, unanswered.",
	"encompass_0": "The tour encompassed several major cities in Europe.",
	"encompass_1": "Her job encompasses a wide range of responsibilities.",
	"encompass_2": "The study encompasses an analysis of different age groups.",
	"encompass_3": "The term 'Classical music' encompasses various musical styles and periods.",
	"encompass_4": "The concept of health encompasses both physical and mental well-being.",
	"dale_0": "The small village nestled in a peaceful dale.",
	"dale_1": "They enjoyed a picnic in the sunny dale.",
	"dale_2": "The river flowed gently through the dale.",
	"dale_3": "He painted a landscape featuring a green, verdant dale.",
	"dale_4": "Legends tell of a mysterious creature living in the dale.",
	"fascinate_0": "The intricate machinery fascinated the young engineer.",
	"fascinate_1": "She was fascinated by the rich history of the castle.",
	"fascinate_2": "The magician's tricks continue to fascinate audiences.",
	"fascinate_3": "The complexity of the human brain fascinates researchers.",
	"fascinate_4": "His tales of adventure fascinated the children.",
	"chorus_0": "The chorus sang harmoniously in the concert.",
	"chorus_1": "A chorus of birdsong greeted them in the morning.",
	"chorus_2": "She joined the school's chorus group this year.",
	"chorus_3": "The play's Greek chorus provided commentary on the action.",
	"chorus_4": "His announcement was met with a chorus of approval.",
	"plaza_0": "The city's main plaza was bustling with activity.",
	"plaza_1": "They met at the central plaza before starting the tour.",
	"plaza_2": "The plaza in front of the theater was beautifully lit.",
	"plaza_3": "Street performers entertained the crowds in the plaza.",
	"plaza_4": "The new shopping plaza had a variety of stores.",
	"morality_0": "The philosopher wrote extensively on the subject of morality.",
	"morality_1": "The morality of the decision was hotly debated.",
	"morality_2": "His actions raised questions about the morality of corporate practices.",
	"morality_3": "The film explored themes of morality and justice.",
	"morality_4": "She adhered strictly to her personal code of morality.",
	"lounge_0": "The airport lounge offered a comfortable waiting area for passengers.",
	"lounge_1": "They relaxed in the hotel lounge after a long day.",
	"lounge_2": "The jazz band played in a cozy downtown lounge.",
	"lounge_3": "The students used the lounge as a study space.",
	"lounge_4": "The lounge chair by the pool was her favorite spot.",
	"hostility_0": "The negotiation ended in hostility between the two parties.",
	"hostility_1": "Despite the initial hostility, they eventually reached an agreement.",
	"hostility_2": "The hostile crowd voiced their displeasure loudly.",
	"hostility_3": "He faced hostility from skeptics who doubted his theory.",
	"hostility_4": "The proposal was met with hostility by the community.",
	"iris_0": "Her eyes were notable for their brightly colored irises.",
	"iris_1": "He planted a row of irises along the garden path.",
	"iris_2": "The camera's iris adjusted to the changing light.",
	"iris_3": "The artist captured the delicate features of the iris in his painting.",
	"iris_4": "Iris recognition technology is used for secure identification.",
	"rally_0": "The team rallied to win in the final minutes of the game.",
	"rally_1": "Supporters gathered at the rally to hear the candidate speak.",
	"rally_2": "The community organized a rally for climate action.",
	"rally_3": "The stock market rallied after the announcement of the merger.",
	"rally_4": "She rallied her friends to help with the charity event.",
	"dame_0": "Dame Judi Dench is an acclaimed actress of the British stage.",
	"dame_1": "The title of Dame was bestowed upon her for her philanthropic work.",
	"dame_2": "The old dame in the village was known for her wisdom.",
	"dame_3": "She played the role of the dame in the traditional pantomime.",
	"dame_4": "The community respected the dame for her contributions to local charities.",
	"incidence_0": "The incidence of the disease has decreased due to effective vaccination.",
	"incidence_1": "Researchers study the incidence and spread of infections.",
	"incidence_2": "There was a high incidence of car accidents at that intersection.",
	"incidence_3": "The report highlights the incidence of poverty in the region.",
	"incidence_4": "The incidence rate is a crucial factor in epidemiological studies.",
	"stride_0": "He crossed the room in just a few long strides.",
	"stride_1": "The company has made significant strides in product development.",
	"stride_2": "She improved her stride technique for better running efficiency.",
	"stride_3": "The industry has taken great strides toward sustainability.",
	"stride_4": "He hit his stride halfway through the marathon.",
	"grassroots_0": "The campaign started as a grass-roots movement.",
	"grassroots_1": "Grass-roots organizations played a key role in the community project.",
	"grassroots_2": "The politician's grass-roots approach resonated with the voters.",
	"grassroots_3": "They launched a grass-roots fundraising campaign.",
	"grassroots_4": "The policy change was a result of grass-roots advocacy.",
	"restraint_0": "The police officer used restraint in handling the situation.",
	"restraint_1": "Budgetary restraints limited the scope of the project.",
	"restraint_2": "He showed remarkable restraint in not responding to the provocation.",
	"restraint_3": "The safety restraints in the car were securely fastened.",
	"restraint_4": "The artist's use of color restraint made the painting striking.",
	"hierarchy_0": "The company's hierarchy was clearly defined in its organizational chart.",
	"hierarchy_1": "In the social hierarchy of high school, popularity played a big role.",
	"hierarchy_2": "The military operates within a strict hierarchy of ranks.",
	"hierarchy_3": "She challenged the traditional hierarchy in her community.",
	"hierarchy_4": "The hierarchy of needs theory is a well-known psychological model.",
	"lance_0": "The medieval knight wielded a lance in the tournament.",
	"lance_1": "The doctor used a small lance to perform the procedure.",
	"lance_2": "He lanced the boil with expert precision.",
	"lance_3": "In ancient warfare, the lance was a common weapon.",
	"lance_4": "The fisherman used a lance to catch the large fish.",
	"elusive_0": "The elusive criminal was finally caught by the police.",
	"elusive_1": "A solution to the problem proved elusive.",
	"elusive_2": "The rare bird is elusive and hard to spot.",
	"elusive_3": "The concept of happiness can sometimes seem elusive.",
	"elusive_4": "He was chasing an elusive dream of success.",
	"obscure_0": "The author was relatively obscure until her recent book.",
	"obscure_1": "The details of the ancient ritual remain obscure.",
	"obscure_2": "He made an obscure reference that few understood.",
	"obscure_3": "The path through the forest was obscure and overgrown.",
	"obscure_4": "The film gained a cult following despite its initial obscurity.",
	"prestigious_0": "She won a prestigious award for her scientific research.",
	"prestigious_1": "The university is known for its prestigious academic programs.",
	"prestigious_2": "The prestigious firm attracted clients from around the world.",
	"prestigious_3": "The competition was a prestigious event in the industry.",
	"prestigious_4": "He graduated from a prestigious law school.",
	"bout_0": "After a brief bout of illness, he returned to work.",
	"bout_1": "The boxer won his latest bout with a knockout.",
	"bout_2": "She had a bout of homesickness during her travels.",
	"bout_3": "The team suffered a bout of poor performance this season.",
	"bout_4": "The country experienced a bout of economic instability.",
	"embed_0": "The journalist was embedded with the military unit.",
	"embed_1": "The software allows you to embed videos in presentations.",
	"embed_2": "Small stones were embedded in the concrete walkway.",
	"embed_3": "The experience embedded a deep sense of gratitude in him.",
	"embed_4": "The spy was embedded in the organization for years.",
	"stain_0": "The red wine left a stain on the carpet.",
	"stain_1": "His reputation was stained by the scandal.",
	"stain_2": "The wood was treated with a dark stain.",
	"stain_3": "She managed to remove the ink stain from her shirt.",
	"stain_4": "The sun's rays can stain fabrics over time.",
	"unveil_0": "The artist will unveil her latest sculpture at the gallery.",
	"unveil_1": "The company is set to unveil a new line of products.",
	"unveil_2": "The memorial was unveiled in a solemn ceremony.",
	"unveil_3": "Plans to unveil the new park to the public are underway.",
	"unveil_4": "The museum unveiled a rare artifact from ancient times.",
	"pipeline_0": "The proposed pipeline has sparked environmental concerns.",
	"pipeline_1": "He's working on several projects that are in the pipeline.",
	"pipeline_2": "The pipeline transports oil across several states.",
	"pipeline_3": "The company has a pipeline of innovative products.",
	"pipeline_4": "The educational program acts as a pipeline for young talent.",
	"applicant_0": "The job applicant presented an impressive resume.",
	"applicant_1": "Each applicant must submit a portfolio of their work.",
	"applicant_2": "The number of applicants for the course exceeded expectations.",
	"applicant_3": "The visa applicant was interviewed at the embassy.",
	"applicant_4": "The scholarship program attracted hundreds of applicants.",
	"deposit_0": "She made a deposit into her savings account.",
	"deposit_1": "The hotel requires a deposit to secure the reservation.",
	"deposit_2": "A large deposit of natural gas was discovered in the area.",
	"deposit_3": "The mineral deposit is a significant find for the company.",
	"deposit_4": "He received his security deposit back after vacating the apartment.",
	"robust_0": "The system is designed to be robust and reliable.",
	"robust_1": "He has a robust constitution, rarely getting sick.",
	"robust_2": "The robust debate was a sign of a healthy democracy.",
	"robust_3": "The wine is known for its robust flavor.",
	"robust_4": "They developed a robust strategy to tackle the issue.",
	"withhold_0": "The company decided to withhold the release of the product.",
	"withhold_1": "She chose to withhold judgment until she had all the information.",
	"withhold_2": "The government was accused of withholding crucial data.",
	"withhold_3": "They withheld part of his salary for tax purposes.",
	"withhold_4": "The witness decided to withhold information from the police.",
	"aviation_0": "The aviation industry has been greatly affected by the pandemic.",
	"aviation_1": "He pursued a career in aviation after leaving the military.",
	"aviation_2": "The museum showcases the history of aviation.",
	"aviation_3": "Safety is a top priority in aviation regulations.",
	"aviation_4": "The conference gathered experts from the aviation sector.",
	"adjacent_0": "Their house is adjacent to a beautiful park.",
	"adjacent_1": "The two countries are adjacent to each other.",
	"adjacent_2": "The hotel room had an adjacent lounge area.",
	"adjacent_3": "The school expanded by building on the adjacent land.",
	"adjacent_4": "The factory is located adjacent to the railway line.",
	"lethal_0": "The venom of the snake is highly lethal.",
	"lethal_1": "The detective investigated the use of a lethal weapon in the crime.",
	"lethal_2": "The dose of the drug was accidentally lethal.",
	"lethal_3": "Soldiers are trained to use lethal force if necessary.",
	"lethal_4": "The plant produces a lethal toxin.",
	"terrace_0": "They enjoyed breakfast on the sunny terrace overlooking the garden.",
	"terrace_1": "The hillside was lined with houses featuring beautiful terraces.",
	"terrace_2": "The restaurant's terrace offered a stunning view of the city skyline.",
	"terrace_3": "They transformed the rooftop into a green terrace.",
	"terrace_4": "In the evening, the terrace became a perfect spot for stargazing.",
	"devotion_0": "Her devotion to her family was admirable.",
	"devotion_1": "He showed great devotion in his daily work.",
	"devotion_2": "The shrine was a place of devotion and prayer.",
	"devotion_3": "The book described the saint's life of devotion.",
	"devotion_4": "Their devotion to environmental causes inspired many.",
	"scrutiny_0": "The company's financial dealings came under intense scrutiny.",
	"scrutiny_1": "He faced public scrutiny after the allegations surfaced.",
	"scrutiny_2": "Her work stands up to the closest scrutiny.",
	"scrutiny_3": "The policy change is subject to legal scrutiny.",
	"scrutiny_4": "The research project underwent rigorous ethical scrutiny.",
	"pier_0": "They strolled along the pier, watching the boats.",
	"pier_1": "Fishermen lined the pier, hoping for a good catch.",
	"pier_2": "The old pier was a popular spot for tourists.",
	"pier_3": "They watched the sunset from the end of the pier.",
	"pier_4": "The restaurant on the pier had a fantastic seafood menu.",
	"inhale_0": "Inhale deeply and exhale slowly to relax.",
	"inhale_1": "He inhaled the fresh mountain air.",
	"inhale_2": "It's dangerous to inhale toxic fumes.",
	"inhale_3": "The runner inhaled sharply before sprinting.",
	"inhale_4": "She inhaled the scent of the flowers in the garden.",
	"endeavor_0": "The team's latest endeavor was a huge success.",
	"endeavor_1": "She put all her effort into her new business endeavor.",
	"endeavor_2": "The scientific endeavor contributed significantly to our understanding of the topic.",
	"endeavor_3": "He supported her in all her creative endeavors.",
	"endeavor_4": "Their joint endeavor led to a groundbreaking discovery.",
	"insure_0": "They decided to insure the house against natural disasters.",
	"insure_1": "It's important to insure your car for both theft and accidents.",
	"insure_2": "The policy will insure you against loss of income.",
	"insure_3": "The artwork was insured for a substantial amount.",
	"insure_4": "Travelers are advised to insure their baggage during international travel.",
	"trademark_0": "The logo is a registered trademark of the company.",
	"trademark_1": "His trademark sense of humor was evident in his speech.",
	"trademark_2": "The product's design became a trademark of the brand.",
	"trademark_3": "She filed a lawsuit over the unauthorized use of her trademark.",
	"trademark_4": "The chef's dishes were known for their trademark spiciness.",
	"empower_0": "The program aims to empower young people through education.",
	"empower_1": "She felt empowered after attending the self-defense class.",
	"empower_2": "The new law will empower small businesses to compete more effectively.",
	"empower_3": "Empowering employees leads to a more productive workplace.",
	"empower_4": "The movement seeks to empower marginalized communities.",
	"albeit_0": "He accepted the job, albeit with some hesitation.",
	"albeit_1": "The trip was enjoyable, albeit tiring.",
	"albeit_2": "She made progress, albeit slower than expected.",
	"albeit_3": "The novel was well-written, albeit a bit long.",
	"albeit_4": "It was a small victory, albeit an important one.",
	"leverage_0": "The company used its market leverage to influence suppliers.",
	"leverage_1": "She leveraged her experience to secure the new position.",
	"leverage_2": "In negotiations, he used his knowledge as leverage.",
	"leverage_3": "They sought to leverage technology to improve efficiency.",
	"leverage_4": "Her unique skills gave her leverage in the industry.",
	"siege_0": "The castle was under siege for several months.",
	"siege_1": "The city withstood the siege until reinforcements arrived.",
	"siege_2": "Reporters laid siege to the celebrity's home.",
	"siege_3": "The siege of the fortress was a key event in the war.",
	"siege_4": "The prolonged legal siege drained their resources.",
	"manual_0": "The car's manual transmission required skill to operate.",
	"manual_1": "He referred to the instruction manual to assemble the furniture.",
	"manual_2": "Manual laborers were hired for the construction project.",
	"manual_3": "The manual included guidelines for troubleshooting common issues.",
	"manual_4": "Skills in manual arts were highly valued in traditional crafts.",
	"contrary_0": "Contrary to expectations, the event was well-attended.",
	"contrary_1": "The witness gave a statement that was contrary to earlier reports.",
	"contrary_2": "On the contrary, I agree with your point.",
	"contrary_3": "The novel's ending is contrary to traditional happy endings.",
	"contrary_4": "His cheerful demeanor was contrary to the somber mood.",
	"canopy_0": "The forest canopy provided shade and habitat for wildlife.",
	"canopy_1": "They set up a canopy over the picnic area.",
	"canopy_2": "The parachute canopy filled with air, slowing the descent.",
	"canopy_3": "The bed was draped with a delicate canopy.",
	"canopy_4": "Birds chirped from high in the tree canopy.",
	"denounce_0": "The government officially denounced the acts of aggression.",
	"denounce_1": "Activists denounce the use of animals in testing.",
	"denounce_2": "He publicly denounced his former party's policies.",
	"denounce_3": "The report was denounced as inaccurate and misleading.",
	"denounce_4": "Religious leaders denounce the violence in the region.",
	"bulletin_0": "The latest weather bulletin warned of an approaching storm.",
	"bulletin_1": "The school posts daily bulletins on its website.",
	"bulletin_2": "A bulletin board in the office displayed important announcements.",
	"bulletin_3": "The news bulletin at noon covered the recent political developments.",
	"bulletin_4": "The community bulletin board was a hub for local events and services.",
	"integral_0": "Community support is integral to the success of the project.",
	"integral_1": "Mathematics is an integral part of the engineering curriculum.",
	"integral_2": "Her work has been integral in shaping the new policy.",
	"integral_3": "The river plays an integral role in the region's ecosystem.",
	"integral_4": "Music is an integral feature of the cultural festival.",
	"adhere_0": "The sticker didn't adhere well to the textured surface.",
	"adhere_1": "It's important to adhere to the guidelines provided.",
	"adhere_2": "The paint failed to adhere to the damp wall.",
	"adhere_3": "They adhere to traditional methods in their craftsmanship.",
	"adhere_4": "The contract requires both parties to adhere to its terms.",
	"sympathetic_0": "Her friends were sympathetic to her situation.",
	"sympathetic_1": "The teacher was sympathetic to the student's difficulties.",
	"sympathetic_2": "The documentary presented a sympathetic portrait of the community.",
	"sympathetic_3": "The judge was sympathetic, but firm in her decision.",
	"sympathetic_4": "He felt a sympathetic connection with the lost traveler.",
	"invoke_0": "The lawyer invoked an obscure law to support the case.",
	"invoke_1": "The ritual invoked ancient traditions and beliefs.",
	"invoke_2": "Protesters invoke their right to free speech.",
	"invoke_3": "In times of trouble, she would invoke her grandmother's wisdom.",
	"invoke_4": "The author invoked a sense of nostalgia in his readers.",
	"slogan_0": "The campaign's catchy slogan was easily remembered.",
	"slogan_1": "Protesters chanted slogans during the march.",
	"slogan_2": "The company's new slogan reflected its environmental commitment.",
	"slogan_3": "He wore a t-shirt emblazoned with a political slogan.",
	"slogan_4": "The advertising team developed a slogan for the brand launch.",
	"op_0": "The photo op at the event was popular with guests.",
	"op_1": "The military op was carried out with precision.",
	"op_2": "He underwent a knee op to repair the injury.",
	"op_3": "The spy thriller revolved around a covert op.",
	"op_4": "The doctor scheduled the op for early next week.",
	"turbine_0": "The wind turbine generates electricity for the local area.",
	"turbine_1": "The engineers are developing a more efficient turbine design.",
	"turbine_2": "The plant's turbine is a key part of its power generation.",
	"turbine_3": "They visited a farm with several solar and wind turbines.",
	"turbine_4": "The hydroelectric turbine harnesses the power of the river.",
	"profoundly_0": "Her speech profoundly impacted everyone in the audience.",
	"profoundly_1": "The book is profoundly influential in the field of psychology.",
	"profoundly_2": "He was profoundly deaf but excelled in visual arts.",
	"profoundly_3": "The experience changed her perspective profoundly.",
	"profoundly_4": "The landscape was profoundly beautiful, leaving them speechless.",
	"surpass_0": "The sequel surpassed the original movie in popularity.",
	"surpass_1": "Her performance surpassed all expectations.",
	"surpass_2": "The athlete aimed to surpass the world record.",
	"surpass_3": "The sales this quarter have surpassed last year's total.",
	"surpass_4": "His dedication to the project surpassed that of his colleagues.",
	"clan_0": "The Scottish clan gathered for its annual festival.",
	"clan_1": "Family ties were strong within the clan.",
	"clan_2": "The clan was known for its traditional crafts.",
	"clan_3": "Rivalry between the clans led to occasional disputes.",
	"clan_4": "The clan chief played a significant role in community decisions.",
	"precede_0": "A thunderous applause preceded her entrance on stage.",
	"precede_1": "The introduction precedes the first chapter of the book.",
	"precede_2": "Customarily, a warning sign precedes the hazardous area.",
	"precede_3": "The quiet hours of dawn precede the city's awakening.",
	"precede_4": "Historical events that preceded the war are crucial to understanding it.",
	"pasture_0": "The cows grazed in the green pasture.",
	"pasture_1": "He converted the barren land into fertile pasture.",
	"pasture_2": "The horses were let out into the pasture for exercise.",
	"pasture_3": "She reminisced about playing in the pastures during her childhood.",
	"pasture_4": "The rolling pastures were a defining feature of the rural landscape.",
	"workforce_0": "The company is committed to diversifying its workforce.",
	"workforce_1": "A skilled workforce is crucial for economic growth.",
	"workforce_2": "Technological changes are reshaping the workforce.",
	"workforce_3": "The aging workforce presents new challenges to employers.",
	"workforce_4": "They implemented policies to improve workforce wellbeing.",
	"archaeologist_0": "The archaeologist unearthed relics from an ancient civilization.",
	"archaeologist_1": "He dreamed of becoming an archaeologist and exploring historic sites.",
	"archaeologist_2": "The archaeologist carefully documented the site's findings.",
	"archaeologist_3": "A renowned archaeologist led the university's research team.",
	"archaeologist_4": "The discovery was significant in the field of archaeology.",
	"underneath_0": "The cat hid underneath the bed during the thunderstorm.",
	"underneath_1": "They discovered a hidden compartment underneath the floorboards.",
	"underneath_2": "Underneath her tough exterior, she was very compassionate.",
	"underneath_3": "The roots of the tree stretched far underneath the ground.",
	"underneath_4": "He wore a warm sweater underneath his coat.",
	"beneficiary_0": "She was named the beneficiary of her uncle's will.",
	"beneficiary_1": "The charity was the main beneficiary of the fundraising event.",
	"beneficiary_2": "As a beneficiary of the scholarship, he could attend college.",
	"beneficiary_3": "The new policy's beneficiaries included small business owners.",
	"beneficiary_4": "The trust fund designated several beneficiaries.",
	"stalk_0": "The photographer quietly stalked the wild animals for the perfect shot.",
	"stalk_1": "The rose's stalk was thorny and robust.",
	"stalk_2": "He carefully cut the stalk of each flower for the bouquet.",
	"stalk_3": "The corn stalks grew tall in the summer sun.",
	"stalk_4": "She felt uncomfortable, suspecting someone was stalking her.",
	"pronounce_0": "The teacher showed the students how to pronounce the difficult word.",
	"pronounce_1": "The judge will pronounce the verdict tomorrow.",
	"pronounce_2": "The doctor pronounced the patient in good health.",
	"pronounce_3": "The name is hard to pronounce for non-native speakers.",
	"pronounce_4": "She struggled to pronounce the French phrases correctly.",
	"sandy_0": "The children played on the sandy beach by the sea.",
	"sandy_1": "His shoes were filled with sandy grit after the hike.",
	"sandy_2": "The village was surrounded by vast, sandy dunes.",
	"sandy_3": "They walked hand in hand along the sandy shore.",
	"sandy_4": "The sandy texture of the soil made it easy to dig.",
	"pilgrim_0": "Pilgrims travel to the holy site every year.",
	"pilgrim_1": "The ancient city was a popular destination for pilgrims.",
	"pilgrim_2": "The pilgrim carried only essentials for the journey.",
	"pilgrim_3": "The shrine has welcomed pilgrims for centuries.",
	"pilgrim_4": "As a pilgrim, she sought spiritual enlightenment.",
	"mobility_0": "The new infrastructure improves mobility within the city.",
	"mobility_1": "After the surgery, he slowly regained mobility in his leg.",
	"mobility_2": "Public transportation is essential for urban mobility.",
	"mobility_3": "The app offers solutions for better mobility planning.",
	"mobility_4": "Wheelchairs enhance the mobility of individuals with physical disabilities.",
	"arguably_0": "She is arguably the best pianist of her generation.",
	"arguably_1": "The invention of the wheel is arguably one of humanity's most important advancements.",
	"arguably_2": "Arguably, the book's final chapter is its most compelling.",
	"arguably_3": "It's arguably more efficient to work from home.",
	"arguably_4": "The restaurant is arguably the best in town for Italian cuisine.",
	"fundamentally_0": "The new policy will fundamentally change the way we do business.",
	"fundamentally_1": "His views are fundamentally different from mine.",
	"fundamentally_2": "The invention of the internet has fundamentally altered communication.",
	"fundamentally_3": "Fundamentally, the two theories have much in common.",
	"fundamentally_4": "The crisis requires a solution that addresses the fundamentally underlying issues.",
	"plague_0": "The medieval town was devastated by the plague.",
	"plague_1": "He was plagued by doubts about his decision.",
	"plague_2": "Locust plagues can destroy crops and vegetation.",
	"plague_3": "The team was plagued by injuries throughout the season.",
	"plague_4": "Efforts to control the rat plague were successful.",
	"exert_0": "Athletes must exert a lot of energy in competitions.",
	"exert_1": "He didn't want to exert pressure on her to make a decision.",
	"exert_2": "The machine can exert a force of up to a ton.",
	"exert_3": "Parents sometimes exert influence over their children's choices.",
	"exert_4": "The gravitational pull exerted by the moon affects ocean tides.",
	"foremost_0": "She is one of the foremost experts in her field.",
	"foremost_1": "The foremost priority of the organization is safety.",
	"foremost_2": "The museum houses works by some of the foremost artists of the century.",
	"foremost_3": "He considered integrity to be his foremost virtue.",
	"foremost_4": "The company's foremost goal is to achieve sustainability.",
	"brochure_0": "The travel brochure highlighted exotic destinations.",
	"brochure_1": "They picked up a brochure about the museum's exhibits.",
	"brochure_2": "The brochure provides information on the university's programs.",
	"brochure_3": "He designed an informative brochure for the health clinic.",
	"brochure_4": "The property's brochure featured photos of the luxurious interior.",
	"showcase_0": "The event was a showcase of local talent.",
	"showcase_1": "The museum's new exhibit showcases ancient artifacts.",
	"showcase_2": "The fashion show was a perfect showcase for the designer's work.",
	"showcase_3": "The gallery provided a showcase for emerging artists.",
	"showcase_4": "The student's project was a showcase of her research skills.",
	"exaggerate_0": "He tends to exaggerate when telling stories.",
	"exaggerate_1": "The importance of regular exercise cannot be exaggerated.",
	"exaggerate_2": "The report seemed to exaggerate the risks involved.",
	"exaggerate_3": "Artists often exaggerate features to create a certain effect.",
	"exaggerate_4": "She warned not to exaggerate expectations for the event.",
	"composer_0": "The composer's symphony was performed by a renowned orchestra.",
	"composer_1": "She met the famous composer at a music festival.",
	"composer_2": "The composer drew inspiration from traditional folk music.",
	"composer_3": "His work as a film composer earned him several awards.",
	"composer_4": "The composer dedicated her latest piece to her mentor.",
	"weaver_0": "The weaver demonstrated traditional techniques on the loom.",
	"weaver_1": "Her grandmother was a skilled weaver of intricate tapestries.",
	"weaver_2": "The village is known for its community of expert weavers.",
	"weaver_3": "The weaver used colorful threads to create a beautiful pattern.",
	"weaver_4": "The exhibition showcased the work of local weavers.",
	"gay_0": "The city hosted a vibrant and inclusive gay pride parade.",
	"gay_1": "He came out as gay to his family and friends.",
	"gay_2": "The novel explores the experience of a young gay man in the 1960s.",
	"gay_3": "The gay rights movement has achieved significant milestones over the years.",
	"gay_4": "She is an active supporter of gay rights issues.",
	"verify_0": "The facts need to be verified before publication.",
	"verify_1": "He went to the bank to verify his account balance.",
	"verify_2": "The technician verified that the system was functioning correctly.",
	"verify_3": "Witnesses were able to verify the suspect's alibi.",
	"verify_4": "The scientists conducted experiments to verify their hypothesis.",
	"coincidence_0": "It was just a coincidence that they wore the same outfit.",
	"coincidence_1": "They were surprised by the coincidence of meeting in a foreign country.",
	"coincidence_2": "The resemblance between the two cases is more than a coincidence.",
	"coincidence_3": "He doesn't believe in coincidences, preferring to see patterns.",
	"coincidence_4": "The coincidence of their arrival times caused some confusion.",
	"patty_0": "She grilled a veggie patty for her burger.",
	"patty_1": "The chef shaped the ground beef into a patty.",
	"patty_2": "He flipped the patty over on the hot skillet.",
	"patty_3": "Each patty was seasoned to perfection.",
	"patty_4": "The fast-food chain introduced a new chicken patty sandwich.",
	"succession_0": "The king's death triggered a succession crisis.",
	"succession_1": "They planted crops in succession for a continuous harvest.",
	"succession_2": "She won three tennis tournaments in quick succession.",
	"succession_3": "The company's succession plan ensured a smooth leadership transition.",
	"succession_4": "The movie featured a succession of comedic scenes.",
	"commute_0": "His daily commute to work takes an hour.",
	"commute_1": "She listens to podcasts during her morning commute.",
	"commute_2": "The new rail service will make the commute much easier.",
	"commute_3": "Traffic congestion significantly lengthens the commute.",
	"commute_4": "Many employees now have a remote work option to avoid the commute.",
	"intensify_0": "The debate began to intensify as opinions clashed.",
	"intensify_1": "Efforts to find the missing person have intensified.",
	"intensify_2": "The storm is expected to intensify overnight.",
	"intensify_3": "The training program intensifies as the competition approaches.",
	"intensify_4": "The aroma of spices intensified in the bustling market.",
	"correspond_0": "The symbol corresponds to an ancient script.",
	"correspond_1": "His actions did not correspond with his words.",
	"correspond_2": "We corresponded via email for several months.",
	"correspond_3": "The map's colors correspond to different elevation levels.",
	"correspond_4": "The findings correspond closely to our initial hypotheses.",
	"swirl_0": "She watched the leaves swirl in the wind.",
	"swirl_1": "The artist added a swirl of color to the canvas.",
	"swirl_2": "Cream was added to the coffee, creating a beautiful swirl.",
	"swirl_3": "The dancers' dresses created mesmerizing swirls as they moved.",
	"swirl_4": "A swirl of emotions overwhelmed her during the speech.",
	"spontaneous_0": "The party was a spontaneous gathering of friends.",
	"spontaneous_1": "His spontaneous humor added life to the conversation.",
	"spontaneous_2": "They decided on a spontaneous weekend road trip.",
	"spontaneous_3": "The audience's spontaneous applause was a great compliment.",
	"spontaneous_4": "Spontaneous decisions sometimes lead to the best adventures.",
	"superintendent_0": "The school superintendent announced the new education policy.",
	"superintendent_1": "The building's superintendent was quick to fix the issue.",
	"superintendent_2": "The superintendent of the park enforced conservation regulations.",
	"superintendent_3": "As superintendent, she implemented several key reforms.",
	"superintendent_4": "The superintendent addressed the concerns at the meeting.",
	"footage_0": "The documentary included rare footage of the event.",
	"footage_1": "Security cameras captured footage of the incident.",
	"footage_2": "The film's archival footage provided historical context.",
	"footage_3": "They reviewed the game footage to improve their strategy.",
	"footage_4": "The news broadcast showed footage of the natural disaster.",
	"fulfil_0": "She worked hard to fulfil her lifelong dream.",
	"fulfil_1": "The company must fulfil its contract obligations.",
	"fulfil_2": "Fulfilling his duties as a doctor was his top priority.",
	"fulfil_3": "The program aims to fulfil the needs of the community.",
	"fulfil_4": "He was unable to fulfil the promises he made.",
	"ramp_0": "The building's ramp made it accessible to wheelchairs.",
	"ramp_1": "Skateboarders practiced tricks on the ramp.",
	"ramp_2": "The truck slowly ascended the loading ramp.",
	"ramp_3": "A temporary ramp was set up for the event.",
	"ramp_4": "The ramp led down to the underground parking.",
	"divert_0": "The construction work will divert traffic to an alternate route.",
	"divert_1": "Efforts were made to divert the floodwater from the town.",
	"divert_2": "She tried to divert his attention from the surprise.",
	"divert_3": "The funds were illegally diverted to a private account.",
	"divert_4": "The pilot had to divert the flight due to bad weather.",
	"corrupt_0": "The investigation revealed corrupt practices within the organization.",
	"corrupt_1": "He refused to be involved in the corrupt scheme.",
	"corrupt_2": "The file could not be opened because it was corrupt.",
	"corrupt_3": "The politician's corrupt behavior was exposed by the media.",
	"corrupt_4": "Efforts were made to reform the corrupt system.",
	"reconstruction_0": "The old building underwent extensive reconstruction.",
	"reconstruction_1": "After the war, the city faced a long period of reconstruction.",
	"reconstruction_2": "The accident victim had facial reconstruction surgery.",
	"reconstruction_3": "Reconstruction efforts after the disaster were supported by international aid.",
	"reconstruction_4": "The historical society is dedicated to the accurate reconstruction of historical events.",
	"incoming_0": "The soldiers braced for the incoming attack.",
	"incoming_1": "The phone was constantly busy with incoming calls.",
	"incoming_2": "The airport manages thousands of incoming and outgoing flights daily.",
	"incoming_3": "He was responsible for responding to all incoming correspondence.",
	"incoming_4": "The business prepared for the incoming holiday season.",
	"ore_0": "The mine produces a significant amount of iron ore.",
	"ore_1": "Ore extraction has been a major industry in the region.",
	"ore_2": "The geologist identified the rock as copper ore.",
	"ore_3": "Ore processing is the first step in metal production.",
	"ore_4": "The company exports ore to several countries.",
	"drape_0": "She draped a shawl over her shoulders.",
	"drape_1": "The stage was draped with ornate curtains.",
	"drape_2": "The fabric was elegantly draped across the table.",
	"drape_3": "He draped his arm around her as they walked.",
	"drape_4": "The hills were draped in a blanket of snow.",
	"con_0": "The police arrested the man for running a con.",
	"con_1": "She realized too late that the deal was a con.",
	"con_2": "The con artist tricked victims with a fake lottery.",
	"con_3": "He fell for the con and lost his savings.",
	"con_4": "The movie featured an elaborate con against a corrupt businessman.",
	"referral_0": "The doctor gave her a referral to a specialist.",
	"referral_1": "The job opening was found through a friend's referral.",
	"referral_2": "The agency works on a referral basis.",
	"referral_3": "He thanked his colleague for the client referral.",
	"referral_4": "The referral program rewards customers for bringing new business.",
	"attribute_0": "She attributed her success to hard work and dedication.",
	"attribute_1": "The painting's style was attributed to a famous artist.",
	"attribute_2": "The attribute of patience is important in teaching.",
	"attribute_3": "The software has several useful attributes.",
	"attribute_4": "He attributed the failure to a lack of planning.",
	"professional_0": "Her professional attitude at work earned her a promotion within a year.",
    "professional_1": "As a professional photographer, he has a keen eye for detail.",
    "professional_2": "Seeking professional advice can often save time and money.",
    "professional_3": "His professional skills were evident in the way he managed the project.",
    "professional_4": "She always maintains a professional demeanor in client meetings.",
    "portrait_0": "The artist painted a portrait that perfectly captured her smile.",
    "portrait_1": "His portrait hangs in the gallery as a testament to his legacy.",
    "portrait_2": "We took a family portrait at the beach last summer.",
    "portrait_3": "She found an old portrait of her grandmother in the attic.",
    "portrait_4": "The museum featured a portrait exhibit from various historical periods.",
    "notification_0": "I received a notification on my phone about the upcoming meeting.",
    "notification_1": "The app sends a daily notification to remind users to exercise.",
    "notification_2": "He turned off all notifications to focus on his work.",
    "notification_3": "The notification about the flight delay arrived just in time.",
    "notification_4": "Email notifications are often crucial for timely communication.",
    "financially_0": "They became financially independent at a young age.",
    "financially_1": "The project was financially viable and attracted several investors.",
    "financially_2": "Financially, the decision to buy a house was a big commitment.",
    "financially_3": "The company is financially stable, showing consistent profit growth.",
    "financially_4": "Budgeting wisely is key to becoming financially secure.",
    "curriculum_0": "The school's curriculum focuses on both academics and extracurricular activities.",
    "curriculum_1": "They revised the curriculum to include more modern texts.",
    "curriculum_2": "A strong curriculum is essential for a well-rounded education.",
    "curriculum_3": "Parents were involved in developing the new curriculum.",
    "curriculum_4": "The curriculum was adapted to accommodate remote learning.",
    "evacuee_0": "Each evacuee was provided with essential supplies at the shelter.",
    "evacuee_1": "The evacuee shared her harrowing story of escaping the disaster.",
    "evacuee_2": "As an evacuee, he received medical and psychological support.",
    "evacuee_3": "The community rallied to support the evacuees with food and clothing.",
    "evacuee_4": "Evacuees were transported to safe zones by the authorities.",
    "evocative_0": "The painting was evocative of her childhood memories.",
    "evocative_1": "His evocative speech stirred emotions in the audience.",
    "evocative_2": "The fragrance was evocative of a spring morning.",
    "evocative_3": "The novel's evocative descriptions brought the setting to life.",
    "evocative_4": "Music can be highly evocative, transporting us to different times and places.",
    "brutal_0": "The brutal winter storm caused widespread damage.",
    "brutal_1": "He was known for his brutal honesty, which was sometimes off-putting.",
    "brutal_2": "The history book described the brutal realities of war.",
    "brutal_3": "The athlete underwent a brutal training regimen to prepare for the competition.",
    "brutal_4": "The movie depicted the brutal challenges faced by the protagonist.",
    "therapy_0": "After the accident, she underwent physical therapy to regain her strength.",
    "therapy_1": "Music therapy has proven to be effective in reducing stress.",
    "therapy_2": "Cognitive behavioral therapy is a common treatment for anxiety.",
    "therapy_3": "The therapy sessions helped him deal with his grief.",
    "therapy_4": "Art therapy allows children to express themselves creatively while healing.",
    "whereby_0": "The company introduced a new system whereby employees can work remotely.",
    "whereby_1": "They signed a treaty whereby both nations would cease hostilities.",
    "whereby_2": "He proposed a plan whereby costs could be reduced by 20%.",
    "whereby_3": "The school has a program whereby students mentor each other.",
    "whereby_4": "There is a process whereby water is purified for drinking.",
    "adopt_0": "Many families choose to adopt pets from animal shelters.",
    "adopt_1": "The company decided to adopt a new marketing strategy.",
    "adopt_2": "Countries need to adopt sustainable practices to protect the environment.",
    "adopt_3": "She decided to adopt a healthier lifestyle.",
    "adopt_4": "The council will adopt new regulations to improve safety.",
    "readjust_0": "He had to readjust to life at home after years abroad.",
    "readjust_1": "The company readjusted its goals in response to the market change.",
    "readjust_2": "After moving to a new city, it took a while to readjust.",
    "readjust_3": "She readjusted the telescope to get a better view of the stars.",
    "readjust_4": "Readjusting their strategy, the team was able to win the game.",
    "analysis_0": "The data requires thorough analysis before a conclusion can be drawn.",
    "analysis_1": "His analysis of the situation was spot on.",
    "analysis_2": "The lab is conducting an analysis of the water samples.",
    "analysis_3": "Financial analysis is key to the company's success.",
    "analysis_4": "The literary analysis helped students understand the novel better.",
    "export_0": "The country is known to export coffee and spices.",
    "export_1": "Export regulations can affect international trade.",
    "export_2": "The software allows users to export data in multiple formats.",
    "export_3": "Oil is one of the main exports of the country.",
    "export_4": "The company expanded its business to export products overseas.",
    "theory_0": "Einstein's theory of relativity revolutionized physics.",
    "theory_1": "The theory was proven wrong through scientific experimentation.",
    "theory_2": "In theory, the plan should work, but it needs to be tested.",
    "theory_3": "He developed a theory based on his research findings.",
    "theory_4": "The theory explains the behavior of the natural world.",
    "somehow_0": "Somehow, she managed to finish the project on time.",
    "somehow_1": "He always finds a way to succeed, somehow.",
    "somehow_2": "I need to figure out how to fix this, somehow.",
    "somehow_3": "She seemed familiar, somehow, although I had never met her.",
    "somehow_4": "Somehow, the truth always comes out.",
    "strain_0": "Lifting heavy objects can cause strain on your back.",
    "strain_1": "The flu strain this year is particularly aggressive.",
    "strain_2": "The constant strain of work led to his burnout.",
    "strain_3": "The relationship was under a lot of strain.",
    "strain_4": "Bacterial strains can become resistant to antibiotics.",
    "exhibition_0": "The art exhibition showcased contemporary paintings.",
    "exhibition_1": "The museum will host a historical artifacts exhibition next month.",
    "exhibition_2": "Her photography will be featured in an international exhibition.",
    "exhibition_3": "The exhibition attracted visitors from around the world.",
    "exhibition_4": "The science exhibition displayed the latest technological advancements.",
    "sustainability_0": "Sustainability is a key factor in modern urban planning.",
    "sustainability_1": "The company's commitment to sustainability is reflected in its practices.",
    "sustainability_2": "Educating about sustainability can lead to more environmentally friendly choices.",
    "sustainability_3": "Sustainability efforts include reducing waste and conserving energy.",
    "sustainability_4": "The project focuses on economic, social, and environmental sustainability.",
    "snap_0": "With a quick snap of his fingers, he caught everyone's attention.",
    "snap_1": "The branch gave a loud snap under the weight of the snow.",
    "snap_2": "She likes to snap photos of her meals for her food blog.",
    "snap_3": "In an instant, he made a snap decision to change his travel plans.",
    "snap_4": "The snap closure on her purse was broken.",
    "nomination_0": "She received a nomination for best actress in a leading role.",
    "nomination_1": "The nomination of the new director will be announced next week.",
    "nomination_2": "His nomination to the board was a significant achievement.",
    "nomination_3": "The film got several nominations at the international film festival.",
    "nomination_4": "The nomination process for the awards is highly competitive.",
    "impact_0": "The environmental impact of the new policy is yet to be seen.",
    "impact_1": "The impact of the teacher on his students was profound.",
    "impact_2": "The economic impact of the pandemic has been widespread.",
    "impact_3": "Her speech made a strong impact on the audience.",
    "impact_4": "The asteroid's impact left a large crater.",
    "persuade_0": "He managed to persuade his friend to join the gym with him.",
    "persuade_1": "The advertisement aims to persuade consumers to buy the product.",
    "persuade_2": "She used her persuasive skills to win the debate.",
    "persuade_3": "It's difficult to persuade him once he's made up his mind.",
    "persuade_4": "The presentation should persuade investors of the project's potential.",
    "perseverance_0": "Her perseverance led her to success in a highly competitive field.",
    "perseverance_1": "Perseverance is key when learning a new language.",
    "perseverance_2": "The team's perseverance was evident in their comeback win.",
    "perseverance_3": "Despite numerous setbacks, his perseverance never wavered.",
    "perseverance_4": "The project was a test of her perseverance and determination.",
    "irresistible_0": "The smell of freshly baked cookies was irresistible.",
    "irresistible_1": "He had an irresistible charm that everyone loved.",
    "irresistible_2": "The offer was so good, it was simply irresistible.",
    "irresistible_3": "Her enthusiasm for life is irresistible.",
    "irresistible_4": "The book's plot was irresistible, keeping readers engaged till the end.",
    "pandemic_0": "The COVID-19 pandemic affected countries worldwide.",
    "pandemic_1": "During the pandemic, many people worked from home.",
    "pandemic_2": "The government took measures to control the spread of the pandemic.",
    "pandemic_3": "The pandemic led to significant changes in the healthcare system.",
    "pandemic_4": "Travel restrictions were imposed worldwide due to the pandemic.",
    "subscription_0": "She has a monthly subscription to her favorite magazine.",
    "subscription_1": "The subscription service offers access to a range of online courses.",
    "subscription_2": "Renewing the subscription annually saves money compared to monthly payments.",
    "subscription_3": "The app's premium features are available through a paid subscription.",
    "subscription_4": "Many people prefer subscription boxes for their convenience and variety.",
    "consumption_0": "Reducing energy consumption helps in combating climate change.",
    "consumption_1": "Water consumption increases during the hot summer months.",
    "consumption_2": "The report details the country's consumption of natural resources.",
    "consumption_3": "There's been a shift in consumer consumption patterns in recent years.",
    "consumption_4": "Moderate alcohol consumption is recommended for a healthy lifestyle.",
    "infinity_0": "The symbol for infinity is a sideways figure eight.",
    "infinity_1": "The concept of infinity is challenging to grasp.",
    "infinity_2": "In mathematics, infinity is not a real but an abstract concept.",
    "infinity_3": "The infinity pool offers a stunning view of the ocean.",
    "infinity_4": "His love for her was like infinity, without end.",
    "schedule_0": "She checked her schedule to see if she was available for a meeting.",
    "schedule_1": "The construction project is on schedule to be completed by June.",
    "schedule_2": "The train's schedule was disrupted due to bad weather.",
    "schedule_3": "He had to rearrange his schedule to attend the conference.",
    "schedule_4": "The team's practice schedule is rigorous and demanding.",
    "initially_0": "Initially, the plan seemed good, but it proved impractical.",
    "initially_1": "She was initially hesitant to take on the project.",
    "initially_2": "The idea was initially met with skepticism.",
    "initially_3": "Initially, the software had several bugs that needed fixing.",
    "initially_4": "He initially opposed the proposal but later changed his mind.",
    "particularly_0": "She is particularly fond of classical music.",
    "particularly_1": "The weather was particularly cold this winter.",
    "particularly_2": "This book is particularly useful for students studying biology.",
    "particularly_3": "He's not particularly interested in sports.",
    "particularly_4": "The city is known for its particularly vibrant art scene.",
    "shroud_0": "The mountain was shrouded in fog.",
    "shroud_1": "Mystery shrouds the ancient legend.",
    "shroud_2": "The forest was shrouded in darkness.",
    "shroud_3": "He managed to shroud his feelings from everyone.",
    "shroud_4": "The shroud of secrecy surrounding the project sparked curiosity.",
    "reveal_0": "The final chapter of the book reveals the murderer's identity.",
    "reveal_1": "She was hesitant to reveal the truth.",
    "reveal_2": "The study reveals important findings about the disease.",
    "reveal_3": "He revealed his plans for the company's future.",
    "reveal_4": "The curtains opened to reveal a beautifully decorated stage.",
    "reckon_0": "I reckon we'll arrive in about two hours.",
    "reckon_1": "He's pretty good at math, don't you reckon?",
    "reckon_2": "I reckon it's going to rain soon, judging by the clouds.",
    "reckon_3": "She reckoned that the project would be completed by next week.",
    "reckon_4": "Do you reckon he'll agree to our proposal?",
    "cheekiness_0": "His cheekiness sometimes gets him into trouble at school.",
    "cheekiness_1": "She admired the cheekiness of the young performer.",
    "cheekiness_2": "The movie is known for its cheekiness and witty dialogue.",
    "cheekiness_3": "His cheekiness is often seen as a sign of confidence.",
    "cheekiness_4": "Despite his cheekiness, he was well-liked by his peers.",
    "awareness_0": "The campaign aims to raise awareness about mental health.",
    "awareness_1": "Environmental awareness is crucial in today's world.",
    "awareness_2": "She has a deep awareness of social issues.",
    "awareness_3": "The event was organized to increase awareness of heart diseases.",
    "awareness_4": "Cultural awareness is important in a diverse society.",
    "portal_0": "The company's portal provides access to all its services.",
    "portal_1": "He entered the ancient portal leading to the hidden chambers.",
    "portal_2": "The online portal is used for submitting assignments.",
    "portal_3": "The portal to the other world was shimmering with light.",
    "portal_4": "The patient portal allows access to medical records and appointments.",
    "entrant_0": "The new entrant in the market is already making waves.",
    "entrant_1": "As a first-time entrant in the competition, she performed exceptionally well.",
    "entrant_2": "Each entrant must submit their work by the deadline.",
    "entrant_3": "The contest attracted over a hundred entrants from around the world.",
    "entrant_4": "The youngest entrant in the race was only sixteen years old.",
    "interactive_0": "The museum's new exhibit features an interactive display for children.",
    "interactive_1": "She enjoys the interactive learning approach in her online courses.",
    "interactive_2": "The game's interactive features make it highly engaging.",
    "interactive_3": "An interactive map of the city is available on the website.",
    "interactive_4": "The seminar was interactive, encouraging audience participation.",
    "plumage_0": "The bird's bright plumage attracted attention from onlookers.",
    "plumage_1": "During mating season, the male's plumage becomes even more vibrant.",
    "plumage_2": "Birdwatchers often identify species by their distinctive plumage.",
    "plumage_3": "The peacock spread its plumage in a dazzling display.",
    "plumage_4": "Her costume included feathers mimicking the plumage of tropical birds.",
    "elaborate_0": "The wedding decorations were elaborate and exquisite.",
    "elaborate_1": "He gave an elaborate explanation of the theory.",
    "elaborate_2": "The plan was too elaborate to be practical.",
    "elaborate_3": "She wore an elaborate gown to the gala.",
    "elaborate_4": "The chef's presentation of the dish was quite elaborate.",
    "mechanism_0": "The clock's mechanism is complex and finely tuned.",
    "mechanism_1": "They discussed the mechanism behind the chemical reaction.",
    "mechanism_2": "A coping mechanism can help deal with stress.",
    "mechanism_3": "The machine's safety mechanism prevents accidents.",
    "mechanism_4": "The body has mechanisms to regulate temperature.",
    "extravagant_0": "The party was an extravagant affair, with lavish decorations.",
    "extravagant_1": "His extravagant lifestyle was not sustainable in the long run.",
    "extravagant_2": "She received an extravagant gift that amazed everyone.",
    "extravagant_3": "The hotel was known for its extravagant interiors.",
    "extravagant_4": "He made an extravagant promise that was hard to keep.",
    "conquer_0": "She aimed to conquer her fear of heights.",
    "conquer_1": "The army set out to conquer new territories.",
    "conquer_2": "He finally managed to conquer the challenging mountain peak.",
    "conquer_3": "With determination, she conquered all obstacles in her path.",
    "conquer_4": "The game's objective is to conquer enemy kingdoms.",
    "appropriately_0": "She dressed appropriately for the formal event.",
    "appropriately_1": "The teacher appropriately addressed the student's behavior.",
    "appropriately_2": "The funds were used appropriately for the designated purpose.",
    "appropriately_3": "He responded appropriately to the criticism.",
    "appropriately_4": "The movie was appropriately rated for mature audiences.",
    "toddle_0": "The baby began to toddle around the room.",
    "toddle_1": "Watching the toddler toddle on the grass was adorable.",
    "toddle_2": "She toddled over to her toys with a big smile.",
    "toddle_3": "The little one is just learning to toddle.",
    "toddle_4": "Every time he tries to toddle, he falls and then gets up again.",
    "quirky_0": "The café had a quirky decor that attracted many visitors.",
    "quirky_1": "She loved his quirky sense of humor.",
    "quirky_2": "The movie is known for its quirky characters.",
    "quirky_3": "Her quirky style set her apart from her peers.",
    "quirky_4": "The book was filled with quirky illustrations.",
    "junior_0": "As a junior analyst, she was eager to learn.",
    "junior_1": "He's in his junior year of high school.",
    "junior_2": "The junior team members looked up to their seniors.",
    "junior_3": "She won the junior championship last year.",
    "junior_4": "The junior version of the game is easier for children.",
    "organization_0": "The non-profit organization focuses on wildlife conservation.",
    "organization_1": "She received an award for her outstanding contributions to the organization.",
    "organization_2": "Good organization is key to managing a successful event.",
    "organization_3": "He volunteered at a local charity organization.",
    "organization_4": "The organization's structure was changed to improve efficiency.",
    "interpret_0": "The data needs to be interpreted carefully to draw accurate conclusions.",
    "interpret_1": "She can interpret Spanish and French into English.",
    "interpret_2": "Different people might interpret the same information differently.",
    "interpret_3": "The lawyer interpreted the law to his client's advantage.",
    "interpret_4": "The artist's work is open to various interpretations.",
    "prompt_0": "He was always prompt in responding to emails.",
    "prompt_1": "The alarm served as a prompt to take her medication.",
    "prompt_2": "The teacher gave the students a writing prompt for their essay.",
    "prompt_3": "Her sudden question caught him without a prompt response.",
    "prompt_4": "Prompt action was required to resolve the issue.",
    "reserve_0": "They made a reserve of food supplies for the winter.",
    "reserve_1": "She often spoke with a sense of reserve.",
    "reserve_2": "The hotel requires a credit card to hold a reservation.",
    "reserve_3": "The nature reserve is home to many rare species.",
    "reserve_4": "He has a deep reserve of patience.",
    "debate_0": "The debate over climate change continues among scientists.",
    "debate_1": "They entered a debate about the best course of action.",
    "debate_2": "The presidential debate was broadcasted live.",
    "debate_3": "The topic for the school debate competition was chosen.",
    "debate_4": "She enjoys engaging in debates on philosophy.",
    "religion_0": "Religion plays a significant role in many people's lives.",
    "religion_1": "The country is home to many different religions.",
    "religion_2": "She studied the influence of religion on art.",
    "religion_3": "Freedom of religion is a fundamental human right.",
    "religion_4": "The book explores the relationship between religion and culture.",
    "basin_0": "The river flows into a large basin.",
    "basin_1": "He filled the basin with water to wash his face.",
    "basin_2": "The Amazon Basin is home to a vast rainforest.",
    "basin_3": "The geologists studied the sedimentary layers of the basin.",
    "basin_4": "Farming in the river basin supports many communities.",
    "glacier_0": "The glacier is slowly melting due to global warming.",
    "glacier_1": "They went on an expedition to study the glacier's movement.",
    "glacier_2": "The mountain's glacier is a popular site for climbers.",
    "glacier_3": "Glacier ice is incredibly dense and compact.",
    "glacier_4": "The retreat of the glacier is a concern for environmentalists.",
    "thrive_0": "The plant thrives in a sunny environment.",
    "thrive_1": "She has created a business that continues to thrive.",
    "thrive_2": "Children thrive when given love and attention.",
    "thrive_3": "Innovation helps businesses to thrive in a competitive market.",
    "thrive_4": "Some species thrive in extreme conditions.",
    "democracy_0": "The country transitioned to democracy after years of dictatorship.",
    "democracy_1": "Democracy allows citizens to have a say in their government.",
    "democracy_2": "The principles of democracy are upheld by the constitution.",
    "democracy_3": "He is a staunch advocate for democracy and human rights.",
    "democracy_4": "The history of democracy dates back to ancient times.",
    "petition_0": "The citizens filed a petition against the new zoning laws.",
    "petition_1": "Over a thousand signatures were collected on the online petition.",
    "petition_2": "The petition to lower tuition fees was successful.",
    "petition_3": "She started a petition to save the historic building from demolition.",
    "petition_4": "The court will hear the petition for a retrial next week.",
    "despite_0": "Despite the rain, the event was well attended.",
    "despite_1": "He remained cheerful despite his illness.",
    "despite_2": "Despite her busy schedule, she finds time to volunteer.",
    "despite_3": "She achieved success, despite having no formal training.",
    "despite_4": "Despite the challenges, they remained committed to the project.",
    "brace_0": "The doctor recommended a brace for her injured knee.",
    "brace_1": "They had to brace the door against the strong winds.",
    "brace_2": "She wore a back brace after the surgery.",
    "brace_3": "The team braced for the tough competition ahead.",
    "brace_4": "He braced himself for the impact of the news.",
    "lido_0": "In the summer, the lido is full of swimmers enjoying the sun.",
    "lido_1": "They spent the afternoon relaxing at the local lido.",
    "lido_2": "The lido offers both swimming and sunbathing facilities.",
    "lido_3": "The newly renovated lido attracted tourists from all over.",
    "lido_4": "She learned to swim at the lido near her house.",
    "silhouette_0": "The silhouette of the mountains was visible at sunset.",
    "silhouette_1": "Her silhouette stood out against the lighted backdrop.",
    "silhouette_2": "The artist captured the cat's silhouette in the painting.",
    "silhouette_3": "The tree's silhouette was striking in the moonlight.",
    "silhouette_4": "The dress had a flattering silhouette that suited her style.",
    "amateur_0": "As an amateur photographer, he enjoyed capturing nature's beauty.",
    "amateur_1": "The amateur league was a starting point for many professional players.",
    "amateur_2": "The play was impressive, especially for an amateur production.",
    "amateur_3": "She won the regional championship in amateur golf.",
    "amateur_4": "Despite being an amateur, her work was of professional quality.",
    "commission_0": "The artist received a commission to paint a mural.",
    "commission_1": "The commission is investigating corruption in the government.",
    "commission_2": "He earns a commission on every sale he makes.",
    "commission_3": "The building was commissioned by a wealthy merchant.",
    "commission_4": "The report by the commission was released yesterday.",
    "impose_0": "The government decided to impose new taxes.",
    "impose_1": "He didn't want to impose his views on others.",
    "impose_2": "The council will impose fines for littering.",
    "impose_3": "She felt her parents were trying to impose their expectations on her.",
    "impose_4": "The trade sanctions were imposed in response to the violation.",
    "hoist_0": "They hoisted the flag at the start of the ceremony.",
    "hoist_1": "The workers hoisted the beams into place.",
    "hoist_2": "With great effort, he hoisted the heavy box.",
    "hoist_3": "The sail was hoisted to catch the wind.",
    "hoist_4": "The rescue team hoisted the stranded hiker to safety.",
    "scot_0": "As a proud Scot, he wore his kilt on special occasions.",
    "scot_1": "The Scot played traditional bagpipes at the festival.",
    "scot_2": "She is a Scot by birth and cherishes her heritage.",
    "scot_3": "The history book covered the contributions of the Scots to the country.",
    "scot_4": "He enjoyed the tales of famous Scot warriors and their bravery.",
    "drone_0": "The drone flew over the landscape, capturing stunning aerial views.",
    "drone_1": "He used a drone to inspect the condition of the roof.",
    "drone_2": "The buzzing of a drone could be heard in the distance.",
    "drone_3": "Drone technology has advanced immensely in recent years.",
    "drone_4": "The farmer uses a drone to monitor crop health.",
    "immensely_0": "She was immensely grateful for their help and support.",
    "immensely_1": "The new software improved our productivity immensely.",
    "immensely_2": "He enjoyed the book immensely and recommended it to others.",
    "immensely_3": "The course benefited her immensely in her career.",
    "immensely_4": "The landscape was immensely beautiful, leaving them in awe.",
    "inquisitive_0": "The inquisitive child asked endless questions about the world.",
    "inquisitive_1": "Her inquisitive nature led her to a career in science.",
    "inquisitive_2": "Inquisitive minds often lead to important discoveries.",
    "inquisitive_3": "The inquisitive journalist uncovered the truth behind the story.",
    "inquisitive_4": "He had an inquisitive look as he examined the artifact.",
    "guttural_0": "The guttural sound of the engine indicated a malfunction.",
    "guttural_1": "His voice was deep and guttural.",
    "guttural_2": "The language had a guttural quality that was difficult to mimic.",
    "guttural_3": "She heard a guttural growl from the shadows.",
    "guttural_4": "Guttural tones are common in some languages.",
    "critically_0": "The film was critically acclaimed for its innovative storytelling.",
    "critically_1": "The patient is critically ill and requires immediate care.",
    "critically_2": "Critically assessing the situation, she made a quick decision.",
    "critically_3": "The book critically examines the impacts of social media.",
    "critically_4": "It's important to think critically about the news we consume.",
    "occasion_0": "The gala was a special occasion that required formal attire.",
    "occasion_1": "He bought a gift for the occasion of their anniversary.",
    "occasion_2": "Every birthday is an occasion to celebrate life.",
    "occasion_3": "The speech was delivered on the occasion of the university's centennial.",
    "occasion_4": "For the occasion, the city was decorated with lights and banners.",
    "marsupial_0": "The kangaroo is a well-known marsupial from Australia.",
    "marsupial_1": "Marsupials carry their young in a pouch.",
    "marsupial_2": "The koala, another marsupial, lives primarily in eucalyptus trees.",
    "marsupial_3": "She studied the behavior of marsupials in the wild.",
    "marsupial_4": "The wombat is a lesser-known marsupial native to Australia.",
    "braille_0": "The book was also available in Braille for visually impaired readers.",
    "braille_1": "He learned to read Braille after losing his sight.",
    "braille_2": "Public buildings should have signs in Braille for accessibility.",
    "braille_3": "The invention of Braille was a major advancement for blind individuals.",
    "braille_4": "She used a special device to write in Braille.",
    "disruption_0": "The storm caused major disruption to transportation services.",
    "disruption_1": "Technological advances can lead to disruption in traditional industries.",
    "disruption_2": "The company faced disruption in its supply chain.",
    "disruption_3": "Protesters aimed to cause disruption to draw attention to their cause.",
    "disruption_4": "The construction work led to disruption in the neighborhood.",
    "vulnerable_0": "The elderly are particularly vulnerable to the flu.",
    "vulnerable_1": "Cybersecurity measures are essential to protect vulnerable data.",
    "vulnerable_2": "In times of crisis, the most vulnerable need the most support.",
    "vulnerable_3": "Endangered species are vulnerable to habitat loss.",
    "vulnerable_4": "Sharing his feelings made him feel vulnerable.",
    "psychoanalyst_0": "The psychoanalyst helped her understand her dreams better.",
    "psychoanalyst_1": "Freud is considered the father of psychoanalysis.",
    "psychoanalyst_2": "She consulted a psychoanalyst to deal with her anxiety.",
    "psychoanalyst_3": "The psychoanalyst wrote several books on human behavior.",
    "psychoanalyst_4": "His therapy sessions with the psychoanalyst were transformative.",
    "sedentary_0": "A sedentary lifestyle can lead to health problems.",
    "sedentary_1": "Many office jobs are sedentary in nature.",
    "sedentary_2": "To combat the effects of a sedentary job, she started exercising.",
    "sedentary_3": "Sedentary behaviors are increasingly common in modern society.",
    "sedentary_4": "He tried to reduce his sedentary hours by taking regular breaks.",
    "misconception_0": "There's a common misconception that cold weather causes colds.",
    "misconception_1": "She wanted to clarify the misconceptions about her work.",
    "misconception_2": "One widespread misconception is that bats are blind.",
    "misconception_3": "Dispelling misconceptions can lead to better understanding.",
    "misconception_4": "The article debunked several misconceptions about nutrition.",
    "instil_0": "The school aims to instil a love of learning in its students.",
    "instil_1": "Her parents instilled in her the values of honesty and hard work.",
    "instil_2": "It's important to instil confidence in young children.",
    "instil_3": "The coach tried to instil a sense of teamwork in his players.",
    "instil_4": "The experience instilled in him a desire to help others.",
    "fanatic_0": "He is a sports fanatic, never missing a game of his favorite team.",
    "fanatic_1": "Her father was a health fanatic, always eating carefully and exercising.",
    "fanatic_2": "The movie attracted science fiction fanatics.",
    "fanatic_3": "She's a fitness fanatic, spending hours at the gym.",
    "fanatic_4": "As a tech fanatic, he always had the latest gadgets.",
    "exaggerate_0": "He tends to exaggerate when telling stories to make them more interesting.",
    "exaggerate_1": "It's common for people to exaggerate their resume skills.",
    "exaggerate_2": "The impact of the event was exaggerated by the media.",
    "exaggerate_3": "She exaggerated her role in the project.",
    "exaggerate_4": "Don't exaggerate your symptoms just to get more attention.",
    "headline_0": "The scandal made headlines in newspapers worldwide.",
    "headline_1": "The headline of the article was eye-catching.",
    "headline_2": "He always reads the headlines first in the morning paper.",
    "headline_3": "The concert's headline act was a famous rock band.",
    "headline_4": "The headline was misleading and exaggerated the story.",
    "excessively_0": "Drinking excessively can harm your health.",
    "excessively_1": "The movie was excessively long and boring.",
    "excessively_2": "He criticized the plan for being excessively complicated.",
    "excessively_3": "They were fined for excessively speeding on the highway.",
    "excessively_4": "She worried excessively about the small details.",
    "apparently_0": "Apparently, the meeting has been postponed.",
    "apparently_1": "He missed his flight, apparently because he overslept.",
    "apparently_2": "The book is, apparently, based on a true story.",
    "apparently_3": "She was, apparently, not informed about the decision.",
    "apparently_4": "Apparently, the restaurant is under new management.",
    "wonder_0": "Children look at the world with a sense of wonder.",
    "wonder_1": "He began to wonder about the purpose of life.",
    "wonder_2": "The beauty of the night sky filled her with wonder.",
    "wonder_3": "They wondered why he had left the party early.",
    "wonder_4": "The ancient ruins were a wonder to behold.",
    "entitlement_0": "His sense of entitlement made him unpopular with his coworkers.",
    "entitlement_1": "She has a strong sense of entitlement to the family fortune.",
    "entitlement_2": "Entitlement programs are crucial for supporting the needy.",
    "entitlement_3": "The law outlined the entitlements of each beneficiary.",
    "entitlement_4": "He questioned the entitlement mentality of the younger generation.",
    "arrogance_0": "His arrogance made it difficult for others to work with him.",
    "arrogance_1": "She was put off by his arrogance and lack of humility.",
    "arrogance_2": "The company's arrogance led to its downfall.",
    "arrogance_3": "Many saw his confidence as arrogance.",
    "arrogance_4": "His arrogance blinded him to his own mistakes.",
    "lyrics_0": "The song's lyrics resonated with many people.",
    "lyrics_1": "He writes the lyrics for all his songs.",
    "lyrics_2": "The lyrics of the national anthem are known to everyone.",
    "lyrics_3": "She struggled to remember the lyrics on stage.",
    "lyrics_4": "The band's lyrics often address social and political issues.",
    "accomplished_0": "She is an accomplished pianist, performing worldwide.",
    "accomplished_1": "The mission was accomplished successfully.",
    "accomplished_2": "He felt accomplished after completing the challenging hike.",
    "accomplished_3": "The accomplished author received another award for her work.",
    "accomplished_4": "After years of hard work, she became an accomplished scientist.",
    "enthusiasm_0": "His enthusiasm for the project was contagious.",
    "enthusiasm_1": "The crowd's enthusiasm energized the performers.",
    "enthusiasm_2": "She lost her enthusiasm for the hobby over time.",
    "enthusiasm_3": "The teacher's enthusiasm inspired the students.",
    "enthusiasm_4": "Despite initial enthusiasm, the plan was eventually abandoned.",
    "influence_0": "The artist's work had a significant influence on modern art.",
    "influence_1": "Parents have a strong influence on their children's development.",
    "influence_2": "He used his influence to help his friend get the job.",
    "influence_3": "The book had a profound influence on her thinking.",
    "influence_4": "Social media influencers have a wide reach and influence.",
    "application_0": "Her application for the scholarship was accepted.",
    "application_1": "The new application was downloaded by thousands within days.",
    "application_2": "He demonstrated the practical application of the theory.",
    "application_3": "The job application required a resume and a cover letter.",
    "application_4": "This principle has wide application in various fields.",
    "practical_0": "The course offers both theoretical and practical experience.",
    "practical_1": "She always gave practical advice.",
    "practical_2": "The device is practical for everyday use.",
    "practical_3": "In practical terms, the plan was difficult to implement.",
    "practical_4": "His practical approach to problems made him a good leader.",
    "perspective_0": "Traveling abroad gave her a new perspective on life.",
    "perspective_1": "From an economic perspective, the policy makes sense.",
    "perspective_2": "The book is written from the perspective of a child.",
    "perspective_3": "He tried to see things from her perspective.",
    "perspective_4": "Artists use perspective to create depth in their paintings.",
    "neglected_0": "The neglected garden was overgrown and wild.",
    "neglected_1": "He felt neglected by his friends.",
    "neglected_2": "The report highlighted the neglected issues in the community.",
    "neglected_3": "The neglected building became a haven for artists.",
    "neglected_4": "She worked to help neglected and abandoned animals.",
    "latitude_0": "The city is located at a latitude of 45 degrees north.",
    "latitude_1": "Countries at higher latitudes experience more extreme seasons.",
    "latitude_2": "The latitude lines on the map help determine a location's climate.",
    "latitude_3": "She enjoyed the latitude her boss gave her to make decisions.",
    "latitude_4": "Latitude is measured in degrees north or south of the equator.",
    "tetrahedron_0": "A tetrahedron is a solid shape with four triangular faces.",
    "tetrahedron_1": "He used a model of a tetrahedron to explain molecular structures.",
    "tetrahedron_2": "The game involved fitting together different shapes, including a tetrahedron.",
    "tetrahedron_3": "In geometry, a tetrahedron is a type of polyhedron.",
    "tetrahedron_4": "The artist created a large sculpture shaped like a tetrahedron.",
    "impractical_0": "Wearing high heels on a hiking trip is impractical.",
    "impractical_1": "The plan was imaginative but impractical with the current budget.",
    "impractical_2": "It's impractical to carry so much luggage for a short trip.",
    "impractical_3": "While beautiful, the design was impractical for everyday use.",
    "impractical_4": "He had a tendency to come up with creative yet impractical ideas.",
    "longitude_0": "The prime meridian is the zero-degree line of longitude.",
    "longitude_1": "Longitude lines run from the North Pole to the South Pole.",
    "longitude_2": "By combining latitude and longitude, you can pinpoint any location on Earth.",
    "longitude_3": "The ship's captain calculated their longitude using a sextant.",
    "longitude_4": "Changes in longitude affect the local time zones.",
    "pole_0": "The explorers set out on an expedition to the North Pole.",
    "pole_1": "He fixed a flag to the top of the pole.",
    "pole_2": "Magnetic fields are strongest at the poles of a magnet.",
    "pole_3": "The tent was supported by a central pole.",
    "pole_4": "The fishing village was dotted with poles for drying fish.",
    "accurately_0": "The test aims to accurately measure the students' abilities.",
    "accurately_1": "The artist accurately depicted the landscape in his painting.",
    "accurately_2": "It's important to accurately follow the recipe for the best results.",
    "accurately_3": "The machine can accurately cut materials to the specified size.",
    "accurately_4": "The book accurately portrays the historical events of that era.",
    "proportion_0": "The proportions of the building were carefully designed for aesthetic appeal.",
    "proportion_1": "A large proportion of the budget was allocated to health care.",
    "proportion_2": "She explained the proportion of ingredients needed for the cake.",
    "proportion_3": "The survey revealed that a high proportion of residents favored the plan.",
    "proportion_4": "In art, getting the proportions right is essential for a realistic portrayal.",
    "concept_0": "The concept of time travel has always fascinated scientists.",
    "concept_1": "She struggled to understand the abstract concept presented in class.",
    "concept_2": "The artist's new exhibition is based on the concept of unity.",
    "concept_3": "They developed a business concept that was revolutionary.",
    "concept_4": "The concept of freedom varies from one culture to another.",
    "chunk_0": "He cut the cheese into small chunks.",
    "chunk_1": "A large chunk of ice fell off the glacier.",
    "chunk_2": "Allocating time in chunks can improve productivity.",
    "chunk_3": "The dog chewed on a chunk of bone.",
    "chunk_4": "A chunk of the budget was used for renovating the office.",
    "prior_0": "Prior experience in the field is required for the job.",
    "prior_1": "She had to cancel her trip due to a prior commitment.",
    "prior_2": "The decision was made based on information available prior to the meeting.",
    "prior_3": "He checked the room prior to the guests' arrival.",
    "prior_4": "Prior to the 19th century, the area was inhabited by indigenous tribes.",
    "potential_0": "The young athlete has great potential for a successful career.",
    "potential_1": "Renewable energy sources have huge potential for environmental sustainability.",
    "potential_2": "They conducted a study to assess the market potential of the product.",
    "potential_3": "The potential benefits of the treatment outweigh its risks.",
    "potential_4": "She showed potential in science at an early age.",
    "demonstrate_0": "The experiment was designed to demonstrate the basic principles of physics.",
    "demonstrate_1": "The protestors gathered to demonstrate against the new policy.",
    "demonstrate_2": "He demonstrated his cooking skills in the competition.",
    "demonstrate_3": "The teacher asked her to demonstrate the solution to the class.",
    "demonstrate_4": "The study demonstrates a clear link between diet and health.",
    "Untypical_0": "His behavior was untypical for someone usually so reserved.",
    "Untypical_1": "The warm weather in winter was untypical for the region.",
    "Untypical_2": "She made an untypical decision that surprised her friends.",
    "Untypical_3": "Untypical results in the experiment led to further investigation.",
    "Untypical_4": "It was untypical of him to arrive late.",
    "criticism_0": "The author's work has received both criticism and praise.",
    "criticism_1": "He accepted the criticism gracefully and vowed to improve.",
    "criticism_2": "The policy faced criticism for not being inclusive enough.",
    "criticism_3": "Her criticism of the plan was constructive and helpful.",
    "criticism_4": "Despite criticism, the artist remained true to her style.",
    "simultaneously_0": "The event will be broadcasted simultaneously in multiple countries.",
    "simultaneously_1": "He was able to manage several tasks simultaneously.",
    "simultaneously_2": "The two singers sang simultaneously, creating a beautiful harmony.",
    "simultaneously_3": "The lights in the building turn on simultaneously at dusk.",
    "simultaneously_4": "Working and studying simultaneously can be quite challenging.",
    "irritate_0": "The smoke from the fire started to irritate her eyes.",
    "irritate_1": "His constant joking began to irritate her.",
    "irritate_2": "Certain skincare products can irritate sensitive skin.",
    "irritate_3": "Loud noises tend to irritate him when he's working.",
    "irritate_4": "The tag on the shirt was irritating her neck.",
    "contemporary_0": "Contemporary art often challenges traditional boundaries.",
    "contemporary_1": "The writer is well-known for her contemporary novels.",
    "contemporary_2": "They live in a house with contemporary design.",
    "contemporary_3": "The museum features a collection of contemporary sculptures.",
    "contemporary_4": "She is a fan of contemporary jazz music.",
    "dominate_0": "The team continued to dominate the game in the second half.",
    "dominate_1": "Large corporations dominate the industry.",
    "dominate_2": "The mountain dominates the landscape of the region.",
    "dominate_3": "Throughout history, various empires have sought to dominate their neighbors.",
    "dominate_4": "The color red dominates the painting, drawing attention to its center.",
    "restrictive_0": "The restrictive regulations hindered the growth of new businesses.",
    "restrictive_1": "He found the diet too restrictive and difficult to maintain.",
    "restrictive_2": "The city's zoning laws are quite restrictive.",
    "restrictive_3": "Some argue that the law is too restrictive on personal freedoms.",
    "restrictive_4": "The restrictive policy limits the amount of traffic in the city center.",
    "reassuring_0": "Her words were reassuring in a time of uncertainty.",
    "reassuring_1": "The doctor's reassuring manner helped calm the patient.",
    "reassuring_2": "It was reassuring to know that help was on the way.",
    "reassuring_3": "The steady progress of the project was reassuring to investors.",
    "reassuring_4": "He gave a reassuring smile before the performance began.",
    "inevitably_0": "Inevitably, the truth about the scandal was revealed.",
    "inevitably_1": "Changes in technology inevitably lead to new ways of working.",
    "inevitably_2": "Due to the economic crisis, layoffs were inevitably necessary.",
    "inevitably_3": "Inevitably, the small town changed as the years passed.",
    "inevitably_4": "The debate inevitably turned to the subject of funding.",
    "elegance_0": "The elegance of her dress caught everyone's attention.",
    "elegance_1": "There was a simplicity and elegance in the design of the building.",
    "elegance_2": "The ballet dancer moved with grace and elegance.",
    "elegance_3": "The software solution stood out for its elegance and efficiency.",
    "elegance_4": "The dinner party was arranged with elegance and taste.",
    "intimidate_0": "The defendant tried to intimidate the witness.",
    "intimidate_1": "He used his size to intimidate his opponents.",
    "intimidate_2": "The goal was not to intimidate but to inform.",
    "intimidate_3": "The complexity of the machine can intimidate new users.",
    "intimidate_4": "Intimidate tactics are often counterproductive in negotiations.",
    "aptitude_0": "She has a natural aptitude for mathematics.",
    "aptitude_1": "The test is designed to measure the students' aptitude in critical thinking.",
    "aptitude_2": "His aptitude for languages became apparent at a young age.",
    "aptitude_3": "They offer training for people with an aptitude for computer programming.",
    "aptitude_4": "An aptitude test helped her decide her career path.",
    "relive_0": "Watching old videos allowed him to relive his childhood memories.",
    "relive_1": "She didn't want to relive the trauma of the past.",
    "relive_2": "The reunion was a chance to relive their college days.",
    "relive_3": "Through his storytelling, we could relive his incredible journey.",
    "relive_4": "Athletes often relive their moments of glory in retirement.",
    "tend_0": "She tends to be cautious when making decisions.",
    "tend_1": "Gardeners tend the plants to ensure they grow properly.",
    "tend_2": "He tends to forget things easily.",
    "tend_3": "People tend to eat more when they are stressed.",
    "tend_4": "Nurses tend to patients in the hospital.",
    "buzz_0": "The room was filled with the buzz of excited conversation.",
    "buzz_1": "She felt a buzz of excitement at the news.",
    "buzz_2": "The city streets are always full of buzz and activity.",
    "buzz_3": "The announcement created a buzz in the industry.",
    "buzz_4": "He could hear the buzz of the bees in the garden.",
    "Unwind_0": "After a long week, she liked to unwind with a good book.",
    "Unwind_1": "Vacations are a perfect time to unwind and relax.",
    "Unwind_2": "A warm bath can help you unwind at the end of the day.",
    "Unwind_3": "He found gardening a great way to unwind from the stress of work.",
    "Unwind_4": "The spa offers many treatments to help guests unwind.",
    "strategy_0": "The company's long-term strategy focuses on sustainable growth.",
    "strategy_1": "Her strategy for winning the game was well thought out.",
    "strategy_2": "The military strategy was carefully planned by the generals.",
    "strategy_3": "They discussed various marketing strategies for the new product.",
    "strategy_4": "Adapting a strategy to changing circumstances can be challenging.",
    "isolation_0": "The isolation of living in a remote village can be challenging.",
    "isolation_1": "During the pandemic, many people experienced feelings of isolation.",
    "isolation_2": "The laboratory experiment required the isolation of the chemical compound.",
    "isolation_3": "He preferred the isolation of the countryside to the bustle of the city.",
    "isolation_4": "Isolation can sometimes lead to depression.",
    "discipline_0": "Martial arts require discipline and dedication.",
    "discipline_1": "She maintained discipline in the classroom.",
    "discipline_2": "Financial discipline is important for saving money.",
    "discipline_3": "Psychology is a fascinating academic discipline.",
    "discipline_4": "Discipline is essential for achieving one's goals.",
    "comment_0": "He made a comment about the weather.",
    "comment_1": "The article's comment section was filled with diverse opinions.",
    "comment_2": "She declined to comment on the rumors.",
    "comment_3": "His witty comment made everyone laugh.",
    "comment_4": "Please leave a comment under the post with your thoughts.",
    "fuss_0": "She made a big fuss over the wedding arrangements.",
    "fuss_1": "The baby started to fuss when he got tired.",
    "fuss_2": "There was a lot of fuss about the celebrity's visit.",
    "fuss_3": "He didn't understand what all the fuss was about.",
    "fuss_4": "They managed to resolve the issue without much fuss.",
    "intervene_0": "The government had to intervene in the crisis.",
    "intervene_1": "Sometimes it's necessary to intervene in disputes to find a resolution.",
    "intervene_2": "The teacher intervened when the argument between students escalated.",
    "intervene_3": "He intervened to help the lost tourist.",
    "intervene_4": "Medications can intervene effectively in the disease process.",
    "reluctantly_0": "She reluctantly agreed to go to the party.",
    "reluctantly_1": "He reluctantly handed over the keys to his car.",
    "reluctantly_2": "The child reluctantly did his homework.",
    "reluctantly_3": "Reluctantly, they accepted the changes they had to make.",
    "reluctantly_4": "He reluctantly admitted he was wrong.",
    "obstacle_0": "Lack of funding was a major obstacle to completing the project.",
    "obstacle_1": "She overcame many obstacles on her way to success.",
    "obstacle_2": "The fallen tree was an obstacle in the road.",
    "obstacle_3": "Fear of failure can be a psychological obstacle.",
    "obstacle_4": "Negotiating obstacles in the race tested their endurance.",
    "conventional_0": "He preferred conventional methods over modern ones.",
    "conventional_1": "The treatment was effective but not as good as the conventional one.",
    "conventional_2": "Their house had a conventional design.",
    "conventional_3": "Conventional wisdom suggests that hard work leads to success.",
    "conventional_4": "She wasn't interested in a conventional career.",
    "indigenous_0": "The museum exhibited art from indigenous cultures.",
    "indigenous_1": "Indigenous plants are well adapted to the local climate.",
    "indigenous_2": "They learned about the traditions of the indigenous people.",
    "indigenous_3": "Efforts were made to preserve the indigenous languages.",
    "indigenous_4": "Indigenous communities often have unique cultural practices.",
    "occasionally_0": "He occasionally goes for a walk in the park.",
    "occasionally_1": "Occasionally, she would look up from her book and gaze out the window.",
    "occasionally_2": "We meet up occasionally for coffee.",
    "occasionally_3": "The machine occasionally breaks down but it's generally reliable.",
    "occasionally_4": "She treats herself to a dessert occasionally.",
    "attitude_0": "Her positive attitude was infectious.",
    "attitude_1": "He had a very casual attitude towards his studies.",
    "attitude_2": "Changing your attitude can change your life.",
    "attitude_3": "Their attitude towards the project was very professional.",
    "attitude_4": "The company's attitude towards customer service is exemplary.",
    "architecture_0": "The city is known for its Gothic architecture.",
    "architecture_1": "She studied architecture in college.",
    "architecture_2": "The building's architecture was modern and striking.",
    "architecture_3": "They admired the ancient architecture of the Roman ruins.",
    "architecture_4": "Sustainable architecture is becoming increasingly popular.",
    "timber_0": "The house was built with high-quality timber.",
    "timber_1": "Timber harvesting is a major industry in the region.",
    "timber_2": "They used local timber for the construction project.",
    "timber_3": "The forest is managed for sustainable timber production.",
    "timber_4": "Timber beams support the structure of the barn.",
    "preoccupation_0": "His preoccupation with work left little time for family.",
    "preoccupation_1": "Her main preoccupation was her children's education.",
    "preoccupation_2": "The novel explores the preoccupation with social status.",
    "preoccupation_3": "Safety is our primary preoccupation on the job site.",
    "preoccupation_4": "The artist's preoccupation with light is evident in his paintings.",
    "refine_0": "The company continues to refine its business strategies.",
    "refine_1": "He refined his painting technique over the years.",
    "refine_2": "The process was refined to increase efficiency.",
    "refine_3": "They are working to refine the software for better user experience.",
    "refine_4": "Refine your arguments to make them more persuasive.",
    "assurance_0": "He gave his assurance that the work would be done on time.",
    "assurance_1": "The quality assurance team checks every product.",
    "assurance_2": "She spoke with an air of assurance.",
    "assurance_3": "Customers need assurance of the safety of their personal data.",
    "assurance_4": "His assurance was comforting in a time of uncertainty.",
    "outset_0": "From the outset, the project faced many challenges.",
    "outset_1": "It was clear from the outset that the plan was ambitious.",
    "outset_2": "She explained the rules at the outset of the game.",
    "outset_3": "At the outset of his career, he worked as a junior engineer.",
    "outset_4": "The company was profitable from the outset.",
    "reinforce_0": "The general ordered to reinforce the troops at the frontline.",
    "reinforce_1": "The teacher used games to reinforce the lesson.",
    "reinforce_2": "They had to reinforce the walls to prevent collapse.",
    "reinforce_3": "Positive feedback can reinforce good behavior in children.",
    "reinforce_4": "Structural engineers reinforced the bridge to increase safety.",
    "pursue_0": "She decided to pursue a career in medicine.",
    "pursue_1": "The police pursue the suspect through the city.",
    "pursue_2": "He chose to pursue his interest in photography.",
    "pursue_3": "The committee will pursue further investigations.",
    "pursue_4": "They encouraged her to pursue her artistic talents.",
	"artisan_0": "There's a real sense of authenticity and care in every product that an artisan crafts. ",
	"artisan_1": "The skill of the artisan was evident in the intricate details of the hand-carved wooden sculpture. ",
	"artisan_2": "The city has a thriving market full of local artisan goods, from jewelry and ceramics to bread and cheese. ",
	"artisan_3": "As an artisan, he takes pride in using only traditional methods and materials in his work.",
	"artisan_4": "Being an artisan requires not only craftsmanship and creativity, but also passion and patience.",
	"genuine_0": "Her genuine smile made everyone in the room feel warm and welcomed.",
	"genuine_1": "The vendor assured me that the diamond was genuine and not a synthetic imitation.",
	"genuine_2": "He expressed his feelings for her in a genuine way, leaving no doubt about his sincerity.",
	"genuine_3": "The note that came with the flowers was extremely touching and genuine.",
	"genuine_4": "Despite their disagreements, her care and concern for him was genuine.",
	"solemnity_0": "During the solemnity of the ceremony, everyone was moved to tears.",
	"solemnity_1": "His solemnity made it hard for me to tell if he was joking or serious about the matter.",
	"solemnity_2": "Despite the festive atmosphere, a sense of solemnity hung over the gathering as everyone remembered the one who could not be with them.",
	"solemnity_3": "The solemnity of his words made everyone reflect deeply on the reality of the situation.",
	"solemnity_4": "She bowed her head in solemnity and offered up a silent prayer.",
	"hone_0": "To improve his writing skills, Michael decided to hone them by enrolling in a specialized course.",
	"hone_1": "She took up sculpture to hone her craft and explore her artistic passions.",
	"hone_2": "It's important to continuously hone your communication skills in order to maintain strong relationships.",
	"hone_3": "The coaching sessions helped him hone his leadership qualities.",
	"hone_4": "It takes years of practice and hard work to hone one's skills in playing a musical instrument.",
	"bound_0": "The deer was bound across the forest clearing in swift leaps.",
	"bound_1": "I have bound the package with a ribbon.",
	"bound_2": "The book was bound in a beautiful rich leather.",
	"bound_3": "We are bound by the decisions we make in life.",
	"bound_4": "The plane is bound for Los Angeles, we better check in now.",
	"itinerary_0": "The tour guide handed out an itinerary so we knew what sights we'd be visiting each day.",
	"itinerary_1": "His itinerary for the business trip includes three meetings and a networking event.",
	"itinerary_2": "Before embarking on their road trip, they carefully planned an itinerary to maximize their sightseeing.",
	"itinerary_3": "The wedding planner created a detailed itinerary to ensure all festivities ran smoothly.",
	"itinerary_4": "My travel agent sent me an email of our holiday itinerary, including flights, accommodation, and tours.",
	"subsequent_0": "After her acclaimed debut novel, all of her subsequent books were bestsellers.",
	"subsequent_1": "The researchers made an initial discovery, but their subsequent findings were even more significant.",
	"subsequent_2": "His argument with his boss had subsequent repercussions on his career.",
	"subsequent_3": "The main course was excellent, but the subsequent dessert course was truly outstanding.",
	"subsequent_4": "The initial stages of training were difficult, but he was able to adapt to the subsequent challenges with ease.",
	"nostalgia_0": "When I found my old childhood toys in the attic, a wave of nostalgia washed over me.",
	"nostalgia_1": "Watching old cartoons from the 90s brings back a particular kind of nostalgia.",
	"nostalgia_2": "The grandma's attic was filled not only with dust-covered furniture but had an air of nostalgia too.",
	"nostalgia_3": "The scent of freshly baked bread always triggered a sense of nostalgia in Sarah reminding her of her grandmother's kitchen.",
	"nostalgia_4": "The music from the 80s filled Mike with nostalgia, reminding him of his youthful days.",
	"approach_0": "We'll have to modify our approach if we want to succeed in this project. ",
	"approach_1": "As they went further into the cave, they saw a bear approach from a distance. ",
	"approach_2": "Management believes that a collaborative approach to problem-solving produces the best results.",
	"approach_3": "Could you please approach the professor and ask him about the assignment?",
	"approach_4": "Many athletes have a unique approach to preparing for a big game.",
	"contribution_0": "The young physician made a significant contribution to the field of cancer research.",
	"contribution_1": "The local charity appreciates any contribution, no matter how small.",
	"contribution_2": "Emma's creative ideas were a great contribution to our team project.",
	"contribution_3": "His contribution to the community, as a volunteer firefighter, has not been forgotten.",
	"contribution_4": "The music icon's contribution to the world of pop culture has been truly monumental.",
	"appreciate_0": "I really appreciate all the help you've given me with this project.",
	"appreciate_1": "As an art lover, she was able to appreciate the beauty of the painting.",
	"appreciate_2": "He did not appreciate the criticism he received about his cooking.",
	"appreciate_3": "She offered a sincere thank you to show her appreciation for his kindness.",
	"appreciate_4": "I would appreciate it if you could send me the document before the meeting.",
	"innovative_0": "The innovative design of the smartphone has set a new standard in the industry.",
	"innovative_1": "Her innovative approach to teaching engages students and promotes critical thinking.",
	"innovative_2": "The company's innovative strategies helped streamline the production process, resulting in improved efficiency and reduced costs.",
	"innovative_3": "Archeologists have found evidence of innovative agriculture techniques used by ancient civilizations.",
	"innovative_4": "Steve Jobs is remembered as an innovative leader in the technology sector.",
	"variously_0": "The knitwork on the sweater was variously colored, presenting a beautiful array of autumn shades.",
	"variously_1": "The project proposal was variously interpreted by the team, leading to a lengthy discussion.",
	"variously_2": "The product is variously priced around the world due to differences in import taxes and shipping costs.",
	"variously_3": "The survey results were variously regarded, with some viewing them as encouraging, while others saw them as worrying.",
	"variously_4": "His comments were variously received, depending on the personal beliefs of each audience member.",
	"query_0": "John's thoughtful query left the professor pondering for a moment before answering. ",
	"query_1": "The detective's query made the suspect feel even more nervous. ",
	"query_2": "Sally sent a query to the travel agency concerning the details of her upcoming trip. ",
	"query_3": "When I didn't understand the assignment, I shot a quick query to my instructor asking for clarification. ",
	"query_4": "The article ends with a powerful query that pushes the readers to think deeply about the topic.",
	"inefficiency_0": "The inefficiency of the old machinery was causing delays in production.",
	"inefficiency_1": "One of the main causes of environmental issues is the inefficiency in energy consumption.",
	"inefficiency_2": "The company was criticized for its inefficiency in responding to customer complaints.",
	"inefficiency_3": "After analyzing the project, the manager identified multiple areas of inefficiency that were contributing to the delays. ",
	"inefficiency_4": "Innovations in technology often aim to eliminate human inefficiency and streamline processes.",
	"punctual_0": "Jane is always punctual, showing up on time for every meeting and appointment.",
	"punctual_1": "Being punctual is an important quality for anyone in a professional field. ",
	"punctual_2": "As a doctor, he was punctual to all his appointments, earning the respect and appreciation of his patients. ",
	"punctual_3": "My mother taught me the importance of being punctual from a very young age.",
	"punctual_4": "His punctual arrival at work every day set the standard for the rest of the staff.",
	"strip_0": "The city's main strip was filled with charming little shops and restaurants.",
	"strip_1": "The comic strip I read this morning was absolutely hilarious.",
	"strip_2": "He used a metal strip to reinforce the broken chair leg. ",
	"strip_3": "To paint the wall, we had to strip the old wallpaper off first.",
	"strip_4": "The runway strip was lit up, ready for the plane to take off.",
	"stain_0": "I accidentally spilled my coffee, leaving a large brown stain on the white carpet.",
	"stain_1": "The red wine stain on her blouse wouldn't come out no matter how much she scrubbed it.",
	"stain_2": "The varnish stained my hands a deep shade of brown.",
	"stain_3": "His character was once impeccable, but the scandal has left a permanent stain on his reputation. ",
	"stain_4": "Despite using high quality paint, the stain on the wall keeps seeping through.",
	"spine_0": "The spine is integral to human structure, providing support and facilitating movement.",
	"spine_1": "She ran her fingers delicately along the spine of the ancient book.",
	"spine_2": "The x-ray showed a curvature in his spine, indicating scoliosis. ",
	"spine_3": "After the car accident, he suffered from severe spine injuries that required immediate surgery. ",
	"spine_4": "The animal's defensive mechanism was a series of sharp spines on its back.",
	"sleeve_0": "He rolled up his sleeves and began to wash the dishes.",
	"sleeve_1": "She noticed a stain on the sleeve of her new white blouse.",
	"sleeve_2": "The designer added some lace detailing to the sleeve of the wedding dress for a touch of elegance.",
	"sleeve_3": "Dan kept his cards close to his sleeve, never letting anyone know his next move.",
	"sleeve_4": "I want this sweater but in a short sleeve, it's too hot for long sleeves right now.",
	"handicap_0": "The physical handicap did not deter him from becoming a successful athlete.",
	"handicap_1": "The government has initiated several programs to assist people with severe mental and physical handicaps.",
	"handicap_2": "Their team won the match even with a severe handicap.",
	"handicap_3": "Lack of education is a serious handicap in today's job market. ",
	"handicap_4": "Despite his handicap, Jon was able to live a fulfilling and independent life.",
	"emerge_0": "After many years of study and hard work, she began to emerge as a leading figure in her field.",
	"emerge_1": "When the sun started to set, stars began to emerge in the night sky.",
	"emerge_2": "After the hurricane, several serious issues began to emerge in regards to the city's infrastructure.",
	"emerge_3": "The butterfly will emerge from its cocoon transformed and ready to fly.",
	"emerge_4": "As the meeting went on, a definite pattern started to emerge regarding the company's economic challenges.",
	"delicate_0": "Cathy handled the delicate porcelain doll with great care so as not to break it.",
	"delicate_1": "The delicate lace of her wedding gown was carefully hand-stitched by her grandmother.",
	"delicate_2": "The negotiation between the two countries was a delicate matter that required extreme diplomacy.",
	"delicate_3": "The delicate aroma of fresh flowers wafted through the open window.",
	"delicate_4": "His feelings were so delicate that even the slightest criticism would make him upset.",
	"appliance_0": "The dishwasher is the most used appliance in our house.",
	"appliance_1": "We decided to upgrade all the kitchen appliances to more energy-efficient models.",
	"appliance_2": "My coffee maker is the most valued appliance in my apartment.",
	"appliance_3": "Many appliances nowadays are designed with smart technology, allowing you to control them with your phone.",
	"appliance_4": "She instructed the delivery team to place the new appliance next to the refrigerator.",
	"religious_0": "My grandmother was a deeply religious woman, known for her unwavering devotion to her faith.",
	"religious_1": "The religious themes in the art museum were prevalent, reflecting the historical significance of faith in society.",
	"religious_2": "They sought a religious exemption which would excuse them from participating in certain activities conflicting with their beliefs.",
	"religious_3": "The religious conflict in the region led to years of unrest and violence.",
	"religious_4": "He chose to study religious studies at the university, hoping to understand the various beliefs and practices from around the world.",
	"ideal_0": "The perfect weather for a picnic is sunny and warm - it's the ideal condition.",
	"ideal_1": "The public health policy implemented by the government is far from the ideal one, and it requires several improvements.",
	"ideal_2": "His disciplined lifestyle and commitment towards his goals make him an ideal role model for youth.",
	"ideal_3": "The company found the ideal candidate for the position who fulfilled all the qualifications and had a charming personality as well. ",
	"ideal_4": "A small cottage in the countryside, surrounded by nature, is my ideal place to live after retirement.",
	"abstract_0": "The painting was so abstract that people had different interpretations of what it represented.",
	"abstract_1": "He had an abstract way of thinking that made him a true genius.",
	"abstract_2": "The abstract of her thesis was well-written and intriguing.",
	"abstract_3": "She loves abstract art because it doesn't constrict her imagination to preconceived notions.",
	"abstract_4": "In order to understand the research paper, he first read the abstract to get an overview of the topic.",
	"virtual_0": "The virtual reality game she was playing felt so real, it was almost terrifying.",
	"virtual_1": "Due to the pandemic, many events are being conducted in a virtual environment.",
	"virtual_2": "The company adopted virtual meetings to reduce travel costs and increase efficiency.",
	"virtual_3": "My computer has a virtual assistant that helps with day-to-day tasks.",
	"virtual_4": "He spent so much time in the virtual world of his video game, he often forgot about the real world.",
	"philosophy_0": "His philosophy in life is to treat everyone with kindness and respect.",
	"philosophy_1": "She decided to study philosophy in university, focusing on the philosophies of ancient civilizations.",
	"philosophy_2": "The philosophy behind this management style is to empower employees to make their own decisions.",
	"philosophy_3": "Understanding various worldviews and philosophies helps you gain a broader perspective on life.",
	"philosophy_4": "The philosophy of mindfulness encourages the practice of being fully present and aware in every moment.",
	"dare_0": "I wouldn't dare to question his decision in the board meeting.",
	"dare_1": "He accepted the dare and jumped into the icy lake, much to everyone's surprise.",
	"dare_2": "The boy showed a lot of courage, he dare to speak up against the bully in front of the whole class.",
	"dare_3": "Playing truth or dare was always the highlight of our college get-togethers.",
	"dare_4": "How dare you accuse me of such a thing without any proof?",
	"cast_0": "The director was proud of the cast he assembled for his upcoming movie.",
	"cast_1": "The fisherman expertly cast his line out into the middle of the lake. ",
	"cast_2": "There was a beautiful golden cast of light in the sky as the sun began to set. ",
	"cast_3": "Karl broke his leg while skiing last winter and had to wear a cast for two months.",
	"cast_4": "She cast a worried glance in his direction, unsure of how he would react.",
	"tobacco_0": "Tobacco smoking is the leading cause of preventable death worldwide.",
	"tobacco_1": "Many farmers in Kentucky make their living by growing tobacco.",
	"tobacco_2": "He decided to quit his tobacco addiction for his health and loved ones.",
	"tobacco_3": "The dried leaves of tobacco can be used for smoking in cigarettes, cigars, or pipes. ",
	"tobacco_4": "The cultivation of tobacco was an important industry in the early American colonies.",
	"symptom_0": "Constant fatigue could be a symptom of an underlying health condition.",
	"symptom_1": "Loss of smell and taste is a common symptom of Covid-19.",
	"symptom_2": "The doctor asked me to note down any changes in my symptoms.",
	"symptom_3": "Fever and chills are typical symptoms of the flu.",
	"symptom_4": "Discoloration on a device's screen might be a symptom of a hardware malfunction.",
	"permanent_0": "She decided to go for a permanent tattoo instead of the temporary henna ones.",
	"permanent_1": "The treatment led to a permanent improvement in his health condition. ",
	"permanent_2": "The new law ushered in permanent changes in the immigration process.",
	"permanent_3": "Nelly took up a permanent teaching position at the local university after years of part-time work.",
	"permanent_4": "After the accident, he was left with a permanent scar on his face.",
	"engage_0": "When we engage in meaningful conversations, we learn more about each other.",
	"engage_1": "The soldiers were ordered to engage the enemy at sunrise.",
	"engage_2": "I find her classes very engaging because she uses real-life examples in her explanations.",
	"engage_3": "The new marketing strategy is designed to engage potential customers, encouraging them to interact with our brand.",
	"engage_4": "All the features in the new app are specifically integrated to engage users and keep them hooked on the platform.",
	"adjust_0": "Maria had to adjust her schedule to fit in her new yoga class.",
	"adjust_1": "The tailor had to adjust the dress as it was too big for me.",
	"adjust_2": "When my cousin moved to Australia, he had to adjust to the different time zone.",
	"adjust_3": "The new policies at work are tough, but we need to adjust ourselves to them for continued efficiency.",
	"adjust_4": "After his surgery, Ted had to adjust his lifestyle and diet to facilitate better recovery.",
	"accuse_0": "Despite his protestations of innocence, the jury decided to accuse him of the crime. ",
	"accuse_1": "His sister accused him of stealing her favorite bracelet, causing a heated argument between them. ",
	"accuse_2": "The politician was accused of corruption, triggering a major scandal within the government. ",
	"accuse_3": "The district attorney did not have enough evidence to accuse the suspect formally. ",
	"accuse_4": "She felt betrayed when her best friend accused her of spreading rumors about her.",
	"status_0": "Before proceeding with the meeting, the manager asked for a status update on the project.",
	"status_1": "His status in society rose after his novel became a bestseller.",
	"status_2": "The symbols on the map indicate the status of each region during the war.",
	"status_3": "The status of her health has significantly improved after starting a new treatment. ",
	"status_4": "In university, he studied the influence of socioeconomic status on educational achievement.",
	"spectrum_0": "The psychologist's report provided a detailed analysis of his behavior across a broad spectrum of situations.",
	"spectrum_1": "Scientists use the electromagnetic spectrum to observe and measure different types of radiation.",
	"spectrum_2": "The political spectrum spans from the extreme left to the extreme right, encompassing all different types of ideologies and beliefs.",
	"spectrum_3": "Autism is a spectrum disorder, meaning it affects individuals in different ways and to varying degrees.",
	"spectrum_4": "The art exhibit showcased a wide spectrum of styles, from abstract to realistic, reflecting the diverse talents of the artists.",
	"solitary_0": "After a long day at work, all Tom wanted was a solitary evening with his book.",
	"solitary_1": "The solitary tree in the middle of the field stood tall, unscathed by the elements.",
	"solitary_2": "In prison, the unruly inmates were often threatened with solitary confinement as punishment.",
	"solitary_3": "Susan generally preferred a solitary lunch, using it as a time to reflect and recharge.",
	"solitary_4": "The solitary lighthouse on the coastline served as the only guiding light for ships in the foggy nights.",
	"radiate_0": "The heat seemed to radiate off the desert sand, making it almost unbearable to walk on.",
	"radiate_1": "Her positive energy seemed to radiate throughout the entire room, lifting everyone's spirits. ",
	"radiate_2": "After the successful launch, pride and happiness seemed to radiate from every member of the team.",
	"radiate_3": "The old lamp, though dim, still managed to radiate a comforting glow in the dark bedroom.",
	"radiate_4": "As the sun began to set, a beautiful range of colours began to radiate across the sky.",
	"gloom_0": "The gloom in the room was almost tangible after hearing the distressing news.",
	"gloom_1": "A sense of gloom descended over the town after the factory shut down, leaving many jobless.",
	"gloom_2": "He sat alone in the gloom, his mind filled with dark thoughts.",
	"gloom_3": "As the sun went down, the forest was rapidly filled with gloom and foreboding shadows.",
	"gloom_4": "Despite the general air of gloom in the wake of the hurricane, the community came together to support and help one another.",
	"breadth_0": "The impressive breadth of his knowledge in physics astounded everyone in the room.",
	"breadth_1": "The breadth of the Amazon River is difficult to comprehend until you see it in person.",
	"breadth_2": "Our team was struck by the incredible breadth of wildlife we came across during our research expedition.",
	"breadth_3": "The discussion should not just focus on depth, but also on the breadth of the issue at hand.",
	"breadth_4": "The breadth of the bridge was wide enough to accommodate four lanes of traffic.",
	"atom_0": "Atoms are tiny particles that make up everything in the universe.",
	"atom_1": "When two atoms combine, they form a molecule.",
	"atom_2": "Each atom consists of a nucleus and one or more electrons revolving around the nucleus.",
	"atom_3": "The type of atom can be determined by the number of protons in its nucleus.",
	"atom_4": "Scientists were able to split an atom, unleashing the destructive power of atomic bombs.",
	"sufficient_0": "The money we have raised for the charity event is sufficient to feed hundreds of homeless people.",
	"sufficient_1": "Sarah did not receive sufficient notice to prepare for the surprise test. ",
	"sufficient_2": "The data provided was not sufficient for the researcher to reach any definitive conclusion. ",
	"sufficient_3": "After completing four years of college, I still did not feel I had sufficient knowledge to enter the job market.",
	"sufficient_4": "John had eaten a sufficient amount of food at lunch, so he decided to skip dinner.",
	"regret_0": "Jack was filled with regret after realizing he had forgotten his wife's birthday.",
	"regret_1": "She left the party early, but later regretted her decision when she saw pictures of what she missed.",
	"regret_2": "I regret not taking the opportunity to study abroad when I had the chance.",
	"regret_3": "They expressed their deep regret for the inconvenience caused by the delay.",
	"regret_4": "Living with regret is not healthy; instead, focus on learning from your mistakes.",
	"inspect_0": "The health department frequently comes to inspect the kitchen in our restaurant for cleanliness.",
	"inspect_1": "We hired a professional to inspect the house for any potential structural issues before purchasing it.",
	"inspect_2": "The customs officer will inspect your baggage upon your arrival to ensure you aren't transporting any prohibited items.",
	"inspect_3": "The mechanic needs to inspect my car due to an unusual noise it's been making.",
	"inspect_4": "The fire marshal is scheduled to inspect the school's new fire safety system next week.",
	"disabled_0": "The new community center is designed with the disabled in mind, providing wheelchair-friendly ramps and accessible bathrooms.",
	"disabled_1": "After the tragic accident, he is now classified as disabled due to his inability to walk.",
	"disabled_2": "Laws have been put in place to ensure that disabled individuals are not excluded or discriminated against.",
	"disabled_3": "It is our ethical duty to offer assistance to disabled persons when they need it.",
	"disabled_4": "Disabled people have achieved incredible feats in spite of their physical limitations.",
	"stable_0": "The horse was kept in a well-maintained stable.",
	"stable_1": "Despite the economic crisis, my job remains stable.",
	"stable_2": "Her condition is now stable after the surgery, the doctor said.",
	"stable_3": "The old man was walking across the ice, trying to keep his balance stable. ",
	"stable_4": "The table won't be stable unless you fix that broken leg.",
	"somewhat_0": "He found the movie somewhat interesting, but it wasn't his favorite. ",
	"somewhat_1": "I was somewhat relieved when I realized the assignment wasn't due until next week. ",
	"somewhat_2": "The cake is somewhat sweeter than I anticipated. ",
	"somewhat_3": "The report is somewhat complicated; I need to study it more thoroughly. ",
	"somewhat_4": "Despite the cold weather, the playground was somewhat crowded with kids enjoying their day off.",
	"resume_0": "After the fire alarm was cleared, the students were able to resume their test.",
	"resume_1": "John decided to revise his resume in hopes of making a better impression on potential employers.",
	"resume_2": "Melissa instantly regretted her decision to lie on her resume when she was asked about her qualifications in the interview.",
	"resume_3": "When the power outage was over, the concert was able to resume to the delight of the fans.",
	"resume_4": "I attended a resume writing workshop to help present my experiences and skills in a more professional way.",
	"convict_0": "The convict was sentenced to five years in prison for theft.",
	"convict_1": "Despite the evidence against him, they could not convict him due to lack of witness.",
	"convict_2": "The jury voted unanimously to convict the man of fraud.",
	"convict_3": "As a lawyer, her job was to defend the convict in court. ",
	"convict_4": "After serving his time, the convict was released and sought to start a new life.",
	"satellite_0": "The satellite was launched into orbit to monitor climate changes on the Earth.",
	"satellite_1": "The TV station uses a satellite to broadcast its signal to viewers around the world.",
	"satellite_2": "Scientists are tracking a rogue satellite that's on a collision course with Earth.",
	"satellite_3": "Satellite data can help predict weather patterns and natural disasters with greater accuracy.",
	"satellite_4": "To restore their internet connection, they had to adjust the position of their satellite dish.",
	"statistics_0": "The professor thoroughly reviewed all the basic concepts of statistics during the first week of classes.",
	"statistics_1": "According to recent statistics, unemployment rates have significantly decreased in the past year.",
	"statistics_2": "My sister is struggling with her statistics course this semester and needs extra tutoring.",
	"statistics_3": "Covid-19 statistics from various countries were presented in the global health summit.",
	"statistics_4": "The baseball player's statistics have improved remarkably since he started his training routine.",
	"poverty_0": "The rise in unemployment in the city has led to an increase in poverty.",
	"poverty_1": "Along with improving healthcare, eradicating poverty is one of the main goals of the United Nations.",
	"poverty_2": "Poverty is a challenging social issue that requires empathetic and committed solutions.",
	"poverty_3": "Children living in poverty often face significant disadvantages in their education and health.",
	"poverty_4": "In many developing countries, people are striving to escape the harsh realities of extreme poverty.",
	"instant_0": "In an instant, the lightning illuminated the dark landscape. ",
	"instant_1": "She was terrified the instant she saw the shadowy figure in her backyard.",
	"instant_2": "My instant reaction was to scream when I saw the spider.",
	"instant_3": "The news of his promotion brought him instant joy.",
	"instant_4": "I love modern technology because it provides instant access to information from all over the world.",
	"immigrant_0": "His grandparents were immigrants from Italy who came to America in search of a better life.",
	"immigrant_1": "The country has seen an increase in immigrant populations in recent years.",
	"immigrant_2": "She is grateful for the opportunities she has had as an immigrant in the United States.",
	"immigrant_3": "The policies on immigrant workers have become stricter over the past decade.",
	"immigrant_4": "As an immigrant, he faced many cultural and language barriers.",
	"barrier_0": "The language barrier made it difficult for the foreign student to understand his classmates.",
	"barrier_1": "The Great Barrier Reef is home to an amazing diversity of marine life.",
	"barrier_2": "The city had to erect a barrier along the river to prevent flooding.",
	"barrier_3": "Overcoming the barrier of my fear was the most difficult part of learning to drive.",
	"barrier_4": "The cyclist had to swerve suddenly to avoid hitting the barrier on the road.",
	"approximate_0": "The approximate cost of repairing the car would be around two thousand dollars.",
	"approximate_1": "The teacher asked us to give an approximate value for the square root of 50.",
	"approximate_2": "In the report, they only provided approximate numbers, not the exact figures.",
	"approximate_3": "The tour guide gave us an approximate time of arrival at the campsite.",
	"approximate_4": "We won't know the final total until all the payments are in, but I can give you an approximate amount now.",
	"element_0": "The periodic table is a chart that organizes all of the known chemical elements according to their properties.",
	"element_1": "Oxygen is an important element needed for all human life functions.",
	"element_2": "The element of surprise was crucial to the success of their plan.",
	"element_3": "In the sci-fi series, they introduced a new element that allowed faster-than-light travel.",
	"element_4": "Every story has an element of truth in it.",
	"agriculture_0": "Agriculture has been one of the most significant economic activities throughout human history.",
	"agriculture_1": "Modern technology has made a significant impact on agriculture, improving both the quantity and quality of crop yields.",
	"agriculture_2": "Many developing countries heavily rely on agriculture for their national income and employment.",
	"agriculture_3": "Agriculture is much more than just planting seeds; it includes the study of soil composition, weather patterns, and crop diseases.",
	"agriculture_4": "Sustainable agriculture practices can help in the fight against global warming while also ensuring food security.",
	"formal_0": "The company holds a formal dinner for its employees every year.",
	"formal_1": "There was a formal announcement about the merger on Monday morning.",
	"formal_2": "John wore a formal suit to his best friend's wedding.",
	"formal_3": "As a lawyer, she is adept at writing formal letters.",
	"formal_4": "Your manners should be formal when you are in a professional setting.",
	"criticize_0": "The teacher had to criticize the student's behavior in order to bring about a positive change.",
	"criticize_1": "My boss tends to criticize my work excessively, which can be quite demotivating.",
	"criticize_2": "It's much easier to criticize someone's efforts than to do the work yourself.",
	"criticize_3": "You have no right to criticize me, considering your past mistakes.",
	"criticize_4": "She doesn't take kindly to people who criticize her cooking skills.",
	"commute_0": "My daily commute to the office downtown takes about an hour by train.",
	"commute_1": "Since he moved to the suburbs, Alex has a long commute but he enjoys the peaceful lifestyle.",
	"commute_2": "To save on gas and reduce her carbon footprint, Sarah decided to start biking to work for her morning commute.",
	"commute_3": "Due to the heavy snowfall, the usual easy commute turned into a long and treacherous journey. ",
	"commute_4": "The company offers a special program that helps to subsidize the cost of the commute for its employees.",
	"process_0": "The recruitment process of the company involves several challenging steps.",
	"process_1": "Cooking is a creative process that involves combining flavors and textures in new ways.",
	"process_2": "She spent several hours observing the manufacturing process in the factory.",
	"process_3": "The process of photosynthesis allows green plants to convert sunlight into energy.",
	"process_4": "It's important to understand the learning process to improve educational methods.",
	"foundation_0": "The earthquake caused severe damage to the building's foundation. ",
	"foundation_1": "The heart foundation has been doing great work in raising awareness about heart diseases. ",
	"foundation_2": "Her knowledge and skills provide a solid foundation for her successful career as an engineer. ",
	"foundation_3": "The makeup artist used a high-quality foundation to give the model a flawless complexion. ",
	"foundation_4": "The foundation of my friendship with Peter is our shared love for music and books.",
	"dine_0": "We have planned to dine at the new restaurant downtown tonight.",
	"dine_1": "They had the chance to dine with the city's most influential figures at the charity event.",
	"dine_2": "Due to the pandemic, many people preferred to dine at home rather than eating out.",
	"dine_3": "She loves to dine on fresh seafood whenever she goes to the coast.",
	"dine_4": "Can you join me to dine at my place this weekend? I am planning to cook Italian.",
	"contract_0": "The contract between the two business partners clearly defined the terms of their agreement.",
	"contract_1": "Without a signed contract, there's no guarantee that the services will be performed.",
	"contract_2": "Due to certain discrepancies, the company decided to cancel the contract with the supplier.",
	"contract_3": "The lawyer reviewed the contract carefully to ensure all terms were legally sound and fair.",
	"contract_4": "Although enthusiastic about the project, Amanda didn't want to rush into signing the contract before fully understanding its implications.",
	"committee_0": "The committee was created to oversee the new project.",
	"committee_1": "As part of the fundraising committee, I was responsible for organizing the charity event.",
	"committee_2": "The school committee decided to implement a new curriculum for the upcoming year.",
	"committee_3": "He was selected to serve on the city planning committee because of his background in architecture. ",
	"committee_4": "The foreign relations committee is responsible for formulating our country's overseas policies.",
	"thesis_0": "In order to graduate, I need to write a compelling thesis about environmental conservation.",
	"thesis_1": "Her thesis on gender roles in literature won an academic award for its insightful analysis.",
	"thesis_2": "In his thesis work, he proposed an innovative solution for energy efficiency.",
	"thesis_3": "Before starting your research paper, formulate a clear thesis statement to guide your study.",
	"thesis_4": "I've spent the whole summer working on my thesis, studying the effects of social media on mental health.",
	"portion_0": "The chef carefully measured out each portion before cooking.",
	"portion_1": "The larger portion of my paycheck goes towards paying rent and utilities.",
	"portion_2": "Only a small portion of the cake was left after the party.",
	"portion_3": "I was surprised when I received a substantial portion of my grandfather's estate.",
	"portion_4": "She cut the pizza into equal portions so everyone could have a slice.",
	"obvious_0": "It was obvious from his face that he was really pleased with his gift.",
	"obvious_1": "The answer to the puzzle was not obvious at first glance.",
	"obvious_2": "Her happiness was obvious when she finally passed her driving test.",
	"obvious_3": "Despite the obvious warning signs, he continued down the dangerous path. ",
	"obvious_4": "The obvious choice for dinner was the pizza place around the corner because it was the only one still open.",
	"apparent_0": "From his sudden change in behavior, it was apparent that something was troubling him.",
	"apparent_1": "It was apparent to everyone in the room that she was upset about the news.",
	"apparent_2": "After reading the report, it became apparent that we needed to change our strategy.",
	"apparent_3": "Despite the apparent simplicity of the task, it took us several hours to finish it.",
	"apparent_4": "From the group's dynamic, it was apparent that they had been working together for a long time.",
	"compose_0": "The famous musician stayed up late every night to compose his new symphony.",
	"compose_1": "The class was given an assignment to compose a poem about nature.",
	"compose_2": "The team had to compose themselves before the crucial match.",
	"compose_3": "Different kinds of gases compose our atmosphere.",
	"compose_4": "He spent a considerable amount of time to compose a convincing resignation letter.",
	"tutor_0": "After struggling with mathematics, I decided to hire a tutor to help me improve.",
	"tutor_1": "My tutor helped me understand the complex concepts of quantum physics.",
	"tutor_2": "John works as a tutor for high school students after his regular job.",
	"tutor_3": "Finding a tutor who can effectively teach my child was a difficult task, but I found one eventually.",
	"tutor_4": "The tutor was patient and thorough, explaining every detail until I understood.",
	"initial_0": "I felt a strong initial attraction when I first met my husband.",
	"initial_1": "The initial plan was to go to the beach, but the weather forced us to change our minds.",
	"initial_2": "His initial reaction to the news was one of shock and disbelief.",
	"initial_3": "Following the initial assessment, the doctor will advise on the next steps for treatment.",
	"initial_4": "The movie had an exciting initial sequence that grabbed the audience's attention right away.",
	"guarantee_0": "The company offers a two-year guarantee on all its electrical products. ",
	"guarantee_1": "I cannot give you a guarantee that the trip will be without any difficulties. ",
	"guarantee_2": "The store provides a money-back guarantee if the product doesn't meet customer satisfaction. ",
	"guarantee_3": "After Andrea's wonderful pitch, the investment was almost a guarantee.",
	"guarantee_4": "The dealership wouldn't honor the car's guarantee because it was not bought from them.",
	"consult_0": "Before making any major decisions, I always consult my parents for their advice. ",
	"consult_1": "If the symptoms persist, it's better to consult a doctor right away.",
	"consult_2": "The council will have to consult with environmental experts before proceeding with the construction project. ",
	"consult_3": "She had to consult her lawyer before signing the complex contract. ",
	"consult_4": "Could you consult the manual? I'm not sure how to operate this machine.",
	"adequate_0": "The shelter had an adequate supply of food and water for the disaster relief effort.",
	"adequate_1": "His performance at work was barely adequate, just meeting the basic requirements but showing no initiative or creativity.",
	"adequate_2": "The school must provide adequate resources for students with special educational needs.",
	"adequate_3": "The hospital needs more funding to ensure an adequate number of beds for patients.",
	"adequate_4": "She did not feel that the apartment\u2019s security measures were adequate to keep her safe.",
	"sue_0": "If they don't refund her money, she is going to sue the shop for selling defective products.",
	"sue_1": "John decided to sue his former employer for wrongful dismissal.",
	"sue_2": "The parents of the injured boy wanted to sue the careless driver.",
	"sue_3": "If you continue to breach the contract, we will have no choice but to sue you.",
	"sue_4": "Sue decided to become a lawyer so she could help people navigate the legal system.",
	"oppose_0": "He is willing to oppose any decision that may cause harm to the environment.",
	"oppose_1": "Many people oppose the new tax laws as they believe it will disproportionately impact the poor.",
	"oppose_2": "The two political parties often oppose each other on key policy issues. ",
	"oppose_3": "As a human rights activist, she pledged to oppose oppression in all its forms.",
	"oppose_4": "Though he respects the president, he's not afraid to oppose certain policies when he disagrees with them.",
	"monitor_0": "I need to buy a new monitor because the old one is acting up.",
	"monitor_1": "As a teacher, it's crucial to monitor the progress of each of your students.",
	"monitor_2": "The nurse must monitor the patient's vital signs constantly.",
	"monitor_3": "Environmental scientists monitor pollution levels to understand their impact on wildlife.",
	"monitor_4": "The security guard was told to monitor the entrance to ensure no one enters without identification.",
	"insist_0": "Despite everyone telling him it was a bad idea, he would insist on going forward with his plan.",
	"insist_1": "The teacher would insist that all students do their homework on time in order to pass her class.",
	"insist_2": "They insist that they saw a ghost in the back yard, even though no one else believes them.",
	"insist_3": "My parents always insist that I eat all my vegetables before leaving the dinner table.",
	"insist_4": "She insists that she's not tired, but her yawning and droopy eyes say otherwise.",
	"fund_0": "The charity set up a fund to help those affected by the disaster.",
	"fund_1": "The city has allocated a large fund for the renovation of the public park.",
	"fund_2": "With dwindling personal funds, Bob had to look for a part-time job.",
	"fund_3": "Annabelle used her savings fund to buy her first car.",
	"fund_4": "The United Nations established a global fund to address climate change issues.",
	"convert_0": "In order to make the recipe, she had to convert ounces to grams to measure out the flour correctly. ",
	"convert_1": "His goal was to convert the unused attic space into a cozy guest room. ",
	"convert_2": "She was born a Protestant but chose to convert to Catholicism in her twenties. ",
	"convert_3": "The solar panels convert sunlight into energy for the house.",
	"convert_4": "Even after hours of persuasive argument, he was unable to convert his friends to his political viewpoint.",
	"abuse_0": "The counselor offered her a safe space where she could openly discuss her years of emotional abuse.",
	"abuse_1": "Substance abuse is a serious issue that affects many individuals and their families across the world.",
	"abuse_2": "He was charged with animal abuse after he was caught mistreating his dogs.",
	"abuse_3": "The proliferation of online platforms has unfortunately led to a higher instance of cyber abuse.",
	"abuse_4": "The charity works tirelessly to support survivors of child abuse and to raise awareness about this horrific issue.",
	"interfere_0": "I didn't mean to interfere with your plans, please go ahead as you wish.",
	"interfere_1": "The construction noise was starting to interfere with my concentration at work.",
	"interfere_2": "I appreciate your concern, but I don't want you to interfere in my personal issues.",
	"interfere_3": "The cloudy weather might interfere with our plan for a picnic this weekend.",
	"interfere_4": "The introduction of new technology can sometimes interfere with traditional jobs and industries.",
	"forecast_0": "The weather forecast for tomorrow predicts heavy rainfall and strong winds.",
	"forecast_1": "The financial forecast for the next quarter shows a significant increase in revenues. ",
	"forecast_2": "According to the forecast, we should expect a drop in temperatures tonight. ",
	"forecast_3": "Their sales forecast was way off; they sold four times as many products as they had anticipated.",
	"forecast_4": "The city council is working on a forecast to address potential water shortages in the future.",
	"admission_0": "The college sent me a letter of admission, confirming that I had been accepted into their program.",
	"admission_1": "The security guard asked for our admission tickets before we could enter the concert venue.",
	"admission_2": "After a long hesitation, she made a reluctant admission that she was responsible for the mistake.",
	"admission_3": "The principal told me that the admission process for the new students would start next week.",
	"admission_4": "The museum offers free admission for children under the age of five.",
	"convey_0": "She used a simple story to convey her message about the importance of kindness. ",
	"convey_1": "The artist uses powerful imagery to convey the struggles and triumphs of human life.",
	"convey_2": "His tone of voice was enough to convey his disappointment to the rest of the team.",
	"convey_3": "The fundraising campaign was successful because it was able to accurately convey the dire situation of the homeless population.",
	"convey_4": "The novel brilliantly conveys the complexities of growing up in a tumultuous time.",
	"conclude_0": "After examining all the evidence, the detective was able to conclude that the suspect was innocent.",
	"conclude_1": "The conference will conclude with a panel discussion on climate change.",
	"conclude_2": "After completing her research, she concluded that the proposal would not be feasible.",
	"conclude_3": "The author concluded the novel with an unexpected twist that surprised all his readers.",
	"conclude_4": "From the data we have gathered, we can conclude that the new marketing strategy has significantly increased our sales.",
	"broad_0": "The river was so broad that it took us half a day to cross it by boat.",
	"broad_1": "His broad smile warmed the entire room.",
	"broad_2": "The field was broad enough to accommodate two football pitches side by side.",
	"broad_3": "She admired herself in the mirror, pleased with her broad, muscular shoulders that were a product of her rigorous weight training.",
	"broad_4": "While it was just a small town, it was known for its broad streets that made movement easy and efficient.",
	"ancestor_0": "To honor her ancestors, Alyssa set up a small shrine with their photos. ",
	"ancestor_1": "In their culture, they believe their ancestors' spirits protect them. ",
	"ancestor_2": "Chris found out that an ancestor from the 1800s was a renowned poet. ",
	"ancestor_3": "The anthropologists discovered fossils that they believed belonged to a common ancestor of modern humans. ",
	"ancestor_4": "She spent years researching her family tree to learn more about her ancestors.",
	"scholar_0": "As a dedicated scholar, she devoted countless hours to her research on ancient civilizations.",
	"scholar_1": "He was honored as a scholar, having been recognized for his vast contributions to the field of physics.",
	"scholar_2": "The scholar traveled across the world, collecting information to help in his study of rare plants.",
	"scholar_3": "The university is well-known for producing scholars who have made significant contributions to various academic fields.",
	"scholar_4": "As an art scholar, John always incorporated historical and cultural references in his work to deepen the understanding of his subjects.",
	"occupation_0": "Mary's primary occupation is a pediatric nurse, but she also volunteers at the local food bank during her free time.",
	"occupation_1": "During the occupation of the city, many historical buildings were damaged.",
	"occupation_2": "Tom has an interesting occupation; he is a marine biologist and spends most of his time researching sea animals.",
	"occupation_3": "During World War II, the Japanese occupation of China resulted in massive casualties and hardships for the local population.",
	"occupation_4": "Changing his occupation from a corporate lawyer to a high school teacher was a big decision for him.",
	"colleague_0": "I have a meeting arranged with my colleague, Lisa, to discuss the project details.",
	"colleague_1": "My colleague is always willing to help when I have a question about our software system.",
	"colleague_2": "John and I are not just colleagues, we have also become good friends over time.",
	"colleague_3": "My colleague gave a compelling presentation at the annual conference.",
	"colleague_4": "Working well with colleagues is essential for a harmonious work environment.",
	"automatically_0": "The lights in the hallway will automatically turn off at midnight.",
	"automatically_1": "The doors at the shopping mall open automatically when someone approaches.",
	"automatically_2": "If you set a schedule, the coffee machine will automatically brew coffee at the same time every day.",
	"automatically_3": "When you sign up for the news letter, you automatically get a discount on your next purchase. ",
	"automatically_4": "My phone automatically updates its software during the night, so I always have the latest version.",
	"proceed_0": "After explaining the rules, the game master signaled for the participants to proceed.",
	"proceed_1": "Once the construction team was ready, the foreman gave them the green light to proceed with the project.",
	"proceed_2": "She was hesitant about her decision, but chose to proceed anyways.",
	"proceed_3": "Before they could proceed with the operation, they needed the patient's informed consent.",
	"proceed_4": "The meeting will proceed as scheduled despite the absence of a few team members.",
	"border_0": "The rugged border between the two countries is heavily patrolled.",
	"border_1": "After a long journey, they finally reached the southern border of Spain. ",
	"border_2": "The colorful border of the quilt was made by hand.",
	"border_3": "Smugglers sometimes try to sneak illegal goods across the border.",
	"border_4": "The immigration officials tightened the security at the border.",
	"supplement_0": "After his doctor's recommendation, John began taking a daily multivitamin supplement to improve his health.",
	"supplement_1": "To supplement her income, she started a part-time job on the weekends.",
	"supplement_2": "The textbook comes with a supplement that includes practice problems and solutions.",
	"supplement_3": "Certain diets are low in calcium and may require you to take a supplement to meet the necessary dietary requirements.",
	"supplement_4": "This article serves as a supplement to the main research, providing additional data and details.",
	"estimate_0": "The contractor gave us an estimate for the kitchen renovation, and it was much higher than we anticipated.",
	"estimate_1": "I can only estimate how long it will take me to complete this report as it depends on the complexity of the data.",
	"estimate_2": "According to the state's estimate, the new highway project should be completed by next spring.",
	"estimate_3": "The futurist made an estimate that most cars will be electric by 2040.",
	"estimate_4": "We've received an estimate from three different landscaping companies, but we haven't decided who to hire yet.",
	"rapidly_0": "The wildfire spread rapidly through the dry forest, engulfing everything in its path. ",
	"rapidly_1": "His health was rapidly deteriorating, prompting an immediate visit to the hospital.",
	"rapidly_2": "Jane rapidly rose through the ranks of the company, impressing her superiors with her dedication and work ethic. ",
	"rapidly_3": "Technology is advancing so rapidly that it's hard to keep up with the latest innovations. ",
	"rapidly_4": "The landscape was changing rapidly as the train whisked us through towns and countrysides.",
	"possess_0": "The antique collector is known to possess some of the rarest items in the world.",
	"possess_1": "I only possess a basic knowledge of Italian so I hired a tutor to help me learn more.",
	"possess_2": "He possesses an incredible talent for playing the piano and astonishes everyone who hears him.",
	"possess_3": "The evil sorcerer is rumored to possess magical powers that can control the weather.",
	"possess_4": "To run a successful business, one must possess a deep understanding of market trends and consumer behavior.",
	"intent_0": "The detective examined all possible evidence to understand the perpetrator's intent behind the crime.",
	"intent_1": "Her intent was to finish painting the room by the end of the week, despite her busy schedule. ",
	"intent_2": "His comment was taken the wrong way, but his original intent was to compliment, not to hurt her feelings.",
	"intent_3": "The judge questioned the defendant's underlying intent in stirring up trouble in his community.",
	"intent_4": "The intent of the educational program was to enhance students' science and mathematical skills.",
	"household_0": "His household was always filled with love, warmth, and good food.",
	"household_1": "Every member of the household was asked to contribute to the household chores.",
	"household_2": "Samantha has five children, making her household bustling and lively.",
	"household_3": "Due to the pandemic, many households are experiencing an increase in day-to-day stress.",
	"household_4": "Their household was known throughout the neighborhood for its beautifully maintained garden.",
	"desire_0": "His desire to succeed in his career had him working late nights and weekends.",
	"desire_1": "The twinkling jewelry in the store's display stirred a desire in her to start her own collection.",
	"desire_2": "Working towards fulfilling your heart's desire brings immense satisfaction.",
	"desire_3": "The desire to travel the world was a dream they both shared since childhood.",
	"desire_4": "Even with all his fame and wealth, he possessed an unquenchable desire for knowledge.",
	"domestic_0": "The domestic cat, unlike its wild cousins, has adapted to living with humans.",
	"domestic_1": "She majored in domestic science at university, focusing on home management skills and nutrition. ",
	"domestic_2": "Domestic violence is a serious issue that affects many households across the country.",
	"domestic_3": "The local airline offers domestic flights to all major cities within the country. ",
	"domestic_4": "Stacy was fond of her domestic duties such as cooking and cleaning, finding them therapeutic.",
	"exceptional_0": "The chef's culinary skills are truly exceptional, earning him a Michelin star. ",
	"exceptional_1": "The kindergarten teacher remarked that Jenny's reading ability was exceptional for her age. ",
	"exceptional_2": "His exceptional performance in the game earned him the Most Valuable Player award. ",
	"exceptional_3": "The paintings at the gallery were of exceptional quality, attracting art enthusiasts from all over the world. ",
	"exceptional_4": "The movie had an exceptional storyline, keeping the audience engaged from beginning to end.",
	"startled_0": "The sudden burst of fireworks startled the dog, sending him running for cover.",
	"startled_1": "Jill was so engrossed in her book that she was startled when her phone rang.",
	"startled_2": "I was startled by the cold touch of the hand on my shoulder.",
	"startled_3": "The noise of the fallen vase startled the sleeping baby, causing her to cry out in fear.",
	"startled_4": "He was startled to find a snake in his garden shed.",
	"pasture_0": "The farmer released his cows into the lush green pasture where they could graze freely. ",
	"pasture_1": "The view from the hilltop was fantastic, revealing wide open pastures basking in the sunset's warm glow. ",
	"pasture_2": "The horse seemed content, munching on the fresh grass in the pasture. ",
	"pasture_3": "After weeks of drought, the pasture was parched and brown, leaving the farmers worried about their livestock.",
	"pasture_4": "Sarah always felt peaceful when she took a stroll through the rolling pastures surrounding their countryside home.",
	"thoroughly_0": "He thoroughly cleaned his house before the guests arrived.",
	"thoroughly_1": "She is thoroughly familiar with the software we are using for the project.",
	"thoroughly_2": "The detective thoroughly investigated the crime scene, leaving no stone unturned.",
	"thoroughly_3": "Make sure you thoroughly wash the vegetables before a meal.",
	"thoroughly_4": "He studied thoroughly for his final exams, spending countless hours reviewing the material.",
	"rinse_0": "After you finish washing the dishes, make sure you rinse them thoroughly with hot water.",
	"rinse_1": "She had to rinse the shampoo out of her hair before applying the conditioner.",
	"rinse_2": "The salad leaves need to be rinse carefully to get rid of any dirt and pesticides.",
	"rinse_3": "The dentist always asks me to rinse my mouth out after a dental cleaning.",
	"rinse_4": "I'll rinse these glasses with soapy water before we use them for dinner.",
	"demonstration_0": "The cooking demonstration at the fair attracted a huge crowd, as the chef expertly prepared gourmet dishes before their eyes.",
	"demonstration_1": "The peaceful demonstration for climate change consisted of thousands of activists marching and holding up banners in the city center.",
	"demonstration_2": "Sarah gave a demonstration on how to use the new software during the company's weekly meeting.",
	"demonstration_3": "I had to attend a safety demonstration before I could start my first day at the construction site.",
	"demonstration_4": "His flawless demonstration of the piano piece left the audience in awe.",
	"negotiation_0": "In order to close the deal, the two companies entered into several weeks of intense negotiation.",
	"negotiation_1": "His impressive negotiation skills often gained him better deals than his competitors.",
	"negotiation_2": "The union and the management were still in negotiation about the workers' annual salary increase.",
	"negotiation_3": "Sarah knew she would have to rely on her best negotiation tactics to persuade her boss for a raise.",
	"negotiation_4": "The diplomat was selected for the task due to his vast experience in international negotiation.",
	"cheeky_0": "The cheeky child stuck out his tongue at his older sister.",
	"cheeky_1": "He gave a cheeky smile when he knew he got away with stealing an extra cookie.",
	"cheeky_2": "Her cheeky remarks during the meeting made everyone laugh.",
	"cheeky_3": "It's quite endearing how the baby gives cheeky winks to everyone he meets.",
	"cheeky_4": "The teacher disapproved of his cheeky attitude when he uttered a sarcastic comment during class.",
	"notorious_0": "Al Capone is perhaps one of the most notorious gangsters in American history. ",
	"notorious_1": "The notorious serial killer was finally caught after a decade-long manhunt.",
	"notorious_2": "His notorious reputation made people fear him even though he'd never personally done them harm.",
	"notorious_3": "Their notorious late-night parties caused problems for their neighbors several times.",
	"notorious_4": "The school was notorious for its strict and somewhat archaic rules on student conduct.",
	"possession_0": "The book you found in the library is now in my possession.",
	"possession_1": "The police arrested him for illegal possession of firearms.",
	"possession_2": "Ownership is often proven by possession of a title or deed.",
	"possession_3": "It gives me great joy to donate a good portion of my possessions to charity.",
	"possession_4": "She was so attached to her grandmother's necklace that she considered it her most precious possession.",
	"authenticity_0": "The authenticity of the painting was questioned due to irregular brush strokes.",
	"authenticity_1": "She admired her friend for his authenticity in all his dealings.",
	"authenticity_2": "To maintain the authenticity of the story, the author thoroughly researched the historical time period.",
	"authenticity_3": "The authenticity of the diamond was confirmed by a professional gemologist.",
	"authenticity_4": "The restaurant was loved by customers for its authenticity in creating traditional Thai cuisine.",
	"specialize_0": "Because Maria decided to specialize in pediatric medicine, she could help deal with various children's health issues.",
	"specialize_1": "We are looking for a chef who can specialize in Italian cuisine for our new restaurant.",
	"specialize_2": "This law firm specializes in handling cases related to intellectual property and patent rights.",
	"specialize_3": "Kevin is a personal trainer who specializes in helping athletes recover from injuries and regain optimal performance.",
	"specialize_4": "She decided to further her studies and specialize in agricultural science to contribute to more sustainable farming practices.",
	"reputation_0": "His recent misconduct has severely damaged his reputation in the community.",
	"reputation_1": "She has a reputation for being an exceptionally talented pianist.",
	"reputation_2": "The school has gained an excellent reputation for its high academic standards.",
	"reputation_3": "Despite his mistakes, he's trying hard to uphold his reputation.",
	"reputation_4": "The company risks tarnishing its reputation if it continues to ignore these issues.",
	"prosthetic_0": "After losing his leg in a car accident, John was fitted with a prosthetic limb.",
	"prosthetic_1": "With modern advancements, prosthetic limbs are designed to provide more natural movement and better functionality.",
	"prosthetic_2": "She is an engineer, specializing in the design and manufacturing of prosthetic devices.",
	"prosthetic_3": "He was amazed at how well his friend adapted to his new prosthetic arm.",
	"prosthetic_4": "Although the prosthetic eye cannot restore vision, it does provide a cosmetic solution for those who have lost an eye.",
	"league_0": "Manchester United is one of the most successful teams in the Premier League.",
	"league_1": "Our school's basketball team won the league championship this year.",
	"league_2": "The League of Nations was an intergovernmental organization founded as a result of the Paris Peace Conference that ended the First World War.",
	"league_3": "She is in the same league as some of the best writers of our century.",
	"league_4": "Jessica joined a bowling league to meet new people and have fun.",
	"scripted_0": "The reality show was far from authentic; it was obvious that every scene was heavily scripted.",
	"scripted_1": "The teacher had scripted some useful phrases on the board for the foreign language class.",
	"scripted_2": "The play had been brilliantly scripted, with witty dialogue and unexpected plot twists.",
	"scripted_3": "She followed the scripted speech during the presentation as she was too nervous to ad lib.",
	"scripted_4": "Despite knowing that the interview was scripted, the audience found the conversation engaging.",
	"fast track_0": "The company put the new project on the fast track, hoping to beat their competitors to the market.",
	"fast track_1": "After impressing the coach with her incredible speed, Lisa found herself on the fast track to becoming a professional athlete. ",
	"fast track_2": "The university offers a fast track program that allows students to complete their degree in 3 years instead of 4. ",
	"fast track_3": "John quickly rose through the ranks of the office, his exceptional work ethic putting him on the fast track to a senior position.",
	"fast track_4": "Knowing the growing demand for sustainable energy sources, the government put renewable energy projects on the fast track.",
	"protest_0": "Hundreds of people gathered in the city square in protest against the controversial legislation. ",
	"protest_1": "She quit her job in protest when her boss refused to listen to her complaints. ",
	"protest_2": "His absence at the meeting was seen as a silent protest against the company's policies. ",
	"protest_3": "Protest signs filled the streets as people voiced their opposition to the new law. ",
	"protest_4": "The authorities used tear gas to disperse the crowd at the protest rally.",
	"commemorate_0": "Every year, we commemorate the great contributions of our founding fathers on Independence Day.",
	"commemorate_1": "The monument was built to commemorate the soldiers who sacrificed their lives in World War II.",
	"commemorate_2": "They released a special edition stamp to commemorate the Queen's 90th birthday.",
	"commemorate_3": "Our family gathers every year to commemorate the day our grandparents immigrated to this country.",
	"commemorate_4": "The museum exhibition was designed to commemorate the 50th anniversary of the moon landing.",
	"intend_0": "I intend to finish my work today itself without any delays. ",
	"intend_1": "They intend to spend their vacation in Greece next summer. ",
	"intend_2": "She never intended to upset you with her words. ",
	"intend_3": "We initially intended to host the party outdoors, but due to the weather, we had to change our plans. ",
	"intend_4": "I didn't intend for this to be a full blown argument.",
	"genocide_0": "The Holocaust is one of the most infamous examples of genocide in history, where around six million Jews were systematically exterminated by the Nazis.",
	"genocide_1": "The atrocities committed in Rwanda in 1994 have been universally recognized as a horrific instance of genocide.",
	"genocide_2": "Teaching about the Armenian genocide is essential to recognize the suffering of thousands of people and learn the lessons of history.",
	"genocide_3": "Reports from the Cambodian refugees revealed the horrific genocide carried out by the Khmer Rouge regime.",
	"genocide_4": "The U.N. strives to prevent actions that could lead to genocide, which is considered an egregious violation of human rights.",
	"rugby_0": "My older brother plays rugby for a local team every weekend.",
	"rugby_1": "The annual rugby tournament drew teams from all four corners of the globe.",
	"rugby_2": "One of the key aspects to understanding rugby is learning the different positions and their specific roles. ",
	"rugby_3": "He got injured during the last rugby match now he has to skip the next few games for recovery.",
	"rugby_4": "Rugby is a rough sport that requires both physical toughness and strategic planning.",
	"resistance_0": "The resistance offered by the copper wire affects the efficiency of the electrical circuit.",
	"resistance_1": "In his lecture, the professor discussed the resistance movements that sprang up during World War II.",
	"resistance_2": "The doctor prescribed antibiotics to treat the infection, but warned that the bacteria might develop a resistance to the medication.",
	"resistance_3": "Despite the government's attempts to suppress it, the resistance group was gaining more supporters every day.",
	"resistance_4": "The fitness instructor emphasized the importance of resistance training for building muscle strength and endurance.",
	"racism_0": "Racism in any form, whether intentional or unintentional, is a social evil that must be eradicated from our society. ",
	"racism_1": "The effects of racism can be psychologically damaging to those who experience it. ",
	"racism_2": "The company implemented strict policies to combat racism in the workplace.",
	"racism_3": "The civil rights movement was a significant step toward combating racism in the United States.",
	"racism_4": "Despite public awareness campaigns and education, racism is still an issue in many parts of the world today.",
	"ethnicity_0": "Jessica loved studying different cultures, traditions, and ethnicities around the world.",
	"ethnicity_1": "One's ethnicity doesn't determine their capabilities, skills, or value.",
	"ethnicity_2": "The university promotes a diverse student body representing numerous ethnicities from around the globe.",
	"ethnicity_3": "Understanding the influence of ethnicity on different social structures and norms is a crucial part of sociology.",
	"ethnicity_4": "The population of the city is very diverse, boasting a wide range of ethnicities and backgrounds.",
	"liberated_0": "After years of living under a repressive regime, the people were finally liberated by the revolutionaries.",
	"liberated_1": "The dog, once liberated from its tight leash, ran around the park in pure joy.",
	"liberated_2": "My new work-from-home schedule has liberated me from my hour-long commute.",
	"liberated_3": "The end of her oppressive marriage felt like she had been finally liberated.",
	"liberated_4": "When I paid off my student loans, I felt such a sense of relief, as if I had been liberated from a heavy burden.",
	"nazi_0": "The Nazi party, led by Adolf Hitler, ruled Germany with an iron fist during World War II.",
	"nazi_1": "The atrocities committed by the Nazis resulted in the genocide of six million Jews during the Holocaust.",
	"nazi_2": "Many films and books have been made about the Nazis and the horrors they committed.",
	"nazi_3": "The last vestiges of the Nazi regime were eradicated during the Nuremberg Trials.",
	"nazi_4": "The use of Nazi symbolism and ideologies are generally considered highly offensive and are banned in many parts of the world.",
	"annual_0": "The company just released its annual report and it shows significant growth compared to last year.",
	"annual_1": "Our annual family reunion is something I always look forward to each year.",
	"annual_2": "The annual flower show in our city attracts visitors from all over the country.",
	"annual_3": "Registering for the annual membership will save you a lot of money if you plan to use the gym regularly.",
	"annual_4": "I finally bought tickets to the annual jazz festival I've always wanted to attend.",
	"organ_0": "The heart is a vital organ responsible for pumping blood throughout the body.\r ",
	"organ_1": "She expertly played a beautiful tune on the large church organ. ",
	"organ_2": "The donation of organs can save many lives. ",
	"organ_3": "His doctor advised him to take better care of his liver, as it is an essential organ for metabolism. ",
	"organ_4": "Learning to play the organ requires a lot of patience and practice.",
	"alternative_0": "Rather than taking a taxi, you could consider the alternative of using public transportation.",
	"alternative_1": "Jenny found an alternative solution to the problem that saved them a lot of time.",
	"alternative_2": "The search for alternative energy resources has gained momentum with the growing threat of global warming.",
	"alternative_3": "Eating plant-based food is a great alternative for those who want to avoid meat in their diet.",
	"alternative_4": "We had planned a picnic outdoors, but due to rain, we had to switch to our alternative plan of having an indoor party.",
	"omen_0": "They took the black cat crossing their path as an omen of bad luck coming their way.",
	"omen_1": "The clear skies at dawn was an omen for the sailor, who believed it signaled smooth voyaging ahead.",
	"omen_2": "Many people believe that a broken mirror is an omen for seven years of strife.",
	"omen_3": "A dove landing on the windowsill was interpreted as an omen of peace and good fortune.",
	"omen_4": "The strange sighting of a full moon during the day, she believed, was an eerie omen of changes to come.",
	"augur_0": "Many people believed that the unexpected rainstorm was an augur of good luck for their agricultural crops. ",
	"augur_1": "The unexpected promotion at work could augur a period of success and prosperity for Susan. ",
	"augur_2": "The sudden decrease in our sales could augur a difficult financial period for our business in the upcoming months. ",
	"augur_3": "His intuitive understanding of technology may augur well for his future career as a computer scientist. ",
	"augur_4": "The black cat crossing our path was, according to old superstition, an augur of bad luck to follow.",
	"inaugurate_0": "The new president is scheduled to inaugurate the museum next week.",
	"inaugurate_1": "They will inaugurate the annual sports event next Monday, followed by an opening ceremony.",
	"inaugurate_2": "The mayor will inaugurate the newly built park along with a tree planting ceremony.",
	"inaugurate_3": "The new council members plan to inaugurate a revolutionary environmental policy in the city.",
	"inaugurate_4": "The CEO will inaugurate the company's latest technological innovation at a gala event tonight.",
	"campaign_0": "The mayoral candidate launched an aggressive campaign to win over undecided voters.",
	"campaign_1": "Michelle started a fundraising campaign to help cover her friend's medical expenses.",
	"campaign_2": "The advertising campaign for the new product was incredibly successful, leading to high sales and brand recognition.",
	"campaign_3": "During his political campaign, the senator promised to address issues such as healthcare and education.",
	"campaign_4": "The environmental organization's Save the Rainforest campaign helped raise awareness about deforestation.",
	"cultivate_0": "We must cultivate a better understanding of our shared history in order to build a peaceful world.",
	"cultivate_1": "Farmers in this region cultivate a variety of crops, including wheat, corn, and soybeans.",
	"cultivate_2": "As an entrepreneur, you need to cultivate connections in your network to help grow your business.",
	"cultivate_3": "The teachers aim to cultivate a love of learning in their students to keep them motivated throughout the school year.",
	"cultivate_4": "To maintain a healthy mental state, she decided to cultivate mindfulness through daily meditation and yoga.",
	"preference_0": "His preference for Italian cuisine becomes obvious when checking his restaurant reservations.",
	"preference_1": "I understand that you have a preference for early morning meetings, so I have scheduled our discussion for 8 a.m.",
	"preference_2": "Susan has a strong preference for classical music, often attending symphony and opera performances.",
	"preference_3": "The teacher asked the students about their course preference for the next semester.",
	"preference_4": "Despite her mother's preference for her to become a lawyer, Sally decided to pursue a career in the arts.",
	"enthusiast_0": "As a fitness enthusiast, he spent most of his free time at the gym. ",
	"enthusiast_1": "John is a car enthusiast and spends every Saturday polishing his collection of vintage autos.",
	"enthusiast_2": "She was a gardening enthusiast who took great pride in her stunning flowerbeds. ",
	"enthusiast_3": "An enthusiast of French cuisine, he often cooks dishes like escargot and Coq au vin at home. ",
	"enthusiast_4": "He's an art enthusiast; he spends his weekends hunting for new artists to support.",
	"adventurous_0": "Lisa's adventurous spirit compelled her to go skydiving for her birthday.",
	"adventurous_1": "Michael's adventurous personality made him a fun travel companion, always seeking off-beaten paths.",
	"adventurous_2": "The novel outlined the adventurous life of a girl who sailed around the world.",
	"adventurous_3": "Trying exotic foods at the international food festival was an adventurous experience for me.",
	"adventurous_4": "The adventurous hike through the dense rainforest was both challenging and exhilarating.",
	"logistical_0": "The logistical complications of the trip made it impossible to proceed as planned.",
	"logistical_1": "It was a major logistical achievement to coordinate the delivery of goods to all the stores across the country.",
	"logistical_2": "The team had to face enormous logistical challenges in setting up the event in such a remote location.",
	"logistical_3": "He is responsible for all logistical operations at the warehouse, ensuring deliveries arrive on time.",
	"logistical_4": "She made a logistical error which delayed the launch of the project by two weeks.",
	"standardize_0": "In order to ensure the product's consistent quality, the company has decided to standardize its manufacturing process.",
	"standardize_1": "The educational department aims to standardize the curriculum across all schools in the state.",
	"standardize_2": "It is important to standardize measurements in scientific research to maintain accuracy and reliability.",
	"standardize_3": "To boost efficiency in the workplace, our management decided to standardize the software used by all departments.",
	"standardize_4": "In baking, it's crucial to standardize your measurements to achieve the desired results every time.",
	"academic_0": "The university is recognized worldwide for its superior academic standards.",
	"academic_1": "She received an academic scholarship based on her exceptional grade point average.",
	"academic_2": "The professor authored several academic papers on climate change.",
	"academic_3": "The school encourages academic competition, highlighting students who excel in their studies.",
	"academic_4": "His academic pursuits in the field of neuroscience have contributed greatly to our understanding of the brain.",
	"tolerable_0": "The heat at the beach in mid-afternoon was barely tolerable without an umbrella or shade.",
	"tolerable_1": "Although the movie wasn't the best, it was certainly tolerable for a few hours of fun.",
	"tolerable_2": "I would describe the dentist appointment as tolerable - not enjoyable, but not as bad as I had anticipated.",
	"tolerable_3": "After changing the recipe a bit, the dish became tolerable, rather than disgusting as it initially was.",
	"tolerable_4": "The medication did not take away the pain entirely, but it reduced it to a tolerable level.",
	"accommodate_0": "The hotel can accommodate up to 500 guests. ",
	"accommodate_1": "To accommodate her busy schedule, we adjusted the meeting time. ",
	"accommodate_2": "Our teacher will accommodate our needs by providing extra instruction after class. ",
	"accommodate_3": "The small restaurant couldn't accommodate our large party, so we had to split into smaller groups. ",
	"accommodate_4": "The airplane was not able to accommodate his oversized luggage, so he had to ship it separately.",
	"apprentice_0": "The skilled carpenter took on a young apprentice to carry on his craftsmanship after he retired.",
	"apprentice_1": "As an apprentice in the bakery, she learned how to mix and bake all types of bread and pastries.",
	"apprentice_2": "The apprentice watched his master carefully, trying to absorb every detail of her technique.",
	"apprentice_3": "Julia decided to be an apprentice to a local painter to improve her artistic skills.",
	"apprentice_4": "His role as an apprentice electrician involved many long hours of installing, maintaining, and repairing electrical systems under the supervision of a licensed electrician.",
	"intellect_0": "Her superior intellect often intimidated her classmates during discussions.",
	"intellect_1": "The company seeks employees with a high level of intellect to perform complex tasks.",
	"intellect_2": "His intellect, coupled with his incredible work ethic, made him one of the best scientists of his time.",
	"intellect_3": "The intellect displayed by the chess player during the tournament was impressive.",
	"intellect_4": "Despite his young age, he has an intellect that surpasses that of his peers.",
	"intellectual_0": "John is known for his intellectual approach to solving problems.",
	"intellectual_1": "The conference was a gathering of some of the brightest intellectual minds.",
	"intellectual_2": "My friend is an intellectual, always reading and studying various subjects in depth.",
	"intellectual_3": "Their discussion shifted to intellectual matters such as philosophy and literature.",
	"intellectual_4": "Intellectual property legislation protects creators' rights to their inventions and works.",
	"strength_0": "His strength was evident in his firm handshake.",
	"strength_1": "The strength of the bridge was tested under the weight of the heavy trucks.",
	"strength_2": "The strength of her personality made her excel in her leadership role.",
	"strength_3": "It took all of his strength to lift the heavy box onto the truck.",
	"strength_4": "Her strength and perseverance in conquering her illness inspired everyone around her.",
	"intelligent_0": "He's extremely intelligent, he graduated from Harvard at the top of his class.",
	"intelligent_1": "To keep her mind sharp, she enjoys reading books, taking online courses, and other activities that enhance her intelligent thinking.",
	"intelligent_2": "Many people believe that crows are one of the most intelligent bird species.",
	"intelligent_3": "Max's intelligent decisions at a young age paved the way for his successful career.",
	"intelligent_4": "Being intelligent is not only having knowledge, but also knowing how to apply it, advised the wise old professor.",
	"distinctly_0": "He distinctly remembers the time when he first saw the Grand Canyon.",
	"distinctly_1": "Despite the noise, I could distinctly hear the sound of a violin.",
	"distinctly_2": "Her ideas are distinctly different from those of her colleagues.",
	"distinctly_3": "The smell of the freshly baked pies was distinctly noticeable the moment I entered the kitchen.",
	"distinctly_4": "During the meeting, his voice distinctly shook when discussing the challenges the company was facing.",
	"crucial_0": "Regular exercise is crucial for maintaining a healthy body and mind.",
	"crucial_1": "Learning to manage your time effectively is crucial when handling multiple tasks and deadlines.",
	"crucial_2": "The government played a crucial role in providing aid after the earthquake. ",
	"crucial_3": "The research study had a crucial impact on the development of the new medication. ",
	"crucial_4": "It's crucial to stay hydrated during hot weather to prevent heatstroke.",
	"scheme_0": "The city revealed the scheme for the new park yesterday, highlighting features meant to entertain and engage both kids and adults.",
	"scheme_1": "She uncovered her brother's scheme to secretly eat all the cookies before dinner.",
	"scheme_2": "The government has launched a new scheme to boost employment among recent graduates.",
	"scheme_3": "The detectives foiled the criminal\u2019s scheme to rob the bank.",
	"scheme_4": "He developed a meticulous scheme for investing in the stock market to maximize his profits.",
	"promotion_0": "After years of hard work, John finally received a well-deserved promotion at his job.",
	"promotion_1": "The store was offering a special promotion, with all items half off for the weekend.",
	"promotion_2": "She was thrilled to hear about her promotion to Senior Manager.",
	"promotion_3": "The innovative promotion for the new movie generated lots of excitement among the audience.",
	"promotion_4": "After the promotion, he will now be overseeing the entire department.",
	"divorce_0": "After much thought and consideration, they decided to get a divorce.",
	"divorce_1": "The rise in divorce rates is a concerning societal issue that requires serious attention.",
	"divorce_2": "Getting a divorce poses a significant emotional challenge, especially when children are involved.",
	"divorce_3": "They both hoped they could reconcile their differences and avoid a divorce.",
	"divorce_4": "The legal procedure for a divorce is complex and time-consuming, typically requiring the assistance of an attorney.",
	"organic_0": "The increased demand for organic food reflects a growing awareness of health issues.",
	"organic_1": "Organic farming methods help preserve the environment by reducing pollution and waste.",
	"organic_2": "She prefers to shop at local markets to ensure she's getting fresh, organic produce.",
	"organic_3": "The label says it's organic, but always check whether it's certified by a reliable agency.",
	"organic_4": "I started growing my own vegetables because I wanted to ensure that everything was organic and free from harmful pesticides.",
	"pledge_0": "I made a pledge to myself to exercise daily to maintain good health.",
	"pledge_1": "The university alumni pledged a significant donation to support the building of the new library. ",
	"pledge_2": "As part of her wedding vows, she pledged to love and cherish him in health and sickness.",
	"pledge_3": "During the annual fundraiser, the local community pledged their support for the homeless shelter.",
	"pledge_4": "The environmental activist gave a passionate speech, urging everyone to pledge their commitment to reducing carbon footprints.",
	"abolish_0": "The organization has been campaigning tirelessly to abolish child labor worldwide.",
	"abolish_1": "Some people believe that we should abolish the death penalty because it is inhumane.",
	"abolish_2": "The new government has plans to abolish taxes on certain essential items.",
	"abolish_3": "There is a growing movement to abolish private prisons in the U.S.",
	"abolish_4": "The city council voted to abolish overnight parking on the busy street for safety reasons.",
	"orchard_0": "The apple trees in the orchard were heavy with ripe, red fruit.",
	"orchard_1": "They spent the whole afternoon picking cherries in the sunny orchard.",
	"orchard_2": "My grandfather has an extensive orchard, where he grows all kinds of fruits.",
	"orchard_3": "The old cherry orchard has been in my family for generations.",
	"orchard_4": "My dream home includes acres of land where I could plant my very own orchard.",
	"wart_0": "The witch in the story had a large wart on her nose that made her look even more menacing. ",
	"wart_1": "Tim was upset when he discovered a wart on his hand; he hoped it would go away soon. ",
	"wart_2": "The old tree trunk was covered with knobby growths that looked like giant warts. ",
	"wart_3": "She was embarrassed about the wart on her chin and tried to hide it with make-up. ",
	"wart_4": "The doctor recommended freezing the wart off to prevent it from spreading.",
	"warty_0": "The warty toad hopped from leaf to leaf in the dense undergrowth.",
	"warty_1": "His hands were rough and warty, revealing a long history of manual labor.",
	"warty_2": "The skin of the pumpkin was uneven and warty, but it was perfect for a spooky Halloween decoration.",
	"warty_3": "The strangely captivating warty octopus resides deep within the ocean.",
	"warty_4": "My grandmother taught me a home remedy for warty growths using natural ingredients.",
	"promote_0": "In order to promote her new book, the author launched an extensive marketing campaign.",
	"promote_1": "The company decided to promote John to a managerial position due to his excellent performance.",
	"promote_2": "This charity event aims to promote awareness about poverty and homelessness in our city.",
	"promote_3": "The government launched a new program to promote healthy eating among school-age children.",
	"promote_4": "Celebrities often use their platforms to promote important causes and social issues.",
	"citrus_0": "The citrus aroma from the lemon grove was incredibly refreshing.",
	"citrus_1": "I enjoy a glass of freshly squeezed citrus juice every morning for breakfast.",
	"citrus_2": "Citrus fruits, like oranges and grapefruits, are high in vitamin C and antioxidants.",
	"citrus_3": "My favorite citrus to use in cooking is lime, it adds a fresh tang to the dishes.",
	"citrus_4": "The farmer's market had a wide selection of citrus fruits, each one looking more succulent and juicy than the last.",
	"trial_0": "The trial for the high-profile murder case starts next week.",
	"trial_1": "The use of AI in the medical field is still in its trial phase.",
	"trial_2": "The lawyer was busy preparing for the upcoming trial.",
	"trial_3": "She was acquitted after a long and contentious trial.",
	"trial_4": "He agreed to a 30-day free trial of the new software.",
	"accused_0": "The accused man sat nervously in the defendant's chair as the jury deliberated his fate.",
	"accused_1": "He vehemently denied the charges, affirming that he was wrongfully accused.",
	"accused_2": "During the heated argument, Peter accused Sara of being unfaithfully.",
	"accused_3": "The lawyer did an excellent job defending the accused in the high-profile case.",
	"accused_4": "The accused stood before the judge awaiting his verdict with a look of fear and anxiety on his face.",
	"parliament_0": "The parliament convened to discuss the new legislation on climate change.",
	"parliament_1": "The British Parliament is divided into two houses, the House of Commons and the House of Lords.",
	"parliament_2": "The members of the parliament were debating the proposed changes to the education system.",
	"parliament_3": "She aspired to become a member of parliament to contribute positively to society.",
	"parliament_4": "The parliament ratified the agreement after weeks of discussing its potential impacts.",
	"career_0": "John has a fulfilling career in the technology industry. ",
	"career_1": "She is planning to change her career path from law to education. ",
	"career_2": "A successful career is often the result of hard work, determination, and strategic planning. ",
	"career_3": "After many years in banking, he felt it was time for a career shift into nonprofit work. ",
	"career_4": "College students often seek internships to get a head start in their planned career.",
	"mentorship_0": "The mentorship program at our company has significantly improved employee performance and job satisfaction.",
	"mentorship_1": "Jay found the mentorship from his college professor immensely helpful in shaping his career path.",
	"mentorship_2": "Kathy attributes her success as a surgeon to the excellent mentorship she received during her residency. ",
	"mentorship_3": "Mentorship is a valuable process that aids personal development, specifically in guidance and support in achieving long-term goals.",
	"mentorship_4": "The mentorship relationship between Lauren and her boss allowed her to gain industry knowledge that she would not have obtained through ordinary work tasks.",
	"visualization_0": "Through visualization, the architect was able to plan out every detail of the building before construction began.",
	"visualization_1": "Visualization exercises helped the athlete mentally practice his routine and boost performance during the competition.",
	"visualization_2": "With the help of data visualization tools, they could convert complex reports into simple graphs and charts for everyone to understand.",
	"visualization_3": "One of the key benefits of meditation is visualization, helping people to imagine their future achievements and desired outcomes.",
	"visualization_4": "The teacher used visualization techniques to aid students in grasping the concepts of physics.",
	"esteem_0": "The renowned scientist was held in great esteem by his colleagues for his groundbreaking research.",
	"esteem_1": "He had always been taught to treat others with respect and esteem, regardless of their background.",
	"esteem_2": "Her self-esteem took a hit when she was rejected from her dream university.",
	"esteem_3": "The award was a sign of the esteem in which the community held her.",
	"esteem_4": "Despite their differences, he held her in the highest esteem for her courage and tenacity.",
	"society_0": "The increase in poverty is a problem that society needs to address urgently.",
	"society_1": "In our modern society, technology plays a significant role in our everyday lives.",
	"society_2": "The injustices in society are more pronounced when seen from the perspective of marginalized groups.",
	"society_3": "Literature can provide a valuable reflection of society and its values.",
	"society_4": "Society's perception of mental health has changed significantly over the years.",
	"isolate_0": "After testing positive for the virus, she had to isolate at home for two weeks.",
	"isolate_1": "Scientists are working tirelessly to isolate the specific genes responsible for this disease.",
	"isolate_2": "The island's unique ecosystem was formed because it was able to isolate from the mainland millions of years ago.",
	"isolate_3": "The software is designed to isolate any potential threats and remove them before they harm the computer. ",
	"isolate_4": "Feeling ignored by his peers, Joe began to isolate himself both in school and at home.",
	"compartment_0": "The suitcase has a secret compartment at the bottom where you can store valuable items.",
	"compartment_1": "Jack opened the compartment in his car, revealing a set of maps and tools for emergencies.",
	"compartment_2": "The train compartment was surprisingly full, with people and luggage filling every inch of space.",
	"compartment_3": "She carefully arranged her tools in the garden shed's compartment to make them easily accessible.",
	"compartment_4": "After boarding the plane, I located my seat and stored my carry-on luggage in the overhead compartment.",
	"pram_0": "Emma placed her newborn daughter carefully in the pram for their afternoon walk in the park. ",
	"pram_1": "The mother kept all the baby essentials in the under storage basket of the pram. ",
	"pram_2": "Paul pushed the pram along the sidewalk, gently rocking his son to sleep. ",
	"pram_3": "The pram they chose was durable, portable, and had plenty of room for their twins. ",
	"pram_4": "The baby smiled and gurgled, cozy and snug in the plush padding of her pram.",
	"dull_0": "The lecture was so dull that I found myself falling asleep multiple times.",
	"dull_1": "She tried to sharpen the dull pencil, but it just kept breaking. ",
	"dull_2": "The weather outside was dull and grey, reflecting his gloomy mood.",
	"dull_3": "His repeated jokes were dull and lacked the originality they once had.",
	"dull_4": "The once vibrant paint on the walls had faded to a dull shade over the years.",
	"karaoke_0": "The bar down the street hosts a lively karaoke night every Friday. ",
	"karaoke_1": "She practiced her favorite songs for hours, hoping to impress everyone at the karaoke party. ",
	"karaoke_2": "The crowd clapped and sang along as he belted out a classic rock song at the karaoke machine.",
	"karaoke_3": "Karaoke is a popular pastime in Japan, with many business deals discussed in karaoke rooms instead of office spaces.",
	"karaoke_4": "For her birthday, Sarah's friends surprised her with a trip to a karaoke lounge, knowing her love for singing.",
	"specialized_0": "The medical field is filled with doctors who have specialized in different areas such as cardiology, neurology, or orthopedics.",
	"specialized_1": "Jack operates a specialized software development company that creates custom applications for financial institutions.",
	"specialized_2": "The nature reserve has a team of specialized experts who work on wildlife preservation and rehabilitation.",
	"specialized_3": "In order to repair vintage automobiles, one needs a specialized set of skills and tools.",
	"specialized_4": "She specialized in family law, focusing specifically on child custody cases.",
	"illustrator_0": "My dream is to become a renowned illustrator for children's books.",
	"illustrator_1": "The illustrator of the graphic novel used vibrant colors to depict the superhero's powers.",
	"illustrator_2": "Hiring a professional illustrator can significantly enhance the appearance of your book.",
	"illustrator_3": "She is not only an author, but also an accomplished illustrator, having designed the artwork for all of her own book covers.",
	"illustrator_4": "The illustrator brought the story to life with his creative and expressive drawings.",
	"befriend_0": "When I moved to a new city, I was lucky to befriend my neighbor who helped me adjust to my new surroundings.",
	"befriend_1": "Back in high school, I decided to befriend the shy girl in class and we've stayed close friends ever since.",
	"befriend_2": "Matthew made an effort to befriend his coworkers, understanding the importance of having good relationships at work.",
	"befriend_3": "Mary couldn't believe how quickly her toddler managed to befriend all the other kids at the park.",
	"befriend_4": "A good way to get involved in your local community is to attend events and befriend your neighbors.",
	"dialogue_0": "The heated dialogue between the two politicians dominated the news headlines. ",
	"dialogue_1": "Jessica was working on the dialogue for her latest screenplay, trying to nail the characters' dynamics and speech patterns. ",
	"dialogue_2": "The novel's sharp and witty dialogue kept me entertained throughout the entire read.",
	"dialogue_3": "A candid dialogue about race relations is necessary in order to create positive change. ",
	"dialogue_4": "The dialogue in the movie was realistic and relatable, making the characters feel more alive.",
	"sinister_0": "The old, derelict mansion on the hill had a surprisingly sinister aura about it, making all the villagers keep their distance.",
	"sinister_1": "The detective didn't trust the suspect; there was something sinister in the way he smiled.",
	"sinister_2": "She had a sinister suspicion that her phone was being tracked or monitored.",
	"sinister_3": "His refusal to answer the direct questions raised a sinister doubt in her mind.",
	"sinister_4": "Despite the beautiful exterior of the castle, there was a sinister history of crime and violence associated with it.",
	"banish_0": "The council decided to banish him from the village due to his disturbing actions.",
	"banish_1": "When she caught her boyfriend cheating, she banished him from her life.",
	"banish_2": "The old king was known for his ruthless rule, often choosing to banish those who disagreed with him.",
	"banish_3": "The headmistress threatened to banish anyone from the school premises who was found breaking the rules.",
	"banish_4": "After multiple warnings, the unethical company was finally banished from the industry.",
	"moor_0": "In the 15th and 16th centuries, the Moors were famous for their achievements in various fields such as arts, sciences, and philosophy.",
	"moor_1": "We enjoyed a long hike over the windswept moors, appreciating the untouched beauty of the landscape.",
	"moor_2": "The ancient castle was located high on the moor, overlooking the vast, barren land below.",
	"moor_3": "He read a novel about the Moors and their civilization in the Golden Age of Islam.",
	"moor_4": "In the harbor, the sailors prepared to moor their boat for the impending storm.",
	"evacuate_0": "The army issued orders to evacuate the city immediately due to the approaching hurricane.",
	"evacuate_1": "We had to evacuate the building because of a bomb threat.",
	"evacuate_2": "The city's residents refused to evacuate, despite the danger posed by the erupting volcano.",
	"evacuate_3": "The fire alarm went off requiring everyone to evacuate the school in an orderly manner.",
	"evacuate_4": "The hospital had to evacuate all its patients during the flood, moving them to a safer location.",
	"rebound_0": "After her devastating breakup, it took her months to rebound and start to feel happy again.",
	"rebound_1": "The basketball player leaped high into the air to grab a rebound.",
	"rebound_2": "The stock market experienced a quick rebound after a week of significant losses.",
	"rebound_3": "They feared that their son was merely a rebound relationship for her, after her recent divorce.",
	"rebound_4": "The company expected to rebound from its recent financial difficulties in the coming year.",
	"restriction_0": "The government implemented travel restrictions due to the ongoing health crisis. ",
	"restriction_1": "The bird sanctuary put restrictions on visitors to protect the nesting areas. ",
	"restriction_2": "The doctor advised him to put a restriction on his sugar intake because of his diabetes. ",
	"restriction_3": "The concert had age restrictions, only allowing those who were 18 years and older. ",
	"restriction_4": "As part of her probation, she had a restriction from leaving the country.",
	"division_0": "The division of the large estate among the four siblings caused a lot of disagreements.",
	"division_1": "In mathematics, division is one of the four basic operations along with addition, subtraction, and multiplication.",
	"division_2": "The marketing division of the company was awarded for their innovative campaign strategy.",
	"division_3": "There seems to be a clear division of opinions on this issue within our community.",
	"division_4": "The division of labor in the factory helps to increase efficiency and productivity.",
	"dramatic_0": "The sunset was dramatic, with colors ranging from deep purple to blazing orange.",
	"dramatic_1": "The play had a dramatic ending that left the audience in tears.",
	"dramatic_2": "She made a dramatic entrance in her sparkling gown, turning every head in the room.",
	"dramatic_3": "The car chase was the most dramatic scene in the movie, keeping viewers on the edge of their seats.",
	"dramatic_4": "After his dramatic rescue from the fire, the firefighter was hailed as a hero in his town.",
	"culture_0": "The rich culture of Japan is reflected through their traditions, etiquettes and art.",
	"culture_1": "It's essential for global businesses to understand the varying cultures of their international clients.",
	"culture_2": "Experiencing different cultures while traveling broadens one's perspective and understanding of the world.",
	"culture_3": "Food is often a significant part of a country's culture, each dish telling a story about its history and people.",
	"culture_4": "The indigenous culture of the Maoris is deeply respected and celebrated in New Zealand.",
	"podcast_0": "My new favorite podcast is all about global travel adventures and tips.",
	"podcast_1": "Listening to podcasts during my daily commute really helps to pass the time.",
	"podcast_2": "I've been considering starting up my own podcast to discuss contemporary literature.",
	"podcast_3": "Podcasts offer a great way to learn new things, as experts from various fields often share their knowledge in these audio shows.",
	"podcast_4": "My friend recommended a crime investigation podcast, which has now become my go-to for late-night listening.",
	"evolution_0": "The evolution of technology has greatly increased our ability to communicate and access information.",
	"evolution_1": "Charles Darwin's theory of evolution sparked a revolution in the world of biology and natural sciences.",
	"evolution_2": "The evolution from a nomadic lifestyle to developing civilizations marked a pivotal period in human history.",
	"evolution_3": "John is studying the evolution of music genres from classical to modern pop.",
	"evolution_4": "Evolution is a process that allows species to adapt to their environment to increase their chances of survival.",
	"heartfelt_0": "Her heartfelt apology for her actions was deeply appreciated by her friends.",
	"heartfelt_1": "John's heartfelt letter to his sister expressed how much he missed her.",
	"heartfelt_2": "Giving support to the charity was a heartfelt act of kindness upon his part.",
	"heartfelt_3": "The film aroused a heartfelt response from the audience, leaving many in tears.",
	"heartfelt_4": "I have a heartfelt gratitude for everyone who supported me through this difficult time in my life.",
	"theoretical_0": "The concept of time travel is still purely theoretical and has not been scientifically proven.",
	"theoretical_1": "The physicist explained the theoretical basis for teleportation, which intrigued everyone in the seminar.",
	"theoretical_2": "Allison's theoretical approach to the problem led her to explore various potential solutions.",
	"theoretical_3": "The economics course involved both theoretical discussions and practical case studies to give a comprehensive outlook.",
	"theoretical_4": "John's theoretical knowledge of music helped him understand and interpret complex classical compositions.",
	"inherited_0": "She inherited her mother's blue eyes and her father's love for music.",
	"inherited_1": "After his wealthy uncle passed away, he unexpectedly inherited a large sum of money.",
	"inherited_2": "Many common diseases can be inherited from our family's gene pool.",
	"inherited_3": "He inherited the family business when his father decided to retire.",
	"inherited_4": "Anna was nervous about how she'd handle the farm she'd just inherited from her grandfather.",
	"variety_0": "The variety of flowers in the garden made it incredibly colorful and beautiful.",
	"variety_1": "The clothing store boasts a wide variety of styles and sizes to suit anyone's taste.",
	"variety_2": "The chef is praised for the variety of cuisines he can expertly prepare.",
	"variety_3": "A balanced diet should include a variety of fruits and vegetables to ensure getting all the necessary nutrients.",
	"variety_4": "In the music festival, there was a variety of bands playing different genres, appealing to a broad audience.",
	"intrigue_0": "The movie's complex plot and unexpected twists filled me with intrigue.",
	"intrigue_1": "The mysterious stranger had an aura of intrigue that was impossible to ignore.",
	"intrigue_2": "After reading the first chapter, she was caught in the intrigue of the novel and couldn't put it down.",
	"intrigue_3": "His sudden resignation from the company has sparked a lot of intrigue within the industry.",
	"intrigue_4": "The ancient ruins were full of intrigue, inspiring countless theories about the people who had once inhabited them.",
	"lens_0": "The lens of my camera is cracked, I need to get it replaced soon.",
	"lens_1": "His new glasses came with a special lens that blocks the harmful blue light from digital screens.",
	"lens_2": "In photography, choosing the right lens can greatly affect the outcome of your pictures.",
	"lens_3": "She has a unique lens through which she sees the world, which is reflected in her artwork.",
	"lens_4": "The microscope's powerful lens allowed them to study the cellular structure closely.",
	"contemplate_0": "I often contemplate about starting my own business, but there are too many uncertainties. ",
	"contemplate_1": "After losing her job, Samantha had to contemplate her next move carefully. ",
	"contemplate_2": "Jason spent the whole night contemplating whether to accept or decline the job offer from the other team. ",
	"contemplate_3": "The famous philosopher spent most of his life contemplating the meaning of existence. ",
	"contemplate_4": "The council is contemplating a ban on the use of plastic bags to promote environmental awareness.",
	"suspend_0": "The university had to suspend the professor due to the allegations against him.",
	"suspend_1": "After discovering the malfunction, they decided to suspend the operation of the machine for safety reasons.",
	"suspend_2": "If you don't complete your assignments on time, they will suspend your privileges to use the library.",
	"suspend_3": "The development of the project had to be suspended indefinitely due to lack of funding.",
	"suspend_4": "Because of the critical situation, the government decided to suspend the implementation of the new policy.",
	"accuracy_0": "The accuracy of his research was applauded by senior scientists.",
	"accuracy_1": "To ensure the highest accuracy in your findings, always double-check the data.",
	"accuracy_2": "Her accuracy in predicting market trends is what makes her top in the field.",
	"accuracy_3": "The doctor's accuracy in diagnosis helped to save many lives. ",
	"accuracy_4": "His skill as a sniper depends on pinpoint accuracy.",
	"decomposer_0": "Mould and fungi are important decomposers in a natural ecosystem, breaking down dead organic material and returning nutrients to the soil.",
	"decomposer_1": "Earthworms are known as decomposers as they feed on dead plant material and allow bacteria in their gut to break it down.",
	"decomposer_2": "In the food chain, decomposers play a crucial role in nutrient cycling, making essential minerals available to plants.",
	"decomposer_3": "The decomposer is an organism that decays dead bodies, allowing the recycling of matter in an ecosystem.",
	"decomposer_4": "The forest floor is teeming with decomposers such as beetles and fungi, helping to maintain the health of the woodland ecosystem.",
	"distribute_0": "The teacher asked the students to distribute the textbooks evenly among the class.",
	"distribute_1": "The organization plans to distribute food parcels to the less fortunate in our community next week. ",
	"distribute_2": "Their company is responsible for the manufacture and distribute of kitchen appliances all over the country.",
	"distribute_3": "It's important to evenly distribute weight in the backpack to ensure comfort while hiking. ",
	"distribute_4": "The government announced it would distribute the vaccine starting from the most vulnerable groups.",
	"commitment_0": "His commitment to excellence in his job earned him promotion within just a few months.",
	"commitment_1": "She showed a strong commitment to her fitness routine by hitting the gym every single day.",
	"commitment_2": "Their successful marriage is a testimony to their commitment to each other.",
	"commitment_3": "Upon accepting the new job, he made a commitment to finish all his tasks on time and to the best of his ability.",
	"commitment_4": "Volunteering at the orphanage for 10 years displayed her deep commitment to community service.",
	"enhance_0": "The warm lighting in the room really enhances the color of the furniture.",
	"enhance_1": "My goal is to enhance my skills in digital marketing through online classes and workshops.",
	"enhance_2": "The makeup artist applied a highlighter to enhance the model's facial features. ",
	"enhance_3": "A good pair of running shoes can enhance your performance in a marathon. ",
	"enhance_4": "The new software update will significantly enhance the functionality of the smartphone.",
	"amenity_0": "The hotel showcased a wide array of amenities, from a fitness center to a gourmet restaurant. ",
	"amenity_1": "Access to high-quality education and healthcare are fundamental amenities in a well-developed society. ",
	"amenity_2": "Upon moving into their new apartment, they realized it lacked basic amenities, such as a dishwasher and air conditioning. ",
	"amenity_3": "The cabin, while rustic, offered the amenity of an outdoor hot tub with a stunning mountain view.",
	"amenity_4": "Despite its high price, the resort was well-loved by vacationers due to its lavish amenities and impeccable service.",
	"impart_0": "The mentor will impart his wisdom and experience to the new trainees.",
	"impart_1": "During the workshop, the author managed to impart a great deal of knowledge about the process of writing a novel.",
	"impart_2": "My grandmother always made it a point to impart lessons of kindness and humility through her stories.",
	"impart_3": "In order to prevent future accidents, it's essential to impart safety instructions to all employees.",
	"impart_4": "The teacher made every effort to impart his passion for art to his students.",
	"derived_0": "The mathematician derived an important theorem from a series of logical statements.",
	"derived_1": "The unique taste of the soup is derived from a secret recipe passed down through generations.",
	"derived_2": "The word 'hello' is derived from the Old English greeting 'h\u01fdl'.",
	"derived_3": "The medical treatment is derived from ancient healing practices.",
	"derived_4": "The researchers derived new insights from the experimental data.",
	"excursion_0": "The school is organizing an excursion to the local science museum next week for the students to learn more about the subject in an interactive way.",
	"excursion_1": "During our vacation, we went on a day-long excursion to the nearby islands and got to experience their rich marine life.",
	"excursion_2": "The hike to the mountains was the most memorable part of our excursion.",
	"excursion_3": "The travel agency offers an exciting excursion package that includes a tour of the city and a visit to the famous national park.",
	"excursion_4": "As part of our cruise package, we are given an opportunity to go on an excursion to experience the unique culture and famous landmarks of each port we dock at.",
	"emit_0": "The radio tower emits signals that make it possible for us to listen to our favorite stations.",
	"emit_1": "The fluorescent light bulbs emit a harsh, white light that isn't very pleasant to the eyes.",
	"emit_2": "People should be aware that cars emit harmful pollutants into the air, which contribute to global warming.",
	"emit_3": "The flowers in the garden emit a wonderful fragrance that attracts many bees and butterflies.",
	"emit_4": "The warning system will emit a loud siren when a threat is detected.",
	"sector_0": "The tech industry is a rapidly growing sector of the economy.",
	"sector_1": "Many investors are looking at the renewable energy sector as a promising investment opportunity. ",
	"sector_2": "The private sector is expected to take on more responsibilities as government funding decreases.",
	"sector_3": "Her research focuses primarily on the impact of policy changes within the healthcare sector. ",
	"sector_4": "The tourism sector, a major source of income for the country, has been severely affected by the pandemic.",
	"expand_0": "The company has plans to expand into the international market next year.",
	"expand_1": "As the universe continues to expand, scientists search for answers as to why this is happening.",
	"expand_2": "The professor advised her students to go into more detail and expand on their initial thoughts during the discussion.",
	"expand_3": "Lisa thought the balloon would pop, but it continued to expand as she filled it with more air.",
	"expand_4": "In order to accommodate more patients, the hospital needs to expand its current facility.",
	"tolerant_0": "Despite their many disagreements, Andrew remained tolerant towards his colleague's differing opinions.",
	"tolerant_1": "A tolerant society is one that respects and appreciates the diversity of its people.",
	"tolerant_2": "The headmaster expected all students to be tolerant of each others' cultural backgrounds.",
	"tolerant_3": "It is crucial to be tolerant with young children as they learn and grow.",
	"tolerant_4": "She was patient and tolerant, even when faced with challenging situations.",
	"tactless_0": "His tactless comment about her dress ruined the rest of their date.",
	"tactless_1": "The tactless politician often offended and shocked the public with his blunt and forthright opinions.",
	"tactless_2": "Jack was fired because of his tactless remarks to his boss about the new business plan.",
	"tactless_3": "Her tactless joke about his weight made everyone in the room feel uncomfortable.",
	"tactless_4": "He was often described as a tactless man, often putting his foot in his mouth at the worst moments.",
	"emphasis_0": "He placed a particular emphasis on the importance of reading for personal development.",
	"emphasis_1": "The new curriculum puts an emphasis on analytical and problem-solving skills.",
	"emphasis_2": "Her speech had an emphasis on environmental conservation and sustainable practices.",
	"emphasis_3": "With a threat of economic recession, there is an increased emphasis on budget control within the company. ",
	"emphasis_4": "Her emphasis on the word final made me understand that we won't be revising the project anymore.",
	"moderate_0": "The debate will be supervised by a skilled moderate who will ensure all rules are followed.",
	"moderate_1": "He leads a life of moderate luxury, preferring not to indulge excessively in material things.",
	"moderate_2": "The doctor urged her to exercise, but with a moderate intensity due to her health condition.",
	"moderate_3": "The team decided to take  a moderate approach to their project, deciding not to take unnecessary risks.",
	"moderate_4": "Weather forecasts predict moderate rainfall throughout the week, which will be a relief amidst this drought.",
	"confess_0": "After much hesitation, he decided to confess his feelings to her.",
	"confess_1": "She broke down during the police interrogation and began to confess to the crime she had committed.",
	"confess_2": "It was hard for him to confess that he was wrong all this time.",
	"confess_3": "I feel the need to confess that I am the one who spilled coffee on your report; I'm really sorry.",
	"confess_4": "Every Sunday, she would go to church to confess her sins to the priest.",
	"concede_0": "Despite all his efforts, John had to concede that he could not complete the project on time.",
	"concede_1": "The trailing soccer team, already three goals behind, conceded another goal in the second half. ",
	"concede_2": "In the high-stakes game of politics, the politician was reluctant to concede his seat to his opponent.",
	"concede_3": "After reviewing the evidence, the scientists conceded that their hypothesis was wrong. ",
	"concede_4": "Emma tried to debate the topic for hours, but in the end, she had to concede that her brother had a point.",
	"confide_0": "Feeling overwhelmed, John decided to confide in his therapist about his feelings of anxiety. ",
	"confide_1": "Despite having many friends, she only confided her deepest secrets to her sister.",
	"confide_2": "He confided to his wife that he had made a huge mistake at work and didn't know how to fix it.",
	"confide_3": "Typically reserved, it was surprising when she confided in me about her marital issues.",
	"confide_4": "After years of silence, he finally confided in his parents about his struggles with addiction.",
	"prescribe_0": "The doctor decided to prescribe antibiotics to help fight off the infection.",
	"prescribe_1": "It is the duty of psychiatrists to prescribe the appropriate medication for their patients' mental health conditions.",
	"prescribe_2": "The nutritionist may prescribe a specific diet to aid in the patient's weight loss.",
	"prescribe_3": "My dermatologist prescribed a new cream to help with my skin problems.",
	"prescribe_4": "The regulations prescribe the minimum safety standards for the workplace.",
	"required_0": "The amount of effort required to finish this project is substantial.",
	"required_1": "All required documents must be submitted by Monday.",
	"required_2": "It is required by law to wear a seatbelt while driving.",
	"required_3": "Additional evidence is required to support his claim.",
	"required_4": "A lot of determination and persistence are required to succeed in this field.",
	"offer_0": "The supermarket had a special offer on fresh vegetables today. ",
	"offer_1": "She decided not to accept the job offer as it required relocating to a different city.",
	"offer_2": "Jake offered to help me move my furniture to the new apartment.",
	"offer_3": "The agency offered a brilliant vacation package that was hard to resist. ",
	"offer_4": "After a lengthy discussion, the salesman finally agreed to offer a discount on the new car.",
	"microchip_0": "The veterinarian recommended implanting a microchip in our dog for easy identification if he ever went missing.",
	"microchip_1": "The rapid evolution of microchip technology has revolutionized the technology industry.",
	"microchip_2": "Modern credit cards are equipped with a microchip to enhance security and reduce fraud.",
	"microchip_3": "In the past few decades, the size of microchips has dramatically reduced, leading to the miniaturization of electronic devices. ",
	"microchip_4": "The engineer was specialized in developing microchips for various industrial machinery.",
	"viral_0": "The video of the dancing dog has gone viral, with millions of views worldwide in just a few days. ",
	"viral_1": "The viral outbreak caused panic in the town, leading to a shortage of food and medicines.",
	"viral_2": "The marketing team hoped that their new advertising campaign would go viral and reach a larger audience.",
	"viral_3": "When she woke up one morning, Lily was surprised to find that her tweet had gone viral overnight.",
	"viral_4": "The most challenging aspect of viral diseases is that they can mutate rapidly, making treatment difficult.",
	"crest_0": "The hikers finally reached the crest of the steep mountain after a long, grueling journey.",
	"crest_1": "On the crest of the hill, there stood a majestic old castle which had a grand view of the entire town below.",
	"crest_2": "She designed a unique family crest that reflected her ancestral roots.",
	"crest_3": "The eagle, spread its wings and soared high, flying over the crest of waves formed in the ocean during the storm.",
	"crest_4": "The crest of the helmet was adorned with a golden design, symbolizing the wearer's high-ranking status.",
	"reassess_0": "After her injury, she had to reassess her ability to compete in the marathon. ",
	"reassess_1": "Their latest financial losses forced the company to reassess their business strategies. ",
	"reassess_2": "The teacher decided to reassess the student's performance after learning about his personal issues.",
	"reassess_3": "Upon realizing the scale of the project, the team had to reassess the deadline for completion.",
	"reassess_4": "The lockdown made him reassess his priorities and focus more on his well-being and relationships.",
	"eventually_0": "Despite the many challenges we faced, we eventually found the solution to our problem.",
	"eventually_1": "She was determined to learn to play the piano, and eventually, she was able to perform at a concert.",
	"eventually_2": "They will eventually realize their mistake and apologize.",
	"eventually_3": "After many years of hard work and dedication, he eventually became the CEO of the company.",
	"eventually_4": "I couldn't understand the logic behind his decision, but it eventually made sense when he explained it thoroughly.",
	"vital_0": "Good nutrition and regular exercise are vital for maintaining optimal health.",
	"vital_1": "In the desert, finding a source of water is vital for survival.",
	"vital_2": "Regular communication is vital in maintaining long-distance relationships. ",
	"vital_3": "It is vital to ensure the safety of children in school.",
	"vital_4": "Showing respect for others' beliefs and opinions is a vital attribute of open-mindedness.",
	"joint_0": "My grandparents run a small pizza joint downtown where they serve the most delicious pizzas.",
	"joint_1": "After my knee surgery, I have to do exercises every day to strengthen the joint.",
	"joint_2": "She rolled a joint and lit it up, inhaling deeply and feeling the stress of the day slip away.",
	"joint_3": "The decision to purchase the new house was not unilateral, it was a joint decision made by both my wife and me.",
	"joint_4": "The new contract was a joint venture between the two leading tech firms in the city.",
	"summit_0": "The summit of Mount Everest is the highest point on Earth. ",
	"summit_1": "World leaders attended the annual global economic summit to discuss trade regulations and other financial matters. ",
	"summit_2": "After a long and strenuous hike, we finally reached the summit of the mountain, and the view was breathtaking. ",
	"summit_3": "The climate change summit brought together scientists and policy makers from around the globe to discuss solutions to the environmental crisis. ",
	"summit_4": "The eagle soared high above the summit of the rocky peak.",
	"savage_0": "The savage storm left the coastal town in ruins. ",
	"savage_1": "In the wild, animals have to be savage to survive. ",
	"savage_2": "The critics gave a savagely negative review for his latest film. ",
	"savage_3": "The boxing match turned savage in the final round, with both parties landing severe blows on each other. ",
	"savage_4": "His tweets are known for their savage wit attacking political figures.",
	"bottleneck_0": "The narrow bridge on the highway often creates a bottleneck during rush hour.",
	"bottleneck_1": "The bottleneck in the production line was causing significant delays in delivering the final product.",
	"bottleneck_2": "The company needs to identify possible bottlenecks in the system to improve efficiency. ",
	"bottleneck_3": "The construction work in the main street led to a bottleneck, causing major traffic congestion.",
	"bottleneck_4": "The unexpected influx of customer orders created a bottleneck in the supply chain, hampering delivery times.",
	"denial_0": "After years of denial, he finally admitted to his mistakes.",
	"denial_1": "Her denial of the accusations created a further layer of distrust in their relationship.",
	"denial_2": "Living in denial about his health problems only increased the risks.",
	"denial_3": "Her request for a visa met with a denial from the embassy.",
	"denial_4": "The company's denial of the allegations did not convince the public.",
	"racial_0": "The new law is designed to prevent racial discrimination in hiring practices.",
	"racial_1": "They conducted a study on racial disparities in healthcare provision.",
	"racial_2": "The terrible act was a clear case of racial hatred and intolerance.",
	"racial_3": "He is conducting research on racial identity formation among adolescents.",
	"racial_4": "The racial makeup of the city is incredibly diverse, with people from all over the world living harmoniously together.",
	"spectator_0": "As a mere spectator at the soccer game, I felt the thrill of the crowd surge through me as the final goal was scored. ",
	"spectator_1": "The magician called a random spectator from the audience to assist with his next trick. ",
	"spectator_2": "Despite being an enthusiastic spectator, Maria would never have the courage to actually play rugby. ",
	"spectator_3": "Every spectator in the concert hall was entranced by the powerful performance of the orchestra.",
	"spectator_4": "As a spectator at the marathon, Pete was inspired to start training for next year's race.",
	"oath_0": "After finishing law school and passing the bar exam, she proudly took the attorney's oath to uphold justice and the constitution.",
	"oath_1": "The witnesses swore an oath in the courtroom, promising to share only the truth on their testimonies.",
	"oath_2": "The soldiers made an oath of loyalty to their commanders, vowing to protect their homeland at all costs.",
	"oath_3": "As part of their wedding ceremony, the couple exchanged personal oaths of love and commitment in front of their loved ones.",
	"oath_4": "A Hippocratic oath is taken by doctors as a commitment to ethical medical practice.",
	"criminal_0": "The police are searching for a possible criminal who robbed the bank yesterday.",
	"criminal_1": "The criminal's actions have caused great distress among the community.",
	"criminal_2": "After years of dedicated detective work, they finally brought the notorious criminal to justice.",
	"criminal_3": "The central character of the movie is a former criminal who is trying to turn his life around.",
	"criminal_4": "In order to understand the motivations of a criminal, some psychologists dive deep into their past experiences.",
	"offence_0": "His rude comment caused great offence to everyone present at the meeting.",
	"offence_1": "She took offence at his mocking tone and stormed out of the room.",
	"offence_2": "The offence was considered minor, but he was still penalized for breaking the law.",
	"offence_3": "Possession of illegal substances is a serious offence, carrying potential jail time.",
	"offence_4": "The team planned their offence carefully before the big game, hoping to secure a win.",
	"expert_0": "As an expert in computer programming, John was the go-to person in his team for complex coding problems.",
	"expert_1": "The company hired a digital marketing expert to enhance their online presence and engagement.",
	"expert_2": "With her master's degree in nutrition and years of experience, she became an expert on the impacts of diet on health.",
	"expert_3": "He's not just a car mechanic, he's an expert; he can tune your car to peak performance and also give advice on how to maintain it.",
	"expert_4": "Sarah, a real estate expert, provided valuable insights during the town hall meeting, helping people understand the current market trends more effectively.",
	"destination_0": "The best part about travelling is the journey, not just the final destination.",
	"destination_1": "Hawaii is a popular holiday destination for many tourists around the world.",
	"destination_2": "The GPS indicated that we were still 20 miles away from our destination.",
	"destination_3": "Each train station on the map was a potential destination for our summer trip.",
	"destination_4": "Due to snowy conditions, the flight was diverted to an alternative destination.",
	"executive_0": "As an executive at a leading software company, he was responsible for making major corporate decisions.",
	"executive_1": "The executive branch of the government is responsible for implementing and enforcing laws.",
	"executive_2": "She recently became an executive producer of a popular television series.",
	"executive_3": "The executive article in the agreement focused on the roles and responsibilities of all parties involved.",
	"executive_4": "Our company is looking to hire an executive with extensive experience in digital marketing and e-commerce.",
	"carbon_0": "Carbon is an essential element found in nature, and is also one of the primary building blocks of life.",
	"carbon_1": "The burning of fossil fuels introduces a great deal of carbon into our atmosphere, contributing to global warming.",
	"carbon_2": "Carbon dating is a scientific method used by archaeologists to determine the age of ancient artifacts and fossils.",
	"carbon_3": "Graphite and diamond are two forms of carbon having different physical properties - graphite is soft and slippery, while diamond is the hardest known substance.",
	"carbon_4": "Carbon fiber is a strong, lightweight material used in the production of many high-performance products, such as sports equipment and luxury cars.",
	"politician_0": "The politician's speech resonated well with his constituents, gaining him many supporters.",
	"politician_1": "The corruption scandal severely tarnished the reputation of the once beloved politician.",
	"politician_2": "The politician spent his career advocating for increased funding in public education.",
	"politician_3": "The charismatic politician was known for his ability to engage with the public through his eloquent speeches.",
	"politician_4": "Many youngsters aspire to become a politician to induce positive changes in society.",
	"innocent_0": "The child's innocent laughter echoed throughout the park. ",
	"innocent_1": "After a thorough investigation, the accused was found innocent of all charges.",
	"innocent_2": "She had an innocent charm that won the hearts of everyone she met. ",
	"innocent_3": "His innocent comment accidentally sparked off a heated argument. ",
	"innocent_4": "The kitten looked up at me with such innocent eyes, I couldn't resist feeding it some milk.",
	"roam_0": "As a kid, he loved to roam through the woods behind his house, exploring the array of fascinating creatures that dwelled there.",
	"roam_1": "The majestic herd of buffalo roamed freely across the vast prairie.",
	"roam_2": "I enjoy roaming around the city center on the weekends, always discovering something new.",
	"roam_3": "On our vacation, we spent days roaming through the charming alleyways of the ancient city.",
	"roam_4": "Lost in their fascination for the art museum, they roamed around for hours, forgetting all about time.",
	"immune_0": "After having chickenpox as a child, she is now immune to the virus.",
	"immune_1": "Thanks to his high-stress job, he's become immune to the effects of caffeine.",
	"immune_2": "The hospital was studying patients who seemed immune to the disease.",
	"immune_3": "A healthy lifestyle can help to boost your immune system.",
	"immune_4": "Many bacteria have become immune to common antibiotics, which is a growing concern in the medical field.",
	"nominate_0": "The board decided to nominate John as the new CEO of the company.",
	"nominate_1": "For the upcoming election, the party is expected to nominate a strong candidate.",
	"nominate_2": "Ellen truly deserved to be nominated for the Teacher of the Year award.",
	"nominate_3": "During their meeting, they discussed who they should nominate for the vacant management position.",
	"nominate_4": "As the current club president, it is my responsibility to nominate my successor.",
	"passionate_0": "She's very passionate about environmental conservation, dedicating her time and energy in promoting sustainable practices.",
	"passionate_1": "Thomas is passionate about cooking; he spends endless hours preparing and perfecting his dishes.",
	"passionate_2": "His passionate speech about equality inspired many to reconsider their prejudices and biases.",
	"passionate_3": "Despite having a well-paying corporate job, his true passion lay in photography.",
	"passionate_4": "I love watching her when she explains physics, her face lights up displaying her passionate interest in the subject.",
	"recipe_0": "I followed the recipe exactly, but the cake still didn't rise properly.",
	"recipe_1": "The chef refused to reveal his secret recipe for the delicious pasta sauce.",
	"recipe_2": "This recipe for chicken soup has been in my family for generations.",
	"recipe_3": "My grandmother wrote her recipes in a small, tattered notebook that she kept hidden in her kitchen.",
	"recipe_4": "The key to a good cookie is following the recipe to the letter, you can't leave out any ingredients.",
	"recruit_0": "The military is eager to recruit new members into their ranks.",
	"recruit_1": "As a headhunter, it's my job to recruit top talent for major corporations.",
	"recruit_2": "The football coach went to the high school game to recruit promising players for his team.",
	"recruit_3": "The company launched an aggressive campaign to recruit new employees for its overseas operations.",
	"recruit_4": "The university visits high schools to recruit exceptional students for their science and technology programs.",
	"destruction_0": "The destruction of the ancient temple by the earthquake left the entire city in a state of shock.",
	"destruction_1": "War often causes extreme destruction, making it difficult for countries to rebuild.",
	"destruction_2": "Environmental activists are concerned about the rampant destruction of the rainforests by logging companies.",
	"destruction_3": "The hurricane caused widespread destruction, displacing thousands of residents and destroying homes.",
	"destruction_4": "Government\u2019s lack of plans for preservation has led to the destruction of many historic buildings in the city.",
	"comparison_0": "We made a side-to-side comparison to see which speaker system had the better sound quality.",
	"comparison_1": "Olivia conducted a detailed comparison of all the leading smartphones before deciding which one to buy.",
	"comparison_2": "In comparison to his previous work, the artist's newest painting felt vibrant and full of life.",
	"comparison_3": "The teacher asked us to write an essay making a comparison between two characters in the novel.",
	"comparison_4": "The comparison made between traditional and online marketing strategies showed that both methods equally have their unique advantages.",
	"indeed_0": "It was indeed a surprise when he announced his retirement from the team.",
	"indeed_1": "The concert was, indeed, one of the best I've ever attended.",
	"indeed_2": "Indeed, there are times when everyone needs a little help.",
	"indeed_3": "Do you think she will accept the job? Indeed, I do believe she will.",
	"indeed_4": "The grandeur of the mountains was, indeed, awe-inspiring to behold.",
	"harmony_0": "The harmony between the violin and the piano made the concert unforgettable.",
	"harmony_1": "Natural parks teach us the importance of living in harmony with nature.",
	"harmony_2": "The siblings lived in perfect harmony, never fighting or arguing.",
	"harmony_3": "The harmony of colors in her painting was so pleasing to the eye.",
	"harmony_4": "Our team works in harmony to achieve common goals and objectives.",
	"editorial_0": "John loves to start his day by reading the editorial section of the newspaper.",
	"editorial_1": "The editorial written by the senior journalist created a massive uproar in the political circles.",
	"editorial_2": "She was asked to submit an editorial on environmental issues for the upcoming edition of the magazine.",
	"editorial_3": "His thought-provoking editorials consistently manage to stir discussions among the readers.",
	"editorial_4": "Immersing herself in political editorials helps Suzy to gain different perspectives on the current affairs.",
	"optimal_0": "The optimal time to plant gardenias is in the early spring.",
	"optimal_1": "To achieve optimal results in the gym, you should combine a balanced diet with regular exercise.",
	"optimal_2": "She was successful in creating the optimal environment for her kid's birthday party.",
	"optimal_3": "The doctor suggested that an optimal water intake for a healthy adult should be around two liters per day. ",
	"optimal_4": "To realize the optimal benefits from the software, it is important to understand its full range of features.",
	"dominance_0": "The company's dominance in the tech industry is unrivaled due to its innovative inventions.",
	"dominance_1": "In chess, possessing a strategic dominance often leads to a victory.",
	"dominance_2": "Michelle's dominance over her fears helped her become a successful public speaker.",
	"dominance_3": "The lion, as a predator, displays dominance over all other animals in the savannah.",
	"dominance_4": "The political party's dominance would be determined by the results of the upcoming election.",
	"dangle_0": "Her diamond earrings dangle elegantly whenever she turns her head.",
	"dangle_1": "The cat furiously tried to paw at the toy as I dangled it just out of reach.",
	"dangle_2": "From the ceiling, colorful streamers dangle to create a festive ambiance.",
	"dangle_3": "I often dangle incentives to motivate my team to finish their work ahead of schedule.",
	"dangle_4": "Elderly vines dangled from the trellis, adding a rustic charm to the sunny garden.",
	"marital_0": "They have been experiencing marital problems lately due to their lack of communication.",
	"marital_1": "The counselor has a special interest in marital and family therapy.",
	"marital_2": "The movie tells the story of a couple trying to overcome their marital issues.",
	"marital_3": "We attended a marital seminar to strengthen our understanding and bond in marriage.",
	"marital_4": "The law stated that marital assets had to be divided equally after the divorce.",
	"contender_0": "Despite being new to the political scene, Sarah quickly emerged as a top contender for the mayoral position.",
	"contender_1": "The young athlete proved himself a serious contender in the national boxing championship.",
	"contender_2": "As the product is technically advanced, it is a strong contender in the marketplace.",
	"contender_3": "He may be an underdog, but don't disregard him as a contender for the championship title.",
	"contender_4": "The film is considered a major contender for several awards at the upcoming film festival.",
	"retiree_0": "My father, a recent retiree, is discovering new hobbies to keep himself active and engaged.",
	"retiree_1": "The local community center offers specialized programs for retirees to help them stay social and healthy.",
	"retiree_2": "After 40 years of teaching, Mrs. Johnson became a retiree and moved to Florida to enjoy the sunshine.",
	"retiree_3": "As a retiree, he discovered a passion for painting and took up art as a new venture in his golden years. ",
	"retiree_4": "The government often offers certain tax benefits and health insurance options to retirees.",
	"condo_0": "My parents just bought a new condo in Florida as their retirement home.",
	"condo_1": "The real estate agent showed the couple a two-bedroom condo facing the sea. ",
	"condo_2": "We decided to downsize and move into a condo for easier maintenance.   ",
	"condo_3": "Renting a condo seemed like a more practical option for us right now.",
	"condo_4": "The condo has a fantastic view of the city and a communal swimming pool.",
	"erode_0": "Heavy rainfall over the years has caused the cliff to erode significantly.",
	"erode_1": "The constant rush of the river has started to erode the bank and make it unstable.",
	"erode_2": "Angry and destructive, the storm threatened to erode the little sandcastle built by the children.",
	"erode_3": "Pollution can erode air quality and make it harmful for us to breathe.",
	"erode_4": "Corrosion can quickly erode metal, causing structures to become unstable over time.",
	"rite_0": "The rite of passage was a significant event in the tribe, marking the transition from boyhood to manhood.",
	"rite_1": "The wedding rite was full of symbolism, reflecting the couple's cultural and religious beliefs.",
	"rite_2": "Participating in the rite of holy communion is an important aspect of Christian worship.",
	"rite_3": "Graduation is seen as a rite of passage, signifying the move from student to professional life.",
	"rite_4": "Ancient civilizations had elaborate burial rites, intended to ensure a safe journey to the afterlife for the deceased.",
	"ammunition_0": "The police seized a large cache of weapons and ammunition during the raid.",
	"ammunition_1": "His controversial comments just gave his political opponents more ammunition against him. ",
	"ammunition_2": "I realized that without the appropriate ammunition, my hunting trip would be fruitless.",
	"ammunition_3": "Ammunition is strictly regulated and monitored in many countries to ensure public safety. ",
	"ammunition_4": "In a metaphorical sense, his extensive knowledge on the subject was his ammunition in the debate.",
	// "intervene_0": "The teacher had to intervene when the argument between two students began to escalate.",
	// "intervene_1": "The United Nations decided not to intervene in the civil unrest due to a lack of consensus among member states.",
	// "intervene_2": "The parents intervened in their child's unhealthy habits and began cooking healthier meals.",
	// "intervene_3": "The lifeguard was prepared to intervene if the swimmers ventured into the dangerous part of the ocean. ",
	// "intervene_4": "Rain intervened our plans for a picnic, so we decided to stay indoors and watch a movie.",
	"babe_0": "Look up to the sky babe, the stars are out tonight.",
	"babe_1": "Don't worry babe, I will always be there for you.",
	"babe_2": "That movie last night was so thrilling, wasn't it, babe?",
	"babe_3": "Good morning, babe. I made your favorite breakfast.",
	"babe_4": "Babe, could you please turn down the music? I'm trying to read.",
	"confirmation_0": "I received a confirmation email that my flight was successfully booked.",
	"confirmation_1": "The confirmation ceremony in our church is a big event that typically happens in the spring. ",
	"confirmation_2": "The lab results provided confirmation of the suspected diagnosis.",
	"confirmation_3": "Before you can finalize your order, you will need to click on the link sent to your email for confirmation.",
	"confirmation_4": "After several experiments, we finally got confirmation that our hypothesis was correct.",
	"nun_0": "The kind-hearted nun visited the hospital every week to comfort the sick.",
	"nun_1": "Alice abandoned her corporate job with the intent to become a nun and help the less privileged. ",
	"nun_2": "The convent was home to twenty devoted nuns who served the community admirably.",
	"nun_3": "Sister Maria is a very disciplined nun and always follows strict rules of the monastery.",
	"nun_4": "The nun dedicated her life to prayer, seeking spiritual enlightenment and solace.",
	"bust_0": "The police had been planning the drug bust for weeks before they finally raided the house. ",
	"bust_1": "In the sculpture class, they were learning how to craft a bust out of clay. ",
	"bust_2": "After the financial crisis, the economic bubble burst, causing many businesses to go bust. ",
	"bust_3": "She accidentally knocked over and broke the marble bust displayed in the living room.",
	"bust_4": "The tour guide pointed out the bronze bust of the famous poet prominently displayed in the library.",
	"replicate_0": "After several failed attempts, the scientists were finally able to replicate the results of the original experiment.",
	"replicate_1": "The company wanted to replicate the success of their competitor's marketing campaign.",
	"replicate_2": "The art student tried to replicate the intricate details of the famous painting for their final project.",
	"replicate_3": "Archaeologists replicate ancient tools to understand how they were used by our ancestors. ",
	"replicate_4": "In nature, DNA replicates itself during cell division to ensure the new cells have the same genetic material as the parent cell.",
	"verse_0": "The lyrics of a song typically include a verse, a chorus, and sometimes a bridge. ",
	"verse_1": "He found comfort in reciting his favorite verse from the Bible. ",
	"verse_2": "The poet meticulously worked on each verse of her poem, striving for perfect rhythm and rhyme. ",
	"verse_3": "The lead singer forgot the second verse of their popular song during the live performance.",
	"verse_4": "In Shakespeare's plays, the noble characters generally speak in verse while the commoners typically speak in prose.",
	"reconciliation_0": "The aim of their meeting was to bring about a reconciliation between the two rival factions.",
	"reconciliation_1": "After years of conflict, the two nations finally signed a treaty of reconciliation.",
	"reconciliation_2": "The story is about a family's path to reconciliation after a decade-long estrangement. ",
	"reconciliation_3": "The bank's accounting department is responsible for the daily reconciliation of all financial transactions to ensure accuracy.",
	"reconciliation_4": "She made her way to church, desperately seeking reconciliation for her past mistakes.",
	"linebacker_0": "The linebacker made an impressive tackle, stopping the opposing team's running back in his tracks.",
	"linebacker_1": "As a linebacker, John's primary responsibility on the team is to prevent offensive runs and protect their own team's end zone.",
	"linebacker_2": "The quarterback was blindsided by the linebacker who came rushing in from the side.",
	"linebacker_3": "He trained rigorously in the offseason to improve his speed and agility and secure his position as the starting linebacker.",
	"linebacker_4": "That linebacker is known for his exceptional skill in reading the opposing team's offense and disrupting their plays.",
	"cater_0": "We hired a professional to cater our wedding reception, ensuring that the food would be delicious and plentiful.",
	"cater_1": "The company announced it would cater lunch for all employees while they discuss the annual budget.",
	"cater_2": "Many online courses cater to student's unique learning styles by providing varied materials and teaching methods.",
	"cater_3": "The new restaurant downtown caters to vegetarians and vegans, offering a variety of plant-based meals.",
	"cater_4": "The travel agency catered to our needs by arranging a guided tour that included all the places we were eager to visit.",
	"disposal_0": "The proper disposal of hazardous waste is essential for the wellbeing of our environment.",
	"disposal_1": "I leased a cabin upstate, complete with a waste disposal and a large backyard.",
	"disposal_2": "You'll have to configure the garbage disposal under the kitchen sink on your own.",
	"disposal_3": "She had ample resources at her disposal to renovate the old building.",
	"disposal_4": "At your disposal, there is a team of experts ready to assist you with your project.",
	"bid_0": "The company submitted a bid for the construction project.",
	"bid_1": "At the auction, she made a high bid for the antique painting.",
	"bid_2": "The governor made a bid for re-election last year.",
	"bid_3": "The poker player had to decide if he wanted to raise, call, or fold his current bid.",
	"bid_4": "She decided to bid for the vintage car at the local auction house.",
	"aftermath_0": "After the destructive tornado, the town was left to grapple with the aftermath.",
	"aftermath_1": "The financial aftermath of the market crash was felt by investors globally.",
	"aftermath_2": "Post-war, the country had to deal with the aftermath of devastation and loss.",
	"aftermath_3": "The aftermath of the scandal damaged the company's reputation considerably.",
	"aftermath_4": "The emergency workers were quick to offer their aid in the aftermath of the earthquake.",
	"firearm_0": "Every firearm should be stored properly to avoid accidents, especially in households with children.",
	"firearm_1": "He was charged with illegal possession of a firearm.",
	"firearm_2": "The detective lifted the firearm carefully with a gloved hand, to preserve any potential forensic evidence.",
	"firearm_3": "After hearing a loud noise, he realized it was the sound of a discharged firearm.",
	"firearm_4": "To ensure their safety, the police officers are trained to handle various types of firearms.",
	"pavement_0": "After the long day, Steve slumped against a wall and sat on the cool pavement.",
	"pavement_1": "The pavement glistened in the city lights after a brief rain shower.",
	"pavement_2": "Children's chalk drawings transformed the boring gray pavement into a vibrant canvas of colors.",
	"pavement_3": "The constant change from hot to cold weather caused the pavement to crack.",
	"pavement_4": "The city announced a plan to replace the old, damaged pavement with new, eco-friendly materials.",
	"realism_0": "The movie was praised for its realism, featuring a raw and deeply moving portrayal of a soldier's experience in war.",
	"realism_1": "Annabel's painting style is characterized by a keen sense of realism; her portraits are almost photographic in their detail.",
	"realism_2": "The author wanted to convey realism in his novel, so he spent three months researching life in the 19th century.",
	"realism_3": "Since her poem reflected a brilliant use of realism, it was easy for readers to relate to and understand her experiences.",
	"realism_4": "While her earlier works leaned towards fantasy, her recent books demonstrate an even blend of magical elements and stark realism.",
	"committed_0": "He is fully committed to completing his project on time, working late nights and even on weekends.",
	"committed_1": "As a captain, she is committed to leading her team to victory in the upcoming tournament.",
	"committed_2": "The committed volunteer spent his entire summer helping to build houses for the homeless.",
	"committed_3": "They were committed to each other and decided to take their relationship to the next level by getting engaged.",
	"committed_4": "The musician committed his whole life to mastering the violin, practicing everyday for hours.",
	"bolster_0": "The addition of the new team member will bolster our chances of winning the championship.",
	"bolster_1": "The generous donations from the community helped to bolster the relief efforts after the devastating hurricane.",
	"bolster_2": "She was feeling unsure about her new project, but a vote of confidence from her boss served to bolster her spirits.",
	"bolster_3": "They added extra pillows to bolster the comfort of the bed.",
	"bolster_4": "The government introduced a new economic policy to bolster the struggling economy.",
	"gossip_0": "The ladies in the salon often engaged in idle gossip while they had their hair done.",
	"gossip_1": "Filled with gossip, the small town seemed to know everyone's business. ",
	"gossip_2": "I try to avoid gossip, as I think it's harmful to people's reputations. ",
	"gossip_3": "The magazine was full of celebrity gossip, which she read with a mix of disbelief and amusement.",
	"gossip_4": "John always became the center of office gossip because of his flamboyant lifestyle.",
	"catastrophe_0": "The earthquake was a catastrophe, leaving the entire city devastated and in desperate need of help. ",
	"catastrophe_1": "The government's lack of preparation for the hurricane turned an already serious situation into an even greater catastrophe.",
	"catastrophe_2": "The financial market crash in 2008 was referred to as an economic catastrophe by many experts. ",
	"catastrophe_3": "The Titanic sinking was one of the greatest maritime catastrophes in history, resulting in the loss of many lives. ",
	"catastrophe_4": "Increasing the production of harmful pollutants could lead to an environmental catastrophe.",
	"selective_0": "The university has a very selective admission process, accepting only the best students applying. ",
	"selective_1": "My aunt's selective memory only appears when it suits her, conveniently forgetting the times she promises to babysit. ",
	"selective_2": "Some historians have been criticised for being selective in their use of sources, thereby presenting a biased view of the events.",
	"selective_3": "The infection is selective, affecting only certain organs in the body.",
	"selective_4": "Restaurants are becoming more selective about their suppliers to ensure the quality of their dishes.",
	"ferry_0": "We took the ferry to cross the river to reach the other side of the town.",
	"ferry_1": "Seeing the sunset from a ferry ride on the sea is something I will never forget.",
	"ferry_2": "The daily commuters waited impatiently in the queue for the ferry to arrive.",
	"ferry_3": "The introduction of a new ferry service has significantly reduced travel time between the two islands.",
	"ferry_4": "I forgot my camera on the ferry; I hope someone turns it in to lost and found.",
	"vietnamese_0": "My best friend introduced me to Vietnamese cuisine, and now I can't get enough of it.",
	"vietnamese_1": "She is taking an online course to learn Vietnamese language.",
	"vietnamese_2": "Our neighborhood is known for its authentic Vietnamese restaurant that serves the best pho.",
	"vietnamese_3": "The Vietnamese people are widely admired for their resilience and hospitality.",
	"vietnamese_4": "I'm fascinated by the Vietnamese culture, particularly its art and traditional music.",
	"modified_0": "The car has been heavily modified with a more powerful engine and larger tires.",
	"modified_1": "After reviewing the feedback, the author modified the book's ending to make it more impactful.",
	"modified_2": "The design of the building was modified to accommodate more office spaces.",
	"modified_3": "My teacher modified the lesson plan to better suit the online learning environment.",
	"modified_4": "The scientist modified the experiment according to the new guidelines set by the board.",
	"contrast_0": "The contrast between their political beliefs led to many spirited discussions at the dinner table.",
	"contrast_1": "When the artist placed the light colors next to the dark ones, it created a stunning contrast in the painting.",
	"contrast_2": "As a researcher, she had to contrast the differing results from two separate experiments.",
	"contrast_3": "His bright, outgoing personality stood in stark contrast to his introspective and shy sister's demeanor.",
	"contrast_4": "The green trees provided an amazing contrast against the deep blue sky.",
	"gradual_0": "The gradual increase in temperature over the summer was hardly noticed by the residents.",
	"gradual_1": "The changes to the law were made in a gradual process that took many years.",
	"gradual_2": "He noticed a gradual improvement in his health after changing his diet and workout routine.",
	"gradual_3": "The gradual climb of the mountain was long, but it wasn't too steep.",
	"gradual_4": "Her understanding of the new language was gradual, requiring patience and consistent studying.",
	"preside_0": "The judge will preside over the court proceeding tomorrow.",
	"preside_1": "As the club president, it's his duty to preside at all the meetings and make important announcements.",
	"preside_2": "The queen is expected to preside over the royal gathering, just as tradition dictates.",
	"preside_3": "She was honored to preside over the annual charity event, which saw a record number of attendees this year.",
	"preside_4": "With his extensive experience and wisdom, he was the perfect choice to preside over the academic board.",
	"contradiction_0": "His statement was a clear contradiction to what he had said earlier.",
	"contradiction_1": "The teacher was able to pick up on the contradiction in Emma's story.",
	"contradiction_2": "The most intriguing part of the mystery was the contradiction between the evidence and the suspect's alibi.",
	"contradiction_3": "Being both intensely private and wildly charismatic marked a vivid contradiction in his personality. ",
	"contradiction_4": "The contradiction in the government's policy caused public confusion and disillusionment.",
	"matrix_0": "The digital data was stored in a complex matrix.",
	"matrix_1": "The movie The Matrix introduced many people to a vision of a virtual reality world.",
	"matrix_2": "In order to solve the problem, she calculated the determinant of the matrix.",
	"matrix_3": "The DNA sequencing results were plotted in a matrix, making it easier to interpret the information. ",
	"matrix_4": "The geological matrix of the soil was perfect for the vineyard, enhancing the taste of the harvested grapes.",
	"applaud_0": "The audience began to applaud as soon as the band started playing their hit song.",
	"applaud_1": "The team's innovative strategy was so effective that even their competitors had to applaud their success.",
	"applaud_2": "The way she handled the crisis deserved not just recognition, but a resounding applaud from everyone.",
	"applaud_3": "It's impressive enough to make everyone in the room stand and applaud.",
	"applaud_4": "The principal asked everyone to applaud the students who had won the science competition.",
	"anticipation_0": "The anticipation for the new video game release was palpable among the group of friends.",
	"anticipation_1": "The children were filled with anticipation as their parents guided them towards the surprise.",
	"anticipation_2": "He could hardly contain his anticipation for the upcoming ski trip. ",
	"anticipation_3": "The week before Christmas was always filled with anticipation and excitement.",
	"anticipation_4": "Our anticipation grew as the date for the concert drew closer.",
	"guerrilla_0": "The guerrilla fighters staged a series of surprise attacks against the enemy forces. ",
	"guerrilla_1": "The instructor taught a course on guerrilla warfare tactics during the military training. ",
	"guerrilla_2": "Hidden deep in the jungle, the guerrilla camp served as a base for the rebel group's operations. ",
	"guerrilla_3": "The government has implemented strict policies to suppress guerrilla activities in the remote regions of the country. ",
	"guerrilla_4": "Danny wrote his thesis on the impact of guerrilla marketing strategies in today's digital economy.",
	"situate_0": "After he moved to his new apartment, it took a week to situate all his furniture and make it feel like home.",
	"situate_1": "The cabin was strategically situated atop the hill, to give a perfect view of the rising sun.",
	"situate_2": "When she started her new job, it took a few days to situate herself with the new tasks and responsibilities.",
	"situate_3": "The airport was conveniently situated just ten miles away from the city center.",
	"situate_4": "The teacher situated the shy student next to more outgoing companions to encourage him to participate more.",
	"bureaucratic_0": "The bureaucratic process for obtaining a visa was lengthy and confusing, causing me to miss my planned trip.",
	"bureaucratic_1": "Some employees complained because they found the company's policies too bureaucratic and restrictive.",
	"bureaucratic_2": "The bureaucratic decision-making in the government often led to delays and dissatisfaction among citizens.",
	"bureaucratic_3": "Jane found dealing with the bureaucratic paperwork for her tax return extremely frustrating.",
	"bureaucratic_4": "Despite the need for a quick solution, the bureaucratic red tape resulted in a slow response.",
	"opposing_0": "The two opposing teams in the football match were both strong and competitive.",
	"opposing_1": "Both lawyers presented strong arguments in the court, each opposing the other's viewpoint.",
	"opposing_2": "The project was halted due to the opposing opinions of the board of directors.",
	"opposing_3": "They belong to opposing political parties, yet they maintain a cordial personal relationship.",
	"opposing_4": "Scientists are still debating the theory due to the high volume of opposing evidence.",
	"intersection_0": "We lost power yesterday when a truck hit the electrical lines at the intersection.",
	"intersection_1": "Please let's meet at the intersection of 7th Street and 3rd Avenue, that's a place everyone knows.",
	"intersection_2": "The house is located near the intersection of the two main roads in town.",
	"intersection_3": "The intersection of art and technology is where creative innovation takes place.",
	"intersection_4": "Traffic was heavy, especially around the city center's intersections.",
	"formulate_0": "Scientists are currently working hard to formulate a vaccine for the new virus strain.",
	"formulate_1": "Sarah will need to formulate a solid business plan before she can launch her startup.",
	"formulate_2": "The teaching team will meet tomorrow to formulate strategies for improving student engagement.",
	"formulate_3": "Our marketing manager is constantly challenging us to formulate fresh and innovative advertising concepts.",
	"formulate_4": "It took the author several years to formulate the plot for his bestselling novel.",
	"roster_0": "The school just released a new roster for teachers assigned to each grade level for the next academic year.",
	"roster_1": "Before the game began, the coach shared a roster of the team players, indicating their positions and roles.",
	"roster_2": "The office manager maintains a weekly roster to ensure proper rotation of tasks among the staff.",
	"roster_3": "There was a change in the band's roster when their lead guitarist decided to pursue a solo career.",
	"roster_4": "The company roster indicated that they had over 200 employees working across three different branches.",
	"unconscious_0": "The bang on the head rendered him unconscious immediately.",
	"unconscious_1": "She was unconscious for two days after the accident.",
	"unconscious_2": "His unconscious bias came to the surface in the way he treated her compared to the other employees.",
	"unconscious_3": "He fell unconscious due to the extreme heat and lack of water.",
	"unconscious_4": "The movie explored the concept of accessing our unconscious thoughts and dreams.",
	"reformer_0": "Martin Luther King Jr. was a significant reformer who fought tirelessly for civil rights in America.",
	"reformer_1": "The young reformer was passionate about climate change and actively campaigned for new environmental policies.",
	"reformer_2": "The country needed a bold reformer, one who wasn't afraid to take on the excessive corruption in the government.",
	"reformer_3": "Susan B. Anthony was an influential reformer who played a critical role in the women's suffrage movement.",
	"reformer_4": "Gandhi, known as a peace-loving reformer, had a major impact on India's step towards independence from British colonial rule.",
	"accusation_0": "After hearing the harsh accusation made against him, he reeled with shock and quickly defended himself. ",
	"accusation_1": "The accusation left her stranded between disbelief and horror, unable to respond or explain her actions.",
	"accusation_2": "His false accusation could have ruined my career and reputation, if not for my quick thinking and proof of innocence.",
	"accusation_3": "The manager responded angrily to the customers' accusation of poor service, insisting that his staff had been doing their best.",
	"accusation_4": "Laura bristled at the sudden accusation from her best friend, doubting the trust between them for a moment.",
	"naive_0": "The scammer was able to easily trick the naive elderly man into handing over his life savings.",
	"naive_1": "Being naive, she thought she could rely on her roommate's promises without any doubts. ",
	"naive_2": "Despite growing up in a tough neighborhood, he managed to maintain a somewhat naive belief in the inherent good in people.",
	"naive_3": "Her naive enthusiasm for the new project was infectious and inspired the rest of the team.",
	"naive_4": "His naive world view puzzled those around him, who knew that things were never as simple as he seemed to believe.",
	"drip_0": "I could hear a consistent drip from the leaky faucet in the middle of the night.",
	"drip_1": "Juan has been hanging around with the stylish group and now his fashion sense is really starting to drip.",
	"drip_2": "He watched the last drip of coffee fall into the pot, signaling it was time for his morning cup.",
	"drip_3": "The nurse adjusted the drip to ensure the medicine was administered slowly.",
	"drip_4": "After the rain, droplets of water continued to drip from the leaves of the trees, creating a soothing sound.",
	"morally_0": "Stealing is considered morally wrong by most societies.",
	"morally_1": "It is morally reprehensible to take advantage of someone's vulnerability.",
	"morally_2": "She found her boss's business practices to be morally questionable.",
	"morally_3": "Morally, I felt obligated to report the mistake and return the extra money given to me.",
	"morally_4": "We need to make decisions that are not only legally sound but also morally upright.",
	"glen_0": "The cabin in the glen was nestled between tall, mossy pine trees. ",
	"glen_1": "We hiked through the quiet glen, listening to the trickling stream nearby. ",
	"glen_2": "I've always dreamed about owning a peaceful cottage in a secluded glen. ",
	"glen_3": "Deer often come out to graze in the shadowy glen at dusk. ",
	"glen_4": "The sun was setting and light was fading from the glen, casting long shadows on the grass.",
	"underestimate_0": "Never underestimate the power of a kind word or a sincere apology. ",
	"underestimate_1": "They really underestimated the amount of time it would take to complete the project. ",
	"underestimate_2": "It's easy to underestimate the importance of a balanced diet on your overall health.",
	"underestimate_3": "Some people underestimate the courage it takes to admit your own mistakes.",
	"underestimate_4": "I find many people underestimate her because of her quiet demeanor, but she is incredibly intelligent and capable.",
	"injustice_0": "The rampant racial injustice in the society deeply disturbed Mark, prompting him to join the protest.",
	"injustice_1": "The court ruling was a blatant example of injustice, as the innocent man was sent to jail.",
	"injustice_2": "Helen believes that lack of access to quality education is one of the greatest injustices facing children in her community.",
	"injustice_3": "He documented instances of social injustice in his book, inspiring many to push for reforms.",
	"injustice_4": "It was a heartbreaking injustice that she, despite being the most deserving candidate, was overlooked for the promotion.",
	"activism_0": "Sara's strong sense of social justice led her to a life of activism, championing for the rights of marginalized communities.",
	"activism_1": "With the rise of the internet, digital activism has become a significant tool for spreading awareness and organizing protests.",
	"activism_2": "Some celebrities use their global influence and status to promote activism, helping to bring attention to numerous causes and charities.",
	"activism_3": "Martin Luther King Jr. is perhaps one of the most well-known figures of activism in the fight for civil rights in America.",
	"activism_4": "Environmental activism has grown in recent years, with more individuals and organizations seeking to combat climate change and protect our planet.",
	"audit_0": "Every year, the company hires an external firm to conduct an audit of their financial statements.",
	"audit_1": "After the scandal, a thorough audit was carried out to ensure transparency within the organization.",
	"audit_2": "She spent several weeks preparing all the necessary documents and files for the annual audit.",
	"audit_3": "The IRS selected his tax return for an audit due to inconsistencies in his reported income.",
	"audit_4": "The Chief Executive Officer has requested a full systems audit to identify any issues with the company's current IT infrastructure.",
	"canadian_0": "Amanda is a proud Canadian, originally hailing from Toronto.",
	"canadian_1": "The Canadian landscape is renowned for its vast forests and beautiful lakes.",
	"canadian_2": "As a Canadian, he grew up playing hockey, a national passion in his country.",
	"canadian_3": "We spent our vacation exploring the Canadian Rockies, charmed by their majestic beauty.",
	"canadian_4": "The Canadian government has made significant strides in environmental conservation.",
	"infinite_0": "The universe is believed to be infinite, extending way beyond what our eyes can see.",
	"infinite_1": "His infinite knowledge on the subject surprised everyone in the room, as he provided detailed answers to every question. ",
	"infinite_2": "The teacher explained that mathematically, the symbol for infinite is the sideways eight, also known as a lemniscate. ",
	"infinite_3": "She loved him with an infinite amount of passion, unlike anything she had ever experienced before.",
	"infinite_4": "The infinite possibilities of space exploration exhilarate scientists and enthusiasts alike.",
	"irrelevant_0": "His comments about my attire were completely irrelevant to our discussion about the project.",
	"irrelevant_1": "The professor mentioned that any irrelevant data should be omitted from the final report.",
	"irrelevant_2": "Her irrelevant remarks during the meeting left many attendees confused about her standpoint.",
	"irrelevant_3": "The detective found a number of clues at the crime scene, but surmised that they were mostly irrelevant to the case at hand.",
	"irrelevant_4": "His previous experience in construction is irrelevant to his current job as a software engineer.",
	"assertion_0": "His assertion about the company's success was later proven to be exaggerated.",
	"assertion_1": "She stood by her assertion that the work needed to be completed on time.",
	"assertion_2": "Despite the critics' assertions, the movie became a box office hit.",
	"assertion_3": "The philosopher's assertion that all knowledge is derived from experience sparked a great debate.",
	"assertion_4": "It is important to support your assertion with solid evidence in an essay.",
	"constituent_0": "Water has two primary constituents: hydrogen and oxygen.",
	"constituent_1": "The mayor held a town hall meeting to hear the concerns of his constituents.",
	"constituent_2": "The constitution defines the rights and responsibilities of the constituent members of the union.",
	"constituent_3": "It's crucial for each politician to fully understand the needs of their constituents in order to represent them fairly.",
	"constituent_4": "Grains, fruits, and vegetables are the essential constituents of a balanced diet.",
	"thrust_0": "With a powerful thrust, Jack drove the shovel into the ground.",
	"thrust_1": "As the race began, the runner felt the thrust of adrenaline pushing him forward.",
	"thrust_2": "The jet engine's thrust enables the plane to ascend quickly into the sky.",
	"thrust_3": "He thrust the letter into my hand and stormed off before I had the chance to say a word.",
	"thrust_4": "The boxer ended the match with a perfect thrust of his fist, knocking his opponent to the ground.",
	"electoral_0": "The electoral process for the U.S. presidency is highly complex, involving both primary and general elections.",
	"electoral_1": "Many nations around the world use an electoral system to select their leaders.",
	"electoral_2": "Critics argue that the current electoral college system in the United States is outdated and unrepresentative.",
	"electoral_3": "The electoral campaign lasted for several months, with each candidate trying to gain public approval.",
	"electoral_4": "The electoral map depicted a clear division based on political preferences across different regions.",
	"clarify_0": "I need you to clarify your instructions; I'm not completely sure I understand what you're asking.",
	"clarify_1": "The teacher took the time to clarify the complicated theory to ensure all the students fully understood.",
	"clarify_2": "Could you clarify your point-of-view on this topic? I find it a bit confusing.",
	"clarify_3": "The manual should clarify how to assemble the equipment safely and correctly.",
	"clarify_4": "The politician tried to clarify his controversial statement during a press conference.",
	"inappropriate_0": "His joke was completely inappropriate for a formal business meeting, causing some people in the room to cringe.",
	"inappropriate_1": "Despite the seriousness of this topic, your laughter is inappropriate and disrespectful.",
	"inappropriate_2": "She was reprimanded by her boss for wearing inappropriate clothing to the office.",
	"inappropriate_3": "Using your phone at the dinner table is considered inappropriate in many cultures.",
	"inappropriate_4": "His inappropriate language and behavior led to his expulsion from school.",
	"onset_0": "The onset of winter brings shorter days and colder temperatures.",
	"onset_1": "I knew something was wrong when I started feeling weakness, the first onset of the flu. ",
	"onset_2": "At the onset of the meeting, the manager laid out the goals and objectives for the team.",
	"onset_3": "The patient was treated immediately at the onset of symptoms, preventing any further complications. ",
	"onset_4": "The onset of the digital age has drastically transformed the way we communicate and do business.",
	"solidarity_0": "The employees showcased solidarity by going on strike together for fair wages.",
	"solidarity_1": "Solidarity among different cultures is crucial for preserving peace and harmony in society.",
	"solidarity_2": "The solidarity of the team during the championship made them victorious.",
	"solidarity_3": "In difficult times, our family's solidarity has always pulled us through.",
	"solidarity_4": "The protest was a clear display of solidarity against social injustice.",
	"credential_0": "To apply for the job, all relevant credentials must be submitted along with the application form.",
	"credential_1": "The doctor had impressive credentials in her field, including a specialty in cardiology.",
	"credential_2": "His fraudulent credentials were discovered during the background check.",
	"credential_3": "Upon finishing her training course, she received her credential as a certified yoga instructor.",
	"credential_4": "Always research the credentials of any investing consultant before deciding to work with them.",
	"misleading_0": "The advertisement was extremely misleading, as the actual product was nothing like what was described.",
	"misleading_1": "His words were misleading, causing her to believe he was someone he wasn't.",
	"misleading_2": "The report contains misleading information that could harm our reputation.",
	"misleading_3": "The salesman used a misleading statement to convince me to make a purchase.",
	"misleading_4": "The company was fined for publishing misleading nutritional information on their food packaging.",
	"prevailing_0": "The prevailing opinion among the scholars was that the artifact belonged to the Medieval period.",
	"prevailing_1": "Despite the odds, she managed to overcome the prevailing prejudice against women in her field.",
	"prevailing_2": "The prevailing wind in this region typically comes from the west.",
	"prevailing_3": "Understanding the prevailing attitudes towards mental health is crucial for therapists.",
	"prevailing_4": "Even after a long debate, the doctor's prevailing advice was for her to have the surgery.",
	"sophomore_0": "John is excited for his sophomore year, as he will finally be able to choose his own electives.",
	"sophomore_1": "As a sophomore, Sara is already standing out on the college tennis team.",
	"sophomore_2": "The second year of high school, known as the sophomore year, can be quite challenging for many students.",
	"sophomore_3": "In his sophomore year of college, Brian switched his major from biology to philosophy.",
	"sophomore_4": "Even though he was only a sophomore, Carlos was already starting to think about what he would write for his senior thesis.",
	"manuscript_0": "The author spent over a year meticulously writing and revising their manuscript before sending it to the publisher.",
	"manuscript_1": "The old manuscript held in the museum featured beautiful, ornate calligraphy that was hundreds of years old.",
	"manuscript_2": "Critiquing an unedited manuscript requires a critical eye and an understanding of the author's intent. ",
	"manuscript_3": "The great fire of the library resulted in the irreparable loss of countless priceless manuscripts.",
	"manuscript_4": "She received a hefty advance from the publisher who was eagerly awaiting her complete manuscript.",
	"ecology_0": "The study of ecology is important for understanding the balance of our planet's ecosystems.",
	"ecology_1": "Her passion for the environment led her to pursue a career in ecology.",
	"ecology_2": "Ecology teaches us how every living organism interacts with each other and with their environment.",
	"ecology_3": "Deforestation has a detrimental impact on the ecology by disrupting the habitats of various species.",
	"ecology_4": "There's a dire need to integrate principles of ecology in our daily lives to ensure a sustainable future.",
	"symphony_0": "The symphony's performance last night was absolutely breathtaking.",
	"symphony_1": "She closed her eyes and lost herself in the beautiful symphony playing on the radio.",
	"symphony_2": "The composer's new symphony will be debuted at the concert next month.",
	"symphony_3": "The sounds of nature created a beautiful, calming symphony that enriched her morning walk.",
	"symphony_4": "He is an accomplished violinist who has played in various symphonies around the world.",
	"parlor_0": "The Victorian-style parlor was filled with intricately carved antique furniture.",
	"parlor_1": "Many evenings, the family gathered in the parlor to play board games and enjoy each other's company. ",
	"parlor_2": "After dinner, we retired to the parlor for coffee and conversation. ",
	"parlor_3": "The parlor in the old mansion was rumored to be haunted, causing visitors to feel uneasy.",
	"parlor_4": "The ice cream parlor downtown was always packed with kids and adults alike, eager to taste the shop's unique flavors.",
	"composite_0": "The composite material used in the construction of the aircraft is both lightweight and durable.",
	"composite_1": "We decided to use a composite plan, combining both traditional and modern design elements in our home.",
	"composite_2": "In art class, we created a composite image of varying landscapes.",
	"composite_3": "The detective created a composite sketch of the suspect based on the eyewitness descriptions.",
	"composite_4": "For the new deck, we chose composite wood because of its resistance to weather and pests.",
	"beck_0": "He gestured with a quick beck of his head for me to follow him.",
	"beck_1": "His subtle beck suggested that he wanted her to come nearer.",
	"beck_2": "At the teacher's beck, the students lined up and prepared to enter the classroom.",
	"beck_3": "I responded instantly to my boss's beck, hurriedly walking over to his office door.",
	"beck_4": "The dog obediently ran over to its owner at the slightest beck.",
	"slender_0": "The young woman had a slender figure, accentuated by the fitted dress.",
	"slender_1": "In the distance, a slender tree stood alone against the horizon.",
	"slender_2": "Looking at the slender volume of poetry, he wasn't sure it was worth the high price.",
	"slender_3": "She held a slender stick, using it to draw figures in the sand.",
	"slender_4": "The necklace featured a slender silver chain, dotted with tiny diamonds.",
	"registration_0": "The deadline for conference registration is tomorrow, don't forget to sign up.",
	"registration_1": "I had to fill out several forms for the registration of my new vehicle.",
	"registration_2": "The university opens registration for new students in March every year.",
	"registration_3": "The registration process was straightforward, requiring only a few personal details and a payment method.",
	"registration_4": "To participate in the race, online registration is mandatory; no onsite registration will be available.",
	"entail_0": "Becoming a professional athlete entails practicing for several hours each day to perfect one's skills.",
	"entail_1": "Adopting a pet will entail a new set of responsibilities for the family.",
	"entail_2": "The project will entail some costs that we have not included in the initial budget.",
	"entail_3": "His new management position entailed a much heavier workload, causing him long hours at the office.",
	"entail_4": "Passing the final exams will entail lots of studying and hard work from her.",
	"buster_0": "Every time my little brother annoys me, I start calling him buster.",
	"buster_1": "The detective in the crime show was known for being a real crime buster.",
	"buster_2": "I tripped over my dog Buster's toy left in the hallway.",
	"buster_3": "Buster, my old retriever, has been my best friend for ten years.",
	"buster_4": "He's a record buster, smashing the previous high score in the game.",
	"diner_0": "Every morning, I grab coffee from the small diner around the corner from my apartment.",
	"diner_1": "Working as a waitress at a bustling diner taught me important skills about customer service.",
	"diner_2": "Despite all the fancy new restaurants in the city, my favorite place to eat is still the old-fashioned diner down the street.",
	"diner_3": "The atmosphere in the diner was cozy and inviting, with the smell of fresh coffee wafting in the air.",
	"diner_4": "The new diner in town boasts a menu of classic American dishes with a modern twist.",
	"transcend_0": "His groundbreaking research has managed to transcend the boundaries of conventional science.",
	"transcend_1": "Art has the power to transcend cultural and language barriers, connecting people across the world.",
	"transcend_2": "As her understanding of the subject deepened, she started to transcend traditional methods and develop her own theories. ",
	"transcend_3": "The selfless acts of kindness he witnessed during the crisis transcended all his previous notions about human nature.",
	"transcend_4": "To truly transcend in sports, an athlete must possess not just physical strength, but also a strong mental resolve.",
	"hurdle_0": "The next major hurdle in the project is securing adequate funding. ",
	"hurdle_1": "Mark trained hard for weeks to master the hurdle race event in the upcoming track and field competition. ",
	"hurdle_2": "Losing her job was a significant hurdle, but she remained optimistic about her future. ",
	"hurdle_3": "Overcoming the language barrier was a huge hurdle for him when he moved to a new country.",
	"hurdle_4": "The athlete gracefully leaped over each hurdle during the race, marveling spectators with his agility and speed.",
	"baptist_0": "The Baptist church on Main Street holds services every Sunday morning.",
	"baptist_1": "John the Baptist is a significant figure in Christian religion, recognized for baptizing Jesus Christ.",
	"baptist_2": "My family has been attending the local Baptist church in our town for generations.",
	"baptist_3": "Many Baptist denominations emphasize adult baptism over infant baptism.",
	"baptist_4": "The Baptist community is known for its focus on individual spiritual development and the literal interpretation of the Bible.",
	"intimidate_0": "He used his physical strength to intimidate the weaker kids at school.",
	"intimidate_1": "Her intelligence and success might intimidate some people, but it motivated me.",
	"intimidate_2": "The enormous size of the building can intimidate first-time visitors.",
	"intimidate_3": "It is not fair to intimidate someone into doing something against their will.",
	"intimidate_4": "The boss uses his position to intimidate his employees, creating a tense work environment.",
	"juvenile_0": "The juvenile turtle was not yet old enough to lay eggs.",
	"juvenile_1": "He has worked as a counselor in a juvenile detention center for over ten years.",
	"juvenile_2": "The movie's juvenile humor did not appeal to the more sophisticated adults in the audience.",
	"juvenile_3": "The juvenile justice system is designed to rehabilitate young offenders rather than punishing them harshly.",
	"juvenile_4": "The birdwatcher was excited to spot a rare juvenile hawk in the wild.",
	"slate_0": "The kitchen countertops were made of beautiful, polished slate. ",
	"slate_1": "They used a slate to write down the day's specials in the cafe.",
	"slate_2": "After switching jobs, I felt like I was starting with a clean slate.",
	"slate_3": "The teacher had a small slate on which she wrote the homework assignments.",
	"slate_4": "The candidate's slate for the upcoming election includes improvements in healthcare and education.",
	"deter_0": "The police presence was intended to deter potential criminals from committing offenses. ",
	"deter_1": "My fear of heights will never deter me from my dream of mountain climbing.",
	"deter_2": "Strict laws and hefty fines deter citizens from breaking traffic rules. ",
	"deter_3": "The threat of punishment did not deter the young boys from sneaking out at night.",
	"deter_4": "Nothing could deter her from completing her degree, not even her challenging work hours.",
	"paradox_0": "The contradiction between his two statements created a paradox that left everyone confused.",
	"paradox_1": "She was stuck in a paradox, knowing that lying was wrong but understanding it was necessary in this situation.",
	"paradox_2": "The 'Paradox of Plenty' suggests that countries with an abundance of natural resources often struggle with economic growth and stability.",
	"paradox_3": "His perpetual tardiness for time management seminars was an ironic and frustrating paradox.",
	"paradox_4": "The age-old paradox, What happens if an unstoppable force meets an immovable object?, has puzzled philosophers for centuries.",
	"provincial_0": "Kelly's provincial attitude towards foreign cuisines often led her to choose local dishes over exotic ones. ",
	"provincial_1": "Despite living in a big city for many years, he still has a provincial mindset. ",
	"provincial_2": "The provincial government is taking steps to improve the education system in rural areas. ",
	"provincial_3": "As a person who grew up in a small village, I had a quite provincial outlook on life until I started travelling.",
	"provincial_4": "Her provincial lifestyle changed dramatically when she moved to New York for her job.",
	"rebellion_0": "The government quelled the rebellion by deploying the military to the troubled region.",
	"rebellion_1": "History books describe the American Revolution as a prominent example of rebellion against oppressive rule.",
	"rebellion_2": "The teenager's rebellion against her parents emerged as a refusal to adhere to their strict curfew.",
	"rebellion_3": "Rebellion in literature often portrays characters breaking societal norms or standing up against injustice.",
	"rebellion_4": "The rebellion in the city culminated in massive protests and civil disturbances.",
	"undo_0": "His hasty actions on the project were so detrimental that it took us weeks to undo the damage.",
	"undo_1": "She quickly realized she had made a mistake on her computer and used the undo button to correct it.",
	"undo_2": "When she saw how upset he was after their argument, she wished she could undo her hurtful words.",
	"undo_3": "Samantha spent hours painting the house, only to have her work undone by a sudden rainstorm.",
	"undo_4": "The magician made a big show about how he could undo the ties on the ropes, no matter how tightly bound.",
	"definitive_0": "The final agreement provided a definitive resolution to their ongoing dispute.",
	"definitive_1": "Her expression was definitive; she was not going to change her mind.",
	"definitive_2": "The doctor's diagnosis was definitive, leaving no room for doubt or confusion.",
	"definitive_3": "They are considered the definitive source for ancient history due to their extensive research and accuracy.",
	"definitive_4": "The author's latest work is seen as the definitive guide to sustainable living.",
	"mentality_0": "His positive mentality helped him to overcome every hurdle that came his way.",
	"mentality_1": "Her narrow-minded mentality made it difficult for her to accept new ideas.",
	"mentality_2": "Joe's business-oriented mentality served him well when he started his own company.",
	"mentality_3": "The coach instilled in us a winning mentality; we believed we could overcome any challenge.",
	"mentality_4": "Tom has a very inclusive mentality; he believes that diversity adds value to our society.",
	"dictator_0": "The dictator ruled with an iron fist, showing no mercy or compassion for his subjects.",
	"dictator_1": "The citizens lived in fear under the brutal regime of the dictator.",
	"dictator_2": "North Korea's dictator consistently makes global headlines due to his militaristic and aggressive policies.",
	"dictator_3": "Even though he was a notorious dictator, some citizens saw his reign as a period of stability.",
	"dictator_4": "The dictator's propaganda machine was so powerful that many people didn't realize the extent of his despotism.",
	"static_0": "The television was showing only static, indicating that the cable connection was lost.",
	"static_1": "My hair stood on end due to the static electricity in the room.",
	"static_2": "In the business world, technology is rapidly advancing, but their strategies seem static and outdated.",
	"static_3": "She could barely hear her friend's voice over the static crackling on her old radio.",
	"static_4": "While adjusting the antenna, he was able to reduce the static and get a clearer picture.",
	"correspondence_0": "The two events share a direct correspondence with each other, meaning they are closely related. ",
	"correspondence_1": "I spent the morning sorting through a pile of correspondence from my fans. ",
	"correspondence_2": "During her research, she found an interesting correspondence between the novelist and his editor. ",
	"correspondence_3": "There is a clear correspondence between diet and health; good food generally leads to good health.",
	"correspondence_4": "The employee was responsible for maintaining correspondence with clients on behalf of the company.",
	"orient_0": "I had to orient myself in the new city before starting my job.",
	"orient_1": "The tour guide helped the group to orient themselves in the sprawling museum.",
	"orient_2": "Excel will automatically orient the text in the direction that you specify.",
	"orient_3": "The orient phase of his design model involved researching the client's business thoroughly.",
	"orient_4": "As a part of their survival training, they learned how to orient themselves using only the stars for navigation.",
	"oblige_0": "I feel obliged to help my sister with her homework because she always helps me with mine.",
	"oblige_1": "The company are legally obliged to disclose this information to their clients.",
	"oblige_2": "She obliged me by giving a ride to the airport when my car broke down.",
	"oblige_3": "The teacher obliged the students to turn in their assignments by Friday.",
	"oblige_4": "Although it was out of his way, he obliged and drove her home.",
	"holocaust_0": "The Holocaust was a tragic genocide during World War II where millions of Jews were systematically exterminated by Nazi Germany.",
	"holocaust_1": "Many films, books, and documentaries have been created to educate people about the horrors of the Holocaust.",
	"holocaust_2": "Visiting the Holocaust Memorial Museum was a sobering experience that deeply moved me.",
	"holocaust_3": "Understanding the Holocaust is crucial to ensure such atrocities are never repeated in human history.",
	"holocaust_4": "The survivors of the Holocaust have shared their heartrending stories to raise awareness and teach the younger generations about the devastating impacts of hatred and prejudice.",
	"don_0": "Don is an old friend who I haven't seen in years.",
	"don_1": "Every morning, I don my uniform and head out for work.",
	"don_2": "Don Julio is a famous brand of tequila.",
	"don_3": "In the mafia world, the title 'Don' is given to the most respected leader.",
	"don_4": "He decided to don his raincoat due to the bad weather.",
	"pervasive_0": "The pervasive scent of her perfume lingered in the room, long after she had left.",
	"pervasive_1": "Social media has become a pervasive part of our daily lives, influencing the way we communicate, work, and play.",
	"pervasive_2": "Corruption has been a pervasive problem in our society for far too long.",
	"pervasive_3": "The pervasive influence of technology can be seen in every aspect of our lives, from transportation to healthcare.",
	"pervasive_4": "Her pervasive optimism, even in the face of adversity, was truly inspiring.",
	"arsenal_0": "The military showed us their impressive arsenal of weapons during our tour at the base.",
	"arsenal_1": "The police force has recently updated their arsenal to combat organized crime.",
	"arsenal_2": "As a carpenter, his garage is full of an extensive arsenal of tools and equipment.",
	"arsenal_3": "The hacker's arsenal included advanced software and hardware devices for breaching security measures.",
	"arsenal_4": "The government is committed to dismantling the rogue state's nuclear arsenal to maintain world peace.",
	"reconcile_0": "After years of disagreement, the two brothers finally managed to reconcile and mend their relationship. ",
	"reconcile_1": "The therapist suggested ways that the couple could reconcile their differences and rebuild their marriage. ",
	"reconcile_2": "It can be difficult to reconcile conflicting evidence when investigating a complex case. ",
	"reconcile_3": "She tried to reconcile her career ambitions with her desire to spend more time with her family. ",
	"reconcile_4": "The finance team had to reconcile the discrepancies between the company\u2019s accounting records and bank statements.",
	"pluck_0": "He managed to pluck the last apple from the tall tree in the garden.",
	"pluck_1": "She showed great pluck when she faced the problems that arose during her business expansion.",
	"pluck_2": "John needed all his pluck to walk into the interview room, ignoring the team of executives seated there.",
	"pluck_3": "The hen plucked feathers from its back to line the nest for its eggs. ",
	"pluck_4": "With a soft pluck, she removed the strings from the old piece of clothing.",
	"entitlement_0": "Some people have a sense of entitlement, thinking they deserve certain privileges without having to work for them.",
	"entitlement_1": "The welfare benefits program provides entitlements to those who need financial aid.",
	"entitlement_2": "His entitlement attitude at work was causing friction with his colleagues.",
	"entitlement_3": "The government has an obligation to fulfill its entitlement promises to the elderly.",
	"entitlement_4": "Her entitlement was apparent when she expected to be promoted without putting in any extra effort.",
	"rivalry_0": "The intense rivalry between the two football teams always attracts a large crowd.",
	"rivalry_1": "Despite the rivalry between the two companies, they both agreed to participate in a charity event together.",
	"rivalry_2": "The sibling rivalry between the two sisters was well known throughout the family.",
	"rivalry_3": "There was a strong sense of rivalry between the two celebrities for the award.",
	"rivalry_4": "Their professional rivalry was frequently discussed in the hallways of their corporation.",
	"revise_0": "In order to improve my grade, I need to revise my paper before submitting it again.",
	"revise_1": "After the feedback from my colleagues, I had to revise several sections of my presentation.",
	"revise_2": "Reading through my novel, I realized that major characters lacked depth, and decided to revise it.",
	"revise_3": "The teacher suggested that she revise her essay to include more supporting details.",
	"revise_4": "They agreed to revise the budget to accommodate unexpected expenses.",
	"liquor_0": "He decided to quit drinking liquor for his health's sake.",
	"liquor_1": "She went to the liquor store to buy a bottle of red wine for the dinner party.",
	"liquor_2": "After the long day, he poured himself a glass of his favorite liquor to relax. ",
	"liquor_3": "He had no liquor license yet, so he could not legally sell alcohol in his restaurant.",
	"liquor_4": "Too much consumption of liquor can lead to severe health issues.",
	"adolescence_0": "During adolescence, young people often experience a variety of physical and emotional changes.",
	"adolescence_1": "It is important to teach about mental health during adolescence, a stage when many mental illnesses can first emerge.",
	"adolescence_2": "Adolescence can be a challenging time, as teenagers struggle to form their own identities and desire independence. ",
	"adolescence_3": "Sarah's adolescence was filled with exploration and discovery, setting the foundation for her career in scientific research.",
	"adolescence_4": "Many major life decisions, such as choosing a career path or mulling over educational opportunities, occur during adolescence.",
	"predominantly_0": "The region is predominantly agricultural, with vast fields of corn stretching as far as the eye can see.",
	"predominantly_1": "The population in this town is predominantly made up of seniors.",
	"predominantly_2": "She uses her right hand predominantly, although she can use both.",
	"predominantly_3": "The university is predominantly focused on research rather than teaching.",
	"predominantly_4": "The conference predominantly revolved around environmental issues, with only a few discussions on other topics.",
	"prestige_0": "The prestigious Ivy League universities have long been associated with academic excellence and prestige.",
	"prestige_1": "Following the success of her previous books, she has acquired significant prestige in the literary world. ",
	"prestige_2": "The prestige of owning a luxury car appealed to him more than the actual performance of the vehicle.",
	"prestige_3": "The Nobel Peace Prize carries a tremendous amount of prestige, reflecting the significant contributions made by the recipient.",
	"prestige_4": "Despite facing challenging times, the company has managed to maintain its prestige and customer base.",
	"systematic_0": "The company adopted a more systematic approach to collecting customer feedback.",
	"systematic_1": "A systematic research method made it easier to collect and analyze data.",
	"systematic_2": "For good results, it's important to carry out a systematic review of the literature.",
	"systematic_3": "She approached her studies with a systematic ordering of her time and resources.",
	"systematic_4": "Their systematic oppression led to the uprising of the local populace.",
	"melody_0": "The sweet melody of the violin filled the concert hall, captivating the audience.",
	"melody_1": "The birds outside her window sang a beautiful melody that brightened her mornings.",
	"melody_2": "I was having trouble remembering the melody of the song we used to sing together.",
	"melody_3": "Each melody he composed was unique, reflecting his vast imagination and talent.",
	"melody_4": "The melody of the lullaby soothed the baby, putting him to sleep in no time.",
	"boulevard_0": "We took a leisurely stroll down the palm-tree-lined boulevard, soaking up the summer sun.",
	"boulevard_1": "She lived in a charming old apartment on a busy boulevard in downtown Paris.",
	"boulevard_2": "The picturesque boulevard was filled with quaint cafes and boutique stores.",
	"boulevard_3": "The city council has decided to plant more trees along the main boulevard to provide shade and improve air quality.",
	"boulevard_4": "After dark, the boulevard came to life with vivid neon lights from bars and late-night eateries.",
	"flawed_0": "Despite its flawed execution, the concept behind the project was ingenious.",
	"flawed_1": "His character is deeply flawed, but that's what makes him interesting.",
	"flawed_2": "The experiment was flawed because it didn't account for all the variables.",
	"flawed_3": "She was acutely aware that her plan was flawed, yet she decided to proceed anyway.",
	"flawed_4": "Critics argued that the tax system was deeply flawed and in urgent need of reform.",
	"ditch_0": "In trying to avoid hitting the animal, the driver swerved and ended up in a ditch along the side of the road. ",
	"ditch_1": "After the rainstorm, the irrigation ditches were full of rushing water. ",
	"ditch_2": "He decided to ditch his old car and buy a new one. ",
	"ditch_3": "Sally was upset when her friends decided to ditch her at the mall. ",
	"ditch_4": "When he saw his teacher approaching, he quickly ditched the cigarette.",
	"stall_0": "The farmer led the horse back to its stall after a day of work in the field.",
	"stall_1": "Due to intense turbulence, the plane's engine started to stall.",
	"stall_2": "The project found itself in a stall because of disagreements among the members of the team.",
	"stall_3": "Mary loved going to the farmer's market to check out the various fruit and vegetable stalls. ",
	"stall_4": "Seeing his friend enter the room, he tried to stall his reaction to the surprise party by pretending to look for his phone.",
	"ensemble_0": "The ensemble of instruments in the orchestra created a symphony that enthralled the audience.",
	"ensemble_1": "Mary carefully selected her outfit, making sure the ensemble was perfect for the formal event.",
	"ensemble_2": "The ensemble cast of the new Broadway play includes many famous actors.",
	"ensemble_3": "It was an incredible ensemble, from the talented dancers to the skilled musicians, all coming together to create a beautiful performance.",
	"ensemble_4": "To create an aesthetically pleasing living room, you need to make sure the ensemble of furniture and decorations match each other well.",
	"expire_0": "The milk will expire in a week, so make sure to use it before that date. ",
	"expire_1": "He suddenly realized his passport had expired a month prior to his upcoming trip. ",
	"expire_2": "My car insurance policy will expire at the end of this month, and I need to renew it. ",
	"expire_3": "Due to the lockdown, the government extended the expire date of all driving licenses. ",
	"expire_4": "If you do not use the voucher by the end of the year, it will expire and you won't be able to redeem it.",
	"startle_0": "The sudden roar of the lion in the zoo managed to startle all the visitors nearby.",
	"startle_1": "I hoped not to startle her, but she jumped when I touched her shoulder.",
	"startle_2": "The loud clap of thunder startle us in the middle of the quiet night.",
	"startle_3": "His unexpected arrival at the party managed to startle everyone.",
	"startle_4": "The popping balloon had the ability to startle the calmest person in the room.",
	"all-star_0": "The NBA All-Star game brings together the best players from different teams for an exciting showdown.",
	"all-star_1": "Many athletes dream of becoming all-stars, coveted for their exceptional skills and performances.",
	"all-star_2": "She was designated an all-star pitcher after her outstanding performance in the last season.",
	"all-star_3": "The all-star cast of the movie ensured that it was a major hit in the box office.",
	"all-star_4": "His all-star performance at the concert left the audience in awe and solidified his reputation as a world-class musician.",
	"warrant_0": "The police obtained a warrant to search the suspect's house for evidence.",
	"warrant_1": "His disregard for safety rules gave the school enough warrant to expel him.",
	"warrant_2": "The CEO\u2019s frequent outbursts were enough warrant for his dismissal from the company.",
	"warrant_3": "You will need a warrant to seize any property from the building.",
	"warrant_4": "Without proper warrant, we cannot proceed with the arrest.",
	"demise_0": "After the king's sudden demise, the entire kingdom went into deep mourning.",
	"demise_1": "The demise of the company was caused by the reckless decisions of its management.",
	"demise_2": "It was devastating to hear about the singer's sudden demise in the news.",
	"demise_3": "The rapid demise of the rainforests has triggered worldwide concern for global warming.",
	"demise_4": "The unwanted demise of his political career hit him very hard.",
	"diagnostic_0": "The doctor ordered a comprehensive diagnostic test to better understand the symptoms Sandra was experiencing.",
	"diagnostic_1": "After a thorough diagnostic analysis, they finally found the issue that was causing the problem with the car.",
	"diagnostic_2": "Three different diagnostic studies confirmed that he had a serious heart condition.",
	"diagnostic_3": "To provide an accurate quote for the repair, the mechanic first performed a complete diagnostic on the vehicle.",
	"diagnostic_4": "With the latest diagnostic technology, we can rapidly identify what kind of treatment a patient requires.",
	"register_0": "I need to register for the upcoming seminar on educational technology.",
	"register_1": "The cashier at the main register was friendly and fast.",
	"register_2": "Could you please register our team for the charity run event?",
	"register_3": "The musical piece was too high for him to sing, as it went above his comfortable vocal register. ",
	"register_4": "The shocked expression didn't even register on her face when she heard the news.",
	"steroids_0": "After months of speculation, the athlete finally admitted to using steroids to enhance his performance.",
	"steroids_1": "The long-term use of steroids can lead to serious health complications.",
	"steroids_2": "The doctor prescribed mild steroids to help with my allergic reaction.",
	"steroids_3": "Many professional sports organizations strictly prohibit the use of steroids and regularly test athletes for them.",
	"steroids_4": "Despite the benefits of steroids in treating illness, their misuse can cause harm to the body.",
	"maternal_0": "Her maternal instincts kicked in when she heard the child crying in distress.",
	"maternal_1": "He was visiting his maternal grandmother's house on the weekend.",
	"maternal_2": "The doctor asked for her maternal family history to assess genetic risks.",
	"maternal_3": "The bird exhibited strong maternal behavior, protecting its nest fiercely.",
	"maternal_4": "She had a caring and maternal nature, always looking after those around her.",
	"slack_0": "My boss was getting frustrated with me for letting my performance slack off the last few weeks. ",
	"slack_1": "The rope had a lot of slack, so it wasn't as secure as we needed it to be. ",
	"slack_2": "Could you cut me some slack? I've been under a lot of stress lately.",
	"slack_3": "If you pull the cable too tight, there will be no slack left to adjust the position of the equipment.",
	"slack_4": "The sales team has been slacking off recently, their productivity rate has considerably dropped.",
	"recite_0": "The young boy was asked to recite the national anthem in front of the school assembly.",
	"recite_1": "As part of their practice, monks recite ancient chants every morning.",
	"recite_2": "Cynthia nervously prepared to recite her speech to the packed auditorium.",
	"recite_3": "Every night before bed, the little girl would recite her prayers with her grandmother.",
	"recite_4": "The teacher asked the class to recite the multiplication table up to 10 during the math lesson.",
	"migrant_0": "The family were classified as migrants after they moved from their rural home to the capital city for better opportunities. ",
	"migrant_1": "The small town was witnessing an influx of migrants from various parts of the country who were looking for work. ",
	"migrant_2": "The difficulties faced by a migrant, including language barriers and cultural differences, can be overwhelming and challenging.",
	"migrant_3": "The country has introduced new supportive policies to aid in the integration of migrants into society. ",
	"migrant_4": "His research focused on analyzing the resilience and entrepreneurial spirit of migrants in metropolitan cities.",
	"verge_0": "We are on the verge of making a breakthrough in our research. ",
	"verge_1": "He was on the verge of tears as he listened to the heartbreaking news. ",
	"verge_2": "The car swerved sharply, almost going off the verge of the road. ",
	"verge_3": "As the deadline loomed closer, Susan was on the verge of a panic attack.",
	"verge_4": "The country is on the verge of an economic crisis, due to the high unemployment rates.",
	"proximity_0": "The proximity of the new hospital to her home made it easy for her to attend her regular check-ups.",
	"proximity_1": "The two best friends enjoyed the proximity of their houses, enabling them to see each other every day.",
	"proximity_2": "Due to the proximity of the fire, the campers had to quickly move their campsite. ",
	"proximity_3": "Property prices increase when they are in close proximity to good schools and accessible transportation.",
	"proximity_4": "Our offices are located in close proximity to the city center making it convenient for everyone to commute.",
	"underscore_0": "The director's stylistic choices really underscore the themes of loss and redemption throughout the film.",
	"underscore_1": "The recent incident only underscores the need for improved health and safety measures at work.",
	"underscore_2": "She used a marker to underscore the significant points in the article.",
	"underscore_3": "The violent storm underscores the urgency of addressing climate change.",
	"underscore_4": "His passionate speech underscored his commitment to justice and equality.",
	"presume_0": "I didn't hear from her as expected, so I presume she must be busy.",
	"presume_1": "After reading the instructions carefully, I presume I understand how to assemble this furniture.",
	"presume_2": "Since the windows were all closed, she presumed that no one was at home.",
	"presume_3": "Don't presume you know what I'm thinking before I've even spoken.",
	"presume_4": "Based on his enthusiastic response, we presume that he likes the gift.",
	"manifest_0": "His happiness was manifest when he heard the news about his promotion.",
	"manifest_1": "The true nature of the criminal was finally made manifest during the trial.",
	"manifest_2": "The symptoms of the disease began to manifest two weeks after exposure.",
	"manifest_3": "The shipping company uses a manifest to keep track of all the cargo on board.",
	"manifest_4": "He has the ability to manifest his dreams into reality through hard work and determination.",
	"communal_0": "The communal garden in the center of the neighborhood was tended by all the residents, fostering a sense of community and cooperation.",
	"communal_1": "The incident sparked a communal violence which lasted for several days in the small town.  ",
	"communal_2": "Sarah enjoyed staying at hostels because of the communal atmosphere and opportunities to meet travelers from around the world. ",
	"communal_3": "The villagers are planning to have a communal feast to celebrate the harvest season. ",
	"communal_4": "The communal housing project provides affordable homes, shared community spaces and resources for the less fortunate.",
	"justification_0": "Without any evidence or justification, blaming someone for a crime is not fair.",
	"justification_1": "The manager demanded a detailed justification for the project's increased budget.",
	"justification_2": "His late arrival required some sort of justification to avoid punishment.",
	"justification_3": "The unethical treatment of animals in labs cannot find any justification in the name of scientific research.",
	"justification_4": "Sarah worked late into the night, crafting a detailed justification for her proposal.",
	"prohibition_0": "Because of the prohibition on alcohol during the 1920s, a rise in illegal activities like bootlegging and speakeasies occurred.",
	"prohibition_1": "The prohibition against smoking in public places is strictly enforced in many cities around the world. ",
	"prohibition_2": "Despite the prohibition on illegal drugs, drug use remains a serious social issue in many countries. ",
	"prohibition_3": "The government's prohibition on cutting down trees in the national park was welcomed by environmentalists. ",
	"prohibition_4": "Prohibition in the United States ended in 1933 with the ratification of the 21st Amendment, which legalized the production and consumption of alcohol.",
	"sway_0": "The strong winds caused the tall trees to sway back and forth.",
	"sway_1": "Her incredible charisma had a great sway over the crowd, they hung on her every word. ",
	"sway_2": "He tried to sway the jury with his emotional testimony.",
	"sway_3": "The rhythm of the music made her sway in time, utterly entranced.",
	"sway_4": "Despite his initial reservations, she managed to sway his opinion with her persuasive argument.",
	"lesbian_0": "Jenny came out to her friends and family as a lesbian when she was in college.",
	"lesbian_1": "From arts to politics, lesbian women have made significant contributions to society.",
	"lesbian_2": "Representation matters; it's important that media portrays lesbian relationships in a respectful and accurate manner.",
	"lesbian_3": "The city's pride parade was flooded with not only gay men but also lesbian couples showing their unity and love.",
	"lesbian_4": "The movie is about a lesbian couple's struggle for acceptance in a small conservative town.",
	"documentation_0": "The department was asked to provide all the necessary documentation for the audit next week.",
	"documentation_1": "Without proper documentation, they won't let you cross the border.",
	"documentation_2": "I spent hours looking through historical documentation for my research paper.",
	"documentation_3": "Before you start the software, please read the documentation carefully.",
	"documentation_4": "We were unable to proceed with the transaction due to a lack of documentation.",
	"chad_0": "After the election, the ground was littered with chad from countless punched ballots. ",
	"chad_1": "The missing chad from the ballot paper sparked a controversy about the results of the election.",
	"chad_2": "Investigators found a chad that had not been entirely punched through, leaving the voter's choice unclear. ",
	"chad_3": "I asked Chad, my new coworker, about his previous job experience. ",
	"chad_4": "Chad is a country in Central Africa with a rich cultural history.",
	"privileged_0": "As a privileged child, he never learned the value of hard work.",
	"privileged_1": "He comes from a privileged background, his family being one of the wealthiest in town.",
	"privileged_2": "I feel privileged to have received such a prestigious award in the field of literature.",
	"privileged_3": "The information she shared with me was privileged, managed to stay confidential.",
	"privileged_4": "Despite his privileged upbringing, he decided to donate the bulk of his wealth to charities.",
	"delegation_0": "The delegation from France arrived promptly for the international conference.",
	"delegation_1": "As a part of her job, Michelle learned the art of delegation to effectively manage her team.",
	"delegation_2": "The president sent a delegation to the Middle East to negotiate a peace treaty.",
	"delegation_3": "Our company's delegation attended the trade fair, showcasing our latest products. ",
	"delegation_4": "In order to save time and increase productivity, John improved his delegation skills, sharing tasks with his colleagues.",
	"appropriation_0": "The appropriation of funds for the new school building was approved by the board after a lengthy discussion.",
	"appropriation_1": "The artist faced backlash for his cultural appropriation of Native American symbols in his latest work.",
	"appropriation_2": "The designer was accused of appropriation when she used traditional African patterns in her runway collection without any acknowledgment.",
	"appropriation_3": "The committee is in charge of the appropriation of funds for different developmental projects in the city.",
	"appropriation_4": "There was a heated debate in the parliament over the appropriation of military budgets.",
	"weary_0": "After a long day at work, she felt exceedingly weary and longed for a hot bath and a good night's sleep. ",
	"weary_1": "The long journey had made him so weary that he fell asleep as soon as he sat down.",
	"weary_2": "He gave a weary sigh, shrugging off his heavy backpack and sinking onto the sofa.",
	"weary_3": "The constant noise in the city began to make her weary, and she yearished for the peace and quiet of the countryside. ",
	"weary_4": "I could see the weariness in her eyes, a clear sign of the stress she was under.",
	"petty_0": "His constant complaints about the color of his office walls seemed petty and unnecessary.",
	"petty_1": "Her petty behavior at the party last night was entirely uncalled for.",
	"petty_2": "They had been best friends for years, but they allowed a petty misunderstanding to ruin their friendship.",
	"petty_3": "The boss was known to make petty rules that did little but annoy his employees.",
	"petty_4": "The court does not have time to deal with such petty issues.",
	"re_0": "I need to review and revise the report before our meeting, re focusing on the conclusions and recommendations.",
	"re_1": "Re. your email, I am currently examining the details you have provided and will respond soon.",
	"re_2": "She had to reapply her makeup after the sudden rain shower.",
	"re_3": "He chose to reinterpret the painting in a more modern style.",
	"re_4": "Re the issues I brought up last week, do we have any updates on the mitigation plans?",
	"aurora_0": "After a week of waiting, we finally witnessed the mesmerizing sight of the aurora borealis lighting up the night sky.",
	"aurora_1": "From her small cabin in Alaska, Becca could see the aurora almost every night during the winter months. ",
	"aurora_2": "The vivid greens and purples of the aurora were reflecting in the still water of the lake, creating a beautiful mirror image.",
	"aurora_3": "The best places to see the aurora australis, also known as the southern lights, are Antarctica, New Zealand, and parts of South America. ",
	"aurora_4": "Janet always had a dream to photograph the aurora borealis in its full glory.",
	"coordination_0": "The smooth coordination between the marketing and sales teams led to a successful product launch.",
	"coordination_1": "His swift response during the emergency showed his exceptional coordination and decision-making skills.",
	"coordination_2": "A soccer game requires high levels of physical and mental coordination between the players.",
	"coordination_3": "The concert was a success thanks to the meticulous coordination of the event organizer.",
	"coordination_4": "The coordination of colors in her outfit gives her a distinctive and fashionable look.",
	"contempt_0": "His cheating led to her complete contempt for him.",
	"contempt_1": "The spoiled brat looked at the hand-me-down clothes with contempt, refusing to wear them. ",
	"contempt_2": "Seeing the country's leaders bicker like children filled the citizens with contempt.",
	"contempt_3": "The corrupt politician was held in contempt by the public for his excessive misuse of power.",
	"contempt_4": "After years of abuse, the employee's contempt for his boss made him quit his job without a backward glance.",
	"prosecute_0": "If they catch the person who committed the crime, they will surely prosecute them.",
	"prosecute_1": "The district attorney decided not to prosecute the case due to lack of evidence.",
	"prosecute_2": "The government vowed to vigorously prosecute corporations that violate environmental regulations.",
	"prosecute_3": "She hired an experienced lawyer who would be able to prosecute her claims successfully.",
	"prosecute_4": "Unfortunately, the victim was too scared to testify, making it difficult to prosecute the crime.",
	"accountable_0": "As a manager, John is accountable for the performance of his team.",
	"accountable_1": "The government must be accountable for its actions and decisions to its citizens.",
	"accountable_2": "The accountant is accountable for making sure the company's financial records are accurate.",
	"accountable_3": "As a teacher, I am accountable for the academic success of my students.",
	"accountable_4": "No one in the organization was willing to be accountable for the project's failure.",
	"immunity_0": "The vaccine works by stimulating the body to produce its own immunity against the disease.",
	"immunity_1": "Some people have natural immunity to certain viruses.",
	"immunity_2": "Regular exercise can boost your body's immunity, reducing the likelihood of getting sick.",
	"immunity_3": "Diplomats are traditionally granted immunity from prosecution in the country where they are working. ",
	"immunity_4": "The immunity granted to the whistleblower protected them from potential retaliatory lawsuits.",
	"diplomacy_0": "In difficult negotiations, good diplomacy is the key to finding a compromise that all parties can accept.",
	"diplomacy_1": "His skills in diplomacy have earned him a reputation as a peacemaker among his peers.",
	"diplomacy_2": "The secretary of state has extensive experience in international diplomacy from his previous role as ambassador.",
	"diplomacy_3": "Multi-lateral diplomacy was conducted to resolve the conflicts between the adjoining nations.",
	"diplomacy_4": "Diplomacy is crucial in preventing tensions between countries from escalating into military confrontations.",
	"fairness_0": "Susan insisted on fairness in every situation, no matter how trivial it seemed.",
	"fairness_1": "The coach demonstrated fairness by making sure all players had equal playing time.",
	"fairness_2": "The notion of fairness is inherent in the legal system, yet not always achieved.",
	"fairness_3": "Fairness in the workplace encourages a harmonious and productive environment.",
	"fairness_4": "The children learned a valuable lesson in fairness when they were asked to divide the candy equally among themselves.",
	"brace_0": "After the car accident, Sally had to wear a brace on her neck to aid in recovery.",
	"brace_1": "John tightened the brace on his knee before starting the race.",
	"brace_2": "The engineer said that we need to install a brace to support the weight of the new roof.",
	"brace_3": "The soldier had to brace himself against the strong winds during the storm.",
	"brace_4": "James just bought a brace of pheasants at the local market.",
	"broadly_0": "She smiled broadly when she saw her surprise birthday party. ",
	"broadly_1": "The professor broadly summarized the topics that would be covered in the lecture.",
	"broadly_2": "His work is broadly respected in the field of economics.",
	"broadly_3": "The scope of the project is broadly defined, giving us some room for creativity.",
	"broadly_4": "The mayor broadly outlined his plan for improving the city's infrastructure.",
	"reflective_0": "John is very reflective, often spending hours considering his actions and their consequences.",
	"reflective_1": "The reflective material on the back of my bicycle helps make me visible to cars at night.",
	"reflective_2": "After reading the book, Susan sat in a reflective mood, mulling over the complex themes presented.",
	"reflective_3": "When the flashlight hit the mirror, the reflective surface bounced the light back, projecting it across the room.",
	"reflective_4": "That reflective essay gave me a better understanding of how the writer views the world.",
	"commuter_0": "Every morning, the commuter train was packed with people traveling to their jobs in the heart of the city. ",
	"commuter_1": "As a commuter, Jane had to wake up early to avoid the rush hour traffic. ",
	"commuter_2": "The commuter pass saved him a lot of money in comparison to buying a ticket daily. ",
	"commuter_3": "The station was bustling with commuters in suits, rushing to catch their trains. ",
	"commuter_4": "Despite being a regular commuter, Jake always carried a map of the subway to navigate through unexpected route closures or delays.",
	"problematic_0": "Her constant lateness to work has become problematic for the entire team.",
	"problematic_1": "The car's engine has been problematic since the day we bought it. ",
	"problematic_2": "The new software update is proving to be problematic, causing the system to crash frequently.",
	"problematic_3": "His tendency to ignore instructions made cooperation with other team members problematic.",
	"problematic_4": "The severe weather conditions are problematic for our planned outdoor event.",
	"ace_0": "Jack hit an ace in the tennis match, which took the whole crowd by surprise.",
	"ace_1": "As an ace pilot, Mary had to undertake some of the most dangerous missions.",
	"ace_2": "Franklin managed to ace his final exam, despite his lack of preparation.",
	"ace_3": "He pulled an ace from the deck of cards, much to everyone's surprise.",
	"ace_4": "Sheila has always been an ace in our organization, handling multiple projects with expertise.",
	"prevalent_0": "The use of reusable shopping bags is becoming more prevalent in order to reduce plastic waste.",
	"prevalent_1": "In low-income communities, malnutrition is quite prevalent among the children.",
	"prevalent_2": "Texting has become a prevalent form of communication among teenagers.",
	"prevalent_3": "Despite advances in medical technology, malaria remains a prevalent disease in many tropical regions.",
	"prevalent_4": "Misinformation and fake news are unfortunately prevalent on social media platforms.",
	"clutch_0": "In the final seconds of the game, the basketball player made a clutch shot, securing his team's victory.",
	"clutch_1": "She clutched her purse tightly as she walked through the dark alley.",
	"clutch_2": "The mechanic discovered that there was a problem with the car's clutch, preventing it from shifting gears properly.",
	"clutch_3": "The mother hen was fiercely protective of her clutch of eggs, not letting anyone approach them. ",
	"clutch_4": "Despite his injuries, the quarterback managed a clutch performance, leading his team to the championships.",
	"freeway_0": "Due to heavy traffic, it took two hours for me to reach home from work even though it's just a thirty-minute drive on the freeway.",
	"freeway_1": "He missed the last exit off the freeway and had to drive an extra twenty miles before the next one appeared.",
	"freeway_2": "They are planning to construct a new freeway to reduce traffic congestion in the city.",
	"freeway_3": "The police set up a checkpoint on the freeway to nab the criminals.",
	"freeway_4": "On long drives, I prefer to take the freeway instead of the scenic route because it cuts my travel time significantly.",
	"battered_0": "The old house on the corner looked battered after years of neglect and weather damage.",
	"battered_1": "She handed me a battered book, filled with notes and annotations from previous readers. ",
	"battered_2": "The storm had left the coastline battered, with many homes and businesses completely destroyed.",
	"battered_3": "The old, battered suitcase had seen countless journeys but still held together through the wear and tear. ",
	"battered_4": "His battered body was a testament to his will, enduring years of rigorous sports with passion and unwavering dedication.",
	"utter_0": "After hearing the unexpected news, he was in utter shock.",
	"utter_1": "His statement is an utter lie, I won't believe a word.",
	"utter_2": "Her room's utter mess came as no surprise, considering how busy she had been.",
	"utter_3": "The match ended in utter chaos, with fans swarming the field.",
	"utter_4": "He was in utter disbelief over his luck, having won the lottery.",
	"discharge_0": "The doctor said that if his condition continues to improve, he could be discharged from the hospital by the end of the week.",
	"discharge_1": "The discharge of harmful chemicals into the river has seriously affected the local ecosystem.",
	"discharge_2": "After violating the company's privacy policy, the employee was discharged immediately.",
	"discharge_3": "The discharge of his duties was impressive, earning him a promotion within a few months.",
	"discharge_4": "Smoke discharge from factories contributes to air pollution.",
	"trajectory_0": "The rocket followed a curved trajectory as it shot up into the sky.",
	"trajectory_1": "A baseball player can change the trajectory of the ball by adjusting the angle of his swing.",
	"trajectory_2": "His career was on a promising trajectory after receiving the promotion.",
	"trajectory_3": "The physics teacher asked the students to calculate the trajectory of a bullet fired at a certain angle.",
	"trajectory_4": "Astronomers spend a lot of time charting the trajectory of celestial bodies.",
	"windshield_0": "Despite the rain beating down on the windshield, he could just make out the outline of the truck ahead.",
	"windshield_1": "The sudden impact of the baseball cracked the windshield of my car.",
	"windshield_2": "The sunrise reflected beautifully on the car's windshield, almost making the early morning drive worth it.",
	"windshield_3": "He used the windshield wipers to clear the accumulating snow.",
	"windshield_4": "The rock had flown up and made a tiny chip in the corner of the windshield.",
	"raider_0": "The raider stealthily moved through the darkened warehouse, looking for valuables to steal.",
	"raider_1": "The autumn wind is a raider, pillaging through orchards.",
	"raider_2": "Many people think of a pirate when they hear the term raider.",
	"raider_3": "I am a huge fan of the football team, the Oakland Raiders.",
	"raider_4": "During the war, the city lived in fear of nightly attacks from air raiders.",
	"manipulation_0": "The politician was accused of manipulation when he misrepresented facts to gain support for his policies.",
	"manipulation_1": "Manipulation of the data was evident when the results of the survey did not align with the population's actual views.",
	"manipulation_2": "The magician's skilled manipulation of the cards left the audience in awe during his performance.",
	"manipulation_3": "Sarah's manipulation techniques often left her friends feeling used and upset.",
	"manipulation_4": "In therapy, he learned how to recognize and resist his ex-partner's emotional manipulation.",
	"analogy_0": "To illustrate his idea better, he used an analogy about a ship sailing through the ocean.",
	"analogy_1": "The teacher explained the relationship between the earth and the sun using an analogy of a torch and a basketball.",
	"analogy_2": "The analogy between the heart and a pump made the concept of how the heart works much clearer.",
	"analogy_3": "He drew an analogy between baking a cake and planning a project to show the necessity of having all your ingredients or resources in place.",
	"analogy_4": "Jennifer found it hard to understand the concept of photosynthesis until her professor used an analogy of a solar panel powering a house.",
	"morale_0": "The team's morale was boosted after winning the championship.",
	"morale_1": "After several losses, the morale amongst the soldiers was unusually low.",
	"morale_2": "The manager implemented new policies to improve employee morale in the company.",
	"morale_3": "The hiking trip helped to build morale among the new college freshmen.",
	"morale_4": "Despite the difficulties encountered by the team, the coach maintained a high morale by encouraging and motivating them.",
	"petition_0": "She started a petition online to protest against the unfair policies of her school.",
	"petition_1": "After gathering enough signatures, they submitted the petition to the city council to stop the demolition of the historic building.",
	"petition_2": "The social activist group is circulating a petition to increase the minimum wage nationwide.",
	"petition_3": "My neighborhood launched a petition to oppose the construction of a new shopping mall in our green park. ",
	"petition_4": "The mayor is considering a citizen's petition to improve the local library's facilities and resources.",
	"insane_0": "The speed at which he finished the marathon was simply insane.",
	"insane_1": "After weeks of intense interrogation, the suspect finally broke, screaming insane chatter that terrified the officers.",
	"insane_2": "The rollercoaster was insanely steep; I could hardly believe I had agreed to go on it.",
	"insane_3": "She had an insane talent for playing the violin, even though she'd only been learning for a year.",
	"insane_4": "The amount of homework our teacher gives us is absolutely insane.",
	"rabbi_0": "The new rabbi of our synagogue is highly knowledgeable in Jewish law and traditions. ",
	"rabbi_1": "Rabbi Cohen spent his afternoons tutoring the children in the Hebrew school.",
	"rabbi_2": "The rabbi carefully prepared for the Passover sermon, knowing he needed to connect with all the generations present.",
	"rabbi_3": "Rabbi Judith led the bar mitzvah ceremony with such grace and compassion.",
	"rabbi_4": "My grandfather, a venerable rabbi, spent his life in service to our community and the teachings of Judaism.",
	"racist_0": "The movie sparked controversy due to its undeniably racist undertones.",
	"racist_1": "Racism, in all its forms, should be actively condemned and rejected.",
	"racist_2": "Many people were shocked to discover the racist remarks the politician had made in his past.",
	"racist_3": "We must educate our children about the harms of racist behavior and the importance of equality.",
	"racist_4": "His racist comments hurt several of his friends and he later apologized for his narrow-mindedness.",
	"respective_0": "The students returned to their respective homes after the field trip.",
	"respective_1": "Each of the four business partners took on their respective roles within the company.",
	"respective_2": "The couples finished dinner and left the restaurant in their respective cars.",
	"respective_3": "The members of the basketball team each had their respective strengths, some excelled in shooting while others specialized in defense.",
	"respective_4": "After the meeting, everyone got back to work in their respective departments.",
	"shrine_0": "Every year, thousands of tourists visit the famous shrine in Kyoto.",
	"shrine_1": "After his grandmother passed away, he created a small shrine in her memory in his home.",
	"shrine_2": "The emperor's shrine is treated with great respect and is considered a sacred place.",
	"shrine_3": "The worshippers approached the shrine to offer prayers and blessings.",
	"shrine_4": "She entered the shrine slowly, reflecting on its historical significance.",
	"levy_0": "The government decided to levy new taxes on imported goods to boost local industries.",
	"levy_1": "The federal agency has the authority to levy fines against corporations that violate environmental regulations.",
	"levy_2": "Susan was upset when she found out her bank had chosen to levy a monthly service fee on her account.",
	"levy_3": "The local council voted to levy a charge on plastic bags to discourage their use and promote recycling.",
	"levy_4": "The United Nations approved measures to levy sanctions against the country due to its human rights violations.",
	"overturn_0": "The defense lawyer was sure that the new evidence would overturn the defendant's conviction.",
	"overturn_1": "To his frustration, the umpire's decision was suddenly overturned by the video replay in the last seconds of the game.",
	"overturn_2": "She threatened to overturn the table if she didn't get an immediate answer to her question.",
	"overturn_3": "After the accident, the car was found overturned in the middle of the road.",
	"overturn_4": "The CEO's decision to cut employee benefits was overturned after a series of protests by staff members.",
	"indictment_0": "The indictment against the mayor was a shock to the entire town, shattering their trust in his leadership.",
	"indictment_1": "After many months of investigation, the grand jury finally issued an indictment against the corrupt official.",
	"indictment_2": "The defense attorney argued that the indictment was based on circumstantial evidence and should be dismissed.",
	"indictment_3": "The sharp increase in crime is a severe indictment of the police department's efficiency.",
	"indictment_4": "Though the indictment carried serious charges, the accused remained confident in his innocence.",
	"dispose_0": "We need to properly dispose of hazardous waste to protect our environment. ",
	"dispose_1": "The city council developed a plan to dispose of old, unused city equipment. ",
	"dispose_2": "After finishing the soda, she looked around for a trash bin to dispose of the empty can. ",
	"dispose_3": "He intended to dispose of his old books at the local second-hand shop. ",
	"dispose_4": "The drugstore provides a secure box for customers to dispose of unwanted medications.",
	"archaeological_0": "The archaeological dig discovered artifacts from a previously unknown ancient civilization.",
	"archaeological_1": "I decided to study archaeology because of my fascination with uncovering historical truth.",
	"archaeological_2": "Our teacher took us on a field trip to an archaeological site to learn more about ancient indigenous tribes.",
	"archaeological_3": "The archaeological evidence suggested that the area used to be a marketplace in the Roman era.",
	"archaeological_4": "The discovery of the archaeological site drew experts from around the world, eager to investigate its secrets.",
	"elicit_0": "The director carefully designed his documentary to elicit a strong emotional response from the audience.",
	"elicit_1": "An effective teacher knows how to elicit creative responses from the students by asking thought-provoking questions.",
	"elicit_2": "The prosecutor tried to elicit the truth from the defendant during the cross-examination. ",
	"elicit_3": "Her joke elicited laughter from the crowd, easing the tense atmosphere. ",
	"elicit_4": "Although the marketing team implemented different strategies, they failed to elicit a significant increase in customers' interest in the product.",
	"escort_0": "His job as a bodyguard required him to escort the celebrity through the crowd.",
	"escort_1": "The museum provided an escort to lead us through the exhibition. ",
	"escort_2": "Upon her arrival, a police officer was assigned to escort the diplomat to her hotel.",
	"escort_3": "For her safety, she hired a professional escort while traveling through the unfamiliar city.",
	"escort_4": "The limousine was escorted by a convoy of police cars to ensure a secured path.",
	"turmoil_0": "The sudden death of the company's CEO threw the business into turmoil.",
	"turmoil_1": "The political turmoil in the country made it unsafe for travelers. ",
	"turmoil_2": "His mind was in turmoil after hearing about his parents' divorce. ",
	"turmoil_3": "Economic turmoil caused a significant rise in unemployment rates. ",
	"turmoil_4": "The team has been in turmoil since the unexpected departure of their coach.",
	"sampling_0": "The restaurant offered a sampling of their menu items at the grand opening.",
	"sampling_1": "In her research project, Amanda will be using a random sampling technique to ensure unbiased results.",
	"sampling_2": "The music producer used sampling to incorporate a piece of a classic song into a new track.",
	"sampling_3": "The doctor took a sampling of cells to test for any potential abnormalities.",
	"sampling_4": "A small sampling of locals gave their opinion on the new city park, with most reactions being positive.",
	"inclusion_0": "The company is working to implement policies that promote diversity and inclusion.",
	"inclusion_1": "The inclusion of more women at leadership levels has resulted in improved business performance and decision-making.",
	"inclusion_2": "Her book received acclaim for its inclusion of diverse characters and perspectives.",
	"inclusion_3": "The teacher's strategies for inclusion in the classroom ensured that every student felt valued and important.",
	"inclusion_4": "The conference promotes the inclusion of ideas and participants from all backgrounds and fields of study.",
	"laurel_0": "He was awarded a laurel wreath for his victory in the marathon.",
	"laurel_1": "The mountain laurel is in full bloom, painting the valley with a dash of pink and white.",
	"laurel_2": "Ancient athletes used to be crowned with a laurel wreath as a symbol of their triumph.",
	"laurel_3": "The laurel trees in her garden bring a unique fragrance that reminds her of her childhood.",
	"laurel_4": "As a poet, his dream was always to earn the laurel of national recognition.",
	"bronco_0": "The rider was thrown off the bucking bronco at the rodeo.",
	"bronco_1": "He turned his horse into a bronco after pulling on the reins too hard.",
	"bronco_2": "Her dream car was a classic 1970 Ford Bronco.",
	"bronco_3": "His cowboy skills were put to the test when he tried to tame the wild bronco.",
	"bronco_4": "The bronco desperately tried to escape its corral, kicking up a cloud of dust.",
	"fling_0": "My sister decided to fling the ball across the yard while we were playing catch.",
	"fling_1": "In a fit of rage, he decided to fling the documents across the room.",
	"fling_2": "She had a brief fling with a guy she met on her trip to Spain last summer.",
	"fling_3": "The catapult was designed to fling large stones at the enemy's fortifications.",
	"fling_4": "The wrestler used all his strength to fling his opponent out of the ring.",
	"signify_0": "The red flag on the beach would signify that it's dangerous to swim because of strong currents. ",
	"signify_1": "He gave her a special bracelet to signify his love for her. ",
	"signify_2": "The national anthem played at the start of the game to signify the commencement of the event. ",
	"signify_3": "The arrival of robins often signify the onset of spring. ",
	"signify_4": "The company changed its logo to signify its transformation and new direction.",
	"oppression_0": "My grandfather often spoke about the oppression he endured during the war years.",
	"oppression_1": "The oppressive regime left the citizens in a state of constant fear and deprivation.",
	"oppression_2": "It is essential to teach students about the history of racial oppression to prevent such atrocities from recurring.",
	"oppression_3": "Despite living under oppressive conditions, she never stopped fighting for freedom and equality.",
	"oppression_4": "Many countries still echo with the cries of those facing religious oppression.",
	"impair_0": "Consuming alcohol can significantly impair our ability to drive safely.",
	"impair_1": "Poor nutrition could impair the child's growth and development.",
	"impair_2": "The injury did not impair his ability to play the sport, but it did slow him down a bit.",
	"impair_3": "Continual exposure to loud noise could impair your hearing over time.",
	"impair_4": "If the virus continues to impair his immune system, he may need hospitalization.",
	"rationale_0": "The doctor clearly explained the rationale behind recommending surgery, to help the patient understand the decision.",
	"rationale_1": "It's important for a business to communicate the rationale for any major changes to its employees to ensure transparency.",
	"rationale_2": "She provided a detailed rationale for choosing this particular car model over the others, as it offered better mileage, durability, and value for money. ",
	"rationale_3": "When making important decisions, it is essential to consider the rationale behind each choice carefully. ",
	"rationale_4": "During the meeting, the team leader asked everyone to provide their rationale on the approach for the new project.",
	"reliance_0": "John's reliance on his GPS system to navigate unfamiliar city streets never failed him.",
	"reliance_1": "The people's reliance on government funding has grown significantly during the economic crisis.",
	"reliance_2": "As a nurse, I learned a heavy reliance on communication and teamwork are critical for patient care.",
	"reliance_3": "The country's reliance on fossil fuels has led to severe environmental issues.",
	"reliance_4": "My reliance on morning coffee has become a fundamental part of my daily routine.",
	"colt_0": "The young colt pranced around the paddock, its energy a delight to observe.",
	"colt_1": "The farmer was amazed at how quickly the colt was able to learn new commands.",
	"colt_2": "Out of all the horses on the ranch, the brown colt was the most spirited and playful.",
	"colt_3": "The colt had a glossy coat and bright eyes, a clear indicator of its good health.",
	"colt_4": "We watched in awe as the newborn colt took its first shaky steps.",
	"scholarly_0": "His approach to the subject was highly scholarly, reflecting a comprehensive understanding and deep research.",
	"scholarly_1": "She spent hours in the library, engrossing herself in scholarly articles to prepare for her thesis. ",
	"scholarly_2": "The professor was known for his scholarly discussions rather than conventional lectures. ",
	"scholarly_3": "Their debates on philosophy were so scholarly that they often went over the heads of the others in the conversation. ",
	"scholarly_4": "As the scholars gathered in the old lecture hall, the air became thick with scholarly ideas and hypotheses.",
	"embargo_0": "The UN imposed a stringent embargo on arms sales to the conflict-ridden country.",
	"embargo_1": "After the allegations of human rights abuses surfaced, many nations considered an embargo on trade with the country involved.",
	"embargo_2": "The embargo on Cuban goods lasted for more than half a century.",
	"embargo_3": "The embargo against the rogue state had little effect as they found ways to smuggle in goods covertly.",
	"embargo_4": "Lifting the embargo could potentially boost the economy and improve the living standard of the citizens.",
	"mourn_0": "After the loss of her dog, she would mourn for a long time. ",
	"mourn_1": "The entire country seemed to mourn the passing of their beloved leader.",
	"mourn_2": "Her friends gathered around her to support her as she began to mourn the death of her father.",
	"mourn_3": "He locked himself in his room to mourn the end of a relationship that he had put a lot into.",
	"mourn_4": "As is traditional, the whole family wore black to mourn the loss of their grandmother.",
	"muffle_0": "He tried to muffle his sobs by burying his face in the pillow.",
	"muffle_1": "In the forest, the heavy snowfall seemed to muffle all sounds.",
	"muffle_2": "She had to muffle her laughter while watching the comedy film in the library. ",
	"muffle_3": "To prevent the baby from waking up, she attempted to muffle the sound of her footsteps by walking on her tip-toes.",
	"muffle_4": "During the orchestra rehearsal, the conductor asked the percussionist to muffle the drums in order to balance the overall sound.",
	"exploitation_0": "The company faced severe backlash for its unfair exploitation of overseas labor.",
	"exploitation_1": "The documentary exposed the cruel exploitation of animals in the fur industry.",
	"exploitation_2": "The exploitation of natural resources in developing countries often leads to environmental issues.",
	"exploitation_3": "One of the novel's themes is the exploitation of workers during the industrial revolution.",
	"exploitation_4": "The human rights organization is campaigning to end the exploitation of child labor in factories.",
	"pending_0": "The approval of the building plans is still pending, as the city council wants to reassess the environmental impact.",
	"pending_1": "Due to a pending investigation, the disciplinary hearing of the officer was postponed.",
	"pending_2": "I can't make any plans for the weekend, because I have a lot of pending work to finish.",
	"pending_3": "The judge's decision on the case is still pending, making the claimants nervous with anticipation.",
	"pending_4": "My application to the university has been submitted but the acceptance is still pending.",
	"exclusion_0": "The exclusion of several important facts rendered the report incomplete and unreliable.",
	"exclusion_1": "She felt a sense of exclusion during the office party because her colleagues ignored her.",
	"exclusion_2": "His exclusion from the team was a controversial decision made by the coach.",
	"exclusion_3": "The club operated a strict exclusion policy for anyone who failed to observe its rules.",
	"exclusion_4": "The provisions for the exclusion of harmful substances were clearly stated in the health and safety guidelines.",
	"manifestation_0": "The sudden manifestation of his talent for poetry surprised everyone in the class.",
	"manifestation_1": "Her constant craving for sweets is a manifestation of stress, not genuine hunger.",
	"manifestation_2": "The large protest was a clear manifestation of public anger towards the new legislation.",
	"manifestation_3": "The excessive rainfall this year is a disastrous manifestation of climate change and global warming.",
	"manifestation_4": "His constant anxiety was a physical manifestation of his traumatic experiences as a child.",
	"apparatus_0": "The gym boasts a wide range of fitness apparatus suitable for all levels.",
	"apparatus_1": "The biology lab was well equipped with the latest scientific apparatus.",
	"apparatus_2": "The firefighters struggled with the heavy apparatus needed to extinguish the towering flames. ",
	"apparatus_3": "The new educational apparatus proved to be an effective tool for learning complex subjects.",
	"apparatus_4": "The photographers set up their apparatus before the wedding ceremony started.",
	"homicide_0": "The detective was assigned to a complex case of homicide that had been unsolved for months.",
	"homicide_1": "Homicide rates have been steadily decreasing in the city ever since the community outreach programs were implemented.",
	"homicide_2": "The courtroom fell silent as the judge read out the verdict, convicting the man for the gruesome act of homicide.",
	"homicide_3": "The fleeing suspect was finally captured and arrested on charges of attempted homicide. ",
	"homicide_4": "A special division of the police department was formed to handle the alarming increase in homicide cases in the area.",
	"insurgent_0": "The government stepped up efforts to quell the insurgent rebellion in the northern regions.",
	"insurgent_1": "The small insurgent group had been conducting covert operations throughout the city.",
	"insurgent_2": "The military apprehended an insurgent trying to cross the border undetected.",
	"insurgent_3": "The news reported about an encounter between the state forces and the insurgents last night.",
	"insurgent_4": "Intelligence reports indicate that the captured insurgent is a high-ranking officer in the rebellion group.",
	"vaguely_0": "The image appeared vaguely familiar, yet he couldn't recall where he had seen it before.",
	"vaguely_1": "She spoke vaguely about her plans for the summer, preferring to keep them a secret.",
	"vaguely_2": "The song playing on the radio was vaguely familiar.",
	"vaguely_3": "He vaguely recalled meeting her at a party but couldn't remember her name.",
	"vaguely_4": "They could only vaguely understand the guide's explanations due to his thick accent.",
	"wan_0": "Her wan complexion gave away the fact that she hadn't been feeling well recently.",
	"wan_1": "Under the pale moonlight, the old building gave off a wan, eerie glow.",
	"wan_2": "He tried to smile, but his wan expression didn't quite reach his eyes.",
	"wan_3": "After a week in the hospital, she was looking rather wan and frail.",
	"wan_4": "His normally rosy cheeks were disturbingly wan following his sickness.",
	"affiliate_0": "After years of independent operation, the bookstore decided to affiliate with a larger national chain. ",
	"affiliate_1": "As an affiliate of the university, the research center has access to extensive resources and funding. ",
	"affiliate_2": "She decided to become an affiliate marketer to earn extra income, promoting products on her blog and earning a commission on each sale. ",
	"affiliate_3": "The local charity is an affiliate of a worldwide organization aimed at ending hunger. ",
	"affiliate_4": "As a broadcast affiliate, the television station has permission to air network programming.",
	"continuity_0": "The continuity of the plot in the novel was disrupted by sudden, contradicting events.",
	"continuity_1": "The director strives to maintain continuity throughout the film by carefully connecting every scene.",
	"continuity_2": "The lecturer stressed the importance of continuity in historical trends for a proper understanding of modern geopolitics.",
	"continuity_3": "She admired the continuity of traditions in her family, which had been passed down from generation to generation.",
	"continuity_4": "The power outage resulted in a break in the continuity of computer services, causing delays in work.",
	"foreman_0": "The foreman on the construction site was responsible for overseeing all the workers and ensuring they followed safety protocols.",
	"foreman_1": "In a jury, the foreman is often chosen for their ability to facilitate discussions and ultimately reach a unanimous decision.",
	"foreman_2": "Bruce was promoted to the position of foreman after years of hard work and commitment at the factory.",
	"foreman_3": "The foreman gathered his crew at the beginning of the shift to delegate tasks for the day.",
	"foreman_4": "Despite the foreman's stern demeanor, he was well-liked and respected for his fair treatment of all the workers.",
	"standpoint_0": "From an environmental standpoint, the widespread use of renewable energy sources is crucial for our planet's future.",
	"standpoint_1": "From a children's standpoint, getting candy for dinner would be the best thing ever.",
	"standpoint_2": "From a financial standpoint, investing in stocks can be a risky but potentially profitable decision.",
	"standpoint_3": "From a historian's standpoint, those ruins contain countless invaluable information about our ancestors.",
	"standpoint_4": "From an engineer's standpoint, the bridge's design is not only aesthetically pleasing but also structurally sound.",
	"contradict_0": "Tom's account of events seemed to contradict the evidence laid out by the police.",
	"contradict_1": "Her expression seemed to contradict her cheery tone.",
	"contradict_2": "I do not mean to contradict you, but the data shows a different conclusion.",
	"contradict_3": "In a court of law, when a witness's statement contradicts an earlier testimony, it can weaken their credibility.",
	"contradict_4": "He was careful not to contradict his father's instructions, even though he didn't agree with them.",
	"disparity_0": "The disparity between the rich and the poor is becoming increasingly noticeable in our city. ",
	"disparity_1": "There was a significant disparity in educational standards between the urban and rural areas.",
	"disparity_2": "The report highlighted a clear disparity in access to healthcare among different socio-economic groups. ",
	"disparity_3": "Despite the public outcry, the disparity between the salaries of men and women continues in many sectors.",
	"disparity_4": "The COVID-19 pandemic has further increased the disparity in income distribution worldwide.",
	"briefing_0": "The CEO conducted a briefing about the company's current status and plans for the next quarter.",
	"briefing_1": "Before embarking on the mission, each astronaut received a thorough briefing from the flight director.",
	"briefing_2": "The press briefing held by the White House provided valuable insights into the administration's future direction.",
	"briefing_3": "In the morning briefing, our team leader emphasized on meeting the project deadlines.",
	"briefing_4": "The emergency response team was rushed into an urgent briefing about the developing hurricane.",
	"partisan_0": "During the election campaign, the politician's statements were decidedly partisan, clearly favoring his own party's agenda.",
	"partisan_1": "Even in her role as a journalist, she could never shed her partisan feelings, often showing bias in her reporting.",
	"partisan_2": "The debate turned into a partisan brawl, with each side steadfastly refusing to see the other's perspective.",
	"partisan_3": "His partisan support for the proposed legislation did not surprise anyone who knew his party affiliation.",
	"partisan_4": "Despite being a proud member of her party, she often strived to make decisions that were fair and not overly partisan.",
	"crap_0": "I can't believe I have to sort through all this crap just to find one important document.",
	"crap_1": "John thinks that new sci-fi movie is total crap, but I really enjoyed it.",
	"crap_2": "Cleaning up after the party was a real crap job, but someone had to do it.",
	"crap_3": "Sorry about the mess in the garage, I've been stashing a lot of old crap in there recently.",
	"crap_4": "I felt like crap after eating all that junk food last night.",
	"territorial_0": "The territorial disputes between China and its neighboring countries have been a source of tension for years.",
	"territorial_1": "Cats are very territorial animals; they don\u2019t like other cats invading their space.",
	"territorial_2": "The city underwent a territorial expansion to accommodate the growing population.",
	"territorial_3": "The territorial rights of indigenous tribes should be recognized and respected.",
	"territorial_4": "The bird became territorial, aggressively charging at anyone who approached its nest.",
	"hum_0": "The constant hum of the refrigerator was the only sound in the quiet apartment.",
	"hum_1": "As she relaxed in her chair, she could hear the soft hum of the city outside.",
	"hum_2": "He started to hum his favorite tune as he cooked dinner.",
	"hum_3": "The fluorescent lights above gave off a faint hum that could be heard throughout the basement.",
	"hum_4": "The car's engine purred to life with a low hum, ready for the long drive ahead.",
	"occupational_0": "His occupational therapist planned a selection of activities tailored specifically to improve his motor function. ",
	"occupational_1": "The occupational risks involved in coal mining are not worth the salary for many job seekers.",
	"occupational_2": "She offers occupational training programs for young individuals with disabilities.",
	"occupational_3": "He left his job due to occupational stress and a lack of job satisfaction. ",
	"occupational_4": "The conference will present the latest studies in occupational health and safety.",
	"severity_0": "The severity of his illness shocked everyone in the family. ",
	"severity_1": "She was taken aback by the severity of the punishment for such a minor offense. ",
	"severity_2": "The severity of the winter storm caused extensive damage to the coastline.",
	"severity_3": "His face showed the severity of the situation, causing a ripple of anxiety through the room.",
	"severity_4": "The severity of the fiscal crisis led to widespread budget cuts in the company.",
	"damn_0": "That movie was so damn good, I've watched it three times already! ",
	"damn_1": "I stubbed my toe on the table. Damn, that hurts! ",
	"damn_2": "He\u2019s lazy and damn proud of it. ",
	"damn_3": "Damn, I forgot my wallet at home! ",
	"damn_4": "I don't just want to be good, I want to be damn great at what I do.",
	"revision_0": "Your essay is quite good, but it still needs some minor revision before final submission.",
	"revision_1": "Due to changes in the market conditions, the company has made a significant revision in its strategies.",
	"revision_2": "The teacher was very thorough in her revision of our research papers.",
	"revision_3": "Our textbook will soon have a new revision, so wait until the next semester before you purchase a copy.",
	"revision_4": "Before he can start painting, the artist typically makes several revisions to his pencil sketch.",
	"conflicting_0": "Their conflicting opinions on the project led to a heated argument in the boardroom.",
	"conflicting_1": "The officers were puzzled due to the conflicting testimonies given by the witnesses of the crime. ",
	"conflicting_2": "John felt conflicting emotions of joy and sadness when he watched his only daughter get married.",
	"conflicting_3": "The archaeologists found conflicting evidence which challenged the established historical timeline.",
	"conflicting_4": "The jury had a harder time reaching a verdict due to the conflicting information presented by the prosecution and the defense.",
	"insufficient_0": "The police determined there was insufficient evidence to charge the suspect with the crime.",
	"insufficient_1": "Having an insufficient amount of sleep can affect your productivity during the day.",
	"insufficient_2": "The funds allotted for the project were insufficient, causing a delay in its completion.",
	"insufficient_3": "His knowledge of the subject was insufficient to pass the exam.",
	"insufficient_4": "Due to insufficient staffing, the restaurant had to temporarily close.",
	"coherent_0": "The ideas she presented were coherent and well-explained, leaving no room for confusion.",
	"coherent_1": "Despite being tired, he managed to deliver a coherent speech at the conference.",
	"coherent_2": "Their plans were not coherent, with different objectives contradicting one another.",
	"coherent_3": "The detective needed a coherent timeline to solve the case correctly.",
	"coherent_4": "He struggled to write a coherent essay because his thoughts were scattered and disorganized.",
	"bosnian_0": "Alex is learning the Bosnian language for his upcoming trip to Bosnia and Herzegovina.",
	"bosnian_1": "The Bosnian cuisine, with its unique blend of eastern and western flavors, is a delight to the taste buds.",
	"bosnian_2": "The national monument preserves a piece of Bosnian history, marking the spot where the country declared its independence.",
	"bosnian_3": "His Bosnian heritage is evident in his love of traditional music and literature from the country.",
	"bosnian_4": "We were thrilled to find a restaurant that served authentic Bosnian dishes in our hometown.",
	"chant_0": "The rhythm and repetition of the Buddhist monks' chant was soothing and calming.",
	"chant_1": "The football fans began to chant the team's anthem loudly from the stands.",
	"chant_2": "At the protest, thousands of participants joined forces to chant slogans calling for equality and justice.",
	"chant_3": "During her morning meditation, she recites a calming chant to help clear her mind. ",
	"chant_4": "The children learned a new chant at school, repeating it all the way home.",
	"occurrence_0": "The occurrence of rain in the desert is very rare.",
	"occurrence_1": "An interesting occurrence took place while I was on my holiday in Italy.",
	"occurrence_2": "The sudden occurrence of the earthquake left everybody in shock.",
	"occurrence_3": "The daily occurrence of heavy traffic is causing great inconvenience to the office goers. ",
	"occurrence_4": "This is not a normal occurrence, I will need to report it to my manager.",
	"implicit_0": "The implicit understanding between them meant no words were needed to convey their thoughts.",
	"implicit_1": "The rules of the club are implicit, with members expected to behave appropriately at all times.",
	"implicit_2": "There was an implicit trust between them, built over years of friendship and countless shared experiences.",
	"implicit_3": "His rejection was not outright, but was implicit in his noncommittal responses and avoidance of the topic.",
	"implicit_4": "She was comfortable in their connection, treasuring the implicit intimacy that comes from deep understanding.",
	"bipartisan_0": "The bipartisan agreement on the immigration bill marked a rare moment of harmony in the divided Congress.",
	"bipartisan_1": "The senator is known for his ability to develop bipartisan solutions to complicated issues.",
	"bipartisan_2": "The hope is for a bipartisan consensus on climate change policy that will benefit everyone.",
	"bipartisan_3": "The bipartisan committee meets every month to discuss new proposed bills.",
	"bipartisan_4": "Despite their usual disagreements, the two party leaders put their differences aside and passed a bipartisan measure to fund the local school.",
	"enrollment_0": "The university experienced a significant increase in enrollment this year, with over 5000 new students.",
	"enrollment_1": "Due to the pandemic, there was a decrease in enrollment rates as students chose to defer their studies.",
	"enrollment_2": "Parents are reminded that the deadline for enrollment in the new school year is April 1st.",
	"enrollment_3": "Enrollment in online courses has skyrocketed due to the convenience they offer.",
	"enrollment_4": "The college is aiming to broaden its diversity by promoting enrollment to underrepresented communities.",
	"instability_0": "The political instability in the country is causing widespread fear and panic.",
	"instability_1": "The recent fluctuations in the stock market are a clear sign of economic instability.",
	"instability_2": "The rapid changes in climate patterns reflect the growing instability of our environment.",
	"instability_3": "His emotional instability was a constant challenge in maintaining a healthy relationship.",
	"instability_4": "The instability of the old bridge makes it unsafe for people to cross.",
	"indict_0": "The grand jury decided to indict the businessman for tax evasion.",
	"indict_1": "The evidence was strong enough for the prosecution to indict the suspect for murder.",
	"indict_2": "After a lengthy investigation, the court decided to indict the politician on charges of corruption.",
	"indict_3": "The FBI had enough evidence to indict the drug dealer, proving a connection to several unsolved cases.",
	"indict_4": "Despite the public outcry, the jury did not indict the officer due to insufficient evidence.",
	"contention_0": "The primary contention between the two political parties is their differing views on healthcare reform.",
	"contention_1": "My contention is that we spent too much on unnecessary items last month, resulting in our financial deficit.",
	"contention_2": "His main contention was that the experiment lacked definitive controls, making the results unreliable.",
	"contention_3": "Sarah's tendency to start a contention during family dinners made her the least favorite guest at the holiday gatherings.",
	"contention_4": "The most notable contention between the two scientists is their differing theories on climate change.",
	"comprehend_0": "I find it difficult to comprehend why she behaves like that.",
	"comprehend_1": "It took me a moment to comprehend the full scale of the disaster.",
	"comprehend_2": "The human mind cannot really comprehend the enormity of the universe.",
	"comprehend_3": "His lecture was so advanced, it was difficult for many students to comprehend.",
	"comprehend_4": "The guidebook was written in English too complex for me to comprehend.",
	"legitimacy_0": "The legitimacy of his claim to the throne was questionable, with evidence suggesting his ancestors were not of royal lineage. ",
	"legitimacy_1": "Despite the legitimacy of her arguments in the debate, many failed to recognize the validity of her points. ",
	"legitimacy_2": "The police raided the shop due to the doubts about the legitimacy of the business operations. ",
	"legitimacy_3": "The legitimacy of the election results is being challenged, due to numerous reports of voting fraud. ",
	"legitimacy_4": "His claim of having a PhD lost its legitimacy when the university confirmed they have no record of his attendance.",
	"disposition_0": "She has such a sunny disposition that she brightens the mood of everyone around her.",
	"disposition_1": "I always admired my grandfather for his enthusiastic and positive disposition.",
	"disposition_2": "His disposition towards working hard resulted in achieving his career goals.",
	"disposition_3": "The man had a sour disposition that made it hard for anyone to connect with him on a personal level.",
	"disposition_4": "Despite the hardships she faced, Rachel maintained an optimistic disposition.",
	"interpreter_0": "The UN conference was filled with skilled interpreters capable of translating numerous languages.",
	"interpreter_1": "I had a dream of becoming an interpreter for international celebrities.",
	"interpreter_2": "We have an interpreter on board to help with communication because our CEO is visiting from Japan.",
	"interpreter_3": "My friend is a gifted language interpreter, and she has worked with many refugees to help them navigate their new lives.",
	"interpreter_4": "An interpreter doesn't only translate words directly, but also the sentiment and cultural nuances as well.",
	"discriminate_0": "It is illegal to discriminate against individuals on the basis of race, religion, or gender. ",
	"discriminate_1": "Jackie's profound ability to discriminate between authentic and fake art pieces made her an asset to the museum. ",
	"discriminate_2": "Discriminate use of antibiotics is crucial to prevent drug resistance. ",
	"discriminate_3": "Far from wanting to discriminate, the company is actively promoting diversity and inclusivity in its workforce. ",
	"discriminate_4": "The law is designed to protect students who feel they have been unfairly discriminated against.",
	"collaborative_0": "The project was a collaborative effort between the two universities.",
	"collaborative_1": "Our team needs to develop a more collaborative approach towards problem-solving.",
	"collaborative_2": "The musicians' collaborative album was a huge success.",
	"collaborative_3": "The teacher emphasized the importance of collaborative learning among students. ",
	"collaborative_4": "The company is launching a collaborative platform that allows experts from different fields to work together.",
	"governance_0": "The governance of the school district was taken over by an external auditing board after suspicion of mismanagement.",
	"governance_1": "The company's corporate governance ensured that all decisions were made ethically and responsibly.",
	"governance_2": "Effective governance is crucial for the success of any democratic society.",
	"governance_3": "The city council's poor governance led to many issues for the town, including neglected public facilities and high crime rates.",
	"governance_4": "Her research focused on the importance of good governance in developing nations to promote economic growth.",
	"vacant_0": "The house next door has been vacant for almost a year now. ",
	"vacant_1": "The stadium was completely vacant after the championship game. ",
	"vacant_2": "She looked around the room, realizing it was vacant and cold. ",
	"vacant_3": "The position in the team was vacant after the manager's sudden resignation. ",
	"vacant_4": "He walked around the parking lot, looking for a vacant spot to park his car.",
	"nationalism_0": "Nationalism played a significant role in the country's struggle for independence.",
	"nationalism_1": "His speech was filled with a strong sense of nationalism, aiming to unite the people under the shared history and heritage of the nation.",
	"nationalism_2": "During the Olympics, people often exhibit a heightened sense of nationalism, vigorously supporting their country's athletes.",
	"nationalism_3": "While nationalism can foster unity and pride among citizens, it can also breed intolerance and hostility towards other nationalities.",
	"nationalism_4": "The rise in nationalism during the early 20th century led to severe global conflicts.",
	"corresponding_0": "The corresponding value for each item in the list is listed in the right column of the table.",
	"corresponding_1": "She sent a letter to the corresponding department to inquire about their services.",
	"corresponding_2": "The symptoms observed in the patient were corresponding to those of a severe flu.",
	"corresponding_3": "For every purchase you make, the corresponding amount will be deducted from your account.",
	"corresponding_4": "In scientific diagrams, parts are often labeled with letters and the explanations are provided in the corresponding legend.",
	"conceptual_0": "Her artwork is highly conceptual, drawing from complex theories and philosophies. ",
	"conceptual_1": "The project was still in its conceptual stage, with many details left to be finalized.",
	"conceptual_2": "To understand this issue thoroughly, one must delve into its conceptual framework. ",
	"conceptual_3": "The artist's conceptual approach made his work intriguing and thought-provoking. ",
	"conceptual_4": "He excels in conceptual thinking, always able to see the bigger picture and predict potential consequences.",
	"slump_0": "After working all through the night, he felt a slump in his energy levels the next morning.",
	"slump_1": "The housing market has experienced a significant slump due to the current economic crisis.",
	"slump_2": "After the team's star player got injured, they went into a slump and lost several games in a row.",
	"slump_3": "He couldn't help but slump in his chair from exhaustion after the long drive.",
	"slump_4": "The slump in her grades was a clear indication that something was affecting her concentration.",
	"ritual_0": "Every morning, Sarah observed the ritual of meditating before anything else, believing it helped her handle the stress of the day.",
	"ritual_1": "Attending Sunday mass had become an important ritual in Mike's family, passed down for generations.",
	"ritual_2": "Tea ceremonies in Japan are traditional rituals that require years of training to perfect.",
	"ritual_3": "The tribe's ritual's of initiation into adulthood involved a grueling three-day journey through the wilderness.",
	"ritual_4": "The ritual of lighting candles on a birthday cake has been a tradition in many cultures for centuries.",
	"intrinsic_0": "Reading for pleasure has an intrinsic value that goes beyond educational benefits.",
	"intrinsic_1": "Her love for children is intrinsic; she doesn't need to fake it to work in the daycare center.",
	"intrinsic_2": "Studying Mathematics doesn't just help in exams, it also develops intrinsic problem-solving skills.",
	"intrinsic_3": "His intrinsic sense of humor makes him popular among his peers.",
	"intrinsic_4": "It is important to promote intrinsic motivation in students to encourage lifelong learning.",
	"efficacy_0": "The efficacy of the new vaccine created a wave of optimism across the globe.",
	"efficacy_1": "Researchers are still testing the efficacy of the new treatment on patients with severe symptoms.",
	"efficacy_2": "I doubt the efficacy of this weight loss program, as no scientific studies support its claims.",
	"efficacy_3": "The efficacy of the tutoring program was evident in the improved test scores of the participating students.",
	"efficacy_4": "The medicine's efficacy varies greatly among individuals, depending on their personal health history and genetic makeup.",
	"taliban_0": "The Taliban, a hardline Islamist group, ruled over Afghanistan from 1996 to 2001.",
	"taliban_1": "Despite international condemnation, the Taliban continues to impose strict interpretations of Islamic law and suppresses the rights of women.",
	"taliban_2": "The Taliban was ousted from power by US-led forces in December 2001; however, they have recently begun to regain influence in the region.",
	"taliban_3": "The Taliban has shown resilience to countless military operations aimed at eradicating their forces.",
	"taliban_4": "The group, known as the Taliban, is considered a major security threat by many nations, largely due to their support of extremist ideologies and terror activities.",
	"retention_0": "The company focused on employee retention to maintain a skilled and experienced workforce.",
	"retention_1": "Having a good memory is dependent on the brain's ability for information retention.",
	"retention_2": "The university has set up several mentoring and support programs to increase student retention rates.",
	"retention_3": "The new software promises improved data retention, allowing businesses to keep records for longer periods. ",
	"retention_4": "Water retention in her body caused swelling in her ankles and feet.",
	"reverend_0": "Reverend Smith is our new parish priest, and he is loved by everyone in the community for his kindness and compassion.",
	"reverend_1": "To all the parishioners, please join us in welcoming Reverend Martinez to our Sunday service.",
	"reverend_2": "When a crisis enveloped our town, Reverend Johnson was at the forefront of the relief efforts.",
	"reverend_3": "The role of Reverend Hawkins extends beyond the church, as he often counsels troubled youth in our community.",
	"reverend_4": "Of all his roles, being a reverend is the one that Dr. Matthews cherishes the most due to his deep passion for serving people spiritually.",
	"societal_0": "His research focuses on the societal impacts of climate change.",
	"societal_1": "The societal norms in some countries discourage women from working outside the home.",
	"societal_2": "The invention of the internet has caused significant societal changes globally.",
	"societal_3": "We should analyze the societal effects of mass media on people's behavior and attitudes.",
	"societal_4": "The breakdown of societal values is often blamed for the increase in crime rates.",
	"squint_0": "I had to squint to read the fine print on the contract.",
	"squint_1": "The bright sunlight made her squint as she stepped out of the dark building.",
	"squint_2": "My grandmother often squints when she forgets to put on her reading glasses.",
	"squint_3": "The detective squinted at the barely visible footprint, trying to discern any useful details.",
	"squint_4": "He squinted at the chalkboard from the back of the classroom, regretting that he had forgotten his glasses at home.",
	"anthropology_0": "Jane decided to major in anthropology because she has always been fascinated with the customs and beliefs of different cultures.",
	"anthropology_1": "The study of anthropology offered him fresh insights into the evolution of human societies.",
	"anthropology_2": "Anthropology is a diverse field that looks into the biological, social, and cultural aspects of human beings across all periods and locations.",
	"anthropology_3": "Their anthropology professor assigned them to carry out a field study, immersing themselves in a local tribe to better understand the people's unique traditions and customs.",
	"anthropology_4": "In medical anthropology, researchers examine how social and cultural factors impact health, medical practice, and healthcare delivery.",
	"probation_0": "After being convicted of a minor offence, Jenny was sentenced to a year of probation instead of prison. ",
	"probation_1": "My brother's reckless behavior led to him being placed on probation for six months.",
	"probation_2": "As part of his probation terms, he had to regularly report to his probation officer and avoid any additional legal troubles. ",
	"probation_3": "Mark is ecstatic as his probation period at work has finally ended and he's now a full-time employee. ",
	"probation_4": "Violating the terms of his probation could result in Robert being sent back to jail.",
	"spatial_0": "The architect had an exceptional understanding of spatial relationships, which allowed him to create innovative designs.",
	"spatial_1": "The accident caused her some spatial memory loss, making it hard for her to navigate even familiar places.",
	"spatial_2": "In psychology, spatial perception is considered a key component in child development and learning. ",
	"spatial_3": "Spatial planning is a crucial element in urban development, ensuring efficient use of land and resources.",
	"spatial_4": "GPS technology uses data from several satellites to provide precise spatial information about a location.",
	"inconsistent_0": "Her inconsistent performance at work has raised concerns about her commitment to the job.",
	"inconsistent_1": "The inconsistent weather made it difficult for them to plan their hiking trip.",
	"inconsistent_2": "His inconsistent statements about the incident led the jury to question his credibility.",
	"inconsistent_3": "The teacher noticed the student's inconsistent attendance and decided to speak with his parents.",
	"inconsistent_4": "Canada\u2019s response to the pandemic has been criticized for being inconsistent and confusing.",
	"methodology_0": "The researcher outlined a comprehensive methodology for conducting the survey. ",
	"methodology_1": "Jane\u2019s methodology for completing the project included a detailed timeline and strict adherence to budget guidelines.",
	"methodology_2": "Our team adopted a unique methodology, combining traditional and digital marketing strategies to optimize our reach. ",
	"methodology_3": "To ensure transparency in our study, we clearly defined our methodology in the first chapter of our research paper. ",
	"methodology_4": "The professor emphasized the importance of using an appropriate and systematic methodology in our theses.",
	"pakistani_0": "Ali is a proud Pakistani, who cherishes his heritage and culture.",
	"pakistani_1": "Pakistani cuisine, known for its rich spices and flavors, is enjoyed worldwide.",
	"pakistani_2": "The Pakistani cricket team was cheered on by millions of fans during the world cup.",
	"pakistani_3": "I am reading about Pakistani history to better understand the country's past and how it has evolved over time.",
	"pakistani_4": "While studying abroad, she made a close friend who was a Pakistani student.",
	"discrepancy_0": "There was a large discrepancy between her version of the incident and the account given by the witnesses. ",
	"discrepancy_1": "The company discovered a major discrepancy in their financial reports, indicating potential fraud. ",
	"discrepancy_2": "The discrepancy between the promised delivery date and the actual arrival of the package upset the customer. ",
	"discrepancy_3": "We noted a significant discrepancy in the test results, prompting us to repeat the experiment. ",
	"discrepancy_4": "The discrepancy between the amount of food produced globally and the number of people suffering from hunger is astonishing.",
	"recruitment_0": "The recruitment process for the new branch will begin next month.",
	"recruitment_1": "He oversees the recruitment and training of new staff members.",
	"recruitment_2": "The army's recruitment has seen a significant increase in the past year.",
	"recruitment_3": "The HR manager is developing new recruitment strategies to attract the best candidates.",
	"recruitment_4": "Her primary job was the recruitment of potential students for the college.",
	"substantive_0": "The judge determined that the defense failed to provide a substantive argument pertaining to the evidence presented in the case.",
	"substantive_1": "During the meeting, the team had a substantive discussion about the company's future plans and potential challenges.",
	"substantive_2": "The research provided substantive proof that environmental pollution is significantly affecting climate change.",
	"substantive_3": "The author's substantive knowledge of history was evident in the detailed descriptions in his novel.",
	"substantive_4": "Despite the complex nature of the problem, they managed to find a substantive solution that satisfied all parties involved.",
	"parole_0": "After serving five years of his sentence, the prisoner was eligible for parole.",
	"parole_1": "The parole board reviewed the case thoroughly before making a decision.",
	"parole_2": "He was released early from prison on parole because of his good behavior.",
	"parole_3": "If you break the conditions of your parole, you run the risk of being sent back to prison.",
	"parole_4": "Keith was anxious as he prepared for his parole hearing, knowing it would decide his future.",
	"parole_5": "",
	"transcript_0": "The university asked me to provide my official transcript as part of the application process.",
	"transcript_1": "She quickly glanced over the trial transcript to familiarize herself with the case details.",
	"transcript_2": "The podcast creators provide a full transcript of each episode on their website for those who prefer reading.",
	"transcript_3": "Jenny had to request her high school transcript to apply for a scholarship.",
	"transcript_4": "New evidence was found in the transcript of the defendant's interrogation.",
	"negotiator_0": "The company needed a skilled negotiator to handle the business merger.",
	"negotiator_1": "As a professional negotiator, John helped resolve issues between two conflicting parties.",
	"negotiator_2": "The negotiator calmly approached the armed man, hoping to defuse the situation without any bloodshed.",
	"negotiator_3": "Mary was well-known as an excellent negotiator and was respected for her ability to find compromises in difficult situations.",
	"negotiator_4": "As a child, Steve was always the negotiator among his friends, helping them find middle ground during their disputes.",
	"cohort_0": "Jack has been assigned to lead a cohort of new trainees at his job.",
	"cohort_1": "After orientation, we were split into various cohorts based on our areas of study.",
	"cohort_2": "The soldiers chanced upon a cohort of enemy troops near the border. ",
	"cohort_3": "Among our student cohort, several individuals have shown great potential in the field of science.",
	"cohort_4": "Dissecting the data by cohort allowed the researchers to spot interesting trends across different age groups.",
	"innings_0": "The cricket match was exciting, lasting for two full innings before the final result was declared.",
	"innings_1": "During his innings, the batsman managed to score over a hundred runs.",
	"innings_2": "We were leading in the first innings, but unfortunately lost the match in the second. ",
	"innings_3": "The baseball player had a spectacular innings, hitting three home runs.",
	"innings_4": "Owing to the intense rain, the match had to be abandoned mid-way through the second innings.",
	"militant_0": "The police are on high alert due to potential threats from militant groups in the area.",
	"militant_1": "His militant stance on environmental issues has made him a polarizing figure in the community.",
	"militant_2": "An alarming number of young men are being recruited by militant organizations online. ",
	"militant_3": "The militant's wife pleaded for his release on national television.",
	"militant_4": "The government is making efforts to negotiate with the militant faction to avoid further conflict.",
	"deposition_0": "The lawyer began the deposition by questioning the police officer about the night of the incident.",
	"deposition_1": "During the deposition, the witness testified that she observed the potential suspect leaving the scene.",
	"deposition_2": "The deposition process was extensive, but after several hours, all necessary information was obtained.",
	"deposition_3": "The judge allowed the lawyer to use the deposition as evidence during the trial. ",
	"deposition_4": "The deposition of sediments over thousands of years resulted in the formation of the Grand Canyon.",
	"precinct_0": "The local community gathered at the precinct for a neighborhood watch meeting.",
	"precinct_1": "Due to the increased crime rate, the police precinct had to hire more officers.",
	"precinct_2": "The regional election results varied greatly from precinct to precinct.",
	"precinct_3": "As the captain of the precinct, he was responsible for managing all the officers within his district.",
	"precinct_4": "Protesters gathered outside the precinct to demand justice for the victims of police brutality.",
	"socioeconomic_0": "Her research focuses on the effects of socioeconomic factors on children's learning.",
	"socioeconomic_1": "The socioeconomic status of a family often dictates the quality of educational opportunities available to them.",
	"socioeconomic_2": "The city's health department is working on projects addressing the socioeconomic determinants of health.",
	"socioeconomic_3": "Socioeconomic inequality is a hot topic in election campaigns these days.",
	"socioeconomic_4": "Sociologists are studying the correlation between socioeconomic background and academic achievement.",
	"interpersonal_0": "Effective communication is essential for strong interpersonal relationships.",
	"interpersonal_1": "The manager praised Susan for her excellent interpersonal skills, noting her ability to calmly resolve conflicts within the team.",
	"interpersonal_2": "The training seminar focused on developing our interpersonal skills to improve client relationships.",
	"interpersonal_3": "In sales and marketing roles, interpersonal abilities are as important as technical knowledge.",
	"interpersonal_4": "Interpersonal dynamics in an office setting can strongly influence the overall productivity of the team.",
	"informant_0": "The police relied heavily on the informant's detailed information to crack the case.",
	"informant_1": "In many crime novels, the story often revolves around a secret informant who provides critical information. ",
	"informant_2": "Jim, a government informant, decided to disclose information about corruption in the department.",
	"informant_3": "Without the information provided by our informant, we would never have discovered the illicit activities happening in the company.",
	"informant_4": "The FBI offered the informant protection in exchange for evidence that could bring down the mafia boss.",
	"linguistic_0": "Linguistic skills are essential for getting a job in the field of international relations.",
	"linguistic_1": "The linguistic diversity in India is astounding, with over 1600 spoken languages.",
	"linguistic_2": "My professor is a linguistic expert and knows seven languages fluently.",
	"linguistic_3": "The study of linguistic patterns helps us understand how language evolves over time.",
	"linguistic_4": "Linguistic barriers can often lead to misunderstandings in multicultural environments.",
	"descriptive_0": "The descriptive portrayal in the book made me feel as if I was right there in the middle of the scenery.",
	"descriptive_1": "I find her descriptive language quite enchanting; it brings her stories to life.",
	"descriptive_2": "The detective took a descriptive account of the crime scene to assist him in his investigation.",
	"descriptive_3": "The teacher asked the students to write a descriptive essay about their favorite vacation spot.",
	"descriptive_4": "Her descriptive review of the restaurant made me want to go there and taste the food myself.",
	"temporal_0": "In terms of temporal changes, climate patterns have fluctuated substantially over the past century.",
	"temporal_1": "He suffered a blow to his temporal region while playing football.",
	"temporal_2": "Temporal events such as birthdays and anniversaries are often celebrated with great enthusiasm.",
	"temporal_3": "The archaeologist was interested in temporal discrepancies between different civilizations.",
	"temporal_4": "While engaged in meditation, our perceptions of temporal realities such as time and space, can alter significantly.",
	"bilateral_0": "The president held a bilateral meeting with the prime minister to discuss trade agreements between the two countries.",
	"bilateral_1": "The bilateral treaty ensures cooperation in areas of mutual interest for both nations.",
	"bilateral_2": "Mr. Smith suffers from bilateral hearing loss, meaning he can't hear properly in either ear.",
	"bilateral_3": "The negotiations were bilateral, involving both the employer and the employee union.",
	"bilateral_4": "The bilateral agreement on nuclear disarmament was a major stepping stone towards global peace.",
	"quantitative_0": "The research study involved a quantitative analysis of the data collected from over a thousand participants.",
	"quantitative_1": "Quantitative data provides numeric measurements which can be statistically analyzed.",
	"quantitative_2": "The DNA test results provided a quantitative measure of the patient's genetic similarity to the sample.",
	"quantitative_3": "In her research, Natalie uses both qualitative and quantitative methods to get a comprehensive understanding of the subject matter.",
	"quantitative_4": "The company uses quantitative methods to assess the financial potential of upcoming projects.",
	"competency_0": "In her new job, Julie required a certain level of competency in Spanish to communicate with clients. ",
	"competency_1": "The interview was designed to test the competency of the applicants in handling difficult situations.",
	"competency_2": "Not only did she prove her competency in accounting, but she also showed great creativity in problem solving.",
	"competency_3": "His lack of competency in the kitchen resulted in many burned meals. ",
	"competency_4": "The certificate is a formal recognition of his competency in auto repair.",
	"coefficient_0": "In mathematical equations, the coefficient is the number that multiplies the variable.",
	"coefficient_1": "In our chemistry class, we had to calculate the coefficient of a chemical reaction.",
	"coefficient_2": "The coefficient of friction between two surfaces determines how difficult it is for them to slide against each each other.",
	"coefficient_3": "In statistics, we looked at the correlation coefficient to measure the strength and direction of a linear relationship between two variables.",
	"coefficient_4": "The coefficient in front of the variable 'x' in her algebraic expression was a negative number, indicating a downward slope in the graph.",
	"qualitative_0": "The qualitative data collected from the survey helped the team understand customer preferences and experiences.",
	"qualitative_1": "The qualitative study emphasized deep, detailed understanding of societal phenomena.",
	"qualitative_2": "The researcher used a qualitative approach to gather more subjective and descriptive data for the study.",
	"qualitative_3": "The qualitative feedback provided by the clients was more valuable than just numerical ratings.",
	"qualitative_4": "His qualitative analysis of the painting focused more on its emotional impact, rather than its technical components.",
	"inaudible_0": "Due to the heavy rain, their conversation became inaudible from my position on the porch.",
	"inaudible_1": "The actors' dialogue was almost inaudible because of the faulty sound system in the theater.",
	"inaudible_2": "The detective tried to analyze the inaudible whispers on the secret recording.",
	"inaudible_3": "He muttered something under his breath that was inaudible to everyone in the room.",
	"inaudible_4": "The inaudible hum of the refrigerator became noticeable only when it suddenly stopped.",
	"pretest_0": "Before the actual experiment began, the researchers conducted a pretest to ensure the validity of their studies.",
	"pretest_1": "The teacher decided to give the students a pretest to gauge their understanding of the material.",
	"pretest_2": "Most driving lessons start with a pretest to assess your basic knowledge and skills behind the wheel.",
	"pretest_3": "The survey team conducted a pretest to check whether the questionnaire was clear and easy to understand for the participants.",
	"pretest_4": "The training course recommended a pretest to identify the areas in which employees needed further instruction.",
	"pretest_5": "",
	"crosstalk_0": "Crosstalk between departments in the office can sometimes lead to miscommunication and confusion.",
	"crosstalk_1": "The crosstalk between the wires was causing disruptions in the signal.",
	"crosstalk_2": "Crosstalk in the mechanism of cellular processes can lead to the development of diseases.",
	"crosstalk_3": "The engineer mentioned that he needed to reduce the crosstalk in the circuit board to improve its performance.",
	"crosstalk_4": "The scientists discovered a crosstalk between the two proteins which was previously unknown.",
	"altar_0": "The couple exchanged vows at the altar in a beautiful church ceremony.",
	"altar_1": "In ancient times, people offered sacrifices at the altar to appease their gods.",
	"altar_2": "The altar was adorned with flowers and candles for the religious festival.",
	"altar_3": "He knelt before the altar in a moment of prayer and reflection.",
	"altar_4": "The restoration of the old cathedral included the intricate work on its historic altar.",
	"anthropologist_0": "The anthropologist spent years living with the tribe to understand their customs and language.",
	"anthropologist_1": "Her dissertation, praised by many, offered a new perspective in cultural anthropology.",
	"anthropologist_2": "As an anthropologist, he is particularly interested in the evolution of human social behaviors.",
	"anthropologist_3": "The museum's exhibit on ancient civilizations was curated by a renowned anthropologist.",
	"anthropologist_4": "She lectures on biological anthropology, focusing on the physical development of humans over time.",
	"bunker_0": "During the war, the soldiers took shelter in a heavily fortified bunker.",
	"bunker_1": "The golfer skillfully hit the ball out of the sand bunker and onto the green.",
	"bunker_2": "The emergency supplies were stored in an underground bunker for safety.",
	"bunker_3": "He built a bunker in his backyard as a precaution against natural disasters.",
	"bunker_4": "The oil storage facility included several large bunkers for holding reserves.",
	"cane_0": "The elderly gentleman leaned on his cane as he walked through the park.",
	"cane_1": "She crafted a beautiful basket from thin strips of cane.",
	"cane_2": "After his injury, he had to use a cane for support while walking.",
	"cane_3": "The artisan demonstrated how to bend cane for furniture making.",
	"cane_4": "In the old days, teachers sometimes used a cane to discipline students.",
	"cement_0": "The workers mixed the cement carefully before laying the foundation for the new building",
	"cement_1": "Cement is a key ingredient in the construction of durable, modern structures.",
	"cement_2": "Over the years, they cemented their friendship through shared experiences and mutual respect.",
	"cement_3": "The company plans to cement its position in the market with its innovative new product line.",
	"cement_4": "Historical events can cement certain locations as significant landmarks.",
	"certify_0": "The course will certify you as a first aid responder upon successful completion.",
	"certify_1": "All our products are certified organic, ensuring the highest quality standards.",
	"certify_2": "The government agency must certify that the building is safe for occupancy.",
	"certify_3": "After completing the training, she was officially certified as a yoga instructor.",
	"certify_4": "The document was certified by a notary public, verifying its authenticity.",
	"collaborate_0": "The two companies decided to collaborate on a joint venture to expand their market reach.",
	"collaborate_1": "Artists from various genres collaborate to create a unique fusion of musical styles.",
	"collaborate_2": "Scientists around the world collaborate to find solutions to climate change.",
	"collaborate_3": "We encourage our team members to collaborate to enhance creativity and efficiency.",
	"collaborate_4": "She will collaborate with renowned authors on her upcoming book project.",
	"compost_0": "We started a compost pile in our backyard to recycle kitchen scraps into nutrient-rich soil.",
	"compost_1": "Composting is an effective way to reduce household waste and contribute to a greener environment.",
	"compost_2": "She added compost to her garden to improve soil fertility and promote healthy plant growth.",
	"compost_3": "The community garden offers compost bins for local residents to dispose of their organic waste.",
	"compost_4": "Educational workshops on composting help people learn how to turn their food waste into valuable compost for gardening.",
	"cylinder_0": "The engine of the car has four cylinders, providing efficient power and performance.",
	"cylinder_1": "To calculate the volume of the cylinder, you need to know its radius and height.",
	"cylinder_2": "She placed the flowers in a tall, glass cylinder vase on the dining table.",
	"cylinder_3": "The ancient scroll was stored in a protective cylinder made of bronze.",
	"cylinder_4": "During the science experiment, they measured the gas pressure inside a sealed cylinder.",
	"disclosure_0": "The company's annual report includes a full disclosure of its financial activities.",
	"disclosure_1": "Before signing the contract, they insisted on full disclosure of all potential risks involved.",
	"disclosure_2": "The whistleblower's disclosure of confidential information sparked a major scandal.",
	"disclosure_3": "Her disclosure about the project's challenges helped the team prepare better.",
	"disclosure_4": "In the interest of transparency, the government has made a policy of routine disclosure of such data.",
	"duration_0": "The duration of the flight from New York to London is approximately seven hours.",
	"duration_1": "He signed a contract for a duration of three years with the company.",
	"duration_2": "The medicine provides relief for a duration of up to 12 hours.",
	"duration_3": "During the duration of the course, students will learn various aspects of web design.",
	"duration_4": "The concert was amazing, but I was surprised by its short duration.",
	"enduring_0": "The enduring beauty of the ancient monument continues to attract tourists from all over the world.",
	"enduring_1": "Her enduring commitment to environmental conservation is truly inspiring.",
	"enduring_2": "Despite the changing trends, the enduring popularity of classical music remains strong.",
	"enduring_3": "He shared an enduring friendship with his college roommate, lasting over 50 years.",
	"enduring_4": "The novel's enduring theme of love and sacrifice resonates with readers of all ages.",
	"erect_0": "The construction crew worked tirelessly to erect the new skyscraper in the city center.",
	"erect_1": "During the ceremony, a statue was erected in honor of the national hero.",
	"erect_2": "The children managed to erect a tent for camping in the backyard all by themselves.",
	"erect_3": "He stood erect, maintaining good posture during the long speech.",
	"erect_4": "The ancient Romans erected many impressive buildings and monuments, some of which still stand today.",
	"firing_0": "The company faced controversy after the unfair firing of several employees.",
	"firing_1": "During the pottery class, we learned about the firing process to harden the clay.",
	"firing_2": "The sound of firing could be heard in the distance, signaling the start of the hunting season.",
	"firing_3": "The engine is not starting because one of the cylinders is not firing properly.",
	"firing_4": "He received a warning for firing questions at the speaker without waiting his turn.",
	"fracture_0": "The X-ray revealed a small fracture in her wrist from the fall.",
	"fracture_1": "Geologists study rock formations to understand the history of fractures and shifts in the Earth's crust.",
	"fracture_2": "His leg fracture required a cast for six weeks to heal properly.",
	"fracture_3": "The accident caused a hairline fracture in the car's windshield.",
	"fracture_4": "The team's unity began to fracture under the pressure of continuous losses.",
	"generic_0": "Many people prefer generic brands because they offer similar quality at a lower price.",
	"generic_1": "The doctor prescribed a generic version of the medicine, which was just as effective as the branded one.",
	"generic_2": "Her speech was filled with generic statements that could apply to any situation.",
	"generic_3": "The store offers a wide range of generic products, from food items to cleaning supplies.",
	"generic_4": "He criticized the movie for its generic plot and predictable characters.",
	"grass-roots_0": "The candidate's success was largely due to a strong grass-roots campaign in rural areas.",
	"grass-roots_1": "This environmental movement started at the grass-roots level and quickly spread nationwide.",
	"grass-roots_2": "She is conducting a series of grass-roots workshops to educate local communities about renewable energy.",
	"grass-roots_3": "The organization prides itself on its grass-roots approach to tackling social issues.",
	"grass-roots_4": "Grass-roots activism has played a vital role in bringing about political change in the region.",
	"gratitude_0": "She expressed her deep gratitude to those who had supported her during difficult times.",
	"gratitude_1": "A simple act of kindness can often be met with immense gratitude.",
	"gratitude_2": "He wrote a heartfelt letter of gratitude to his mentor for all the guidance and support.",
	"gratitude_3": "In a gesture of gratitude, the community organized a surprise party for the retiring teacher.",
	"gratitude_4": "Feeling a sense of gratitude for the small things in life can greatly enhance one's happiness.",
	"gravel_0": "The driveway was covered with a layer of smooth gravel, crunching underfoot as we walked.",
	"gravel_1": "During the construction, large trucks delivered loads of gravel to stabilize the foundation.",
	"gravel_2": "The garden path, lined with flowers, was made of fine gravel, giving it a rustic look.",
	"gravel_3": "She could hear the distinct sound of tires on gravel as the car approached the remote cabin.",
	"gravel_4": "They decided to use gravel as a low-maintenance and affordable option for the backyard landscaping.",
	"hitter_0": "The baseball team's new recruit is a powerful hitter, consistently hitting home runs.",
	"hitter_1": "As a lead-off hitter, she always sets a positive tone for the game with her consistent performance.",
	"hitter_2": "The coach praised him as the most improved hitter of the season.",
	"hitter_3": "In his prime, he was known as one of the league's most feared hitters.",
	"hitter_4": "She's not just a strong hitter; her defensive skills are equally impressive.",
	"homosexuality_0": "The documentary explores the history of homosexuality and its societal perceptions over the centuries.",
	"homosexuality_1": "She wrote her thesis on the portrayal of homosexuality in modern literature.",
	"homosexuality_2": "Debates about the rights of individuals based on their homosexuality are prominent in many countries.",
	"homosexuality_3": "The study aimed to understand the genetic and environmental factors influencing homosexuality.",
	"homosexuality_4": "Their support group provides a safe space for discussions about homosexuality and identity.",
	"imitate_0": "Children often imitate their parents' behavior, learning by observation.",
	"imitate_1": "The artist tried to imitate the style of the famous painter in his own work.",
	"imitate_2": "In order to understand bird calls, the researcher learned to imitate their sounds accurately.",
	"imitate_3": "Fashion trends frequently imitate styles from previous decades.",
	"imitate_4": "The comedian is well-known for his ability to imitate famous celebrities' voices.",
	"inadequate_0": "The funding provided to the school was inadequate to cover all the necessary expenses.",
	"inadequate_1": "Her preparation for the exam was inadequate, leading to a lower score than expected.",
	"inadequate_2": "The small apartment felt inadequate for a family of five.",
	"inadequate_3": "Despite his efforts, he felt inadequate to the challenge he was facing.",
	"inadequate_4": "The report criticized the inadequate safety measures in place at the construction site.",
	"inflict_0": "The storm inflicted severe damage on the coastal towns.",
	"inflict_1": "He didn't want to inflict pain on anyone, even in self-defense.",
	"inflict_2": "The harsh penalties inflicted by the court were controversial.",
	"inflict_3": "Environmental pollution can inflict long-term harm on ecosystems.",
	"inflict_4": "The novel vividly describes the emotional turmoil inflicted by the war on soldiers and civilians alike.",
	"lender_0": "The borrower was grateful to find a lender who offered reasonable interest rates.",
	"lender_1": "As a reputable lender, the bank ensures a transparent loan process for its clients.",
	"lender_2": "The small business turned to an online lender to secure the necessary funding for expansion.",
	"lender_3": "She consulted with several lenders before choosing the mortgage plan that best suited her needs.",
	"lender_4": "The government acted as a lender of last resort during the financial crisis to stabilize the economy.",
	"linear_0": "The graph shows a linear relationship between temperature and pressure.",
	"linear_1": "Her progress in the course was not linear, but she improved significantly over time.",
	"linear_2": "The story is told in a linear fashion, starting from the protagonist's childhood and moving chronologically forward.",
	"linear_3": "In mathematics, a linear equation is an equation that forms a straight line when graphed.",
	"linear_4": "The design of the building features linear architecture, with strong horizontal and vertical lines.",
	"litigation_0": "The company decided to settle the dispute out of court to avoid costly litigation.",
	"litigation_1": "She specializes in commercial litigation, representing clients in complex business disputes.",
	"litigation_2": "The litigation process can be lengthy and requires careful preparation of legal documents.",
	"litigation_3": "They won the litigation, resulting in a significant award of damages.",
	"litigation_4": "The new legislation aims to reduce frivolous litigation and streamline the legal process.",
	"loosely_0": "The story in the film is loosely based on a true event, with several fictional elements added.",
	"loosely_1": "Tie the rope loosely around the post, so it's easy to release when needed.",
	"loosely_2": "Their group is loosely organized, without a strict hierarchy or formal roles.",
	"loosely_3": "The novel is loosely structured, consisting of various unrelated vignettes.",
	"loosely_4": "She wore a scarf loosely draped over her shoulders, giving her a casual look.",
	"marina_0": "We spent the afternoon walking along the marina, admiring the beautiful yachts.",
	"marina_1": "The marina is always busy during the summer with tourists and boating enthusiasts.",
	"marina_2": "They docked their boat at the marina before heading into town for dinner.",
	"marina_3": "The new marina development includes shops, restaurants, and a waterfront promenade.",
	"marina_4": "She loves painting the scenic view of the marina at sunset.",
	"maturity_0": "His maturity is evident in the way he handles complex situations with calm and wisdom.",
	"maturity_1": "The bonds will reach full maturity in 10 years, at which point they can be cashed in.",
	"maturity_2": "As she grew older, she gained a level of emotional maturity that helped her in her relationships.",
	"maturity_3": "The wine will reach its peak of maturity in about five years, making it perfect for aging.",
	"maturity_4": "The young athlete showed a level of maturity beyond his years both on and off the field.",
	"mimic_0": "The comedian was able to mimic the president's voice perfectly, making the audience laugh.",
	"mimic_1": "In the experiment, the robots were programmed to mimic human movements.",
	"mimic_2": "Many songbirds can mimic the sounds of other species and even man-made noises.",
	"mimic_3": "She has a unique talent to mimic accents, making her a versatile actor.",
	"mimic_4": "The new software can mimic the decision-making process of a human expert.",
	"motel_0": "We stayed at a cozy motel by the beach during our vacation.",
	"motel_1": "The motel room had a comfortable bed and a nice view of the ocean.",
	"motel_2": "The motel offered free Wi-Fi and continental breakfast for guests.",
	"motel_3": "I called the motel to make a reservation for next weekend.",
	"motel_4": "The motel's swimming pool was a great place to relax in the evenings.",
	"mule_0": "The farmer used a mule to plow the fields on his farm.",
	"mule_1": "A stubborn mule refused to move, causing a delay in the work.",
	"mule_2": "The mule carried heavy loads up the steep mountain trail.",
	"mule_3": "Mules are known for their strength and endurance.",
	"mule_4": "The old man rode his mule into town to buy supplies.",
	"odor_0": "The odor of fresh flowers filled the room.",
	"odor_1": "A foul odor was coming from the garbage bin.",
	"odor_2": "The perfume had a pleasant and lingering odor.",
	"odor_3": "She detected an unusual odor in the kitchen.",
	"odor_4": "The odor of cinnamon and spices made the kitchen inviting.",
	"ozone_0": "Ozone in the atmosphere protects us from harmful ultraviolet rays.",
	"ozone_1": "The depletion of ozone in the ozone layer is a serious environmental issue.",
	"ozone_2": "We need to take steps to reduce pollution to protect the ozone layer.",
	"ozone_3": "An ozone generator helps purify the air in our home.",
	"ozone_4": "The smell of ozone after a thunderstorm is refreshing.",
	"paradigm_0": "The shift in technology brought about a new paradigm in the industry.",
	"paradigm_1": "The scientist presented a groundbreaking paradigm for understanding the universe.",
	"paradigm_2": "The traditional paradigm of education is evolving with online learning.",
	"paradigm_3": "The company adopted a customer-centric paradigm to improve its products.",
	"paradigm_4": "Changing the old paradigm of thinking can lead to innovation.",
	"pastoral_0": "The pastoral scenery of the countryside was breathtaking.",
	"pastoral_1": "The pastor delivered a pastoral message of hope and faith.",
	"pastoral_2": "She longed for a quiet and pastoral life in the village.",
	"pastoral_3": "The painting depicted a pastoral scene with grazing sheep.",
	"pastoral_4": "The pastoral symphony was inspired by rural life and nature.",
	"poise_0": "Her poise and confidence impressed everyone at the presentation.",
	"poise_1": "Maintaining poise under pressure is a valuable skill.",
	"poise_2": "She walked with grace and poise, turning heads wherever she went.",
	"poise_3": "The ballet dancer performed with perfect poise and balance.",
	"poise_4": "His poise in handling difficult situations earned him respect.",
	"posture_0": "Good posture is essential for a healthy back.",
	"posture_1": "Sitting up straight can improve your posture and appearance.",
	"posture_2": "The yoga instructor emphasized the importance of proper posture.",
	"posture_3": "He corrected his posture to alleviate his back pain.",
	"posture_4": "Slouching can lead to poor posture and back problems.",
	"premiere_0": "The movie's premiere was attended by many celebrities.",
	"premiere_1": "The play had its world premiere in a famous theater.",
	"premiere_2": "I can't wait to see the premiere of the new TV series.",
	"premiere_3": "The band's album will have its premiere on the radio tonight.",
	"premiere_4": "The premiere of the art exhibition was a great success.",
	"prone_0": "Some people are prone to allergies during the spring.",
	"prone_1": "He is prone to making impulsive decisions without thinking.",
	"prone_2": "Being prone to sunburn, she always applies sunscreen.",
	"prone_3": "The wet road made driving prone to accidents.",
	"prone_4": "She is prone to mood swings when she's stressed.",
	"prophet_0": "The prophet predicted a bountiful harvest for the village.",
	"prophet_1": "Many people regarded him as a prophet of peace and wisdom.",
	"prophet_2": "The prophet's teachings inspired a religious movement.",
	"prophet_3": "It's said that the prophet received divine revelations.",
	"prophet_4": "The prophet's prophecies about the future were uncanny.",
	"quota_0": "Each student has a quota of two library books at a time.",
	"quota_1": "The company set a quota for the number of products to be sold this month.",
	"quota_2": "They exceeded their sales quota for the quarter.",
	"quota_3": "The government imposed import quotas on certain goods.",
	"quota_4": "Meeting the daily quota of tasks was a challenge.",
	"registered_0": "The company is registered as a legal entity.",
	"registered_1": "Only registered voters are eligible to cast their ballots.",
	"registered_2": "She registered her trademark to protect her brand.",
	"registered_3": "The event has a registered attendance of over 500 participants.",
	"registered_4": "He registered for the conference online.",
	"renaissance_0": "The Renaissance was a period of great cultural and artistic flourishing.",
	"renaissance_1": "The Renaissance brought about a revival of interest in classical literature.",
	"renaissance_2": "Many famous artists emerged during the Renaissance.",
	"renaissance_3": "The Renaissance marked a transition from medieval to modern times.",
	"renaissance_4": "The architecture of the Renaissance era is renowned for its beauty.",
	"resistant_0": "Some bacteria have become resistant to antibiotics.",
	"resistant_1": "The material used for the jacket is water-resistant.",
	"resistant_2": "She is resistant to change and prefers routine.",
	"resistant_3": "The plant is highly resistant to pests and diseases.",
	"resistant_4": "Resistant strains of crops can withstand harsh weather.",
	"rigorous_0": "The training program is known for its rigorous physical exercises.",
	"rigorous_1": "She followed a rigorous study schedule to prepare for the exam.",
	"rigorous_2": "The company has a rigorous quality control process.",
	"rigorous_3": "The rigorous testing revealed flaws in the product design.",
	"rigorous_4": "His rigorous work ethic contributed to his success.",
	"salvation_0": "Many people find hope and salvation through religion.",
	"salvation_1": "The lifeguard's quick response was a true salvation for the drowning swimmer.",
	"salvation_2": "Donating food to the hungry is an act of salvation.",
	"salvation_3": "She believed that education was her path to salvation.",
	"salvation_4": "The charity's mission is to provide salvation to those in need.",
	"sermon_0": "The priest delivered a powerful sermon on forgiveness.",
	"sermon_1": "The sermon emphasized the importance of love and compassion.",
	"sermon_2": "The congregation listened attentively to the Sunday sermon.",
	"sermon_3": "The sermon touched the hearts of all who heard it.",
	"sermon_4": "He prepared a sermon for the special Christmas service.",
	"shopper_0": "The shopper filled her cart with groceries for the week.",
	"shopper_1": "Black Friday is known for its massive crowds of shoppers.",
	"shopper_2": "The shopper compared prices before making a purchase.",
	"shopper_3": "Online shoppers can find great deals and discounts.",
	"shopper_4": "She's an enthusiastic shopper and enjoys browsing stores.",
	"span_0": "The bridge has a long span that stretches across the river.",
	"span_1": "The athlete's impressive jump covered a wide span.",
	"span_2": "The project spanned several years and required a lot of effort.",
	"span_3": "Her career spanned multiple industries and roles.",
	"span_4": "The book series spans several generations of characters.",
	"spectacle_0": "The fireworks display was a breathtaking spectacle.",
	"spectacle_1": "The circus is known for its spectacular performances.",
	"spectacle_2": "The eclipse was a rare natural spectacle.",
	"spectacle_3": "The parade was a colorful and festive spectacle.",
	"spectacle_4": "The concert was a musical spectacle with elaborate staging.",
	"stark_0": "The contrast between the rich and the poor was stark.",
	"stark_1": "The desert landscape was stark and barren.",
	"stark_2": "The room was decorated in stark white and black colors.",
	"stark_3": "The reality of the situation was stark and unsettling.",
	"stark_4": "His stark honesty sometimes made people uncomfortable.",
	"stein_0": "He raised his beer stein and toasted to a great night.",
	"stein_1": "The bar had a collection of decorative steins on the shelves.",
	"stein_2": "The traditional German stein is made of ceramic or glass.",
	"stein_3": "The engraved stein was a gift from his grandfather.",
	"stein_4": "She collected antique beer steins as a hobby.",
	"strategist_0": "The military strategist devised a brilliant battle plan.",
	"strategist_1": "A good strategist anticipates the moves of their opponents.",
	"strategist_2": "She is a marketing strategist with a keen understanding of consumer behavior.",
	"strategist_3": "The chess strategist carefully considered each move.",
	"strategist_4": "The business strategist helped the company achieve its goals.",
	"swift_0": "The swift river current made swimming dangerous.",
	"swift_1": "The cheetah is known for its swift speed when hunting.",
	"swift_2": "He made a swift decision to accept the job offer.",
	"swift_3": "The swift delivery of the package impressed the customer.",
	"swift_4": "The swift response of the firefighters saved the building from burning down.",
	"theorist_0": "The theorist proposed a new theory of relativity.",
	"theorist_1": "The economic theorist analyzed the market trends.",
	"theorist_2": "She is a prominent political theorist known for her books.",
	"theorist_3": "The theorist's ideas revolutionized the field of psychology.",
	"theorist_4": "The scientific theorist conducted experiments to test the hypothesis.",
	"thereafter_0": "They lived happily ever after, and thereafter they were inseparable.",
	"thereafter_1": "The company's profits declined, and thereafter, layoffs began.",
	"thereafter_2": "The law was enacted in 2005, and thereafter, it was enforced strictly.",
	"thereafter_3": "He won the championship, and thereafter, his fame grew.",
	"thereafter_4": "They got married, and thereafter, they started a family.",
	"tumble_0": "The child took a tumble but quickly got up and continued playing.",
	"tumble_1": "The stock market took a tumble due to economic uncertainty.",
	"tumble_2": "She lost her balance and took a tumble down the stairs.",
	"tumble_3": "The snowboarder attempted a risky trick and took a tumble.",
	"tumble_4": "The gymnast performed a somersault and finished with a perfect tumble.",
	"turf_0": "The football players practiced on the turf field.",
	"turf_1": "The debate over territory led to a turf war between two gangs.",
	"turf_2": "She mowed the grass on her front lawn to maintain the turf.",
	"turf_3": "The company specializes in artificial turf installation.",
	"turf_4": "The turf in the park was soft and comfortable to walk on.",
	"untypical_0": "His behavior was untypical of him; usually, he's very reserved.",
	"untypical_1": "The warm weather in December was untypical for this region.",
	"untypical_2": "Her taste in music was untypical for someone her age.",
	"untypical_3": "This case is untypical and presents unique challenges.",
	"untypical_4": "The company's decision was untypical of their usual practices.",
	"unwind_0": "She liked to unwind by reading a good book in the evening.",
	"unwind_1": "After a long day at work, he needed to unwind and relax.",
	"unwind_2": "The spa offered various treatments to help guests unwind.",
	"unwind_3": "Taking a leisurely walk in the park helped her unwind from stress.",
	"unwind_4": "The vacation was a perfect opportunity to unwind and recharge."
};